<template>
  <a-select v-model="type" v-bind="props" @change="dispatchEvent" @focus="handleFocus">
    <a-select-option :value="option.value" v-for="option in userOptions" :key="option.value">
      <open-data type="userName" :openid="option.value" />
    </a-select-option>
  </a-select>
</template>

<script>
import { clone, isEmpty } from "../common/js/tool";

export default {
  name: "RoleSelect",
  props: {
    value: [Array, Number, String],
    props: {
      type: Object,
      default: () => {
        return {
          placeholder: "请选择",
          allowClear: true,
        };
      },
    },
    system: {
      type: Number,
      default: 2, // 默认人事系统
    },
    privilege: {
      type: Number,
    },
    options: Array,
  },
  data() {
    return {
      type: undefined,
      userOptions: [],
    };
  },
  watch: {
    value() {
      this.setValue();
    },
    options() {
      this.getGroupLeader();
    },
  },
  created() {
    this.setValue();
    this.getGroupLeader();
  },
  methods: {
    getGroupLeader() {
      let options = this.options;
      if (options) {
        this.userOptions = clone(options);
      } else {
        this.$axios({
          url: "/admin/user-role",
          method: "GET",
          params: {
            sort: "-id",
            pageSize: 9999,
            "filter[system]": this.system,
            "filter[privilege]": this.privilege,
          },
        }).then((res) => {
          this.userOptions = [];
          res.data?.forEach((item) => {
            if (item.user_id) {
              item.user_id = JSON.parse(item.user_id);
              item.user_id?.forEach((id) => {
                this.userOptions.push({ ...item, value: id });
              });
            }
          });
        });
      }
    },
    setValue() {
      let value = this.value;
      if (isEmpty(value)) {
        this.type = undefined;
      } else {
        this.type = clone(value);
      }
    },
    dispatchEvent(e) {
      let type = this.type;
      if (isEmpty(type)) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", type);
      }
      this.$emit("change", e);
    },
    handleFocus(e) {
      this.$emit("focus", e);
    },
    encapsulationListeners(listeners) {
      let res = {};
      for (let key in listeners) {
        let handler = listeners[key];
        res[key] = ($event) => {
          handler.call(this, $event);
        };
      }
      return res;
    },
  },
};
</script>

<style lang="less" scoped></style>
