export const dealTimeRangeList = function (list) {
    list.forEach(item => {
        if(item.time_slot) {
            try {
                let conf = JSON.parse(item.time_slot);
                if(conf.range) {
                    conf.range = conf.range.map(r => {
                        let temp = r.split("-");
                        return {
                            start: temp[0],
                            end: temp[1]
                        }
                    })
                }
                for(let key in conf) {
                    item[key] = conf[key]
                }
            } catch (e) {
                console.error(e)
            }
        }
    })
}

export default {
    name: '预约时间段',
    url: '/admin/fs-datelist', // rest接口表名
    query: '', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '名称', dataIndex: 'name'},
            {title: '时间段', dataIndex: 'range', customRender: text => text.map(r => `${r.start}-${r.end}`).join(" ")},
            {title: '说明', dataIndex: 'desc', customRender: text => text || '--'},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入名称查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '名称',
            component: 'a-input',
            props: {
                placeholder: '请输入名称',
            },
            rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
        },
        {
            key: 'range',
            label: '时间段',
            component: 'time-period',
            rules: [{ required: true, message: '请设置时间段', trigger: 'change' }]
        },
        {
            key: 'desc',
            label: '说明',
            component: 'a-input',
            props: {
                placeholder: '时间段说明',
            }
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '名称',
                key: 'name',
            },
            {
                label: '时间段',
                slot: entity => entity.range.map(r => <p>{r}</p>)
            },
            {
                label: '说明',
                key: 'desc',
            },
        ]
    },
    dealList: (list, fn) => {
        dealTimeRangeList(list);
        fn();
    },
    beforeUpdate: function (form) {
        return new Promise(resolve => {
            let conf = {range: form.range.map(r => `${r.start}-${r.end}`)};
            if(form.desc) {
                conf.desc = form.desc;
            }
            form.time_slot = JSON.stringify(conf);
            resolve(form);
        })
    }
}
