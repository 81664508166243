import wx from "wx";
import axios from "./req";
import {getStorageInfo} from "./storage";

const wxConfig = function (api) {
    return new Promise((resolve, reject) => {
        const temple = getStorageInfo().temple;
        axios({
            url: '/admin/live/jssdk',
            method: 'POST',
            data: {
                url: location.href,
                jsApiList: api
            }
        }).then(res => {
            if(typeof res === "string") {
                res = JSON.parse(res);
            }
            let ticket = res;
            wx.agentConfig({
                corpid: temple.crop_id, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: temple.agent_id, // 必填，企业微信的应用id （e.g. 1000247）
                timestamp: ticket.timestamp, // 必填，生成签名的时间戳
                nonceStr: ticket.nonceStr, // 必填，生成签名的随机串
                signature: ticket.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                jsApiList: api, //必填，传入需要使用的接口名称
                success: () => {
                    resolve();
                },
                fail: function(res) {
                    if(res.errMsg.indexOf('function not exist') > -1){
                        reject('版本过低请升级')
                    } else {
                        reject(res);
                    }
                }
            });
        })
    })

}
export {
    wxConfig
}
