const state = {
    imgList: [],
    current: null,
    viewerShow: false,
    viewVideo: null
};

// getters
const getters = {
    imgList: state => state.imgList,
    current: state => state.current,
    viewerShow: state => state.viewerShow,
    viewVideo: state => state.viewVideo,
};

// mutations
const mutations = {
    setImgList(state, value) {
        state.imgList = value;
    },
    setCurrent(state, value) {
        state.current = value;
    },
    setViewerShow(state, value) {
        state.viewerShow = value;
    },
    setViewVideo(state, video) {
        state.viewVideo = video;
    },
};

const actions = {
    showImgList(context, list, current) {
        current = current || list[0].url;
        context.commit("setImgList", list);
        context.commit("setCurrent", current)
        context.commit("setViewerShow", true);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
