<template>
    <div class="live-config">
        <a-button class="live-config-add" @click="showModal"><a-icon type="plus"/>添加直播</a-button>
        <drag-zone :list="list" item-class="live-config-item" class="live-config-list">
            <template v-slot="{item, index}">
                <div class="live-config-img" :style="{backgroundImage: `url(${item.img})`}"></div>
                <div class="live-config-title">{{item.title}}</div>
                <div class="live-config-time" v-if="item.time">{{`${item.time[0]} - ${item.time[1]}`}}</div>
                <div class="live-config-operate">
                    <div class="txt-btn danger" @click="delLive(index)">删除</div>
                    <a-divider type="vertical" />
                    <div class="txt-btn" @click="editLive(index)">修改</div>
                </div>
            </template>
        </drag-zone>
        <a-modal
            title="添加直播"
            :maskClosable="false"
            :closable="false"
            :width="600"
            :footer="null"
            @ok="confirmLive"
            v-model="visible">
            <form-area
                ref="liveForm"
                class="flex-box vertical"
                layout="horizontal"
                :label-col="{span: 4}"
                :wrapper-col="{span: 20}"
                :items="items"
                :entity="form"
                @cancel="cancel()"
                @confirm="confirmLive"></form-area>
        </a-modal>
    </div>
</template>

<script>
    import dragZone from './drag-zone'
    import {clone} from "../common/js/tool";
    export default {
        name: "live-config",
        model: {
            event: 'change'
        },
        components: {
            dragZone
        },
        props: {
            value: Array
        },
        data() {
            return {
                list: [],
                items: [
                    {
                        key: 'title',
                        label: '直播标题',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入直播标题',
                        },
                        rules: [{ required: true, message: '请输入直播标题', trigger: 'blur' }]
                    },
                    {
                        key: 'img',
                        label: '直播封面',
                        component: 'form-upload',
                        rules: [{ required: true, message: '请上传直播封面', trigger: 'change' }]
                    },
                    {
                        key: 'time',
                        label: '直播时间',
                        component: 'a-range-picker',
                        props: {
                            showTime: {format: 'HH:mm'},
                            format: 'YYYY-MM-DD HH:mm',
                            valueFormat: 'YYYY-MM-DD HH:mm',
                            placeholder: ['开始时间', '结束时间']
                        },
                        rules: [{ required: true, message: '请设置直播时间', trigger: 'change' }]
                    },
                    {
                        key: 'url',
                        label: '直播链接',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入直播HLS播放链接',
                        },
                        rules: [{ required: true, message: '请输入直播链接', trigger: 'blur' }]
                    },
                    {
                        key: 'video',
                        label: '录像链接',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入直播录像HLS播放链接',
                        },
                        rules: [{ required: true, message: '请输入直播链接', trigger: 'blur' }]
                    },
                ],
                form: {},
                visible: false,
            }
        },
        watch: {
            value() {
                this.setList();
            }
        },
        created() {
            this.setList();
        },
        methods: {
            setList() {
                let value = this.value;
                value && (this.list = clone(value));
            },
            dispatchInput() {
                let list = this.list;
                this.$emit("change", list && list.length > 0 ? list : null);
            },
            editLive(index) {
                this.form = clone(this.list[index]);
                this.visible = true;
            },
            delLive(index) {
                this.list.splice(index, 1);
                this.dispatchInput();
            },
            cancel() {
                this.visible = false;
                this.form = {};
            },
            confirmLive(live) {
                // 编辑
                if(live.key) {
                    let i = this.list.findIndex(l => l.key == live.key);
                    this.$set(this.list, i, live);
                } else {
                    live.key = Date.now();
                    this.list.push(live);
                }
                this.cancel();
                this.dispatchInput();
            },
            showModal() {
                this.form = {};
                this.visible = true;
            }
        }
    }
</script>

<style lang="less">
    .live-config-item {
        display: inline-block;
        margin: 10px 10px 0 0;
        padding: 10px;
        width: 262px;
        border: var(--border);
        border-radius: 4px;
        line-height: 1;
    }
    .live-config-img {
        position: relative;
        width: 240px;
        height: 135px;
        background: center no-repeat;
        background-size: cover;

    }
    .live-config-title {
        margin-top: 6px;
        line-height: 1.5;
    }
    .live-config-time {
        margin-top: 6px;
    }
    .live-config-operate {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding-top: 10px;
        border-top: var(--border);
        .txt-btn {
            flex: 1;
            text-align: center;
        }
    }
</style>
