<template>
  <div class="purchase-select">
    <div class="purchase-select-content" @click="showModal">
      <slot></slot>
    </div>
    <a-modal
      title="选择申购单"
      :maskClosable="false"
      :closable="false"
      :width="480"
      @cancel="handleCancel"
      @ok="confirmModal"
      v-model="visible">
      <a-table
        row-key="id"
        default-expand-all-rows
        size="middle"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys, onChange: onSelectChange }">
      </a-table>
    </a-modal>
  </div>
</template>

<script>

    import {getUserWxId} from "../../common/js/storage";

    export default {
        name: "purchase-select",
        data() {
            return {
                visible: false,
                list: [],
                columns: [
                    {title: '申购单号', dataIndex: 'subscribe_num'},
                    {title: '商品名称', dataIndex: 'subscribe_goods'},
                    {title: '商品数量', dataIndex: 'num'},
                    {title: '商品状态', dataIndex: 'status', customRender: text => ["未入库", "已入库", "已出库"][text - 1]},
                ],
                selectedRows: []
            }
        },
        computed: {
            selectedRowKeys() {
                return this.selectedRows.map(item => item.id)
            },
        },
        methods: {
            handleCancel() {
                this.visible = false;
            },
            confirmModal() {
                const rows = this.selectedRows;
                this.$emit("change", rows);
                this.visible = false;
            },
            onSelectChange(keys, rows) {
                this.selectedRows = rows;
            },
            showModal() {
                this.visible = true;
                this.selectedRows = [];
                this.getList();
            },
            getList() {
                const url = `/admin/asset-subscribe?pageSize=100&sort=-id&filter[wx_user_id]=${getUserWxId()}&filter[status][neq]=3`;
                this.$axios(url).then(res => {
                    this.list = res.data
                });
            }
        }
    }
</script>

<style lang="less">

</style>
