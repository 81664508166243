import {getStorageInfo} from "../js/storage";
import {getSysList} from "../../permission/subsystem";

export const getRoleTypeText = function (user) {
    user = user || getStorageInfo();
    let res = '普通用户';
    if(user.role == 1) {
        res = '超级管理员';
    } else if(user.userRole && user.userRole.length > 0) {
        if(user.userRole.findIndex(r => r.privilege == 1) >= 0){
            res = '系统管理员';
        } else {
            let role = user.userRole[0];
            let sys = getSysList().find((s => s.id == role.system));
            if(sys) {
                let cr = sys.children.find(c => c.key == role.privilege);
                if(cr) {
                    res = cr.title;
                }
            }
        }
    }
    return res;
}

/**
 * 检查是否具有对应系统的管理员权限
 * @param user
 * @param id 系统id
 * @returns {boolean}
 */
export const checkAdminPermission = function (user, id) {
    user = user || getStorageInfo();
    let res = false;
    if(user.role == 1) {
        res = true;
    } else if(user.userRole && user.userRole.length > 0) {
        res = user.userRole.findIndex(r => r.system == id && r.privilege == 1) >= 0;
    }
    return res;
}

export const getSystemRole = function (sys, user) {
    user = user || getStorageInfo();
    let res = null;
    if(user.role == 1) {
        res = 1;
    } else if(user.userRole && user.userRole.length > 0) {
        let roles = user.userRole.filter(r => r.system == sys);
        if(roles.length > 0) {
            roles.sort((a, b) => a.privilege - b.privilege);
            res = roles[0].privilege;
        }
    }
    return res;
}

/**
 * 判断是否是佛事系统管理员
 * @param user
 * @returns {boolean}
 */
export const isBuddhaAdmin = function (user) {
    user = user || getStorageInfo();
    let res = false;
    if(user.role == 1) {
        res = true;
    } else if(user.userRole && user.userRole.length > 0) {
        res = user.userRole.findIndex(r => r.system == 5 && r.privilege <= 2) >= 0;
    }
    return res;
}

export const getHRRole = function (user) {
    user = user || getStorageInfo();
    let res = 3;
    if(user.role == 1) {
        res = 1;
    } else if(user.userRole && user.userRole.length > 0) {
        let roles = user.userRole.filter(r => r.system == 2);
        if(roles.length > 0) {
            roles.sort((a, b) => a.privilege - b.privilege);
            res = roles[0].privilege;
        }
    }
    return res;
}

export const tagItems = [
    {
        key: 'name',
        label: '标签名称',
        component: 'a-input',
        props: {
            placeholder: '请输入标签名称'
        },
        rules: [{ required: true, message: '请输入标签名称', trigger: 'blur' }]
    },
    {
        key: 'ids',
        label: '部门/成员',
        component: 'select-book',
        props: {
            multiple: true,
            type: "all"
        },
        rules: [{ required: true, message: '请选择标签内的部门/成员', trigger: 'blur' }]
    },
]

export const personItems = [
    {
        key: 'tag',
        label: '标签',
        component: 'select-book',
        props: {
            type: "tag"
        },
    },
]
