/** 评价常量 **/
import {getKeyTitle} from "@/common/js/tool";
import {getConditionNumberText} from "../constant/process";
import {staffTypes} from "./book";
import {clone, isEmpty} from "../js/tool";

export const assCatList = [
    {key: 1, title: '月度评价'},
    {key: 2, title: '季度评价'},
    {key: 3, title: '年度评价'},
]

export const assTypes = [
    {key: 1, title: '个人'},
    {key: 2, title: '部门'},
]

export const assEffectTypes = [
    // {key: 1, title: '立即生效'},
    {key: 2, title: '下次生效'},
]

export const assVisibleTypes = [
    {key: 1, title: '所有被评价人均可查看'},
    {key: 2, title: '仅可查看自己的评价结果'},
]

export const assRuleTypes = [
    {key: 1, title: '主观项'},
    {key: 2, title: '客观项'},
]

export const assRuleCatList = [
    {key: 1, title: '共住精神'},
    {key: 2, title: '仪表语言'},
    {key: 3, title: '岗位纪律'},
    {key: 4, title: '请假病假'},
]

export const assStatusList = [
    {key: 1, title: '启用'},
    {key: 2, title: '禁用'},
]

export const assProgramStatusList = [
    {key: 0, title: '已暂停'},
    {key: 1, title: '使用中'},
]

export const assItemStatus = [
    {key: 0, title: '未完成'},
    {key: 1, title: '已完成'},
    {key: 2, title: '已确认'},
]
export const assListStatus = [
    {key: 0, title: '进行中'},
    {key: 1, title: '已结束'},
]

export const systemAssTypeList = [
    {key: 1, title: '工作响应时间', pre: '平均时间', unit: '分钟'},
    {key: 2, title: '打卡次数', pre: '缺卡次数', unit: '次'},
    {key: 3, title: '外出次数', pre: '外出次数', unit: '次'},
    {key: 4, title: '学习次数', pre: '学习次数', unit: '次'},
    {key: 5, title: '佛事次数', pre: '佛事次数', unit: '次'},
    {key: 6, title: '汇报次数', pre: '汇报次数', unit: '次'},
    {key: 7, title: '僧职告假', pre: '告假次数', unit: '次'},
    {key: 8, title: '加班次数', pre: '加班次数', unit: '次'},
]

export const getAssTypeName = function (type) {
    return getKeyTitle(assTypes, type);
}

export const getAssCatName = function (cat) {
    return getKeyTitle(assCatList, cat);
}

export const getAssEffectTypeName = function (type) {
    return getKeyTitle(assEffectTypes, type);
}

export const getAssRuleType = function (type) {
    return getKeyTitle(assRuleTypes, type);
}

export const getAssRuleCatText = function (cat) {
    return getKeyTitle(assRuleCatList, cat);
}

export const getAssVisibleType = function (type) {
    return getKeyTitle(assVisibleTypes, type);
}

export const getAssStatusName = function (status) {
    return getKeyTitle(assStatusList, status);
}

export const getAssProgramStatusText = function (status) {
    return getKeyTitle(assProgramStatusList, status);
}

export const getAssItemStatusText = function (status) {
    return getKeyTitle(assItemStatus, status);
}

export const getAssListStatusText = function (status) {
    return getKeyTitle(assListStatus, status);
}

export const getSystemAssTypeText = function (type) {
    return getKeyTitle(systemAssTypeList, type);
}

export const getSystemAssTypePre = function (type) {
    return getKeyTitle(systemAssTypeList, type, 'key', 'pre');
}
export const getSystemAssTypeUnit = function (type) {
    return getKeyTitle(systemAssTypeList, type, 'key', 'unit');
}

export const getSysRuleText = function (rule, type, i) {
    return `${i+ 1}. ${getSystemAssTypePre(type)} ${getConditionNumberText(rule.type)} ${(rule.type == 6 ? rule.number.replace(",", "到") : rule.number)} ${getSystemAssTypeUnit(type)}时 ${rule.score} 分`;
}

const assPercentItem = {
    component: 'a-input-number',
    props: {
        min: 0,
        max: 100,
        formatter: value => `${value}%`,
        parser: value => value.replace('%', '')
    },
    style: {
        width: '100%'
    },
}

const assProportionItem = {
    key: 'percent',
    label: '权重',
    ...assPercentItem,
    rules: [{ required: true, message: '请输入权重', trigger: 'blur' }]
}


const percentValidator = (rule, value, callback) => {
    value = Number(value);
    if(isNaN(value) || value < 0) {
        callback(new Error('请输入大于或等于0的数字'));
    } else {
        callback();
    }
}

export const percentControl = {
    key: 'percent',
    label: '单项指标权重',
    component: 'a-input',
    props: {
        placeholder: '请输入单项指标权重',
        suffix: "%"
    },
    span: 12,
    rules: [
        { required: true, message: '请输入单项指标权重', trigger: 'blur' },
        { validator: percentValidator, trigger: 'blur' }
    ]
};

export const normTypeControl = {
    key: 'assType',
    label: '客观项类别',
    component: 'a-select',
    props: {
        placeholder: '请选择客观项类别',
        options: systemAssTypeList
    },
    listeners: {
        change: function (val) {
            let con = this.controls.find(c => c.key === 'normList');
            if(con) {
                this.$set(con.props, 'type', val);
            }
        }
    },
    rules: [{ required: true, message: '请选择客观项类别', trigger: 'change' }]
}

export const normControl = {
    key: 'normList',
    label: '评价标准',
    component: 'ass-sys-rule',
    props: {},
    rules: [{ required: true, message: '请设置评价标准', trigger: 'change' }]
};


export const subjectiveRuleForm = [
    {
        key: 'items',
        label: '评价项',
        component: 'a-textarea',
        props: {
            placeholder: '请输入评价项',
            rows: 4
        },
        rules: [{ required: true, message: '请输入评价项', trigger: 'blur' }]
    },
    {
        key: 'norm',
        label: '评价标准',
        component: 'a-textarea',
        props: {
            placeholder: '请输入评价标准',
            rows: 4
        },
        rules: [{ required: true, message: '请输入评价标准', trigger: 'blur' }]
    },
    {...assProportionItem},
    {
        key: 'selfNum',
        label: '自评',
        ...assPercentItem,
        rules: [{ required: true, message: '请输入自评占比', trigger: 'blur' }]
    },
    {
        key: 'directorNum',
        label: '主管评价',
        ...assPercentItem,
        rules: [{ required: true, message: '请输入主管评价占比', trigger: 'blur' }]
    }
]

export const objectiveRuleForm = [
    { ...normTypeControl },
    { ...assProportionItem },
    { ...normControl }

]

export const assForm = [
    {
        key: 'type',
        label: '评价类型',
        component: 'a-select',
        props: {
            placeholder: '请选择',
            options: assRuleTypes
        },
        rules: [{ required: true, message: '请选择评价类型', trigger: 'change' }]
    }
]

export const scoreForm = [
    {
        label: "分数",
        component: 'a-input-number',
        props: {
            placeholder: '请打分',
            min: 0,
            max: 100
        },
        style: {
            width: '100%'
        },
        rules: [{ required: true, message: '请打分', trigger: 'blur' }]
    },
    {
        label: '评分依据',
        component: 'a-textarea',
        props: {
            placeholder: '请输入评分依据',
            rows: 4
        },
    },
]

// 指标模板基础表单项
export const assTempBaseForm = [
    {
        key: 'name',
        label: '指标名称',
        component: 'a-input',
        props: {
            placeholder: '请输入指标名称',
        },
        span: 12,
        rules: [{ required: true, message: '请输入模板名称', trigger: 'blur' }]
    },
    {
        key: 'type',
        label: '指标类型',
        component: 'a-select',
        props: {
            placeholder: '请选择指标类型',
            options: [...assRuleTypes],
            disabled: true
        },
        span: 12,
        rules: [{ required: true, message: '请选择指标类别', trigger: 'change' }]
    },
    {
        key: 'cat',
        label: '所属大类',
        component: 'a-select',
        props: {
            placeholder: '请选择',
            options: [...assRuleCatList]
        },
        span: 12,
        rules: [{ required: true, message: '请选择所属大类', trigger: 'change' }]
    },
    {
        key: 'group',
        label: '适用对象',
        component: 'a-select',
        props: {
            placeholder: '请选择',
            options: [...staffTypes]
        },
        span: 12,
        rules: [{ required: true, message: '请选择适用对象', trigger: 'change' }]
    },
    { ...clone(percentControl) },
]

// 主观项指标模板其他表单项
export const subjectiveTempExtraForm = [
    // {
    //     ...clone(percentControl),
    //     key: "selfNum",
    //     label: "自评权重",
    //     props: {
    //         placeholder: '请输入自评权重',
    //         suffix: "%"
    //     },
    // },
    // {
    //     ...clone(percentControl),
    //     key: "directorNum",
    //     label: "主管评权重",
    //     props: {
    //         placeholder: '请输入主管评权重',
    //         suffix: "%"
    //     },
    // },
    {
        key: 'detail',
        label: '指标说明',
        component: 'a-textarea',
        props: {
            rows: 4
        }
    }
]

// 客观项指标模板其他表单项
export const objectiveTempExtraForm = [
    {
        key: 'source',
        label: '数据来源',
        component: 'a-input',
        props: {
            placeholder: '请输入数据来源',
        },
        span: 12,
        rules: [{ required: true, message: '请输入数据来源', trigger: 'blur' }]
    },
    {
        ...clone(normTypeControl),
        span: 12,
    },
    {
        ...clone(normControl),
        key: "normList",
        label: "指标说明"
    }
]

const assNormBaseColumns = [
    { title: '序号', dataIndex: 'index', width: 60, customRender: (value, row, index) => index + 1 },
    { title: '所属大类', dataIndex: 'cat', width: 80, customRender: getAssRuleCatText },
    { title: '单项指标权重', dataIndex: 'percent', width: 120, customRender: value => value + "%" },
    { title: '评价指标', dataIndex: 'name', width: 160 },
    { title: '单项总分', dataIndex: 'score', width: 80, customRender: (value, record) => record.percent + '分'},
]

// 获取评价项列表配置 调用需绑定 当前vue实例作用域
export const getAssNormColumns = function (type, editable) {
    // eslint-disable-next-line no-unused-vars
    const h = this.$createElement;
    const columns = [...assNormBaseColumns];
    const length = type == 1 ? this.subjectiveItems.length : this.objectiveItems.length;
    columns.splice(1, 0,{
        title: '类别',
        dataIndex: 'type',
        width: 80,
        customRender: (value, row, index) => {
            return {
                children: getAssRuleType(type),
                attrs: {
                    rowSpan: index == 0 ? length : 0
                }
            }
        }
    });
    let items = [];
    if(type == 1) {
        items = [
            { title: '指标说明', dataIndex: 'detail' },
            // { title: '自评权重', dataIndex: 'selfNum', width: 100, customRender: value => value + "%" },
            // { title: '主管评权重', dataIndex: 'directorNum', width: 100, customRender: value => value + "%" },
        ]
    } else {
        items = [
            {
                title: '指标说明',
                dataIndex: 'normList',
                customRender: (value, row) => value && value.length > 0 ?
                    <div>{ value.map((item, i) => <div>{getSysRuleText(item, row.assType, i)}</div>) }</div> : "无"
            },
        ]
    }
    columns.splice(columns.length - 1, 0, ...items);
    if(editable) {
        columns.push({ title: '操作', dataIndex: 'action', width: 80, scopedSlots: { customRender: 'action'} })
    }
    return columns;
}

export const dealAssTemp = function (info) {
    if(info.config && typeof info.config === 'string') {
        try {
            const config = JSON.parse(info.config);
            for(let key in config) {
                info[key] = config[key];
            }
        } catch (e) {
            console.error(e);
        }
    }
}

export const scoreRender = (text, record) => {
    let res = "客观项自动计算";
    if(record.type == 1) {
        if(isEmpty(text)) {
            res = "未打分"
        } else {
            res = `${text}分`;
        }
    }
    return res;
}

/**
 * 根据评价项 id 或者 key 找到源评价项
 * @param list 评价项列表
 * @param norm 要查找的评价项
 * @param index 是否返回下标
 */
export const getSourceNorm = function (list, norm, index) {
    const i = list.findIndex(n => n.id ? n.id == norm.id : n.key && n.key == norm.key);
    if(!index) {
        return list[i];
    } else {
        return i;
    }
}

/**
 * 判断消息是不是自评消息
 * @param message evaluate_message实体
 * @returns {boolean}
 */
export const isSelfMessage = function (message) {
    return message.wx_user_id == message.user_id || message.is_self == 1;
}

/**
 * 解析评价打分细则
 * @param info evaluate-item 实体信息
 */
export const getAssNormList = function (info) {
    let list = [];
    let message = info.message || [];
    message.forEach(m => {
        m.md = [];
        if (m.detail) {
            try {
                m.md = JSON.parse(m.detail);
            } catch (e) {
                console.error(e);
            }
        }
        m.self = isSelfMessage(m);
    });
    if (info.detail) {
        try {
            let subjectiveList = JSON.parse(info.detail);
            if(message.length > 0) {
                // 自评消息列表
                const selfMsg = message.filter(m => m.self);
                // 主管评价消息列表
                const directorMsg = message.filter(m => !m.self);
                // 自评是否完成
                const selfFinish = selfMsg.length > 0 && selfMsg.every(m => m.status == 1);
                // 主观评是否完成
                const directorFinish = directorMsg.length > 0 && directorMsg.every(m => m.status == 1);
                subjectiveList.forEach((item) => {
                    // 当前评价人评分结束才计算总分
                    if(info.status == 1) {
                        item.score = message.reduce((acc, val) => {
                            const dm = getSourceNorm(val.md, item);
                            if (dm) {
                                let selfScore = dm.selfScore ? dm.selfScore * item.selfNum / 100 : 0;
                                let directorScore = dm.directorScore ? dm.directorScore * item.directorNum / 100 : 0;
                                acc += (selfScore + directorScore) * item.percent / 100;
                            }
                            return acc;
                        }, 0);
                        item.score = Math.round(item.score * 100) / 100;
                    }
                    // 自评结束计算自评分
                    if (selfFinish) {
                        item.selfScore = selfMsg.reduce((acc, val) => {
                            const dm = getSourceNorm(val.md, item);
                            return acc + (dm ? dm.selfScore : 0)
                        }, 0) / selfMsg.length;
                        item.selfScore = Math.round(item.selfScore * 100) / 100;
                    }
                    // 主管评结束计算主管评分
                    if (directorFinish) {
                        item.directorScore = directorMsg.reduce((acc, val) => {
                            const dm = getSourceNorm(val.md, item);
                            return acc + (dm ? dm.directorScore : 0);
                        }, 0) / directorMsg.length;
                        item.directorScore = Math.round(item.directorScore * 100) / 100;
                    }
                });
            }
            list = list.concat(subjectiveList)
        } catch (e) {
            console.log(e)
        }
    }
    if (info.regular_config) {
        try {
            let objectiveList = JSON.parse(info.regular_config);
            let objectiveScore = JSON.parse(info.regular_score);
            if (objectiveScore) {
                objectiveList.forEach((item, i) => {
                    item.score = objectiveScore[i];
                    if(item.normList && item.normList.length > 0) {
                        item.normList.forEach((n, j) => n.showText = getSysRuleText(n, item.assType, j));
                    }
                })
            }
            list = list.concat(objectiveList)
        } catch (e) {
            console.error(e)
        }
    }
    return list;
}
