export const MOBILE_REG = '^1[3-9]\\d{9}$';
export const ID_CARD_REG = '(^[1-9]\\\\d{5}(18|19|([23]\\\\d))\\\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\\\d{3}[0-9Xx]$)|(^[1-9]\\\\d{5}\\\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\\\d{3}$)';

const formItems = [
    {
        title: '文本',
        icon: "icon-text",
        config: {
            component: 'input',
            type: 'text',
            label: '',
            placeholder: '请输入'
        },
        type: 1,
    },
    {
        title: '数字',
        icon: "icon-num",
        config: {
            component: 'input',
            type: 'number',
            label: '',
            placeholder: '请输入'
        },
        type: 2,
    },
    {
        title: '多行文本',
        icon: "icon-text-area",
        config: {
            component: 'input',
            type: 'textarea',
            label: '',
            placeholder: '请输入'
        },
        type: 3,
    },
    {
        title: '单选',
        icon: "icon-select",
        config: {
            component: 'select',
            mode: 'selector',
            label: '',
            placeholder: '请选择',
            options: ['选项一', '选项二'],
        },
        type: 4,
    },
    {
        title: '多选',
        icon: "icon-checkbox",
        config: {
            component: 'multiple-select',
            placeholder: '请选择',
            options: ['选项1', '选项2'],
        },
        type: 12,
    },
    {
        title: '日期',
        icon: "icon-date",
        config: {
            component: 'select',
            mode: 'date',
            label: '日期',
            placeholder: '请选择日期'
        },
        type: 5,
    },
    {
        title: '时间',
        icon: "icon-time",
        config: {
            component: 'select',
            mode: 'time',
            label: '时间',
            placeholder: '请选择时间'
        },
        type: 6,
    },
    {
        title: '地区',
        icon: "icon-district",
        config: {
            component: 'select',
            mode: 'region',
            label: '地区',
            placeholder: '请选择地区'
        },
        type: 14,
    },
    {
        title: '日期时间',
        icon: "icon-date-time",
        config: {
            component: 'date-time',
            label: '日期时间',
            placeholder: '请选择日期时间'
        },
        type: 11,
    },
    {
        title: '上下午',
        icon: "icon-date-half",
        config: {
            component: 'half-date',
            label: '时间',
            placeholder: '请选择时间'
        },
        type: 13,
    },
    {
        title: '身份证号',
        icon: "icon-id-card",
        config: {
            component: 'input',
            type: 'idcard',
            label: '身份证号',
            placeholder: '请输入身份证号码',
        },
        type: 7,
    },
    {
        title: '附件',
        icon: "icon-affix",
        config: {
            component: 'upload',
            label: '',
            limit: 9,
        },
        type: 8,
    },
    {
        title: '成员',
        icon: "icon-user",
        config: {
            component: 'book',
            placeholder: '请选择',
            type: "user"
        },
        type: 9,
    },
    {
        title: '部门',
        icon: "icon-dept",
        config: {
            component: 'book',
            placeholder: '请选择',
            type: "department"
        },
        type: 10,
    },
    {
        title: '指定通讯录',
        icon: "icon-address-file",
        config: {
            component: 'address_book',
            type: "user",
        },
        type: 15
    },
]

const attendItems = [
    {
        title: '请假',
        icon: "icon-leave",
        config: {
            component: 'attendance',
            type: 'leave',
        },
        type: "attendance"
    },
    {
        title: '僧职告假',
        icon: "icon-leave-monk",
        config: {
            component: 'attendance',
            type: 'monk_leave',
        },
        type: "attendance"
    },
    {
        title: '出差',
        icon: "icon-trip",
        config: {
            component: 'attendance',
            type: 'trip'
        },
        type: "attendance"
    },
    {
        title: '加班',
        icon: "icon-overtime",
        config: {
            component: 'attendance',
            type: 'overtime'
        },
        type: "attendance"
    },
    {
        title: '外出',
        icon: "icon-out",
        config: {
            component: 'attendance',
            type: 'out'
        },
        type: "attendance"
    },
]

const specialItems = [
    {
        title: '车辆',
        icon: "icon-car",
        config: {
            component: 'car',
            key: 'car_list',
            limit: 10
        },
        type: "special"
    },
    {
        title: '礼品',
        icon: "icon-gift",
        config: {
            component: 'gift',
            key: 'gift',
        },
        type: "special"
    },
    {
        title: '用餐',
        icon: "icon-dining",
        config: {
            component: 'meal',
            prev: 1,
            max: 2,
            key: 'meal'
        },
        type: "special"
    },
    {
        title: '库房商品',
        icon: "icon-goods",
        config: {
            component: 'subscribe',
            key: 'subscribe',
            goods_type: 1
        },
        type: "special"
    },
    {
        title: '堂口',
        icon: "icon-tk",
        config: {
            component: 'tk',
            key: 'department'
        },
        type: "special"
    },
    {
        title: '资产',
        icon: "icon-asset",
        config: {
            component: 'asset',
            key: 'asset'
        },
        type: "special"
    },
    {
        title: '来访人员',
        icon: "icon-visitors",
        config: {
            component: 'visitors',
            key: 'visit_user_list',
            title: "添加人员"
        },
        type: "special"
    },
    {
        title: '接待需求',
        icon: "icon-reception",
        config: {
            component: 'reception',
            key: 'reception',
        },
        type: "special"
    },
    {
        title: '紧急联系人',
        icon: "icon-contact",
        config: {
            component: 'contacts',
            key: 'contact_person',
            title: "添加紧急联系人"
        },
        type: "special"
    },
    {
        title: '志工变更',
        icon: "icon-volunteer",
        config: {
            component: 'volunteer_modify',
            key: 'volunteer',
            type: "status",
            register_status: 1,
            status: 2,
        },
        type: "special"
    },
    {
        title: '借用单',
        icon: "icon-borrow",
        config: {
            component: 'borrow_apply',
            key: 'borrow_apply',
        },
        type: "special"
    },
]

//基本信息表单（每个表单必有且不可更改）
const base = [
    {
        component: 'input',
        key: 'name',
        type: 'text',
        label: '姓名',
        must: true,
        fixed: true,
        placeholder: '请输入您的姓名',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入您的姓名'}
            },
        ]
    },
    {
        component: 'input',
        key: 'age',
        type: 'number',
        label: '年龄',
        must: true,
        fixed: true,
        placeholder: '请输入您的年龄',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入您的年龄'}
            },
        ]
    },
    {
        component: 'input',
        key: 'mobile',
        label: '手机号码',
        must: true,
        fixed: true,
        placeholder: '请输入您的手机号码',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入您的手机号码'}
            },
            {
                type: 2,
                title: '手机号校验',
                rule: {regex: MOBILE_REG, message: '请输入正确的手机号码'}
            }
        ]
    }
]

const rules = [
    {
        type: 1,
        title: '必填',
        rule: {required: true, message: null}
    },
    {
        type: 2,
        title: '手机号格式校验',
        rule: {regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码'}
    },
    {
        type: 3,
        title: '输入字数限制',
        rule: {min: 0, max: 100, message: null}
    },
    {
        type: 4,
        title: '数字大小限制',
        rule: {small: 0, big: 100, message: null}
    },
    {
        type: 5,
        title: '邮箱格式校验',
        rule: {regex: '^[A-Za-z0-9]+([_\\.][A-Za-z0-9]+)*@([A-Za-z0-9\\-]+\\.)+[A-Za-z]{2,6}$', message: '请输入正确的邮箱'}
    },
    {
        type: 6,
        title: '自定义正则校验',
        rule: {regex: null, message: null}
    }
]


const relyComponents = ['input', 'select']; // 能作为被依赖项的组件

const generateValidator = function (regExp, message) {
    return function (rule, value, callback) {
        if (regExp.test(value)) {
            callback()
        } else {
            callback(new Error(message));
        }
    }
}

export { formItems, attendItems, specialItems, rules, base, relyComponents, generateValidator }
