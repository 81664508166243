import config from './config';
const TOKEN_KEY = 'token';
const UID_KEY = 'uid';
const INFO_KEY= 'info';
const TEMPLE_KEY = "temple";
const WXID_KEY = 'wxId';

const getToken = function() {
  //从配置文件中读取token 若没写入 则从storage中取并写入配置
  let token = config.token;
  if(!token) {
    console.log('Read token from storage.');
    token = getStorage(TOKEN_KEY);
    config.token = token;
  }
  //判断token是否过期 过期返回null
  if(token && Date.now() - token.timestamp > 12 * 60 * 60 * 1000 - 60000) {
    token = null;
  }
  return token;
};

const getUserId = function () {
  return config.uid || sessionStorage.getItem(UID_KEY) || null;
}

const getUserWxId = function() {
  let wxId = config.wxId;
  if(!wxId) {
    wxId = sessionStorage.getItem(WXID_KEY)
    config.wxId = wxId;
  }
  return wxId;
}


const getStorageInfo = function() {
    let info = config.info;
    if(!info) {
      console.log('Read token from storage.');
      info = getStorage(INFO_KEY);
      config.info = info;
    }
    return info;
}

const getUser = function () {
  return config.info || getStorageInfo();
}

const setUser = function (user) {
  config.info = user;
  sessionStorage.setItem(INFO_KEY, JSON.stringify(user));
}

const setToken = function(res) {
  let temp = {
    timestamp: Date.now(),
    token: res.token
  };
  let admin = res.user;
  config.token = temp;
  config.info = admin;
  config.uid = admin.id;
  config.wxId = admin.wx_user_id;
  config.temple = admin.temple_id;
  let value = JSON.stringify(temp);
  sessionStorage.setItem(TOKEN_KEY, value);
  sessionStorage.setItem(INFO_KEY, JSON.stringify(admin));
  sessionStorage.setItem(UID_KEY, config.uid);
  sessionStorage.setItem(WXID_KEY, config.wxId);
  sessionStorage.setItem(TEMPLE_KEY, config.temple);
};

const getStorage = function(key) {
  let result = null;
  let storage = sessionStorage.getItem(key);
  try {
    storage = JSON.parse(storage);
    result = storage;
  } catch (e) {
    console.log(`Storage for ${key} parse failed.`);
  }
  return result;
};

const getTemple = function () {
  let temple = config.temple;
  if(!temple) {
    temple = sessionStorage.getItem(TEMPLE_KEY) || (getStorageInfo() ? getStorageInfo().temple_id : null);
    config.temple = temple;
  }
  return temple;
}

const cleanToken = function() {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(INFO_KEY);
  sessionStorage.removeItem(UID_KEY);
  sessionStorage.removeItem(WXID_KEY);
  config.token = null;
  config.info = null;
  config.uid = null;
  config.wxId = null;
};

export { getToken, getUserId, getStorage, setToken, cleanToken, getStorageInfo, getTemple, getUserWxId, getUser, setUser }
