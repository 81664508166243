const nicheOverview = {
    id: 'nicheOverview',
    router: {
        path: '/light/overview',
        name: 'NicheOverview',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/light/NicheOverview.vue'),
        meta: {
            title: '龛位总览'
        }
    },
}
const nicheDetail = {
    id: 'nicheDetail',
    router: {
        path: '/light/niche/:id',
        name: 'NicheDetail',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/light/NicheDetail.vue'),
        meta: {
            title: '龛位详情'
        }
    },
}
const nicheOrder = {
    id: 'nicheOrder',
    router: {
        path: '/light/order',
        name: 'NicheOrder',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/light/NicheOrder.vue'),
        meta: {
            title: '订单管理'
        }
    },
}
const nicheOrderDetail = {
    id: 'nicheOrderDetail',
    router: {
        path: '/light/order-detail/:id',
        name: 'NicheOrderDetail',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/light/NicheOrderDetail.vue'),
        meta: {
            title: '供灯详情'
        }
    },
}
const nicheOrderCreate = {
    id: 'nicheOrderCreate',
    router: {
        path: '/light/order-create',
        name: 'NicheOrderCreate',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/light/NicheOrderCreate.vue'),
        meta: {
            title: '创建供灯订单'
        }
    },
}
const nicheWorshipGoods = {
    id: 'nicheWorshipGoods',
    router: {
        path: '/light/worship-goods/:id',
        name: 'NicheWorshipGoods',
        component: () => import(/* webpackChunkName: "nwg" */ '../../views/light/NicheWorshipGoods.vue'),
        meta: {
            title: '订单供奉物品'
        }
    },
}
const nicheList = {
    id: 'nicheList',
    router: {
        path: '/light/set/niche-list',
        name: 'NicheList',
        component: () => import(/* webpackChunkName: "nl" */ '../../views/light/NicheNewList.vue'),
        meta: {
            title: '龛位列表'
        }
    },
}
const nicheDistrict = {
    id: 'nicheDistrict',
    router: {
        path: '/light/set/niche-district',
        name: 'NicheDistrict',
        component: () => import(/* webpackChunkName: "nd" */ '../../views/light/NicheDistrict.vue'),
        meta: {
            title: '区域列表'
        }
    },
}
const nicheArea = {
    id: 'nicheArea',
    router: {
        path: '/light/set/niche-area',
        name: 'NicheArea',
        component: () => import(/* webpackChunkName: "na" */ '../../views/light/NicheArea.vue'),
        meta: {
            title: '区块列表'
        }
    },
}
const nicheAreaAdd = {
    id: 'nicheAreaAdd',
    router: {
        path: '/light/set/niche-area/add',
        name: 'NicheAreaAdd',
        component: () => import(/* webpackChunkName: "naa" */ '../../views/light/NicheAreaAdd.vue'),
        meta: {
            title: '添加区块'
        }
    },
}
const nicheAreaDetail = {
    id: 'nicheAreaDetail',
    router: {
        path: '/light/set/niche-area-detail/:id',
        name: 'NicheAreaDetail',
        component: () => import(/* webpackChunkName: "nad" */ '../../views/light/NicheAreaDetail.vue'),
        meta: {
            title: '区块详情'
        }
    },
}
const nicheIcon = {
    id: 'nicheIcon',
    router: {
        path: '/light/set/niche-icon',
        name: 'NicheIcon',
        component: () => import(/* webpackChunkName: "ni" */ '../../views/light/NicheIcon.vue'),
        meta: {
            title: '图标列表'
        }
    },
}
const lightIntro = {
    id: 'lightIntro',
    router: {
        path: '/light/set/intro',
        name: 'LightIntro',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/light/LightIntro.vue'),
        meta: {
            title: '内容管理'
        }
    },
}


const goods = {id: 'goods', entity: 'goods'};
const goodsSet = {
    id: 'goodsSet',
    router: {
        path: '/light/set/goods-set',
        name: 'GoodsSet',
        component: () => import(/* webpackChunkName: "gs" */ '../../views/light/GoodsSet.vue'),
        meta: {
            title: '供奉物品'
        }
    },
}

const lightAdmin =  {
    id: 'lightAdmin',
    router: {
        path: '/light/admin',
        name: 'LightAdmin',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/light/LightAdmin.vue'),
        meta: {
            title: '权限设置'
        }
    },
}

export default {
    nicheOverview,
    nicheDetail,
    nicheOrder,
    nicheOrderDetail,
    nicheOrderCreate,
    nicheWorshipGoods,
    nicheList,
    nicheDistrict,
    nicheArea,
    nicheAreaAdd,
    nicheAreaDetail,
    nicheIcon,
    lightIntro,
    goods,
    goodsSet,
    lightAdmin
}


export const routers = [
    {
        type: 1,
        list: [
            {id: 'nicheOverview', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheOrder', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheOrderDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheOrderCreate', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheWorshipGoods', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheDistrict', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheArea', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheAreaAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheAreaDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'nicheIcon', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'lightIntro', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'goods', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'goodsSet', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'lightAdmin', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    }
]
export const menus = [
    {
        type: 1,
        list: [
            {
                title: '龛位总览',
                path: '/light/overview',
            },
            {
                title: '订单管理',
                path: '/light/order',
            },
            {
                title: '供奉物品',
                path: '/entity/goods',
            },
            {
                title: '系统设置',
                path: '/ligth/set',
                children: [
                    {
                        title: '龛位设置',
                        path: '/light/set/niche-list',
                    },
                    {
                        title: '区域设置',
                        path: '/light/set/niche-district',
                    },
                    {
                        title: '区块设置',
                        path: '/light/set/niche-area',
                    },
                    {
                        title: '图标设置',
                        path: '/light/set/niche-icon',
                    },
                    {
                        title: '内容管理',
                        path: '/light/set/intro',
                    },
                ]
            },
            {
                title: '权限设置',
                path: '/light/admin',
                icon: 'setting'
            }
        ]
    }
]
