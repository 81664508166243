import store from "../store/index";
import routerMap, {entityRouter, homeRouter} from "./router";
import {getStorageInfo} from "@/common/js/storage";
import {clone} from "@/common/js/tool";
import {getRoleRouterMenus} from "./subsystem";

export const generateRouter = function (user) {
    let info = user || getStorageInfo();
    let {routers: conf, menus} = getRoleRouterMenus(info);
    if(conf) {
        let routers = [], action = {}, tempMap = {}
        let home = clone(homeRouter);
        let list = [home]
        conf.forEach(item => {
            let id = item.id;
            if(id == 'screen') {
                list.push({...routerMap.screen.router});
            } else if(id == 'process') {
                list.push({...routerMap.process.router});
            } else if(id == 'volunteerProcess') {
                list.push({...routerMap.volunteerProcess.router});
            } else {
                let router = routerMap[id];
                // 检测是否采用的是公用实体路由
                if(router.entity) {
                    action[router.entity] = item.action;
                    if(router.temp) {
                        tempMap[id] = router.temp;
                    }
                } else {
                    // 不是公共实体路由则将其加进路由
                    if(router.router) {
                        // 将操作权限放入路由的meta里
                        if(router.router.meta) {
                            router.router.meta.action = item.action
                        } else {
                            router.router.meta = {
                                action: item.action
                            }
                        }
                        routers.push(router.router);
                    }
                }
            }
        });
        // 默认将menu第一个作为默认路由
        let temp = menus[0];
        if(temp.children && temp.children.length > 0) {
            let firstRoute = temp.children[0];
            temp = firstRoute.path
            if (firstRoute.children && firstRoute.children.length > 0) {
                // 三级菜单
                temp = firstRoute.children[0].path
            }
        } else {
            temp = temp.path;
        }
        store.commit("setMenus", menus);
        store.commit("setDefaultPath", temp);
        let keys = Object.keys(action);
        if(keys.length > 0) {
            let r = clone(entityRouter);
            r.meta.action = action;
            r.meta.tempMap = tempMap;
            routers.push(r);
        }
        home.redirect = {path: temp};
        home.children = home.children.concat(routers);
        return {routers: list, defaultPath: temp};
    } else {
        return null;
    }
}
