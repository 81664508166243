<template>
  <a-button :type="type" :loading="loading" class="upload-btn">
    <span class="upload-text">{{ uploadText }}</span>
    <label for="name" class="upload-label">
      <input type="file" id="name" @input="selectFile" class="upload-input" accept=".xlsx,.xls" />
    </label>
  </a-button>
</template>

<script>
import { getTemple } from "../common/js/storage";
import { isEmpty } from "../common/js/tool";
export default {
  name: "UploadVolunteer",
  props: {
    // 按钮文本
    uploadText: {
      type: String,
      default: "上传文件",
    },
    // 按钮类型
    type: String,
    url: {
      type: String,
      default: "/admin/common/import",
    },
    noTemple: {
      type: Boolean,
      default: false,
    },
    exrtaParams: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    selectFile(e) {
      if (this.loading) {
        return;
      }
      const file = e.target.files[0] || e.dataTransfer.files[0];
      // 对文件类型做简单限制
      if (!file.type && /\.(?:xlsx|xls)$/.test(file.name)) {
        this.$message.warning("上传的文件格式只能是：xlsx, xls");
        return false;
      }
      const formData = new FormData();
      formData.append("file", file);
      if (!this.noTemple) {
        let temple = getTemple();
        formData.append("temple_id", temple);
      }
      let exrtaParams = this.exrtaParams;
      if (exrtaParams) {
        for (const key in exrtaParams) {
          if (Object.hasOwnProperty.call(exrtaParams, key)) {
            const value = exrtaParams[key];
            if (!isEmpty(value)) {
              formData.append(key, value);
            }
          }
        }
      }

      this.loading = true;
      this.$axios({
        url: this.url,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.error == 0) {
            let list = res.data;
            if (list?.length > 0) {
              this.$emit("change", list);
            } else {
              this.$emit("change", null);
            }
            this.$message.success("导入成功");
          } else {
            let error_list = res.error_list;
            if (error_list?.length > 0) {
              let content = error_list.map((error) => {
                return (
                  <div>
                    第{error.line}行 {error.msg}
                  </div>
                );
              });
              this.$message.error(content);
            } else {
              this.$message.error(res.msg);
            }
          }
          this.loading = false;
          e.target.value = "";
        })
        .catch((err) => {
          console.log("err:", err);
          this.$message.error("上传失败");
          this.loading = false;
          e.target.value = "";
        });
    },
  },
};
</script>

<style lang="less" scoped>
.upload-btn {
  position: relative;
}
.upload-label {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
}
.upload-input {
  width: 100%;
  height: 100%;
}
</style>
