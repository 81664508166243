<template>
  <a-select v-bind="attrs" v-on="listeners">
    <slot name="options" v-bind:list="list" v-if="$scopedSlots.options"></slot>
    <template v-else>
      <a-select-option v-for="d in list" :key="d.id">
        <slot name="title" v-bind:option="d" v-if="$scopedSlots.title"></slot>
        <span v-else>{{ d[nameKey] }}</span>
      </a-select-option>
    </template>
  </a-select>
</template>

<script>

    import {omit, throttle} from "../common/js/tool";

    export default {
        name: "remote-search",
        model: {
            prop: "select",
        },
        props: {
            url: String,
            query: String,
            valueKey: {
                type: String,
                default: "id"
            },
            nameKey: {
                type: String,
                default: "name"
            },
        },
        data() {
            return {
                list: []
            }
        },
        computed: {
            attrs() {
                return Object.assign(
                    {},
                    omit(this.$attrs, ["url", "valueKey", "nameKey", "select"]),
                    {
                        showSearch: true,
                        defaultActiveFirstOption: false,
                        showArrow: false,
                        filterOption: false,
                        notFoundContent: null,
                        value: this.$attrs.select ? this.$attrs.select[this.valueKey] : undefined
                    }
                );
            },
            listeners() {
                return Object.assign(
                    {},
                    this.$listeners,
                    {
                        search: throttle(this.searchValue, 300),
                        change: (val) => {
                            if(val) {
                                val = this.list.find(item => item[this.valueKey] == val);
                            }
                            this.$emit("input", val);
                        }
                    }
                )
            }
        },
        methods: {
            searchValue(value) {
                let url = this.url
                if(value) {
                    url += `?filter[${this.nameKey}][like]=${value}`;
                }
                if(this.query) {
                    url += this.query;
                }
                this.$axios(url).then(res => {
                    this.list = res.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
