<template>
    <div class="select-master">
        <div class="open-master" v-if="needOpen">
            <div class="master-open-preview">
                <open-tablet
                  ref="openTablet"
                  editable
                  @change="handleOpenTabletChange"
                  :buddhism="info"
                  :palace="palace"
                  :box-style="{width: '100%'}"></open-tablet>
            </div>
            <div class="master-list">
                <div class="master-list-title">选择法师 <span>请点击法师卡片或拖拽至开牌单修改，也可自定义添加</span></div>
                <div class="flex-box" style="position:relative;margin-bottom: 10px">
                    <a-input-search allow-clear v-model="keyword" placeholder="输入法名搜索" style="width: 180px" @search="getList"/>
                    <a-select
                      style="margin-left:8px;width: 180px"
                      placeholder="选择技能筛选"
                      @change="getList"
                      allowClear
                      :options="typeOptions"
                      v-model="ability">
                    </a-select>
                    <a-button style="margin-left: 8px" @click="getList">查询</a-button>
                    <div class="flex-box align-center money-btn">
                        <span>显示津贴金额</span>
                        <a-icon :type="showMoney ? 'eye-invisible' : 'eye'" theme="filled" class="master-money-show" @click="switchMoneyVisible()"/>
                    </div>
                </div>
                <div class="master-table">
                    <div class="master-table-row master-table-head">
                        <div class="master-table-td">法名</div>
                        <div class="master-table-td" v-if="showAbility">技能</div>
                        <div class="master-table-td">本月参与佛事次数</div>
                        <div class="master-table-td">本月津贴</div>
                        <div class="master-table-td">状态</div>
                    </div>
                    <div class="master-table-body" v-if="showList && showList.length > 0">
                        <div
                          draggable
                          class="master-table-row"
                          @dragstart="handleDragStart($event, item)"
                          @dragend="handleDragEnd"
                          v-for="item in showList"
                          :key="item.id">
                            <div class="master-table-td">{{item.name}}</div>
                            <div class="master-table-td" v-if="showAbility">{{item.ability}}</div>
                            <div class="master-table-td">{{item.fs_count}}</div>
                            <div class="master-table-td">
                                <div class="flex-box align-center justify-center">
                                    <span>{{item.showMoney ? `¥${item.sum_money}` : '***'}}</span>
                                    <a-icon :type="item.showMoney ? 'eye-invisible' : 'eye'" theme="filled" class="master-money-icon" @click="switchMoneyVisible(item)"/>
                                </div>
                            </div>
                            <div class="master-table-td">{{getMasterStatusText(item.is_free)}}</div>
                        </div>
                    </div>
                    <div class="master-table-empty" v-else-if="list">
                        <a-empty :image="emptyImage" description="暂无法师" />
                    </div>
                    <div class="master-table-loading" v-show="loading">
                        <a-spin />
                    </div>
                </div>
                <div class="master-custom">
                    <div class="master-list-title">外勤人员 <a-button style="margin-left: 8px" type="primary" @click="customMaster">自定义添加</a-button></div>
                    <div class="custom-master-list">
                        <div
                            class="book-item"
                            draggable
                            @dragstart="handleDragStart($event, m)"
                            @dragend="handleDragEnd"
                            v-for="(m, i) in showOtherList"
                            :key="i">
                            <a-icon class="book-icon" type="user" />
                            <div class="book-name">{{m.name}}</div>
                            <a-icon type="close" class="book-remove" @click="removeCustom(i)"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-else>
            <div class="master-type-list">
                <div class="master-type-item" v-for="(t, i) in types" :key="i">
                    <div class="master-type-name" v-if="t.name">{{t.name}} {{t.select ? t.select.length : 0}}/{{t.number == -1 ? '无限制' : t.number || 1}}</div>
                    <div class="select-master-list">
                        <a-tag closable="" class="select-master-item" @close="removeMaster(j, t.select)" v-for="(m, j) in t.select || []" :key="m.id">{{m.name}}</a-tag>
                        <div class="txt-btn" @click="showModal(i)">添加</div>
                    </div>
                </div>
            </div>
            <a-modal
              :title="`选择${index === null ? '' : types[index].name}法师`"
              centered
              :maskClosable="false"
              :closable="false"
              :width="custom ? 400 : 800"
              @ok="confirmSelect"
              v-model="visible">
                <div class="flex-box" style="position:relative;margin-bottom: 10px">
                    <a-input-search allow-clear v-model="keyword" placeholder="输入法名搜索" style="width: 180px" @search="getList"/>
                    <a-select
                      style="margin-left:8px;width: 180px"
                      placeholder="选择堂口筛选"
                      @change="getList"
                      allowClear
                      :options="tkOptions"
                      v-model="tk">
                    </a-select>
                    <a-button style="margin-left: 8px" @click="getList">查询</a-button>
                    <a-button style="margin-left: 8px" type="primary" @click="customMaster">自定义添加</a-button>
                    <div class="flex-box align-center money-btn">
                        <span>显示津贴金额</span>
                        <a-icon :type="showMoney ? 'eye-invisible' : 'eye'" theme="filled" class="master-money-show" @click="switchMoneyVisible()"/>
                    </div>
                </div>
                <a-table
                  ref="masterTable"
                  row-key="id"
                  size="small"
                  :columns="columns"
                  :loading="loading"
                  :row-selection="{ selectedRowKeys: keys, onChange: onSelectChange, getCheckboxProps }"
                  :data-source="list"
                  :pagination="pagination"
                  @change="handleTableChange">
                    <template v-slot:money="text, record">
                        <div class="flex-box align-center">
                            <span>{{record.showMoney ? `¥${text}` : '***'}}</span>
                            <a-icon :type="record.showMoney ? 'eye-invisible' : 'eye'" theme="filled" class="master-money-icon" @click="switchMoneyVisible(record)"/>
                        </div>
                    </template>
                </a-table>
            </a-modal>
        </template>
    </div>
</template>

<script>
    import {getMasterStatusText} from "../common/constant/buddha/master";
    import {getTemple} from "../common/js/storage";
    import OpenTablet from "../layouts/OpenTablet";
    import {Empty} from "ant-design-vue";
    import {getBuddhismMaterTypeList} from "../common/buddha/buddhism";
    import {generateValidator} from "./custom-form/formItems";
    const MASTER_KEY = "OUTSIDE_MASTER"
    const items = [
        {
            key: 'name',
            label: '法名',
            component: 'a-input',
            props: {
                placeholder: '请输入法名',
            },
            rules: [
                { required: true, message: '请输入法名', trigger: 'blur' },
                { validator: generateValidator(/[\u4E00-\u9FA5]+/, "法名必须为中文"), trigger: 'blur' },
            ]
        },
        {
            key: 'mobile',
            label: '手机号码',
            component: 'a-input',
            props: {
                placeholder: '请输入手机号码',
            },
            rules: [
                { required: true, message: "请输入手机号码", trigger: "blur" },
                { validator: generateValidator(/^1[3-9]\d{9}$/, "请输入正确的手机号码"), trigger: 'blur' }
            ]
        },
    ]

    export default {
        name: "select-master",
        model: {
            event: "change"
        },
        components: {
            OpenTablet,
        },
        props: {
            info: Object,
            palace: String,
            showAbility: Boolean
        },
        data() {
            return {
                visible: false,
                list: [],
                keys: [],
                loading: false,
                index: null,
                custom: false,
                name: "",
                ability: undefined,
                tk: undefined,
                keyword: null,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                selectIds: [], // 已分配的法师
                selectMobiles: [], // 已分配的外勤人员手机号
                types: [{name: '', number: -1}],
                emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
                showMoney: false,
                otherList: [],
                tkOptions: []
            }
        },
        computed: {
            // 是否需要开牌
            needOpen() {
                return this.info && this.info.needOpen;
            },
            isPf() {
                const info = this.info
                return info && info.temp && info.temp.fs_type == 1;
            },
            columns() {
                let res = [
                    {title: '法名', dataIndex: 'name'},
                    {title: '堂口', dataIndex: 'tangkou', customRender: text => text?.name || "--"},
                    {title: '本月参与佛事次数', dataIndex: 'fs_count'},
                    {title: '本月津贴', dataIndex: 'sum_money', scopedSlots: { customRender: 'money'}},
                    {
                        title: '状态',
                        dataIndex: 'is_free',
                        customRender: text => {
                            return <span>{getMasterStatusText(text)}</span>
                        }
                    },
                ]
                if(this.showAbility) {
                    res.splice(1,0,{title: '技能', dataIndex: 'ability'});
                }
                return res;
            },
            typeOptions() {
                let res = [];
                if(this.info) {
                    const types = getBuddhismMaterTypeList(this.info);
                    res = types.filter(t => t.ability).map(item => {
                        return {
                            key: item.ability,
                            title: item.ability
                        }
                    })
                }
                return res;
            },
            showList() {
                return (this.list || []).filter(item => this.selectIds.indexOf(item.id) === -1);
            },
            showOtherList() {
                return (this.otherList || []).filter(item => this.selectMobiles.indexOf(item.mobile) === -1);
            }
        },
        watch: {
            info() {
                this.getList();
            },
            needOpen() {
                this.getTkOptions();
            }
        },
        created() {
            this.getList();
            this.getStoreMaster();
            this.getTkOptions();
        },
        methods: {
            getMasterStatusText,
            getStoreMaster() {
                const value = localStorage.getItem(MASTER_KEY);
                if(value) {
                    try {
                        this.otherList = JSON.parse(value);
                    } catch (e) {
                        console.error(e)
                    }
                }
            },
            getTkOptions() {
                if(!this.needOpen) {
                    const url = `/admin/fs-tangkou?pageSize=999`;
                    this.$axios(url).then(res => {
                        this.tkOptions = res.data.map(item => {
                            return {
                                key: item.id,
                                title: item.name
                            }
                        })
                    });
                }
            },
            removeCustom(i) {
                this.otherList.splice(i, 1);
                if(this.otherList.length > 0) {
                    localStorage.setItem(MASTER_KEY, JSON.stringify(this.otherList));
                } else {
                    localStorage.removeItem(MASTER_KEY);
                }
            },
            handleDragOver(e) {
                e.preventDefault();
            },
            handleDragStart(e, item) {
                e.currentTarget.classList.add("on-drag");
                e.dataTransfer.effectAllowed = "copyMove";
                e.dataTransfer.setData("text/plain", JSON.stringify(item))
                e.dataTransfer.setDragImage(this.getDragImage(item, 50), 25, 25);
            },
            handleDragEnd(e) {
                e.currentTarget.classList.remove("on-drag");
            },
            getDragImage(item, height) {
                const canvas = document.createElement("canvas");
                canvas.width = canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                const text = item.name;
                ctx.beginPath();
                ctx.arc(height / 2, height / 2, height / 2, 0,  2 * Math.PI);
                ctx.fillStyle = "#f5f5f5";
                ctx.fill();
                ctx.closePath();
                ctx.font = "14px Times";
                ctx.fillStyle = "#333";
                ctx.fillText(text, height / 2, height / 2);
                const image = document.createElement("img");
                image.width = height;
                image.height = height;
                image.src = canvas.toDataURL();
                return image;
            },
            getCheckboxProps(record) {
                return {
                    props: {
                        disabled: this.types.findIndex(t => t.select && t.select.findIndex(m => m.id == record.id) >= 0) >= 0
                    },
                }
            },
            switchMoneyVisible(record) {
                if(record) {
                    this.$set(record, 'showMoney', !record.showMoney);
                } else {
                    let flag = !this.showMoney;
                    this.showMoney = flag;
                    this.list.forEach(item => {
                        this.$set(item, 'showMoney', flag);
                    })
                }
            },
            handleOpenTabletChange(val) {
                const selectIds = [], selectMobiles = [];
                val.forEach(item => {
                    if(item.select && item.select.length > 0) {
                        item.select.forEach(m => {
                            if(m.id) {
                                selectIds.indexOf(m.id) === - 1 && selectIds.push(m.id)
                            } else if(m.mobile){
                                // 外勤人员用手机号标识
                                selectMobiles.indexOf(m.mobile) === - 1 && selectMobiles.push(m.mobile)
                            }
                        })
                    }
                })
                this.selectIds = selectIds;
                this.selectMobiles = selectMobiles;
                this.dispatchChange(val);
            },
            dispatchChange(val) {
                this.$emit("change", val || this.types);
            },
            confirmSelect() {
                let index = this.index;
                let t = this.types[index];
                let limit = t.number || 1;
                let select = t.select || [];
                let keys = this.keys;
                if(keys.length > 0) {
                    if(limit !== -1 && keys.length > limit) {
                        this.$message.warning(`最多只能添加${limit}个${t.name == '师' ? '' : t.name}法师`);
                    } else {
                        keys = keys.filter(key => select.findIndex(s => s.id == key) === -1);
                        select = select.concat(keys.map(key => {
                            let m = this.list.find(item => item.id == key);
                            return {...m};
                        }));
                        this.$set(t, 'select', select);
                        this.visible = false;
                        this.dispatchChange();
                    }
                } else {
                    this.$message.warning("请至少选择一个法师");
                }
            },
            customMaster() {
                this.name = null;
                this.visible = false;
                this.$confirm({
                    title: '添加法师',
                    content: <form-area
                        ref="masterForm"
                        layout="horizontal"
                        style="margin: -24px;"
                        hide-btn
                        label-col={{span: 6}}
                        wrapper-col={{span: 18}}
                        items={items}
                        entity={{}}></form-area>,
                    width: 480,
                    centered: true,
                    onOk: () => this.$refs.masterForm.handleConfirm().then(form => {
                        let list = this.needOpen ? this.otherList : this.types[this.index].select || [];
                        list.push({...form});
                        if(this.needOpen) {
                            localStorage.setItem(MASTER_KEY, JSON.stringify(list));
                        } else {
                            this.$set(this.types[this.index], 'select', list);
                            this.dispatchChange();
                        }
                        return Promise.resolve();
                    })
                });
            },
            removeMaster(i, list) {
                list.splice(i, 1);
                this.dispatchChange();
            },
            showModal(i) {
                this.index = i;
                let t = this.types[i];
                let limit = t.number || 1;
                if(limit == -1 || !t.select || t.select.length < limit) {
                    let select = this.types[i].select || [];
                    this.keys = select.filter(s => s.id).map(s => s.id);
                    this.getList();
                    this.custom = false;
                    this.visible = true;
                } else {
                    this.$message.warning(`最多只能添加${limit}个${t.name == '师' ? '' : t.name}法师`);
                }
            },
            getList() {
                if(this.loading || !this.info || !this.info.beginTime || !this.info.endTime) return;
                this.loading = true;
                let url = '/admin/master/select';
                let data = {
                    begin_time: this.info.beginTime,
                    end_time: this.info.endTime,
                    temple_id: getTemple()
                }
                this.ability && (data.ability = this.ability);
                this.keyword && (data.name = this.keyword);
                this.tk && (data.fs_tangkou_id = this.tk);
                this.$axios({
                    url,
                    params: data,
                    noTempleFilter: true
                }).then(res => {
                    this.loading = false;
                    if(res.error == 0) {
                        let list = res.data;
                        this.list = list;
                        // 普佛自动选择所有空闲人员
                        if(this.isPf) {
                            this.keys = list.filter(item => item.tangkou && item.tangkou.is_select == 1).map(item => item.id);
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }).catch(() => {
                    this.loading = false;
                })
            },
            onSelectChange(keys) {
                this.keys = keys;
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
            },
        }
    }
</script>

<style scoped lang="less">
    .open-master {
        display: flex;
        margin-bottom: 16px;
        height: 600px;
    }
    .master-open-preview {
        padding-right: 20px;
        width: 540px;
        overflow-y: auto;
    }
    .master-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        min-width: 0;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .master-list-title {
        font-size: 18px;
        font-weight: bold;
        span {
            margin-left: 1em;
            font-weight: normal;
            font-size: 14px;
            color: @text-color-secondary;
        }
    }
    .master-type-list {
        padding-top: 3px;
    }
    .master-type-item {
        display: flex;
        align-items: baseline;
        min-height: 40px;
    }
    .master-type-name {
        flex-shrink: 0;
        width: 60px;
    }
    .select-master-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .txt-btn {
            margin: 6px;
            line-height: 22px;
        }
    }
    .select-master-item {
        margin: 6px;
    }
    .master-money-icon {
        margin-left: 0.5em;
        color: @text-color-secondary;
        cursor: pointer;
    }
    .money-btn {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }
    .master-money-show {
        margin-left: 0.5em;
        font-size: 18px;
        color: @text-color-secondary;
        cursor: pointer;
    }
    .master-table {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 0;
        border: var(--border);
    }
    .master-table-row {
        display: flex;
        &.master-table-head,
        &:not(:last-of-type) {
            border-bottom: var(--border);
        }
        &.on-drag {
            background-color: @background-color-light;
        }
    }
    .master-table-head {
        padding-right: 6px;
        .master-table-td {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .master-table-body {
        flex: 1;
        min-height: 0;
        overflow: auto;
        .master-table-row {
            cursor: move;
        }
    }
    .master-table-td {
        flex: 1;
        min-width: 0;
        padding: 6px 8px;
        line-height: 1.3;
        text-align: center;
        &:nth-of-type(2) {
            flex: none;
            width: 50%;
        }
        &:not(:first-of-type) {
            border-left: var(--border);
        }
    }
    .master-custom {
        margin-top: 12px;
        .book-item {
            cursor: move;
        }
    }
    .custom-master {
        background-color: @background-color-base;
        text-align: center;
    }
    .master-table-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
