<template>
  <div class="cl-subscribe">
    <a-space>
      <entity-picker
        btn-text="选择物品"
        multiple
        :url="goodsConfig.url"
        :query="goodsConfig.query"
        no-temple-filter
        :columns="goodsColumns"
        :search-key-type="{name: 2}"
        @change="handleGoodsChange"
        v-if="list.length == 0 || !list[0].subscribe_num">
        <template v-slot:query="{form}">
          <a-form-model-item label="">
            <a-input v-model="form.name" placeholder="输入商品名称查询"/>
          </a-form-model-item>
        </template>
        <a-button type="primary">商品列表添加</a-button>
      </entity-picker>
      <template v-if="manual">
        <a-button @click="uploadGoods">批量上传</a-button>
        <a-button type="primary" shape="circle" icon="plus" @click="addItem" />
      </template>
      <purchase-select @change="dispatchChange" v-if="goods_type == 2">
        <a-button>申购单领用</a-button>
      </purchase-select>
      <a-button type="primary" @click="dispatchChange([])">清空</a-button>
    </a-space>
    <a-table
      ref="dataTable"
      style="margin: 10px 0"
      :row-key="(record, index) => index"
      bordered
      size="middle"
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      @change="handleTableChange">
      <template v-slot:name="text, record">
        <a-input v-model="record.goodsName" :disabled="!!record.id" placeholder="商品名称" style="margin: -5px 0" v-if="record.edit"></a-input>
        <span v-else>{{ record.goodsName || record.subscribe_goods }}</span>
      </template>
      <template v-slot:amount="text, record">
        <a-input-number :min="1" v-model="record.amount" :precision="0" placeholder="商品数量" style="margin: -5px 0" v-if="record.edit"></a-input-number>
        <span v-else>{{ record.amount || record.num }}</span>
      </template>
      <template v-slot:unit="text, record">
        <a-input v-model="record.subscribe_goods_unit" :disabled="!!record.id" placeholder="单位" style="margin: -5px 0" v-if="record.edit"></a-input>
        <span v-else>{{ record.subscribe_goods_unit }}</span>
      </template>
      <template v-slot:notes="text, record">
        <a-input v-model="record.notes" :disabled="!!record.id" placeholder="备注" style="margin: -5px 0" v-if="record.edit"></a-input>
        <span v-else>{{ record.notes || "--"}}</span>
      </template>
      <template v-slot:action="text, record, index">
        <div class="row-btn" v-if="record.edit">
          <a class="txt-btn" @click.stop="saveGoods(record)">保存</a>
          <a class="txt-btn" @click.stop="cancelGoods(record)">取消</a>
        </div>
        <div class="row-btn" v-else>
          <a class="txt-btn" :class="{grey: record.subscribe_num}" @click.stop="editGoods(record)">编辑</a>
          <a class="txt-btn danger" @click.stop="deleteGoods(index)">删除</a>
        </div>
      </template>
    </a-table>
    <batch-import-modal
      v-model="visible"
      name="申购模板.xlsx"
      url="https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/assets/oa/purchase_template.xlsx"
      @select="handleSelect"></batch-import-modal>
  </div>
</template>

<script>
    import { read, utils } from "xlsx";
    import {clone, omit} from "../../common/js/tool";
    import batchImportModal from "./batch-import-modal";
    import purchaseSelect from "./purchase-select";
    import entityPicker from "./entity-picker";
    import { getTemple } from "../../common/js/storage";

    export default {
        name: "cl-subscribe",
        model: {
            event: "change"
        },
        components: {
            entityPicker,
            batchImportModal,
            purchaseSelect,
        },
        props: {
            value: Array,
            manual: Boolean, // 是否可以手动添加商品
            goods_type: Number, // 1申购 2领用 3借用 4礼品
            tkId: Number,
        },
        data() {
            return {
                list: [],
                columns: [
                    {
                        title: '商品名称',
                        dataIndex: 'goodsName',
                        scopedSlots: { customRender: 'name'},
                        width: "30%",
                    },
                    {
                        title: '商品数量',
                        dataIndex: 'amount',
                        scopedSlots: { customRender: 'amount'},
                        width: 130,
                    },
                    {
                        title: '单位',
                        dataIndex: 'subscribe_goods_unit',
                        scopedSlots: { customRender: 'unit'},
                    },
                    {
                        title: '备注',
                        dataIndex: 'notes',
                        width: "30%",
                        scopedSlots: { customRender: 'notes'}
                    },
                    {title: '操作', key: 'action', width: 100, scopedSlots: { customRender: 'action'}}
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                goods: null,
                current: null,
                visible: false
            }
        },
        computed: {
            goodsColumns() {
                const res = [
                    { title: '物品名称', dataIndex: 'name', },
                    { title: '物品图片', dataIndex: 'img', customRender: text => text ? <s-img src={text} /> : '--' },
                ]
                if(this.goods_type == 2) {
                    res.push({ title: '领用次数', dataIndex: 'count' });
                }
                return res;
            },
            goodsUrl() {
                let res = "";
                const goods_type = this.goods_type;
                switch (goods_type) {
                    case 1:
                        res = "/asset-subscribe/list";
                        break;
                    case 2:
                        res = "/asset-goods/list";
                        break;
                    case 3:
                        res = "/asset-ex-warehouse/list";
                        break;
                    case 4:
                        res = "/asset-goods/gift-list";
                        break;
                }
                return res;
            },
            goodsConfig() {
                let query = `&temple_id=${getTemple()}`;
                if(this.tkId) {
                    query += `&tangkou_id=${this.tkId}`;
                }
                let res = {};
                if(this.goodsUrl) {
                    res = {
                        url: this.goodsUrl,
                        query
                    }
                }
                return res;
            }
        },
        watch: {
            value() {
                this.setList();
            }
        },
        created() {
            this.setList();
        },
        methods: {
            setList() {
                this.list = this.value ? clone(this.value) : [];
            },
            clearAll() {
                this.dispatchChange([]);
            },
            dispatchChange(list) {
                this.$emit("change", list.length > 0 ? list.map(item => omit(item, ["edit"])) : undefined);
            },
            handleGoodsChange(val) {
                if(val) {
                    const list = [...this.list];
                    list.push(...val.map(item => {
                        return {
                            ...item,
                            goodsName: item.name,
                            amount: 1,
                            subscribe_goods_unit: item.goodsUnit?.name || "--",
                        }
                    }));
                    this.dispatchChange(list);
                }
            },
            saveGoods(record) {
                const error = this.checkRecord(record);
                if(error) {
                    this.$message.warning(error);
                    return;
                }
                this.$set(record, "edit", false);
                const list = [...this.list];
                this.dispatchChange(list);
            },
            cancelGoods(record) {
                if(this.goods) {
                    this.$set(record, "goodsName", this.goods.goodsName);
                    this.$set(record, "amount", this.goods.amount);
                    this.$set(record, "subscribe_goods_unit", this.goods.unit);
                    this.$set(record, "notes", this.goods.notes);
                    this.$set(record, "edit", false);
                } else {
                    this.list.splice(0, 1);
                }
                this.current = null;
            },
            addItem() {
                this.dealCurrent();
                this.goods = null;
                const current = {edit: true};
                this.current = current;
                this.list.unshift(current);
            },
            editGoods(record) {
                if(record.subscribe_num) return;
                this.dealCurrent();
                this.current = record;
                this.goods = clone(record);
                this.$set(record, "edit", true);
            },
            deleteGoods(index) {
                const list = [...this.list];
                list.splice(index, 1);
                this.dispatchChange(list);
            },
            uploadGoods() {
                this.visible = true;
            },
            handleSelect(file) {
                const reader = new FileReader();
                reader.onload = e => {
                    const data = e.target.result;
                    const result = read(data, {
                        type: 'binary',
                        cellDates: true,// 为了获取excel表格中的时间，返回格式为世界时间
                    });
                    const names = result.SheetNames;
                    if(names.length > 0) {
                        const sheet = result.Sheets[names[0]];
                        const sheetJson = utils.sheet_to_json(sheet, { header: "A" }).slice(1);
                        const arr = sheetJson.map(item => {
                            return {
                                goodsName: item.A,
                                amount: Math.max(Math.round(item.B), 1),
                                subscribe_goods_unit: item.C,
                                notes: item.D,
                            }
                        })
                        const list = [...this.list];
                        list.push(...arr);
                        this.dispatchChange(list);
                        this.visible = false;
                    }
                };
                reader.readAsBinaryString(file);
            },
            dealCurrent() {
                if(this.current) {
                    const error = this.checkRecord(this.current);
                    if(error) {
                        this.cancelGoods(this.current);
                    } else {
                        this.$set(this.current, "edit", false);
                    }
                }
            },
            checkRecord(record) {
                let error = "";
                if(!record.id && !record.goodsName) {
                    error = "请输入商品名称";
                } else if(!record.amount) {
                    error = "请输入商品数量";
                }
                if(!record.id && !record.subscribe_goods_unit) {
                    error = "请输入单位";
                }
                return error;
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
            },
        }
    }
</script>

<style scoped>

</style>
