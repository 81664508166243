<template>
  <div class="ass-rules">
      <a-space class="ass-rule-btn" v-if="multiple && edit">
        <a-dropdown :trigger="['click']">
          <a-button icon="plus">添加评价项</a-button>
          <a-menu slot="overlay">
            <a-menu-item :key="0" @click="visible = true">从已有模板中添加</a-menu-item>
            <a-menu-item :key="1" @click="addConfigItem()">自定义</a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-space>
      <a-button v-if="!multiple && edit && config.length <= 0" @click="addConfigItem()">设置评价内容</a-button>
      <table class="ass-rule-table" v-show="config.length > 0 || multiple">
        <thead>
        <tr class="ass-rule-tr">
          <th v-if="multiple" style="width: 60px;">序号</th>
          <th>评价类型</th>
          <th>评价项</th>
          <th>评价标准</th>
          <th v-if="multiple">权重</th>
          <th>自评</th>
          <th v-if="ass">评分依据</th>
          <th v-if="ass !== 1">主管评价</th>
          <th v-if="ass >= 2">评分依据</th>
          <th v-if="ass == 3">分数</th>
          <th v-if="edit || (ass && ass < 3)">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(conf, i) in config" :key="i">
          <td v-if="multiple">{{i + 1}}</td>
          <td>{{getAssRuleType(conf.type)}}</td>
          <template v-if="conf.type == 1">
            <td>
              <div v-if="conf.items">
                <div v-for="(p, j) in conf.items.split(/\r|\n/)" :key="j">{{p}}</div>
              </div>
            </td>
            <td>
              <div v-if="conf.norm">
                <div v-for="(p, j) in conf.norm.split(/\r|\n/)" :key="j">{{p}}</div>
              </div>
            </td>
          </template>
          <template v-else>
            <td>
              <div>{{getSystemAssTypeText(conf.assType) || ''}}</div>
            </td>
            <td>
              <div class="ass-norm-list" v-if="conf.normList && conf.normList.length > 0">
                <div class="ass-norm-item" v-for="(n, j) in conf.normList" :key="j">{{getSysRuleText(n, conf.assType, j)}}</div>
              </div>
              <div v-else>无</div>
            </td>
          </template>
          <td v-if="multiple">
            <div class="ass-per-box">{{conf.percent}}%</div>
          </td>
          <td>
            <div class="ass-score" v-if="ass">
              <span>{{conf.selfNum > 0 ? conf.selfScore || '未打分' : '--'}}</span>
            </div>
            <div class="ass-per" v-else-if="conf.type == 1">
              <span>{{conf.selfNum}}%</span>
            </div>
            <div v-else>--</div>
          </td>
          <td v-if="ass">
            <div v-if="conf.selfBasis">
              <div v-for="(p, i) in conf.selfBasis.split(/\r|\n/)" :key="i">{{p}}</div>
            </div>
            <span v-else>{{conf.selfNum > 0 ? '无' : '--'}}</span>
          </td>
          <td v-if="ass !== 1">
            <div class="ass-score" v-if="ass >= 2">
              <span>{{conf.directorNum > 0 ? conf.directorScore || '未打分' : '--'}}</span>
            </div>
            <div class="ass-per" v-else-if="conf.type == 1">
              <span>{{conf.directorNum}}%</span>
            </div>
            <div v-else>--</div>
          </td>
          <td v-if="ass >= 2">
            <div v-if="conf.directorBasis">
              <div v-for="(p, i) in conf.directorBasis.split(/\r|\n/)" :key="i">{{p}}</div>
            </div>
            <span v-else>{{conf.directorNum > 0 ? '无' : '--'}}</span>
          </td>
          <td v-if="ass == 3">{{conf.score}}</td>
          <td v-if="edit || (ass && ass < 3)">
            <a-space v-if="edit">
              <div class="txt-btn" @click="editItem(i)">修改</div>
              <div class="txt-btn" @click="delItem(i)">删除</div>
            </a-space>
            <div class="txt-btn" @click="scoreItem(i)" v-else-if="(conf.selfNum > 0 && ass == 1) || (conf.directorNum && ass == 2)">评分</div>
            <span v-else>--</span>
          </td>
        </tr>
        </tbody>
      </table>
      <a-modal
        title="选择模板"
        :maskClosable="false"
        :closable="false"
        :destroyOnClose="true"
        :width="600"
        @ok="modalConfirm"
        v-model="visible">
        <data-list
            hide-action
            :height="500"
            :url="url"
            :search-key-type="{name: true}"
            :row-selection="{type: 'radio', selectedRowKeys: selectKeys}"
            :columns="columns"
            @rowClick="handleRowClick">
            <template v-slot:query="{form}">
                <a-form-model-item label="">
                    <a-input v-model="form.name" placeholder="输入模板名称查询"/>
                </a-form-model-item>
            </template>
        </data-list>
      </a-modal>
      <a-drawer
        :title="drawerTitle"
        placement="right"
        :mask-closable="false"
        :visible="rule !== null"
        :width="480"
        :body-style="{paddingBottom: '69px'}"
        @close="onClose">
        <form-area
          ref="assForm"
          class="flex-box vertical"
          hide-btn
          :items="items"
          :entity="rule || {}"></form-area>
        <a-space class="footer rule-drawer-footer">
          <a-button @click="onClose">取消</a-button>
          <a-button type="primary" @click="drawerConfirm">确定</a-button>
        </a-space>
      </a-drawer>
  </div>
</template>

<script>
    import {getAssStatusName, getAssTypeName, getAssRuleType, getSysRuleText, getSystemAssTypeText, assForm, scoreForm, subjectiveRuleForm, objectiveRuleForm} from "../../common/hr/ass";
    import {clone} from "../../common/js/tool";

    export default {
        name: "ass-rules",
        model: {
            event: "change"
        },
        props: {
            value: [Object, String, Array],
            ass: Number, // 1 自评 2主管评价 3评价结果 不传则不是打分表格
            edit: {
                type: Boolean,
                default: true
            },
            multiple: Boolean
        },
        data() {
            return {
                config: [],
                drawerType: 1,// 1评价项表单 2打分表单
                index: null,
                visible: false,
                url: '/admin/evaluate-temp',
                columns:[
                    {title: '模板名称', dataIndex: 'name'},
                    {
                        title: '评价类型',
                        dataIndex: 'type',
                        customRender: text => {
                            return <span>{getAssTypeName(text)}</span>
                        }
                    },
                    {
                        title: '启用/禁用',
                        dataIndex: 'status',
                        customRender: text => {
                            return <span>{getAssStatusName(text)}</span>
                        }
                    },
                ],
                rule: null,
                selectKeys: [],
                selectItems: []
            }
        },
        computed: {
            items() {
                let drawerType = this.drawerType;
                let multiple = this.multiple;
                let res = [];
                if(drawerType == 1) {
                    res = clone(assForm);
                    res[0].listeners = {
                        change: function (val) {
                            let list = [];
                            if(val == 1) {
                                list = clone(subjectiveRuleForm);
                            } else if(val == 2) {
                                list = clone(objectiveRuleForm);
                                let normItem = list.find(c => c.key === 'normList');
                                if(normItem) {
                                    normItem.props.type = this.form.assType;
                                }
                            }
                            if(!multiple) {
                                let index = list.findIndex(c => c.key === 'percent');
                                if(index >= 0) {
                                    list.splice(index, 1);
                                }
                            }
                            this.controls.splice(1, this.controls.length - 1, ...list);
                        }
                    }
                } else if(drawerType == 2) {
                    let ass = this.ass;
                    // 自评
                    if(ass == 1) {
                        res = clone(scoreForm);
                        res[0].key = "selfScore";
                        res[1].key = "selfBasis";
                    } else if(ass == 2) {
                        res = clone(scoreForm);
                        res[0].key = "directorScore";
                        res[1].key = "directorBasis";
                    }
                }
                return res;
            },
            drawerTitle() {
                let drawerType = this.drawerType;
                let multiple = this.multiple;
                let res = "";
                if(drawerType == 1) {
                    res =  multiple ? "设置评价项" : '设置评价内容';
                } else if(drawerType == 2) {
                    let ass = this.ass;
                    res =  ass == 1 ? '自评' : "主管评价";
                }
                return res;
            },
        },
        watch: {
            value() {
                this.setConfig();
            },
        },
        created() {
            this.setConfig();
        },
        methods: {
            getSysRuleText,
            getAssRuleType,
            getSystemAssTypeText,
            setConfig() {
                let value = this.value;
                if(value) {
                    if(typeof value === 'string') {
                        try {
                            value = JSON.parse(value);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                    if(!this.multiple) {
                        value = [value];
                    }
                    value.forEach(item => {
                        if(!item.type) {
                            item.type = 1;
                        }
                    })
                    this.config = value;
                } else {
                    this.config = [];
                }
            },
            onClose() {
                this.rule = null;
            },
            scoreItem(i) {
                this.index = i;
                let item = this.config[i];
                this.drawerType = 2;
                this.rule = {...item}
            },
            editItem(i) {
                this.index = i;
                let item = this.config[i];
                this.rule = {...item};
                this.drawerType = 1;
                this.$nextTick(() => {
                    this.items[0].listeners.change.call(this.$refs.assForm, item.type);
                })
            },
            delItem(i) {
                this.config.splice(i, 1);
                this.dispatchChange();
            },
            drawerConfirm() {
                this.$refs.assForm.handleConfirm().then(form => {
                    if(this.drawerType == 1) {
                        this.updateAssConfig(form);
                    } else if(this.drawerType == 2 ){
                        this.setScore(form)
                    }
                });
            },
            setScore(form) {
                let index = this.index;
                if(index !== null) {
                    let item = this.config[index];
                    let keys = this.items.map(con => con.key);
                    keys.forEach(key => {
                        this.$set(item, key, form[key]);
                    });
                }
                this.rule = null;
                this.dispatchChange();
            },
            updateAssConfig(form) {
                let index = this.index;
                if(form.type == 1 && form.selfNum + form.directorNum !== 100) {
                    this.$message.warning("评价项之和必须等于100%");
                    return;
                }
                let item;
                if(form.type == 1) {
                    item = {
                        type: form.type,
                        items: form.items,
                        norm: form.norm,
                        selfNum: form.selfNum,
                        directorNum: form.directorNum
                    }
                } else {
                    item = {
                        type: form.type,
                        assType: form.assType,
                        normList: form.normList
                    }
                }
                if(this.multiple) {
                    item.percent = form.percent;
                }
                if(index === null) {
                    this.config.push(item);
                } else {
                    this.config.splice(index, 1, item);
                }
                this.rule = null;
                this.dispatchChange();
            },
            modalConfirm() {
                let items = this.selectItems;
                if(items.length > 0) {
                    let item = items[0];
                    let detail = item.detail;
                    try {
                        detail = JSON.parse(detail);
                        this.addConfigItem(detail);
                    } catch (e) {
                        console.error(e);
                        this.addConfigItem();
                    }
                }
                this.visible = false;
            },
            handleRowClick({item}) {
                let keys = this.selectKeys;
                if(keys.length > 0 && keys[0] == item.id) {
                    this.selectKeys = [];
                    this.selectItems = [];
                } else {
                    this.selectKeys = [item.id];
                    this.selectItems = [item];
                }
            },
            addConfigItem(rule) {
                this.drawerType = 1;
                this.rule = rule || {};
            },
            dispatchChange() {
                let config = this.config;
                if(config && config.length > 0) {
                    this.$emit("change", this.multiple ? [...config] : config[0]);
                } else {
                    this.$emit("change", null);
                }
            }
        }
    }
</script>

<style lang="less">
  .ass-rules {
    padding-bottom: 10px;
  }
  .ass-rule-table {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    line-height: 1.3;
    table, tr, td, th {
      border: var(--border);
    }
    th {
      white-space: nowrap;
    }
    td, th {
      padding: 6px 12px;
    }
  }
  .rule-drawer-footer {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
  }
</style>
