<template>
  <div class="buddha-select-date" v-if="first && buddhaBirthList">
    <calendar
      ref="calendar"
      class="buddha-time-calendar"
      :month-style="{padding: 0}"
      :week-style="{height: '32px', lineHeight: '32px'}"
      lunar
      :value="first"
      @month="val => current = val">
      <template v-slot:full="{day}">
        <div class="buddha-time-date"
             :class="{'add-day-select': dayObj && dayObj.dateStr === day.dateStr, 'add-day-disable': !day.state}"
             @click.stop.prevent="handleSelect(day)" v-if="day.inMonth">
          <div class="time-date-info">
            <div class="time-date-num">{{day.day}}</div>
            <div class="time-date-lunar">{{day.lunar.lnongDate == "初一" ? `${day.lunar.lnongMonth}月` : day.lunar.lnongDate}}</div>
          </div>
          <div class="time-date-state" :class="[`date-state-${day.state}`]" v-if="day.stateText">{{day.stateText}}</div>
        </div>
      </template>
    </calendar>
    <div class="select-buddhism-time">
        <template v-if="dayObj">
          <div class="buddhism-time-tip" v-if="dayObj.state == 0">此日期不可约，请选择其他日期</div>
          <template v-else>
            <div class="buddhism-time-item">
              <div class="buddhism-time-label">预约日期</div>
              <div class="buddhism-time-input">{{dayObj.dateStr}}</div>
            </div>
            <div class="buddhism-time-item">
              <div class="buddhism-time-label">预约人数</div>
              <div class="buddhism-time-input">{{reserve[dayObj.dateStr] ? reserve[dayObj.dateStr].length : 0}}/{{dayObj.limit == -1 ? '不限' : dayObj.limit || 0}}</div>
            </div>
            <div class="buddhism-time-item">
              <div class="buddhism-time-label">时间段</div>
              <div class="buddhism-time-input">
                <a-select size="small" v-model="range" style="width:160px;" placeholder="请选择时间段" :disabled="edit" @change="dispatchChange" v-if="dayObj.range && dayObj.range.length > 0">
                  <a-select-option :disabled="r.left == 0" v-for="r in dayObj.range" :key="r.rangeId">
                    <div class="buddhism-time-range">{{getRangeName(r.rangeId)}} <span>{{r.left == -1 ? '不限' : `剩余${r.left}`}}</span></div>
                  </a-select-option>
                </a-select>
                <span v-else>暂无可选的时间段</span>
              </div>
            </div>
          </template>
        </template>
        <div class="buddhism-time-tip" v-else>请选择佛事日期</div>
    </div>
  </div>
</template>

<script>
    import calendar from "../calendar/calendar";
    import {getBuddhaNormalTimeConfig, getBuddhaSpecialTimeConfig} from "../../common/buddha/type";
    import {clone, getDate, isArray} from "../../common/js/tool";
    import {getLunarDay} from "../calendar/date";

    const oneDay = 24 * 60 * 60 * 1000;

    export default {
        name: "select-date",
        model: {
            event: "change"
        },
        components: {calendar},
        props: {
            value: [String, Object],
            fsId: Number, // 当前派单的fs id
            info: Object, // fs-temp 实体
            type: String, // 佛事/法会种类
            edit: Boolean,
            history: Boolean,
        },
        data() {
            return {
                normal: null,
                special: {},
                reserve: {},
                current: null,
                advance: null,
                first: null, // 最近一个在预约范围内的日期
                today: null,
                dayObj: null,
                select: null,
                fullRange: [],
                range: undefined
            }
        },
        computed: {
            buddhaBirthList() {
                return this.$store.getters.buddhaBirthList;
            },
        },
        watch: {
            info() {
                this.init();
            },
            history() {
                this.init(true);
            },
            type() {
                this.setAdvance();
                this.setCalendarTable();
            },
            current() {
                if(this.history) {
                    this.setCalendarTable();
                    this.setDay();
                } else {
                    this.getSpecialTime().then(this.setDay);
                }
            },
            value() {
                this.setSelect();
            },
        },
        created() {
            this.$store.dispatch("getBuddhaBirthList", true);
            let today = new Date();
            this.today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        },
        mounted() {
            this.init();
        },
        methods: {
            init(update) {
                // 历史数据从之前日期中选择
                if(this.history) {
                    this.reset();
                    const today = new Date();
                    today.setDate(today.getDate() - 1);
                    this.first = today.pattern("yyyy-MM-dd");
                    this.$store.dispatch("getBuddhaTimeRange").then(range => {
                        this.fullRange = range;
                        this.setCalendarTable();
                    });
                } else {
                    if (this.info) {
                        if(this.info.common) {
                            let info = this.info;
                            if (info.common && info.common.length > 0) {
                                let normal = info.common[0];
                                try {
                                    if (normal.config) {
                                        let conf = JSON.parse(normal.config);
                                        this.normal = conf;
                                        this.setCommonConfig(update);
                                    }
                                } catch (e) {
                                    console.error(e)
                                }
                            }
                        } else {
                            getBuddhaNormalTimeConfig(this.info).then(normal => {
                                this.normal = normal;
                                this.setCommonConfig(update);
                            })
                        }
                    } else {
                        this.reset();
                    }
                }
                this.setSelect();
            },
            setCalendarTable() {
                this.$refs.calendar.table.forEach(tr => {
                    tr.forEach(day => {
                        this.setDayState(day);
                    })
                });
            },
            dispatchChange() {
                let val = this.range;
                if(val) {
                    this.$emit("change", {date: this.dayObj.dateStr, range: val});
                } else {
                    this.$emit("change", null)
                }
            },
            setSelect() {
                let value = this.value;
                if(value && typeof value === 'object') {
                    this.select = value.date;
                    this.range = value.range;
                } else {
                    this.select = null;
                    this.range = undefined;
                }
            },
            reset() {
                this.normal = null;
                this.dayObj = null;
                this.advance = null;
                this.first = null;
                this.range = undefined;
                this.special = {};
                this.reserve = {};
            },
            setCommonConfig(update) {
                this.setAdvance();
                this.setFirst();
                update && this.getSpecialTime().then(this.setDay);
            },
            setFirst() {
                let normal = this.normal;
                let advance = this.advance;
                let res = null;
                let today = this.select ? getDate(this.select) : this.today;
                if (today) {
                    if (advance) {
                        res = today.getTime() + advance[0] * oneDay;
                    } else if (normal && normal.groupDateRange) {
                        let s = getDate(normal.groupDateRange[0] + ' 00:00:00').getTime();
                        if (s > today.getTime()) {
                            res = s;
                        } else {
                            res = today.getTime();
                        }
                    }
                    res = res ? new Date(res).pattern("yyyy-MM-dd") : null;
                }
                this.first = res;
            },
            setAdvance() {
                let normal = this.normal;
                let advance = null;
                if (normal) {
                    if (this.info.fs_type == 1) {
                        let subKey = ["single", "single", "group", "group"][this.type * 1 - 1];
                        advance = normal[`${subKey}Advance`];
                    } else {
                        advance = normal.advance;
                    }
                }
                this.advance = advance;
            },
            setDay() {
                // 当月不包含 select 日期 计算select日期状态
                if(!this.dayObj && this.select) {
                    let date = new Date(this.select);
                    let day = {
                        dateStr: this.select,
                        lunar: getLunarDay(date),
                        date
                    }
                    this.setDayState(day);
                    this.dayObj = day;
                }
            },
            getRangeName(id) {
                let timeRangeList = this.history ? this.fullRange : this.normal ? this.normal.timeRangeList : null;
                let range = null;
                if(timeRangeList) {
                    range = timeRangeList.find(r => r.id == id);
                }
                return range ? range.name : '';
            },
            handleSelect(day) {
                if (day.state == 1) {
                    this.dayObj = day;
                    // 当天只有一个时间段默认选中
                    if(day.range && day.range.length == 1) {
                        this.range = day.range[0].rangeId;
                    }
                    this.dispatchChange();
                }
            },
            getSpecialTime() {
                return getBuddhaSpecialTimeConfig(this.info, this.current, true).then(({special, reserve}) => {
                    this.special = special;
                    this.reserve = reserve;
                    this.setCalendarTable();
                });
            },
            // 获取系统配置的所有时间段
            dealTime(special) {
                this.special = special;
            },
            setDayState(day) {
                let state = 0;
                let num = 0; //当天可预约总数
                if(this.history) {
                    state = day.todayOffset < 0 ? 1 : 0;
                    num = -1;
                    day.range = this.fullRange.map(item => {
                        return {
                            rangeId: item.id,
                            left: -1,
                            limit: -1,
                        }
                    });
                } else {
                    let normal = this.normal;
                    day.range = null;
                    let info = this.info;
                    if (normal) {
                        let special = this.special;
                        let str = day.dateStr;
                        // 普佛单独处理
                        if (info.fs_type == 1) {
                            let subKey = ["single", "single", "group", "group"][this.type * 1 - 1];
                            let advance = normal[`${subKey}Advance`];
                            let timeType = normal[`${subKey}TimeType`];
                            let key = ["singleLive", "singleDie", "groupLive", "groupDie"][this.type * 1 - 1];
                            let flag = false;
                            if(subKey == 'group') {
                                flag = this.dayInRange(day, normal.groupDateRange) && this.dayInType(day, timeType);
                            } else if(subKey == 'single') {
                                // 单独普佛先判断是否是可预约日期
                                flag = this.dayInAdvance(day, advance) && this.dayInType(day, timeType);
                                // 如果是可预约日期 且众信普佛预约日期内不可预约单独普佛 还需判断当天是否是众信普佛预约日
                                if(flag && !normal.coexist) {
                                    flag = !(this.dayInRange(day, normal.groupDateRange) && this.dayInType(day, normal.groupTimeType));
                                }
                            }
                            if (flag) {
                                if (special[str]) {
                                    let conf = special[str];
                                    if (!conf.disable && conf.subTypes.indexOf(key) >= 0) {
                                        day.range = conf[`${key}Range`];
                                    }
                                } else if (normal[`${subKey}Types`].indexOf(key) >= 0) {
                                    day.range = normal[`${key}Range`];
                                }
                            }
                        } else {
                            let advance = normal.advance;
                            if (this.dayInAdvance(day, advance)) {
                                if (special[str]) {
                                    let conf = special[str];
                                    if (!conf.disable) {
                                        day.range = conf.timeRange;
                                    }
                                } else if (normal.timeRange) {
                                    day.range = normal.timeRange;
                                }
                            }
                        }
                    }
                    let range = clone(day.range);
                    if (range && range.length > 0) {
                        state = 1;
                        let rl = this.reserve[day.dateStr];
                        // 普佛筛选出同子类型的预约记录
                        if(rl && info.fs_type == 1) {
                            rl = rl.filter(r => this.type === r.tabletType);
                        }
                        range.forEach(item => {
                            if(num >= 0) {
                                num += item.limit;
                            }
                            if(rl && rl.length > 0) {
                                item.reserveList = rl.filter(r => r.time_range == item.rangeId && (info.fs_type != 1 || this.type === r.tabletType));
                                if(item.limit == -1) {
                                    item.left = -1;
                                } else {
                                    let left = item.limit - item.reserveList.length;
                                    left = left < 0 ? 0 : left;
                                    item.left = left;
                                }
                            } else {
                                item.left = item.limit;
                            }
                        });
                        if(num >= 0 && rl && rl.length >= num) {
                            state = 2;
                        }
                    }
                    day.range = range;
                }
                this.$set(day, 'limit', num);
                this.$set(day, 'state', state);
                this.$set(day, 'stateText', ['未开放', '可选', '约满'][state]);
                if(day.dateStr == this.select) {
                    this.dayObj = day;
                }
            },
            getDateState(day) {
                this.setDayState(day);
            },
            // 是否在可预约日期范围内
            dayInAdvance(day, advance) {
                let offset = Math.round((day.date.getTime() - this.today.getTime()) / oneDay);
                return offset >= advance[0] && offset <= advance[1];
            },
            // 是否在开放预约时间范围内
            dayInRange(day, range) {
                let start = getDate(range[0] + " 00:00:00").getTime();
                // 只能从当天下一天开始预约
                let tomorrow = this.today.getTime() + oneDay;
                if(start < tomorrow) {
                    start = tomorrow;
                }
                let end = getDate(range[1] + " 00:00:00").getTime();
                let now = day.date.getTime();
                return now >= start && now <= end;
            },
            // 是否是每天或者初一、十五、佛诞日
            dayInType(day, timeType) {
                let flag = false;
                if(isArray(timeType)) {
                    const lunar = day.lunar;
                    flag = (timeType.indexOf(1) >= 0 && lunar.lnongDate == '初一')
                        || (timeType.indexOf(2) >= 0 && lunar.lnongDate == '十五')
                        || (timeType.indexOf(3) >= 0 && this.buddhaBirthList.findIndex(day => day.lunarDay == `${lunar.lnongMonth}月${lunar.lnongDate}`) >= 0);
                } else {
                    flag = timeType == 1 || (timeType == 2 && (day.lunar.lnongDate == '初一' || day.lunar.lnongDate == '十五'));
                }
                return flag
            }
        }
    }
</script>

<style scoped lang="less">
  .buddha-select-date {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  .buddha-time-calendar {
    line-height: 1.2;
    .buddha-time-date {
      padding: 4px;
      height: 48px;
    }
    .time-date-state {
      margin-top: 4px;
    }
  }
  .buddha-time-date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6px 10px;
    height: 60px;
    &.add-day-select {
      background-color: @primary-color;
      color: @white;
    }
  }
  .time-date-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .time-date-state {
    margin-top: 6px;
    text-align: right;
    font-size: 12px;
    color: @text-color-secondary;
    &.date-state-1 {
      color: @success-color;
    }
    &.date-state-2 {
      color: #bbb;
    }
  }
  .time-date-lunar {
    font-size: 12px;
  }
  .add-day-disable {
    color: #ccc;
    cursor: auto;
    .time-date-state {
      display: none;
    }
  }
  .select-buddhism-time {
    padding: 6px 10px;
    border: var(--border);
    border-top: 0;
    line-height: 1.5;
  }
  .buddhism-time-item {
    display: flex;
    &:not(:first-of-type) {
      margin-top: 6px;
    }
  }
  .buddhism-time-label {
    min-width: 80px;
  }
  .buddhism-time-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5;
    span {
      margin-left: 10px;
      font-size: 12px;
      color: @orange-6;
    }
  }
  .buddhism-time-tip {
    color: @error-color;
  }
</style>
