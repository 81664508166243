<template>
    <div class="form-box">
        <a-form-model
                class="form-area"
                :class="[`form-${layout}`]"
                ref="ruleForm"
                :label-align="labelAlign"
                :model="form"
                :rules="rules"
                :layout="layout"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
            <template v-if="layout == 'inline'">
                <a-form-model-item :label="item.label" :prop="item.key" v-for="(item, i) in controls" :key="i">
                    <slot :name="item.slot" v-bind="{form, control: item}" v-if="item.slot"></slot>
                    <component
                        :is="item.component"
                        :style="item.style"
                        v-model="form[item.key]"
                        v-bind="{...item.props, disabled}"
                        v-on="encapsulationListeners(item)"
                        v-else>
                    </component>
                </a-form-model-item>
            </template>
            <a-row :gutter="24" v-else>
                <a-col v-for="(item, i) in controls" :key="i" :span="item.span || defaultSpan">
                    <a-form-model-item :label="item.label" :prop="item.key" :class="[`form-item-${formItemRowType}`]">
                        <slot :name="item.slot" v-bind="{form, control: item}" v-if="item.slot"></slot>
                        <component
                            :is="item.component"
                            :style="item.style"
                            v-model="form[item.key]"
                            v-bind="{...item.props, disabled: (item.props && item.props.disabled) || disabled}"
                            v-on="encapsulationListeners(item)"
                            v-else>
                        </component>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <slot></slot>
        </a-form-model>
        <a-space class="footer" v-if="!hideBtn">
            <a-button @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="handleConfirm">{{confirmText}}</a-button>
        </a-space>
    </div>
</template>

<script>
    import {clone} from "../common/js/tool";
    import clTk from "./controls/cl-tk";
    import clAddress_book from "./controls/cl-address_book";
    import clSubscribe from "./controls/cl-subscribe";
    import clSelect from "./controls/cl-select";

    export default {
        name: "form-area",
        components: {
            clTk,
            clAddress_book,
            clSubscribe,
            clSelect
        },
        props: {
            entity: {
                type: Object,
                default() {
                    return {};
                }
            },
            items: Array,
            extraRules: Object,
            labelCol: Object,
            wrapperCol: Object,
            labelAlign: {
                type: String,
                default: 'left'
            },
            layout: {
                type: String,
                default: 'vertical'
            },
            confirmText: {
                type: String,
                default: '保存'
            },
            direct: Boolean, // 是否直接修改传入的 entity
            disabled: Boolean,
            hideBtn: Boolean,
            formItemRowType: String
        },
        data() {
            return {
                controls: [],
                form: {},
                defaultSpan: 24
            }
        },
        computed: {
            rules() {
                let items = this.controls;
                let rules = {};
                if(items) {
                    items.forEach(item => {
                        if(item.rules) {
                            rules[item.key] = item.rules;
                        }
                    });
                }
                if(this.extraRules) {
                    rules = Object.assign(rules, this.extraRules);
                }
                return rules;
            },
        },
        watch: {
            items(val) {
                this.controls = clone(val);
            },
            entity() {
                this.form = {};
                this.setForm();
            }
        },
        created() {
            this.controls = clone(this.items);
            this.setForm();
        },
        methods: {
            setForm() {
                const form = this.direct ? this.entity : clone({...this.entity, ...this.form});
                this.form = form;
                this.controls.forEach(item => {
                    if(item.listeners) {
                        for(let key in item.listeners) {
                            let handler = item.listeners[key];
                            const value = item.component === 'a-radio-group' ? {target: {value: form[item.key]}} : form[item.key];
                            // 最后一个参数 是否是初始化表单标志位
                            handler.call(this, value, true);
                        }
                    }
                })
            },
            encapsulationListeners(con) {
                let res = {};
                const listeners = con.listeners;
                let flag = true;
                if(listeners) {
                    for(let key in listeners) {
                        let handler = listeners[key];
                        if(key == "change" || key == "blur") {
                            res[key] = $event => {
                                handler.call(this, $event);
                                this.$emit("formChange", { value: $event, key: con.key, control: con });
                            }
                            flag = false;
                        } else {
                            res[key] = $event => {
                                handler.call(this, $event);
                            }
                        }
                    }
                }
                if(flag) {
                    const key = con.component === "a-input" ? "blur" : "change";
                    res[key] = $event => {
                        this.$emit("formChange", { value: $event, key: con.key, control: con });
                    }
                }
                return res;
            },
            handleConfirm() {
                return new Promise((resolve, reject) => {
                    this.$refs.ruleForm.validate(valid => {
                        if (valid) {
                            this.$emit("confirm", this.form);
                            resolve(this.form);
                        } else {
                            reject("form error");
                        }
                    });
                })
            },
            handleCancel() {
                this.$emit("cancel");
            }
        }
    }
</script>

<style lang="less">
    .form-box {
        display: flex;
        flex-direction: column;
        .form-area {
            flex: 1;
            padding: 24px;
            overflow: auto;
            &.form-inline {
                padding: 0;
            }
            .form-item-flex {
                display: flex;
            }
        }
    }
</style>
