const programOverview = {
    id: "programOverview",
    router: {
        path: "/program/overview",
        name: "ProgramOverview",
        component: () => import(/* webpackChunkName: "program-overview" */ "../../views/program/ProgramOverview.vue"),
        meta: {
            title: "部门数据看板",
        },
    },
};

// const departmentOverview = {
//     id: "departmentOverview",
//     router: {
//         path: "/department/overview",
//         name: "DepartmentOverview",
//         component: () => import(/* webpackChunkName: "department-overview" */ "../../views/program/department/Security.vue"),
//         meta: {
//             title: "部门数据看板",
//         },
//     },
// };

const plan = {
    id: "plan",
    router: {
        path: "/program/plan",
        name: "ProgramPlan",
        component: () => import(/* webpackChunkName: "program-plan" */ "../../views/program/Plan.vue"),
        meta: {
            title: "工作计划",
        },
    },
};

const contractSetting = {
    id: "contractSetting",
    router: {
        path: "/program/contract-setting",
        name: "ContractSetting",
        component: () => import(/* webpackChunkName: "contract-setting" */ "../../views/program/ContractSetting.vue"),
        meta: {
            title: "合同设置",
        },
    },
};

const contract = {id: 'contract', entity: 'contract'};

const maintenance = {
    id: "maintenance",
    router: {
        path: "/program/maintenance",
        name: "Maintenance",
        component: () => import(/* webpackChunkName: "maintenance" */ "../../views/program/Maintenance.vue"),
        meta: {
            title: "维保设置",
        },
    },
};

const maintenanceOrder = {
    id: "maintenanceOrder",
    router: {
        path: "/program/maintenance-order",
        name: "MaintenanceOrder",
        component: () => import(/* webpackChunkName: "maintenance-order" */ "../../views/program/MaintenanceOrder.vue"),
        meta: {
            title: "维保单管理",
        },
    },
};

const bottledWater = {
    id: "bottledWater",
    router: {
        path: "/program/bottled-water",
        name: "BottledWater",
        component: () => import(/* webpackChunkName: "bottled-water" */ "../../views/program/BottledWater.vue"),
        meta: {
            title: "桶装水领用",
        },
    },
};

const permissionSettings = {
    id: "permissionSettings",
    router: {
        path: "/program/permission-settings",
        name: "PermissionSettings",
        component: () => import(/* webpackChunkName: "permission-settings" */ "../../views/program/PermissionSettings.vue"),
        meta: {
            title: "权限设置",
        },
    },
};

const satisfaction = {
    id: "satisfaction",
    router: {
        path: "/program/satisfaction",
        name: "Satisfaction",
        component: () => import(/* webpackChunkName: "satisfaction" */ "../../views/program/Satisfaction.vue"),
        meta: {
            title: "满意度调查",
        },
    },
};

export default {
    programOverview,
    // departmentOverview,
    plan,
    bottledWater,
    contract,
    contractSetting,
    maintenance,
    maintenanceOrder,
    permissionSettings,
    satisfaction,
}

export const routers = [
    {
        type: 1,
        list: [
            {id: 'programOverview', action: ['add', 'delete', 'update', 'query', 'audit']},
            // {id: 'departmentOverview', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'plan', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'bottledWater', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'maintenance', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'maintenanceOrder', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'contract', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'contractSetting', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'permissionSettings', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'satisfaction', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    }
]

export const menus = [
    {
        type: 1,
        list: [
            {
                name: "overview",
                title: '数据看板',
                path: '/program/overview',
                icon: 'table'
            },
            // {
            //     title: '部门数据看板',
            //     path: '/department/overview',
            //     icon: 'table'
            // },

            {
                name: "water",
                title: '桶装水领用',
                path: '/program/bottled-water',
                icon: 'book'
            },
            {
                name: "plan",
                title: '工作计划',
                path: '/program/plan',
                icon: 'book'
            },
            {
                name: "satisfaction",
                title: '满意度调查',
                path: '/program/satisfaction',
                icon: 'book'
            },
            {
                name: "maintenance",
                title: '维保单管理',
                path: '/program/maintenance-order',
                icon: 'book'
            },
            {
                name: "maintenanceSet",
                title: '维保设置',
                path: '/program/maintenance',
                icon: 'book'
            },
            {
                name: "contract",
                title: '合同管理',
                path: '/entity/contract',
                icon: 'book'
            },
            {
                name: "settings",
                title: '权限设置',
                path: '/program/permission-settings',
            },
        ]
    }
]

export const specialDeptList = [
    {
        name: "库房——物",
        component: "Warehouse",
        dept_id: 10,
        router_ids: ["bottledWater"], menus: ["water"]
    },
    {
        name: "志工团",
        component: "Volunteer",
        dept_id: 129,
        router_ids: ["plan"], menus: ["plan"]
    },
    {
        name: "监院四（安保）、安保部",
        component: "Security",
        dept_id: 15
    },
    {
        name: "客堂",
        component: "Classroom",
        dept_id: 100,
        router_ids: ["satisfaction"],
        menus: ["satisfaction"]
    },
    {
        name: "斋堂",
        component: "Refectory",
        dept_id: 20
    },
    {
        name: "工程部",
        component: "Engineering",
        dept_id: 19,
        router_ids: ["maintenanceOrder", "maintenance", "contract", "contractSetting"],
        menus: ["maintenance", "maintenanceSet", "contract", "settings"]
    },
]