<template>
  <a-modal
    :title="title"
    :visible="visible"
    :width="800"
    :ok-text="form && index === -1 ? '保存' : '确定'"
    @ok="handleOk"
    @cancel="handleCancel">
    <div class="ass-item-select">
      <div class="items-select-top">
        <div class="items-select-total">已选择{{selectList.length}}条</div>
        <a-button type="link" @click="addItem" v-if="type === 1">+ 添加</a-button>
      </div>
      <div class="items-select-area">
        <div class="items-temp">
          <div class="items-temp-search">
            <a-input-search
              v-model="keyword"
              placeholder="输入指标名称搜索"
              style="width: 100%"
              @search="onSearch" />
          </div>
          <div class="items-temp-list" v-if="showTemps.length > 0">
            <div class="temp-select-item" :class="{selected: index === i}" @click="selectItem(i)" v-for="(item, i) in showTemps" :key="i">
              <div class="temp-select-check" @click.stop>
                <a-checkbox :checked="ids.indexOf(item.id) >= 0" @change="handleCheck($event, item)" v-if="item.id" />
              </div>
              <div class="temp-item-name">{{ item.name || "请输入指标名称" }}</div>
              <div class="temp-item-score" v-if="item.percent">{{ item.percent }}分</div>
              <div class="temp-select-remove">
                <a-icon type="close" v-if="!item.id" @click.stop="removeItem(i)" />
              </div>
            </div>
          </div>
          <div class="items-temp-empty" v-else>{{ keyword ? '暂无搜索结果' : '暂无指标模板，请点击添加'}}</div>
        </div>
        <div class="items-form">
          <form-area
            ref="normForm"
            hide-btn
            layout="vertical"
            :items="items"
            :entity="form || {}"
            v-show="form">
          </form-area>
        </div>
      </div>
      <div class="items-select-tip">注：1.主观项与客观项的所有单项指标权重相加须等于100%，所有单项总分相加须等于100分；2.单项评价指标需满足：自评权重+主管评权重=100%；3.单项总分=单项指标权重*100%。</div>
    </div>
  </a-modal>
</template>

<script>
    import {
        assTempBaseForm,
        dealAssTemp,
        getAssRuleType, getSourceNorm,
        objectiveTempExtraForm,
        subjectiveTempExtraForm
    } from "@/common/hr/ass";
    import {clone, omit} from "@/common/js/tool";

    export default {
        name: "ass-item-select",
        props: {
            visible: Boolean,
            value: Array,
            type: Number, // 1主观项 2客观项
        },
        data() {
            return {
                keyword: "",
                source: [], // 指标模板数据源
                ids: [],
                tempList: [],
                index: -1,
                form: null
            }
        },
        computed: {
            title() {
                return getAssRuleType(this.type);
            },
            subjectiveTemps() {
                return this.source.filter(item => item.type === 1);
            },
            objectiveTemps() {
                return this.source.filter(item => item.type === 2);
            },
            baseTemps() {
                return clone(this.type === 1 ? this.subjectiveTemps : this.objectiveTemps);
            },
            items() {
                const res = assTempBaseForm.filter(item => item.key !== 'type');
                if(this.type === 1) {
                    res.push(...subjectiveTempExtraForm);
                } else if(this.type === 2) {
                    res.push(...objectiveTempExtraForm);
                }
                return res;
            },
            // 含搜索条件展示的指标列表
            showTemps() {
                let res = this.tempList;
                const keyword = this.keyword;
                if(keyword) {
                    res = res.filter(item => item.name.indexOf(keyword) >= 0);
                }
                return res;
            },
            selectList() {
                return this.tempList.filter(item => !item.id || this.ids.indexOf(item.id) >= 0);
            }
        },
        watch: {
            visible(val) {
                if(val) {
                    this.setTempList();
                } else {
                    this.form = null;
                    this.index = -1;
                }
            },
        },
        created() {
            this.getTemps();
        },
        methods: {
            selectItem(i) {
                const item = this.showTemps[i];
                const index = this.index;
                if(this.form) {
                    if(index >= 0) {
                        this.checkForm().then(form => {
                            this.showTemps[this.index] = form;
                            this.index = i;
                            this.form = item;
                        });
                    } else {
                        this.$confirm({
                            title: '提示',
                            content: '当前添加的指标未保存，确定放弃此次添加吗？',
                            onOk: () => {
                                const item = this.showTemps[i];
                                this.index = i;
                                this.form = item;
                            },
                        });
                    }
                } else {
                    this.index = i;
                    this.form = item;
                }
            },
            handleCheck(e, item) {
                const checked = e.target.checked;
                const id = item.id;
                const i = this.ids.indexOf(id);
                if(checked && i === -1) {
                    this.ids.push(id);
                } else if(!checked && i >= 0) {
                    this.ids.splice(i, 1);
                }
            },
            removeItem(i) {
                const item = this.showTemps[i];
                const index = this.tempList.findIndex(n => n.key == item.key);
                if(index >= 0) {
                    this.tempList.splice(index, 1);
                    if(i === this.index) {
                        this.index = -1;
                        this.form = null;
                    }
                }
            },
            addItem() {
                this.index = -1;
                this.form = {
                    key: Date.now(),
                    type: this.type
                };
            },
            // 供外部ref调用
            editItem(item) {
                this.$nextTick(() => {
                    const i = getSourceNorm(this.showTemps, item, true);
                    // 找到对应index下标 手动选中
                    if(i >= 0) {
                        this.selectItem(i);
                    } else {
                        // 未找到视为新增
                        this.index = -1;
                        this.form = {
                            ...item,
                            key: item.key || Date.now()
                        }
                    }
                });
            },
            onSearch() {

            },
            setTempList() {
                let res = clone(this.baseTemps);
                const value = this.value;
                const ids = [];
                if(value) {
                    value.forEach(item => {
                        // 如果有id表示是从指标模板中选择的
                        if(item.id) {
                            ids.push(item.id);
                            // 找到对应模板项 覆盖
                            const index = res.findIndex(t => t.id == item.id);
                            if(index >= 0) {
                                res[index] = item;
                            } else {
                                // 找不到则新增一条
                                res.push({...item});
                            }
                        } else {
                            res.push({...item});
                        }
                    });
                }
                this.ids = ids;
                this.tempList = res;
            },
            getTemps() {
                this.$axios(`/admin/evaluate-temp?pageSize=9999`).then(res => {
                    const list = res.data;
                    this.source = list.map(item => {
                        dealAssTemp(item);
                        return omit(item, ["config", "create_time", "update_time"]);
                    });
                    this.setTempList();
                });
            },
            handleOk() {
                if(this.form) {
                    this.checkForm().then(form => {
                        if(this.index >= 0) {
                            this.showTemps[this.index] = form;
                            this.dispatchConfirm();
                        } else {
                            this.tempList.push(form);
                            this.form = null;
                        }
                    });
                } else {
                    this.dispatchConfirm();
                }
            },
            handleCancel() {
                if(this.form && this.index === -1) {
                    this.form = null;
                } else {
                    this.$emit("cancel");
                }
            },
            dispatchConfirm() {
                // 点击确定再从tempList里面查找一遍 防止有修改被覆盖
                const selectList = this.tempList
                    .filter(item => !item.id || this.ids.indexOf(item.id) >= 0)
                    // 去掉自评 （不修改逻辑 默认将自评设为0 主管考评设为100）
                    .map(item => {
                      return {
                        ...item,
                        selfNum: 0,
                        directorNum: 100
                      }
                    });
                this.$emit("confirm", selectList);
            },
            checkForm() {
                return this.$refs.normForm.handleConfirm();
            }
        }
    }
</script>

<style lang="less">
.items-select-top {
  display: flex;
  align-items: center;
}
.items-select-area {
  margin: 10px 0;
  display: flex;
  height: 400px;
  border: 1px solid @border-color-base;
  border-radius: 2px;
}
.items-select-tip {
  font-size: 12px;
  color: @text-color-secondary;
}
.items-temp {
  display: flex;
  flex-direction: column;
  width: 200px;
}
.items-temp-search {
  padding: 10px;
  border-bottom: 1px solid @border-color-base;
}
.items-temp-list {
  flex: 1;
  min-height: 0;
  overflow: auto;
}
.items-form {
  flex: 1;
  border-left: 1px solid @border-color-base;
  min-width: 0;
  overflow: auto;
}
.temp-select-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  &.selected {
    background-color: @primary-color;
    color: #ffffff;
    .temp-item-score,
    .temp-select-remove {
      color: #ffffff;
    }
  }
  &:not(.selected):hover {
    background-color: @background-color-base;
  }
  &:not(:first-of-type) {
    border-top: 1px solid @border-color-base;
  }
}
.temp-select-check {
  width: 16px;
}
.temp-item-name {
  flex: 1;
  padding: 0 5px;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.temp-item-score {
  color: @primary-color;
}
.temp-select-remove {
  margin-left: 5px;
  width: 16px;
  font-size: 16px;
  color: @text-color-secondary;
}
.items-temp-empty {
  padding: 10px;
  color: @text-color-secondary;
  text-align: center;
  font-size: 12px;
}
</style>
