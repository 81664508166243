<template>
  <a-form-model-item :colon="colon" :label-col="labelCol" :wrapper-col="wrapperCol">
    <template v-slot:label>
      <a-space size="large">
        <span>{{ label }}</span>
        <span class="txt-btn" @click.prevent="download">下载模板</span>
      </a-space>
    </template>
    <a-space>
      <upload-file :uploadText="uploadText" @change="dispatchInput"></upload-file>
      <a-button type="primary" @click="showVolunteer" v-if="ids && ids.length > 0">查看志工列表</a-button>
    </a-space>
    <a-modal v-model="visible" :maskClosable="false" title="志工列表" :footer="null">
      <a-table ref="dataTable" :columns="columns" row-key="id" :data-source="list" :loading="loading"> </a-table>
    </a-modal>
  </a-form-model-item>
</template>

<script>
import { downLoadFile, isEmpty } from "../common/js/tool";
import { volunteerTempleUrl } from "../common/hr/volunteer";

export default {
  name: "UploadVolunteer",
  model: {
    event: "change",
  },
  props: {
    value: String,
    label: String,
    // 模板地址
    url: {
      type: String,
      default: volunteerTempleUrl,
    },
    // 模板名称
    fileName: {
      type: String,
      default: "模板",
    },
    labelCol: Object,
    wrapperCol: Object,
    colon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ids: [],
      visible: false,
      list: [],
      loading: false,
      columns: [
        { title: "志工名称", dataIndex: "name" },
        { title: "志工身份证", dataIndex: "id_card" },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
    };
  },
  computed: {
    uploadText() {
      return this.list.length > 0 ? "重新上传excel文件" : "excel文件上传";
    },
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      let value = this.value;
      if (isEmpty(value)) {
        this.ids = [];
      } else {
        this.ids = value.split(",");
      }
      this.setList();
    },
    showVolunteer() {
      this.visible = true;
    },
    setList() {
      if (isEmpty(this.value)) {
        this.list = [];
      } else {
        this.getList();
      }
    },
    getList() {
      this.loading = true;
      let url = `/admin/volunteer/list`;
      this.$axios({
        url,
        method: "POST",
        data: { ids: this.ids.join(",") },
        noTempleFilter: false,
      })
        .then((res) => {
          this.loading = false;
          this.list = res.data;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    download() {
      if (isEmpty(this.url)) {
        this.$message.warning("暂无模板");
        return;
      }
      downLoadFile(this.url, this.fileName);
    },
    dispatchInput(val) {
      if (val) {
        this.$emit("change", val.join(","));
      } else {
        this.$emit("change", null);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
