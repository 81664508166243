<template>
    <a-space class="number-range">
        <a-input-number v-model="min" placeholder="请输入" @change="valueChange"></a-input-number>
        <a-icon type="line" />
        <a-input-number v-model="max" placeholder="请输入" @change="valueChange"></a-input-number>
        <span v-if="suffix">{{suffix}}</span>
    </a-space>
</template>

<script>
    import {isEmpty} from "../common/js/tool";

    export default {
        name: "number-range",
        model: {
            event: 'change'
        },
        props: {
            suffix: String,
            value: [String, Array]
        },
        created() {
            this.setValue();
        },
        data() {
            return {
                min: null,
                max: null
            }
        },
        watch: {
            value() {
                this.setValue();
            }
        },
        methods: {
            setValue() {
                let value = this.value;
                if(typeof value === 'string') {
                    value = value.split(",");
                } else if(!value) {
                    value = [];
                }
                this.min = value[0] || null;
                this.max = value[1] || null;
            },
            valueChange() {
                if(!isEmpty(this.min) && !isEmpty(this.max)) {
                    if(this.min >= this.max) {
                        this.max = this.min * 1 + 1;
                    }
                    this.$emit("change", `${this.min},${this.max}`);
                } else {
                    this.$emit("change", null);
                }
            }
        }
    }
</script>

<style scoped>

</style>
