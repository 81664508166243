const state = {
    niche: null,
};

// getters
const getters = {
    niche: state => state.niche,
};

// mutations
const mutations = {
    setNiche(state, value) {
        state.niche = value;
    },
};

const actions = {
};

export default {
    state,
    getters,
    mutations,
    actions
}
