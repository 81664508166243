import { getTemple } from "./storage";

const isUrl = function(str) {
  return /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/.test(str);
};
const getDate = function(date) {
  if (date) {
    let arr = date.split(/[-:\s]/);
    arr = arr.concat(new Array(6 - arr.length).fill(0));
    arr[1] = arr[1] && arr[1] > 0 ? arr[1] - 1 : 0;
    return new Date(...arr);
  } else {
    return null;
  }
};

const getSuffix = function(filename) {
  let pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos != -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
};
const isArray = function(arr) {
  return Object.prototype.toString.call(arr) === "[object Array]";
};
const isEmpty = function(str) {
  return str === undefined || str === null || str === "" || str === false;
};
const randomString = function(len) {
  len = len || 32;
  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  let maxPos = chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};
const downLoadFile = function(url, fileName) {
  let eleLink = document.createElement("a");
  eleLink.download = fileName;
  eleLink.style.display = "none";
  eleLink.target = "_blank";
  eleLink.href = url;
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
};

const downLoadBlobFile = function(content, fileName) {
  const blob = new Blob([content]);
  if ("download" in document.createElement("a")) {
    // 非IE下载
    const elink = document.createElement("a");
    elink.download = fileName + ".xlsx";
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName);
  }
};

const clone = function(obj) {
  let o;
  switch (typeof obj) {
    case "undefined":
      break;
    case "string":
      o = obj + "";
      break;
    case "number":
      o = obj - 0;
      break;
    case "boolean":
      o = obj;
      break;
    case "object":
      if (obj === null) {
        o = null;
      } else {
        if (obj instanceof Array) {
          o = [];
          for (let i = 0, len = obj.length; i < len; i++) {
            o.push(clone(obj[i]));
          }
        } else if (obj instanceof Date) {
          o = new Date(obj);
        } else {
          o = {};
          for (let k in obj) {
            o[k] = clone(obj[k]);
          }
        }
      }
      break;
    default:
      o = obj;
      break;
  }
  return o;
};

const getDistance = function(config) {
  if (!config) return null;
  let { top, right, bottom, left } = config;
  top = top || 0;
  right = right || 0;
  bottom = bottom || 0;
  left = left || 0;
  return `${top}px ${right}px ${bottom}px ${left}px`;
};
const formatBooleanText = function(row, column, cellValue) {
  return cellValue == 1 ? "是" : "否";
};

const hotelFormatter = function(row, column, cellValue) {
  let res = "";
  if (cellValue) {
    try {
      res = JSON.parse(cellValue).address;
    } catch (e) {
      console.log(e);
    }
  }
  return res;
};

const getVolume = function(item) {
  if (item.length && item.width && item.height) {
    let vol = ((((item.length / 1000) * item.width) / 1000) * item.height) / 1000;
    return Math.round(vol * 10000) / 10000;
  } else {
    return "";
  }
};

const filterOption = function(input, option) {
  return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const getFileSizeText = function(size) {
  let kb = 1024,
    mb = 1024 * 1024,
    gb = 1024 * 1024 * 1024;
  if (size < kb) {
    return `${size}B`;
  } else if (size < mb) {
    return `${Math.round(size / kb)}K`;
  } else if (size < gb) {
    return `${Math.round(size / mb)}M`;
  } else {
    return `${Math.round(size / gb)}G`;
  }
};

const getUrlFileName = function(url) {
  let index = url.lastIndexOf("/");
  if (index >= 0) {
    return url.substr(index + 1);
  } else {
    let str = randomString(6);
    index = url.lastIndexOf(".");
    if (index >= 0) {
      return str + url.substr(index);
    } else {
      return str;
    }
  }
};

const goToDetailRoute = function(vm, options) {
  vm.$store.commit("setDetail", { type: options.type, obj: options.item });
  vm.$router.push({
    name: "EntityDetail",
    params: { type: options.type, id: options.item.id },
    query: options.edit ? { edit: "1" } : {},
  });
};

const getDurationText = function(s) {
  let res = "",
    h = 60 * 60,
    m = 60;
  if (s >= h) {
    res = Math.floor(s / h) + "小时" + getDurationText(s % h);
  } else if (s >= m) {
    res = Math.floor(s / m) + "分钟" + getDurationText(s % m);
  } else if (s > 0) {
    res = s + "秒";
  }
  return res;
};
const getTimePeriod = function(startTime, endTime) {
  let res = null;
  if (startTime && endTime) {
    let start = getDate(startTime);
    let end = getDate(endTime);
    let sd = start.pattern("yyyy-MM-dd"),
      st = start.pattern("HH:mm");
    let ed = end.pattern("yyyy-MM-dd"),
      et = end.pattern("HH:mm");
    if (sd == ed) {
      res = `${sd} ${st} - ${et}`;
    } else {
      res = `${sd} - ${ed}`;
    }
  }
  return res;
};
const getDoubleText = function(num) {
  return num < 10 ? "0" + num : num;
};
/**
 * 根据常量值获取常量名称
 * @param list 常量列表数组
 * @param value 常量值
 * @param valKey 常量值key
 * @param nameKey 常量名称key
 * **/
const getKeyTitle = function(list, value, valKey = "key", nameKey = "title") {
  let res = null;
  if (list) {
    res = list.find((item) => item[valKey] == value);
  }
  return res ? res[nameKey] : "";
};

const getRadioOptionsFromSelect = function(options) {
  return options.map((opt) => {
    return {
      label: opt.title,
      value: opt.key,
    };
  });
};

// 获取圆环上某点的坐标
const getPoint = function(x, y, r, angle) {
  let pi = (Math.PI * angle) / 180;
  let rx = x + r * Math.cos(pi);
  let ry = y - r * Math.sin(pi);
  return { x: rx, y: ry };
};

const printDom = function(dom, style) {
  const iframe = document.createElement("iframe");
  iframe.setAttribute("style", "display:none;border:none;");
  document.body.appendChild(iframe);
  const subWindow = iframe.contentWindow;
  subWindow.document.body.setAttribute("style", "margin:0;");
  if (style) {
    subWindow.document.head.append(style);
  }
  let box = dom.cloneNode(true);
  subWindow.document.body.append(box);
  setTimeout(() => {
    subWindow.print();
    document.body.removeChild(iframe);
  }, 100);
};

/**
 * 数字转换中文大写
 * @param n
 * @return {string}
 */
const numberToText = function(n) {
  if (n === 0) return "零";
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) return "";
  let unit = "仟佰拾亿仟佰拾万仟佰拾元角分",
    str = "";
  n += "00";
  let p = n.indexOf(".");
  if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
  unit = unit.substr(unit.length - n.length);
  for (let i = 0; i < n.length; i++) str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);
  return str
    .replace(/零(仟|佰|拾|角)/g, "零")
    .replace(/(零)+/g, "零")
    .replace(/零(万|亿|元)/g, "$1")
    .replace(/(亿)万/g, "$1$2")
    .replace(/^元零?|零分/g, "")
    .replace(/元$/g, "元整");
};

const omit = (obj, uselessKeys) =>
  Object.keys(obj).reduce((acc, key) => (uselessKeys.includes(key) ? acc : { ...acc, [key]: obj[key] }), {});

const getTimeText = (time) => {
  let res = 0;
  if (time) {
    time = Math.round(Number(time));
    let hour = time / 60;
    if (hour > 1) {
      let minute = time % 60;
      res = `${minute}分钟`;
      res = `${Math.floor(hour)}小时${res}`;
    } else {
      res = `${time}分钟`;
    }
  }
  return res;
};

const dataConvertOptions = function(data, valueKey = "id", labelKey = "name") {
  let res = [];
  if (data && data.length > 0) {
    res = data.map((item) => {
      return {
        ...item,
        label: item[labelKey],
        value: item[valueKey],
      };
    });
  }
  return res;
};
const joinListKey = function(list, key = "id", way = ",") {
  return list?.map((item) => item[key])?.join(way);
};

// 是否为有效时间
const isValidDate = function(date) {
  return date instanceof Date && !isNaN(date.getTime());
};
// 字符串时间转时间戳
const stringConvertTimestamp = function(string) {
  if (isEmpty(string)) {
    return string;
  }
  let date = getDate(string); // 防止YYYY-MM-DD格式，在new Date()时默认时间为早上08:00:00
  if (isValidDate(date)) {
    return date.getTime() / 1000; // 以秒为单位
  }
  return string;
};

// 时间戳转字符串
const timestampConvertString = function(timestamp, format = "yyyy-MM-dd HH:mm") {
  if (isEmpty(timestamp)) {
    return timestamp;
  }
  let date = new Date(timestamp * 1000); // 转为毫秒
  if (isValidDate(date)) {
    return date.pattern(format);
  }
  return timestamp;
};

const validateIDCard = function(idCardNumber) {
  // 身份证号码的正则表达式
  let regex = /^\d{17}(\d|X)$/i;

  // 检查格式是否正确
  if (!regex.test(idCardNumber)) {
    return false; // 返回false表示格式不正确
  }

  // 检查校验位是否正确
  let factorArr = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  let checksumArr = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
  let checksum = 0;
  for (let i = 0; i < 17; i++) {
    checksum += parseInt(idCardNumber.charAt(i), 10) * factorArr[i];
  }
  let mod = checksum % 11;
  if (idCardNumber.charAt(17).toUpperCase() !== checksumArr[mod]) {
    return false; // 返回false表示校验位不正确
  }

  return true; // 返回true表示身份证号码正确
};

const calculateAge = function(idCardNumber) {
  var birthdayStr = idCardNumber.substring(6, 14); // 提取出生日期部分
  var year = parseInt(birthdayStr.substring(0, 4), 10);
  var month = parseInt(birthdayStr.substring(4, 6), 10) - 1;
  var day = parseInt(birthdayStr.substring(6, 8), 10);

  var birthday = new Date(year, month, day);
  var now = new Date();

  var age = now.getFullYear() - birthday.getFullYear();
  if (now < new Date(now.getFullYear(), birthday.getMonth(), birthday.getDate())) {
    age--; // 未达到生日的年龄减一
  }

  return age;
};

/**
 * 从身份证号中获取格式化的出生日期
 * @param {string} idCard - 身份证号码
 * @returns {string|null} - 返回格式化的出生日期，如果身份证号不符合规则则返回null
 */
function getFormattedBirthDateFromIdCard(idCard) {
  var pattern = /^(\d{6})(\d{4})(\d{2})(\d{2}).*$/; // 身份证号码的正则表达式模式
  var matches = idCard.match(pattern); // 利用正则表达式匹配身份证号码

  if (matches) {
    var birthYear = matches[2]; // 获取出生年份
    var birthMonth = matches[3]; // 获取出生月份
    var birthDay = matches[4]; // 获取出生日期

    var formattedDate = birthYear + "-" + birthMonth + "-" + birthDay; // 格式化出生日期
    return formattedDate; // 返回格式化的出生日期
  } else {
    return null; // 如果身份证号不符合规则，则返回null
  }
}

/**
 * 在数字前面自动补0
 * @param {*} num 数字
 * @param {*} length 返回的字符长度
 * @returns {string}
 * e.g:
 * paddingZero(1, 2) => "01"
 * paddingZero(1, 3) => "001"
 */
const paddingZero = function(num, length) {
  const str = num + "";
  return str.length >= length ? str : paddingZero("0" + str, length);
};

const getQuery = function(searchKeyType) {
  let query = "";
  let form = this.form;
  for (let key in form) {
    if (!isEmpty(form[key])) {
      //模糊搜索
      let type = searchKeyType[key] || 0;
      if (typeof type == "function") {
        query += type(form, key);
      } else if (type == 1) {
        query += `&filter[${key}][like]=${form[key]}`;
      } else if (type == 2) {
        query += `&${key}=${form[key]}`;
      } else {
        query += `&filter[${key}]=${form[key]}`;
      }
    }
  }
  return query;
};

const getTempleId = function() {
  let temple = getTemple();
  if (temple) {
    return `&temple_id=${temple}`;
  }
  return "";
}

const getCurrentDateTimestamp = function() {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // 将时、分、秒和毫秒部分设置为零
  return currentDate.getTime() / 1000;
};

/**
 * 判断两个对象的属性值是否相同
 */
const isSameObject = function(a, b) {
  if(a && b && typeof a === "object" && typeof b === "object") {
    return Object.keys(a).every(key => isSameObject(a[key], b[key]));
  } else {
    return a === b;
  }
}

const throttle = function(func, delay) {
  let timer = null;
  let startTime = Date.now();
  let context = this;
  return function(...args) {
    let curTime = Date.now();
    let remaining = delay - (curTime - startTime);
    clearTimeout(timer);
    if (remaining <= 0) {
      func.apply(context, args);
      startTime = Date.now();
    } else {
      timer = setTimeout(() => func.apply(context, args), remaining);
    }
  }
}

// 首字母大写
const capitalizeFirstLetter = function(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const debounce = function(func, delay, immediate) {
  let timeoutId;
  let hasTriggered = false;
  return function (...args) {
    clearTimeout(timeoutId); // 清除已有的定时器
    if (!hasTriggered && immediate) {
      func.apply(this, args); // 立即执行首次调用
      hasTriggered = true;
    } else {
      timeoutId = setTimeout(() => {
        func.apply(this, args); // 在延迟时间过后执行原函数
      }, delay);
    }
  };
};

export {
  omit,
  isUrl,
  getDate,
  getSuffix,
  randomString,
  downLoadFile,
  downLoadBlobFile,
  clone,
  getDistance,
  isEmpty,
  isArray,
  formatBooleanText,
  hotelFormatter,
  getVolume,
  filterOption,
  getUrlFileName,
  goToDetailRoute,
  getDurationText,
  getTimePeriod,
  getDoubleText,
  getFileSizeText,
  getKeyTitle,
  getRadioOptionsFromSelect,
  getPoint,
  printDom,
  numberToText,
  getTimeText,
  dataConvertOptions,
  joinListKey,
  isValidDate,
  stringConvertTimestamp,
  timestampConvertString,
  validateIDCard,
  calculateAge,
  getFormattedBirthDateFromIdCard,
  paddingZero,
  getQuery,
  getTempleId,
  getCurrentDateTimestamp,
  throttle,
  capitalizeFirstLetter,
  debounce,
  isSameObject
};
