<template>
  <div class="ass-scope">
    <div class="ass-scope-edit">
      <a-radio-group v-model="type" @change="dispatchChange" v-if="edit">
        <a-radio :value="1">个人</a-radio>
        <a-radio :value="2">部门</a-radio>
      </a-radio-group>
      <div class="ass-scope-type" v-else>{{getAssTypeName(type)}}</div>
      <div class="flex-box align-center ass-scope-second" v-if="type == 1">
        <span style="margin-right: 1em">评价范围：</span>
        <select-book type="book" multiple v-model="userList" :edit="edit" @change="dispatchChange"></select-book>
      </div>
      <div class="flex-box align-center ass-scope-second" v-else>
        <span style="margin-right: 1em">部门列表：</span>
        <select-book multiple v-model="deptList" :edit="edit" @change="dispatchChange"></select-book>
      </div>
    </div>
  </div>
</template>

<script>
import {getAssTypeName} from "../../common/hr/ass";

export default {
  name: "ass-scope",
  model: {
    event: 'change'
  },
  props: {
    value: Object,
    edit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      type: 1,
      userList: [],
      deptList: [],
    }
  },
  watch: {
    value() {
        this.setValue();
    },
  },
  created() {
    this.setValue();
    // this.dispatchChange();
  },
  methods: {
    getAssTypeName,
    setValue() {
      let value = this.value;
      if(value) {
        if(typeof value === 'string') {
          value = JSON.parse(value);
        }
        this.type = value.type;
        if(value.type == 1) {
          this.userList = value.userList || [];
        } else if(value.type == 2) {
          this.deptList = value.dept || [];
        }
      }
    },
    dispatchChange() {
      let type = this.type;
      let res = {type};
      if(type == 1){
        res.userList = [...this.userList]
      } else {
        res.dept = [...this.deptList];
      }
      this.$emit("change", res);
    }
  }
}
</script>

<style lang="less">
  .ass-scope-type {
    margin-bottom: 6px;
  }
</style>
