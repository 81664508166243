<template>
    <div class="select-entity">
        <a-button class="select-entity-btn" @click="showModal" v-show="multiple || entityList.length == 0" ><a-icon type="select"/>{{btnText}}</a-button>
        <ul class="entity-list" v-if="!hideList" v-show="entityList && entityList.length > 0">
            <li class="entity-item" v-for="(item, i) in entityList" :key="item.id">
                <div class="entity-name">{{item.name || item.real_name || item.uname}}</div>
                <a-icon type="delete" style="cursor: pointer" @click="removeSelect(i)"/>
            </li>
        </ul>
        <a-modal
            :title="btnText"
            :maskClosable="false"
            :closable="false"
            :destroyOnClose="true"
            :width="600"
            @ok="confirmSelect"
            v-model="visible">
            <data-list
                hide-action
                :height="500"
                :url="url"
                :search-key-type="{name: true}"
                :row-selection="{type: multiple ? 'checkbox' : 'radio', selectedRowKeys: selectKeys}"
                :columns="columns"
                :query="query"
                @rowClick="handleRowClick"
                v-if="url">
                <template v-slot:query="{form}">
                    <a-form-model-item label="" v-if="type == 'user'">
                        <a-input v-model="form.mobile" placeholder="输入用户手机号码查询"/>
                    </a-form-model-item>
                    <a-form-model-item label="" v-else>
                        <a-input v-model="form.name" placeholder="输入姓名/名称查询"/>
                    </a-form-model-item>
                </template>
            </data-list>
        </a-modal>
    </div>
</template>

<script>
import {clone, getKeyTitle, isArray} from "../common/js/tool";
import {getIndustryText} from "../common/constant/post";
import { getSystemRoleName } from '../permission/subsystem';
import { volunStatusOptions } from "../common/hr/volunteer";

    export default {
        name: "select-entity",
        model: {
            event: 'change'
        },
        props: {
            btnText: {
                type: String,
                default: '点击选择'
            },
            type: String,
            value: [Array, Object],
            multiple: Boolean,
            hideList: Boolean,
            customQuery: String,
        },
        data() {
            return {
                visible: false,
                entity: null,
                selectKeys: [],
                selectItems: [],
                entityList: []
            }
        },
        created() {
            this.setEntityList()
        },
        computed: {
            url() {
                let type = this.type;
                let res = null
                switch (type) {
                    case 'company':
                        res = '/company';
                        break;
                    case 'user':
                        res = '/user';
                        break;
                    case 'job':
                        res = '/job-cat';
                        break;
                    case 'village':
                        res = '/village';
                        break;
                    case 'hotel':
                        res = '/hotel';
                        break;
                    case 'verifierBy':
                        res = '/admin/user-role';
                        break;
                    case 'volunteer':
                        res = '/volunteer';
                        break;
                }
                return res;
            },
            query() {
                let res = null;
                let type = this.type;
                let user = this.$store.getters.user;
                let customQuery = this.customQuery;
                if (customQuery) {
                    return customQuery;
                }
                if(type == 'hotel') {
                    // 乡村管理员及审核员按照所管理的乡村查询民宿列表
                    if(user.role == 4 || user.role == 5) {
                        res = `&filter[v_id][fis]=${user.vids}`;
                    } else if(user.role == 6) {
                        // 民宿管理员查询自己关联的民宿
                        res = `&filter[admin_id]=${user.id}`;
                    }
                }
                if (type == "verifierBy") {
                    res = `&sort=-id&filter[system]=2&filter[privilege][gt]=2`;
                }

                return res;
            },
            columns() {
                let type = this.type;
                let res = null
                switch (type) {
                    case 'company':
                        res = [
                            {title: '企业名称', dataIndex: 'name'},
                        ];
                        break;
                    case 'user':
                        res = [
                            {title: '用户昵称', dataIndex: 'uname'},
                            {title: '姓名', dataIndex: 'real_name'},
                            {title: '手机号', dataIndex: 'mobile'},
                        ];
                        break;
                    case 'job':
                        res = [
                            {title: '职位名称', dataIndex: 'name'},
                            {
                                title: '行业',
                                dataIndex: 'industry',
                                customRender: text => {
                                    return <span>{text ? getIndustryText(text) : '无'}</span>
                                }
                            },
                        ];
                        break;
                    case 'village':
                        res = [
                            {title: '乡村名称', dataIndex: 'name'},
                        ];
                        break;
                    case 'hotel':
                        res = [
                            {title: '民宿名称', dataIndex: 'name'},
                            {title: '地址', dataIndex: 'location'},
                        ];
                        break;
                    case 'verifierBy':
                        res = [
                            {
                                title: "核销人名称",
                                dataIndex: "user_id",
                                customRender: (text) => {
                                     if (text) {
                                        text = JSON.parse(text);
                                        return <open-data type="userName" openid={text[0]} />;
                                    } else {
                                        return "-";
                                    }
                                },
                            },
                            // { title: "手机号码", dataIndex: "mobile" },
                            {
                                title: "核销类别",
                                customRender: (text) => {
                                    return <span>{getSystemRoleName(text.system, text.privilege)}</span>;
                                },
                            },
                        ]
                        break;
                    case "volunteer":
                        res = [
                            { title: "姓名", dataIndex: "name" },
                            { title: "身份证号", dataIndex: "id_card" },
                            {
                                title: "注册小组",
                                customRender: (text) => {
                                    return <span>{text?.volunteerGroup?.name || "-"}</span>;
                                },
                            },
                            {
                                title: "注册小组志工状态",
                                dataIndex: "register_status",
                                customRender: (text) => {
                                    return <span>{getKeyTitle(volunStatusOptions, text, "value", "label") || "-"}</span>;
                                },
                            },
                        ];
                        break;
                }
                return res;
            }
        },
        watch: {
            value() {
                this.setEntityList();
            }
        },
        methods: {
            setEntityList() {
                let value = this.value;
                if(value) {
                    if(isArray(value)) {
                        this.entityList = this.value;
                    } else {
                        this.entityList = [this.value]
                    }
                } else {
                    this.entityList = [];
                }
            },
            removeSelect(index) {
                this.entityList.splice(index, 1);
                this.dispatchInput();
            },
            showModal() {
                let list = this.entityList;
                let keys = list.map(item => item.id);
                this.selectKeys = keys;
                this.selectItems = clone(list)
                this.visible = true;
            },
            handleRowClick({item}) {
                let keys = this.selectKeys;
                if(this.multiple) {
                    let index = keys.indexOf(item.id);
                    if(index >= 0) {
                        this.selectKeys.splice(index, 1);
                        this.selectItems.splice(index, 1);
                    } else {
                        this.selectKeys.push(item.id);
                        this.selectItems.push(item);
                    }
                } else {
                    if(keys.length > 0 && keys[0] == item.id) {
                        this.selectKeys = [];
                        this.selectItems = [];
                    } else {
                        this.selectKeys = [item.id];
                        this.selectItems = [item];
                    }
                }
            },
            hideModal() {
                this.selectKeys = [];
                this.selectItems = [];
                this.visible = false;
            },
            dispatchInput(list) {
                list = list || this.entityList;
                this.$emit('change', list.length > 1 ? list : list[0]);
            },
            confirmSelect() {
                if(this.selectItems.length > 0) {
                    this.entityList = this.selectItems;
                    this.dispatchInput(this.entityList);
                }
                this.hideModal();
            }
        }
    }
</script>

<style scoped lang="less">
    .select-entity-btn {
        margin: 4px 0 @padding-sm;
    }
    .entity-item {
        display: flex;
        align-items: center;
        padding: @padding-xs;
        min-width: 240px;
        border: var(--border);
        border-radius: @border-radius-base;
        &:not(:first-of-type) {
            margin-top: @padding-xs;
        }
    }
    .entity-name {
        flex: 1;
        margin-right: @padding-sm;
        line-height: 1.5;
    }
</style>
