import { volunteerRoles } from "../../permission/subsystem";
import { dataConvertOptions, timestampConvertString } from "../js/tool";

// 志工模板地址
export const volunteerTempleUrl = "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/template/volunteer.xlsx";

// 志工详情模版
export const volunteerDetailTempleUrl = "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/template/volunteer_detail.xlsx";

// 志工成绩模板
export const scoreTemplateUrl = "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/template/score.xlsx";

// 使用条件
export const conditionOptions = [
  {
    label: "任意时间可用",
    value: "1",
  },
  {
    label: "不支持退款",
    value: "2",
  },
];

// 核销状态
export const statusOptions = [
  {
    label: "未核销",
    value: 1,
  },
  {
    label: "已核销",
    value: 2,
  },
];

export const volunteerModifyTypeOptions = [
  { label: "状态变更", value: "status" },
  { label: "小组变更", value: "group" },
  { label: "不变更", value: "none" },
];

// 志工状态
export const volunStatusOptions = [
  {
    label: "待面试",
    value: 1,
  },
  {
    label: "实习期",
    value: 2,
  },
  {
    label: "正式",
    value: 3,
  },
  {
    label: "休眠",
    value: 4,
  },
  {
    label: "劝退",
    value: 5,
  },
  {
    label: "开除",
    value: 6,
  },
  {
    label: "已故",
    value: 7,
  },
  {
    label: "转组退出",
    value: 8,
  },
  {
    label: "其他",
    value: 9,
  },
];

// 发放条件
export const grantOptions = [
  {
    label: "不限制",
    value: 1,
  },
  {
    label: "限制",
    value: 2,
  },
];

// 核销类别
export const typeOptions = [
  {
    label: "志工服务",
    value: 1,
  },
  {
    label: "志工活动",
    value: 2,
  },
  {
    label: "志工培训",
    value: 3,
  },
  {
    label: "用餐券",
    value: 4,
  },
  {
    label: "结缘品",
    value: 5,
  },
  {
    label: "通行",
    value: 6,
  },
];

// 审核状态
export const auditStatusOptions = [
  {
    label: "待审核",
    value: 1,
  },
  {
    label: "已通过",
    value: 2,
  },
  {
    label: "未通过",
    value: 3,
  },
];

// 审核
export const auditOptions = [
  {
    label: "通过",
    value: 2,
  },
  {
    label: "不通过",
    value: 3,
  },
];

// 是否
export const flagOptions = [
  {
    label: "否",
    value: 2,
  },
  {
    label: "是",
    value: 1,
  },
];

// 授课形式
export const teachingFormatOptions = [
  {
    label: "线上",
    value: 1,
  },
  {
    label: "线下",
    value: 2,
  },
];

// 课件内容
export const coursewareOptions = [
  {
    label: "直播",
    value: 1,
  },
  {
    label: "视频",
    value: 2,
  },
  {
    label: "图文",
    value: 3,
  },
  {
    label: "PDF",
    value: 4,
  },
];

// 名额限制
export const numberOptions = [
  {
    label: "不限名额",
    value: 1,
  },
  {
    label: "限定名额",
    value: 2,
  },
];

// 志工可确认职务种类
export const catOptions = dataConvertOptions(volunteerRoles, "key", "title");

// 注册小组职务种类
export const registerCatOption = [
  {
    label: "组长",
    value: 1,
  },
  {
    label: "值班组长",
    value: 2,
  },
  {
    label: "副组长",
    value: 3,
  },
  {
    label: "岗位负责人",
    value: 4,
  },
  {
    label: "统计人员",
    value: 5,
  },
  {
    label: "普通组员",
    value: 6,
  },
];

// 专业小组职务种类
export const specialCatOptions = [
  {
    label: "组长",
    value: 1,
  },
  {
    label: "副组长",
    value: 2,
  },
  {
    label: "岗位负责人",
    value: 3,
  },
  {
    label: "统计人员",
    value: 4,
  },
  {
    label: "普通组员",
    value: 5,
  },
];

// 考试参加状态
export const examPartOptions = [
  {
    label: "未考试",
    value: 1,
  },
  {
    label: "已考试",
    value: 2,
  },
];

// 考试通过状态
export const examStatusOptions = [
  {
    label: "未通过",
    value: 1,
  },
  {
    label: "已通过",
    value: 2,
  },
];

// 小组种类
export const groupOptions = [
  {
    label: "日常组",
    value: 1,
  },
  {
    label: "专业组",
    value: 2,
  },
];

// 报备类型
export const reportOptions = [
  {
    label: "就餐",
    value: 1,
  },
  {
    label: "车牌",
    value: 2,
  },
];

// 服务日
export const weekOptions = [
  {
    label: "周一",
    value: "周一",
    week: 1,
  },
  {
    label: "周二",
    value: "周二",
    week: 2,
  },
  {
    label: "周三",
    value: "周三",
    week: 3,
  },
  {
    label: "周四",
    value: "周四",
    week: 4,
  },
  {
    label: "周五",
    value: "周五",
    week: 5,
  },
  {
    label: "周六",
    value: "周六",
    week: 6,
  },
  {
    label: "周日",
    value: "周日",
    week: 0,
  },
];

// 符号
export const symbolOptions = [
  {
    label: "大于等于",
    value: 1,
  },
  {
    label: "小于等于",
    value: 2,
  },
  {
    label: "大于",
    value: 3,
  },
  {
    label: "小于",
    value: 4,
  },
  {
    label: "等于",
    value: 5,
  },
  {
    label: "介于",
    value: 6,
  },
];

// 志工账号状态
export const accountStatusOptions = [
  {
    label: "启用",
    value: 1,
  },
  {
    label: "禁用",
    value: 2,
  },
];

// 性别
export const sexOptions = [
  {
    label: "男",
    value: 1,
  },
  {
    label: "女",
    value: 2,
  },
];

// 民族
export const nationOptions = [
  { label: "汉族", value: "汉族" },
  { label: "蒙古族", value: "蒙古族" },
  { label: "回族", value: "回族" },
  { label: "藏族", value: "藏族" },
  { label: "维吾尔族", value: "维吾尔族" },
  { label: "苗族", value: "苗族" },
  { label: "彝族", value: "彝族" },
  { label: "壮族", value: "壮族" },
  { label: "布依族", value: "布依族" },
  { label: "朝鲜族", value: "朝鲜族" },
  { label: "满族", value: "满族" },
  { label: "侗族", value: "侗族" },
  { label: "瑶族", value: "瑶族" },
  { label: "白族", value: "白族" },
  { label: "土家族", value: "土家族" },
  { label: "哈尼族", value: "哈尼族" },
  { label: "哈萨克族", value: "哈萨克族" },
  { label: "傣族", value: "傣族" },
  { label: "黎族", value: "黎族" },
  { label: "傈僳族", value: "傈僳族" },
  { label: "佤族", value: "佤族" },
  { label: "畲族", value: "畲族" },
  { label: "高山族", value: "高山族" },
  { label: "拉祜族", value: "拉祜族" },
  { label: "水族", value: "水族" },
  { label: "东乡族", value: "东乡族" },
  { label: "纳西族", value: "纳西族" },
  { label: "景颇族", value: "景颇族" },
  { label: "柯尔克孜族", value: "柯尔克孜族" },
  { label: "土族", value: "土族" },
  { label: "达斡尔族", value: "达斡尔族" },
  { label: "仫佬族", value: "仫佬族" },
  { label: "羌族", value: "羌族" },
  { label: "布朗族", value: "布朗族" },
  { label: "撒拉族", value: "撒拉族" },
  { label: "毛难族", value: "毛难族" },
  { label: "仡佬族", value: "仡佬族" },
  { label: "锡伯族", value: "锡伯族" },
  { label: "阿昌族", value: "阿昌族" },
  { label: "普米族", value: "普米族" },
  { label: "塔吉克族", value: "塔吉克族" },
  { label: "怒族", value: "怒族" },
  { label: "乌孜别克族", value: "乌孜别克族" },
  { label: "俄罗斯族", value: "俄罗斯族" },
  { label: "鄂温克族", value: "鄂温克族" },
  { label: "崩龙族", value: "崩龙族" },
  { label: "保安族", value: "保安族" },
  { label: "裕固族", value: "裕固族" },
  { label: "京族", value: "京族" },
  { label: "塔塔尔族", value: "塔塔尔族" },
  { label: "独龙族", value: "独龙族" },
  { label: "鄂伦春族", value: "鄂伦春族" },
  { label: "赫哲族", value: "赫哲族" },
  { label: "门巴族", value: "门巴族" },
  { label: "珞巴族", value: "珞巴族" },
  { label: "基诺族", value: "基诺族" },
];

// 皈依
export const conversionOptions = [
  {
    label: "未皈依",
    value: 1,
  },
  {
    label: "已皈依",
    value: 2,
  },
];

// 受戒
export const disciplinedOptions = [
  {
    label: "未受戒",
    value: 1,
  },
  {
    label: "五戒",
    value: 2,
  },
  {
    label: "菩萨戒",
    value: 3,
  },
  {
    label: "八关斋戒",
    value: 4,
  },
];

// 报名渠道
export const channelOptions = [
  {
    label: "媒体",
    value: 1,
  },
  {
    label: "推荐人",
    value: 2,
  },
  {
    label: "自荐",
    value: 3,
  },
  {
    label: "其他",
    value: 4,
  },
];

// 车辆情况
export const carOptions = [
  {
    label: "无自有车辆",
    value: 1,
  },
  {
    label: "乘用车",
    value: 2,
  },
  {
    label: "摩托车",
    value: 3,
  },
  {
    label: "电瓶车",
    value: 4,
  },
];

// 特长
export const specialityOptions = [
  {
    label: "电脑程序设计",
    value: "电脑程序设计",
  },
  {
    label: "网站维护",
    value: "网站维护",
  },
  {
    label: "美工图像处理",
    value: "美工图像处理",
  },
  {
    label: "视频剪辑处理",
    value: "视频剪辑处理",
  },
  {
    label: "声乐舞蹈",
    value: "声乐舞蹈",
  },
  {
    label: "朗诵主持",
    value: "朗诵主持",
  },
  {
    label: "戏剧曲艺",
    value: "戏剧曲艺",
  },
  {
    label: "乐器演奏",
    value: "乐器演奏",
  },
  {
    label: "文案写作",
    value: "文案写作",
  },
  {
    label: "文档处理",
    value: "文档处理",
  },
  {
    label: "活动策划",
    value: "活动策划",
  },
  {
    label: "书法绘画",
    value: "书法绘画",
  },
  {
    label: "摄影录像",
    value: "摄影录像",
  },
  {
    label: "导游解说",
    value: "导游解说",
  },
  {
    label: "教学培训",
    value: "教学培训",
  },
  {
    label: "外语翻译",
    value: "外语翻译",
  },
  {
    label: "茶道",
    value: "茶道",
  },
  {
    label: "园艺插花",
    value: "园艺插花",
  },
  {
    label: "建筑装潢设计",
    value: "建筑装潢设计",
  },
  {
    label: "舞台设计",
    value: "舞台设计",
  },
  {
    label: "灯光音响",
    value: "灯光音响",
  },
  {
    label: "服装缝纫",
    value: "服装缝纫",
  },
  {
    label: "心理咨询",
    value: "心理咨询",
  },
  {
    label: "烹饪",
    value: "烹饪",
  },
  {
    label: "会计",
    value: "会计",
  },
  {
    label: "医疗护理",
    value: "医疗护理",
  },
  {
    label: "法律",
    value: "法律",
  },
  {
    label: "驾驶",
    value: "驾驶",
  },
  {
    label: "手工艺",
    value: "手工艺",
  },
  {
    label: "其他",
    value: "其他",
  },
];

// 学历
export const educationOptions = [
  {
    label: "博士",
    value: 1,
  },
  {
    label: "硕士",
    value: 2,
  },
  {
    label: "本科",
    value: 3,
  },
  {
    label: "大专",
    value: 4,
  },
  {
    label: "中专",
    value: 5,
  },
  {
    label: "高中",
    value: 6,
  },
  {
    label: "初中",
    value: 7,
  },
  {
    label: "小学及以下",
    value: 8,
  },
];

// 婚姻状况
export const marriageOptions = [
  {
    label: "已婚",
    value: 1,
  },
  {
    label: "未婚",
    value: 2,
  },
  {
    label: "离异",
    value: 3,
  },
  {
    label: "丧偶",
    value: 4,
  },
  {
    label: "不明",
    value: 5,
  },
];

// 身体情况
export const bodyOptions = [
  {
    label: "健康",
    value: 1,
  },
  {
    label: "有疾病",
    value: 2,
  },
];

// 服务班次类型
export const classesTypeOptions = [
  {
    label: "上午",
    value: 1,
  },
  {
    label: "下午",
    value: 2,
  },
  {
    label: "全天",
    value: 3,
  },
  {
    label: "晚上",
    value: 4,
  },
];

// 报备
export const dinnerReportOptions = [
  {
    label: "午餐",
    value: 1,
  },
  {
    label: "晚餐",
    value: 2,
  },
];

// 上传状态
export const uploadStatusOptions = [
  {
    label: "未上传",
    value: 1,
  },
  {
    label: "已上传",
    value: 2,
  },
];

// 结缘品领取状态
export const receiveStatusOptions = [
  {
    label: "未领取",
    value: 1,
  },
  {
    label: "已领取",
    value: 2,
  },
];

/* 服务状态 */
const serviceStatusMap = {
  1: "报名截止，未开始",
  2: "报名中",
  3: "进行中",
  4: "已结束",
};
// 获取前一天12点
const getYesterdayTwelve = function(currentDate) {
  // 获取当前时间对象
  currentDate = new Date(currentDate);
  // 将时间设置为前一天
  currentDate.setDate(currentDate.getDate() - 1);
  // 将时间设置为12点
  currentDate.setHours(12, 0, 0, 0);
  // 获取时间戳
  const timestamp = currentDate.getTime();
  return new Date(timestamp).pattern("yyyy-MM-dd HH:mm");
};
export const getServiceStatus = function(item) {
  let status = 1;
  // 默认打卡时间，在服务开始时间之前
  let { service_date, start_time, end_time } = item;
  service_date = timestampConvertString(service_date, "yyyy-MM-dd");
  let enroll_end = getYesterdayTwelve(service_date);
  let now = new Date().pattern("yyyy-MM-dd HH:mm");
  start_time = `${service_date} ${start_time}`;
  end_time = `${service_date} ${end_time}`;
  if (now < enroll_end) {
    status = 2; // 报名中
  } else if (now < start_time) {
    status = 1; // 报名截止，未开始
  } else if (now <= end_time) {
    status == 3; // 进行中
  } else {
    status = 4; // 已结束
  }
  item.status = status;
  item.statusText = serviceStatusMap[status];
};

/* 活动状态 */
const activityStatusMap = {
  1: "未开始",
  2: "报名中",
  3: "报名已截止",
  4: "进行中",
  5: "已结束",
};
export const getActivityStatus = function(item) {
  let status = 1;
  let { enroll_start, enroll_end, activity_start_time, activity_end_time } = item;
  let now = Date.now() / 1000;
  if (now < enroll_start) {
    status = 1;
  } else if (now <= enroll_end) {
    status = 2;
  } else {
    status = 3;
    if (now >= activity_start_time && now <= activity_end_time) {
      status = 4;
    } else if (now > activity_end_time) {
      status = 5;
    }
  }
  item.status = status;
  item.statusText = activityStatusMap[status];
};

// 变更记录模板
export const templeteList = [
  {
    key: 1,
    content: "新招募申请注册【group】，志工状态调整为【status】。",
  },
  {
    key: 2,
    content: "满足面试条件且面试通过，录取为【group】，志工状态由【old_status】调整为【status】。",
  },
  {
    key: 3,
    content: "满足转正条件且申请转正审批通过，志工状态由【old_status】调整为【status】。",
  },
  {
    key: 4,
    content: "超过1年未产生考勤记录，志工状态由【old_status】调整为【status】。",
  },
  {
    key: 5,
    content: "申请转组审批通过，注册小组由【old_group】调整为【group】。",
  },
  {
    key: 6,
    content: "申请休眠激活审批通过，志工状态由【old_status】调整为【status】。",
  },
  {
    key: 7,
    content: "申请已故审批通过，志工状态由【old_status】调整为【status】。",
  },
  {
    key: 8,
    content: "申请开除审批通过，志工状态由【old_status】调整为【status】。",
  },
  {
    key: 9,
    content: "申请劝退审批通过，志工状态由【old_status】调整为【status】。",
  },
  {
    key: 10,
    content: "申请退出审批通过，志工状态由【old_status】调整为【status】。",
  },
  {
    key: 11,
    content: "志工已满70周岁，注册小组由【old_group】自动调整为【念佛团】。",
  },
  {
    key: 12,
    content: "申请休眠激活审批状态由【审批通过】调整为【已失效】，志工状态由【old_status】调整为【status】。",
  },
];

// 小组变更记录模板(后台变更)
export const adminTempleteList = [
  {
    key: 1,
    content:
      "管理员发起变更，将志工【group_type】由【old_group】调整为【group】,并将志工状态由【old_status】调整为【status】。",
  },
  {
    key: 2,
    content: "管理员发起变更，将志工【group_type】由【old_group】调整为【group】。",
  },
  {
    key: 3,
    content: "管理员发起变更，将志工状态由【old_status】调整为【status】。",
  },
  {
    key: 4,
    content: "管理员发起变更，新增志工专业小组【group】，志工状态调整为【status】。",
  },
  {
    key: 5,
    content: "管理员发起变更，删除志工专业小组【group】。",
  },
];

// 小组类型
export const groupTypeOptions = [
  {
    label: "注册小组",
    value: 1,
  },
  {
    label: "专业小组",
    value: 2,
  },
];

// 日历类型
export const calendarOptions = [
  { label: "公历", value: 1 },
  { label: "农历", value: 2 },
];

// 未核销类别
export const unverifiedOptions = [
  { label: "待发放", value: 1 },
  { label: "未领取", value: 2 },
];
