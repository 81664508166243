<template>
  <select-book :value="value" :multiple="multiple" :range="range" type="user" @change="handleChange"></select-book>
</template>

<script>

    export default {
        name: "cl-address_book",
        model: {
            event: "change"
        },
        props: {
            value: [Array, String, Number],
            type: String, // dept 选部门 user 选成员 all 混合
            multiple: Boolean,
            range: Array,
        },
        data() {
            return {}
        },
        computed: {
            select: {
                get() {
                    let res = this.value || [];
                    if(!Array.isArray(res)) {
                        res = [res];
                    }
                    return res;
                },
                set(val) {
                    this.$emit("change", val && val.length > 0 ? this.multiple ? val : val[0] : undefined)
                }
            }
        },
        methods: {
            handleChange(val) {
                this.select = val;
            }
        }
    }
</script>

<style scoped>

</style>
