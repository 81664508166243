<template>
  <div class="ass-sys-rule">
      <div class="sys-rule-list">
        <div class="sys-rule-item" v-for="(n, i) in list" :key="i">
          <div class="sys-rule-text">{{getSysRuleText(n, type, i)}}</div>
          <a-icon type="close-circle" class="sys-rule-remove" @click="removeRule(i)"/>
        </div>
      </div>
      <a-space class="ass-rule-add" @click="addNorm">
        <a-icon type="plus" />
        <span>添加标准</span>
      </a-space>
      <a-modal
          title="设置标准"
          :maskClosable="false"
          :closable="false"
          :destroyOnClose="true"
          :width="700"
          @ok="confirmRule"
          v-model="visible">
          <form-area
            ref="normForm"
            layout="inline"
            hide-btn
            link
            :items="items"
            :entity="form">
          </form-area>
      </a-modal>
  </div>
</template>

<script>
    import {getSystemAssTypePre, getSystemAssTypeUnit, getSysRuleText} from "../../common/hr/ass";
    import { numberTypeItem } from "../../common/constant/process";
    import {clone} from "../../common/js/tool";

    export default {
        name: "ass-sys-rule",
        model: {
            event: "change"
        },
        props: {
            type: Number,
            value: Array
        },
        data() {
            return {
                visible: false,
                form: {},
                list: [],
            }
        },
        computed: {
            items() {
                let type = this.type;
                let res = [
                    {...numberTypeItem, label: getSystemAssTypePre(type)},
                    {
                        key: 'score',
                        label: `${getSystemAssTypeUnit(type)}时`,
                        component: 'a-input-number',
                        props: {
                            placeholder: "请输入",
                            formatter: value => `${value}分`,
                            parser: value => value.replace('分', '')
                        },
                        style: {
                            width: '120px'
                        },
                        rules: [{ required: true, message: '请输入', trigger: 'blur' }]
                    }
                ]
                return res;
            }
        },
        watch: {
            value() {
                this.setList();
            }
        },
        created() {
            this.setList();
        },
        methods: {
            getSysRuleText,
            setList() {
                let value = this.value;
                if(value) {
                    let list = clone(value);
                    list.sort((a, b) => b.score - a.score);
                    this.list = list;
                } else {
                    this.list = [];
                }
            },
            confirmRule() {
                this.$refs.normForm.handleConfirm().then(form => {
                    this.list.push({...form});
                    this.list.sort((a, b) => b.score - a.score);
                    this.visible = false;
                    this.dispatchChange();
                });
            },
            addNorm() {
                if(this.type) {
                    this.form = {};
                    this.visible = true;
                } else {
                    this.$message.warning("请先选择客观项类别")
                }
            },
            removeRule(i) {
                this.list.splice(i, 1);
                this.dispatchChange();
            },
            dispatchChange() {
                let list = this.list;
                if(list && list.length > 0) {
                    this.$emit("change", [...list]);
                } else {
                    this.$emit("change", null);
                }
            }
        }
    }
</script>

<style lang="less">
  .ass-sys-rule {
    line-height: 28px;
  }
  .ass-rule-add {
    padding: 6px 0;
    color: @primary-color;
    cursor: pointer;
  }
  .sys-rule-item {
    display: flex;
    align-items: center;
  }
  .sys-rule-text {
    flex: 1;
  }
  .sys-rule-remove {
    margin-left: 12px;
    color: @text-color-secondary;
    cursor: pointer;
    font-size: 16px;
  }
</style>
