<template>
  <div class="echarts-warpper" ref="echarts"></div>
</template>

<script>
import * as echarts from "echarts";
// 图例
import "echarts/lib/component/legend";
// 提示框
import "echarts/lib/component/tooltip";
// 标题
import "echarts/lib/component/title";
// 极坐标
import "echarts/lib/component/polar";
// 区域缩放
import "echarts/lib/component/dataZoom";
// 柱状图
import "echarts/lib/chart/bar";
// 折线图
import "echarts/lib/chart/line";
// 象柱图
import "echarts/lib/chart/pictorialBar";
// 散点图-无涟漪特效
import "echarts/lib/chart/scatter";
// 饼图
import "echarts/lib/chart/pie";
// 雷达图
import "echarts/lib/chart/radar";
// 自定义系列
import "echarts/lib/chart/custom";
// 水球图
import "echarts-liquidfill";

export default {
  name: "chart",
  props: {
    option: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    defaultAction: {
      type: Function,
      required: false,
      default() {
        return null;
      },
    },
    pieSelectChanged: {
      type: Function,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      chart: undefined,
      minScreenWidth: 1280,
      resizeFrame: undefined,
      currentScreenWidth: 0,
      currentScreenHeight: 0,
      listenScreenHeight: true,
    };
  },
  mounted() {
    this.initChart();
    this.renderChart();
    this.addResizeListener();
    // console.log(this);
  },
  watch: {
    option: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },
  methods: {
    initChart() {
      if (this.chart) {
        return;
      }
      this.chart = echarts.init(this.$refs.echarts);
    },
    renderChart() {
      this.chart.clear();
      this.chart.setOption(this.option);
      if (typeof this.defaultAction === "function") {
        this.defaultAction(this.chart);
      }
      if (typeof this.pieSelectChanged === "function") {
        this.pieSelectChanged(this.chart);
      }
      // this.chart.on('click', function(param) {
      //   console.log(param);
      // });
    },
    resizeChart() {
      if (this.chart) {
        const nowScreenWidth = document.body.clientWidth;
        const nowScreenHeight = document.body.clientHeight;
        if (nowScreenWidth >= this.minScreenWidth && nowScreenWidth !== this.currentScreenWidth) {
          this.chart.resize();
          this.currentScreenWidth = nowScreenWidth;
          this.currentScreenHeight = nowScreenHeight;
          return;
        }
        if (this.listenScreenHeight && nowScreenHeight !== this.currentScreenHeight) {
          this.chart.resize();
          this.currentScreenWidth = nowScreenWidth;
          this.currentScreenHeight = nowScreenHeight;
        }
      }
      this.resizeFrame = undefined;
    },
    frameTest() {
      if (this.resizeFrame) {
        window.cancelAnimationFrame(this.resizeFrame);
      }
      this.resizeFrame = window.requestAnimationFrame(this.resizeChart);
    },
    addResizeListener() {
      window.addEventListener("resize", this.frameTest, false);
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = undefined;
    }
    if (this.resizeFrame) {
      window.cancelAnimationFrame(this.resizeFrame);
      this.resizeFrame = undefined;
    }
    window.removeEventListener("resize", this.frameTest);
  },
};
</script>

<style lang="less" scoped>
.echarts-warpper {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
