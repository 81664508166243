import Vue from "vue";
import {getKeyTitle} from "../js/tool";
// eslint-disable-next-line no-unused-vars
const h = new Vue().$createElement;

// 年月选择
export const ymOptions = [
  {
    value: 1,
    label: "月",
  },
  {
    value: 2,
    label: "年",
  },
];

// 小时|日|月 选择
export const hdmOptions = [
  {
    value: 1,
    label: "小时",
    key: "hour",
  },
  {
    value: 2,
    label: "日",
    key: "day",
  },
  {
    value: 3,
    label: "月",
    key: "month",
  },
];

// 预警类型
export const warningOptions = [
  {
    value: 1,
    label: "烟雾",
  },
  {
    value: 2,
    label: "水压",
  },
  {
    value: 3,
    label: "电力",
  },
  {
    value: 4,
    label: "监控",
  },
  {
    value: 5,
    label: "人流",
  },
  {
    value: 6,
    label: "牌匾",
  },
  {
    value: 7,
    label: "山体",
  },
  {
    value: 8,
    label: "气象",
  },
];

// 设备类型
export const deviceOptions = [
  {
    value: 1,
    label: "烟雾",
  },
  {
    value: 2,
    label: "水压",
  },
  {
    value: 3,
    label: "电力",
  },
  {
    value: 4,
    label: "监控",
  },
  {
    value: 5,
    label: "人流",
  },
  {
    value: 6,
    label: "牌匾",
  },
  {
    value: 7,
    label: "山体",
  },
  {
    value: 8,
    label: "气象",
  },
];

// 预警级别
export const levelOptions = [
  {
    value: 1,
    label: "一级预警",
    define: "严重预警",
  },
  {
    value: 2,
    label: "二级预警",
    define: "次级预警",
  },
  {
    value: 3,
    label: "三级预警",
    define: "微级预警",
  },
  {
    value: 4,
    label: "四级预警",
    define: "轻微预警",
  },
];

// 状态
export const statusOptions = [
  {
    value: 1,
    label: "待处理",
  },
  {
    value: 2,
    label: "已取消",
  },
  {
    value: 3,
    label: "已派单",
  },
  {
    value: 4,
    label: "处理中",
  },
  {
    value: 5,
    label: "已处理",
  },
];

// 设备状态
export const deviceStatusOptions = [
  {
    value: 1,
    label: "在线",
  },
  {
    value: 2,
    label: "异常",
  },
];

// 提醒方式
export const remindOptions = [
  {
    label: "企业微信通知",
    value: "1",
  },
  {
    label: "短信通知",
    value: "2",
  },
  {
    label: "语音电话通知",
    value: "3",
  },
];

// 操作类型
export const operationOptions = [
  { label: "烟雾", value: 1 },
  { label: "水压", value: 2 },
  { label: "电力", value: 3 },
  { label: "监控", value: 4 },
  { label: "人流", value: 5 },
  { label: "牌匾", value: 6 },
  { label: "山体", value: 7 },
  { label: "气象", value: 8 },
  { label: "系统日志", value: 9 },
  { label: "设置权限", value: 10 },
];

// 操作行为
export const behaviorOptions = [
  { label: "预警设置", value: 1 },
  { label: "操作日志导出", value: 2 },
  { label: "新增系统管理员", value: 3 },
  { label: "删除系统管理员", value: 4 },
  { label: "权限变更", value: 5 },
];

// 预警等级饼图
export const getLevalPieChartOption = function(data) {
  let option = {
    color: ["#FD0300", "#FD8D00", "#FFCA00", "#0E5FF9"],
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      right: "15%",
      top: "center",
      icon: "circle",
    },
    series: [
      {
        name: "预警分析",
        type: "pie",
        avoidLabelOverlap: false,
        center: ["35%", "50%"],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };
  return option;
};

// 预警次数分析折线图
export const getCountLineChartOption = function(data) {
  let xData = data.map((item) => item.date);
  let yData = data.map((item) => item.count);
  let option = {
    grid: {
      left: "8%",
      right: "6%",
      top: "20%",
      bottom: 0,
      containLabel: true,
    },
    tooltip: {
      show: true,
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: xData,
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      name: "预警次数分析",
      nameGap: 20,
      nameTextStyle: {
        color: "#333",
        fontSize: 20,
        padding: [0, 0, 0, 0],
        align: "left",
      },
      minInterval: 1,
    },
    series: [
      {
        data: yData,
        type: "line",
        itemStyle: {
          color: "#FF8C00",
        },
      },
    ],
  };
  return option;
};

// 预警事件处理分析柱状图
export const getWorkBarChartOption = function(data) {
  let xData = data.map((item) => item.date);
  let seriesData1 = data.map((item) => item.processed);
  let seriesData2 = data.map((item) => item.untreated);
  let option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      icon: "rect",
      itemWidth: 12,
      itemHeight: 12,
      textStyle: {
        lineHeight: 16,
      },
      left: 0,
      top: "8%",
    },
    grid: {
      left: 0,
      right: 0,
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xData,
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    series: [
      {
        name: "已处理",
        type: "bar",
        barMaxWidth: 80,
        data: seriesData1,
        itemStyle: {
          color: "#FF8C00",
        },
      },
      {
        name: "未处理",
        type: "bar",
        barMaxWidth: 80,
        data: seriesData2,
        itemStyle: {
          color: "#FFCE7E",
        },
      },
    ],
  };
  return option;
};

// 人流量统计折线图
export const getTrafficLineChartOption = function(data) {
  let xData = data.map((item) => item.date);
  let inData = data.map((item) => item.in);
  let outData = data.map((item) => item.out);
  let option = {
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["进客流量", "出客流量"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xData,
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
    },
    series: [
      {
        name: "进客流量",
        type: "line",
        data: inData,
      },
      {
        name: "出客流量",
        type: "line",
        data: outData,
      },
    ],
  };
  return option;
};

// 预警次数波浪图
export const getCountLiquidFillChartOption = function(data) {
  let option = {
    series: [
      {
        //第二个球的填充
        type: "liquidFill",
        radius: "90%",
        center: ["50%", "50%"],
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#2aa1e3",
              },
              {
                offset: 1,
                color: "#08bbc9",
              },
            ],
            globalCoord: false,
          },
        ],
        data: [0.5, 0.47, 0.45], // data个数代表波浪数
        backgroundStyle: {
          borderWidth: 1,
          color: "RGBA(51, 66, 127, 0.7)",
        },
        label: {
          rich: {
            value: {
              fontSize: 44,
              lineHeight: 84,
              color: "#ffffff",
            },
            label: {
              fontSize: 28,
              color: "#ffffff",
            },
          },
          formatter: function() {
            return `{value|${data[0].value}}\n{label|预警次数}`;
          },
        },
        outline: {
          // show: false
          borderDistance: 0,
          itemStyle: {
            borderWidth: 0,
            borderColor: "#112165",
          },
        },
      },
    ],
  };

  return option;
};

// 模块列表
export const moduleOptions = [
  { label: "烟雾管理", value: "1", key: "smoke" },
  { label: "水压管理", value: "2", key: "water" },
  { label: "电力管理", value: "3", key: "power" },
  { label: "监控管理", value: "4", key: "monitor" },
  { label: "人流管理", value: "5", key: "traffic" },
  { label: "牌匾管理", value: "6", key: "plaque" },
  { label: "山体管理", value: "7", key: "massif" },
  { label: "气象管理", value: "8", key: "meteorology" },
  // { label: "舆情管理", value: "9" },
  { label: "车辆管理", value: "10" },
  { label: "日志列表", value: "11" },
  { label: "权限设置", value: "12" },
  { label: "应急预案", value: "13" },
];

// 岗位
export const postOptions = [
  { label: "第一道岗", value: 1 },
  { label: "第二道岗", value: 2 },
  { label: "第三道岗", value: 3 },
];

// 进出
export const inOutOptions = [
  { label: "进", value: 1 },
  { label: "出", value: 2 },
  { label: "进/出", value: 3 },
]

// 数组转对象
const arrayToObject = function(arr) {
  let resObj = {};
  if (arr && arr.length > 0) {
    arr.forEach((item) => {
      Object.assign(resObj, item);
    });
  }
  return resObj;
};

// 获取权限模块名称
const getModuleName = function(keys) {
  let labels = [];
  keys.map((key) => {
    let target = moduleOptions.find((module) => module.value == key);
    target && labels.push(target.label);
  });
  return <span class="txt-btn">{labels.length == moduleOptions.length ? "所有模块" : labels.join("、")}</span>;
};

// 获取人员名称
const getUserName = function(ids) {
  return ids?.map((id, index) => (
    <span class="txt-btn">
      <open-data type="userName" openid={id} />
      {index == ids.length - 1 ? "" : "、"}
    </span>
  ));
};

// 预警设置
const alertSetting = function(details) {
  let level = levelOptions.find((item) => item.value == details.level)?.label;
  let head = getUserName(details.head_wx_user_id?.split(","));
  let real = getUserName(details.real_ids?.split(","));
  let remind = details.remind_method
    ?.split(",")
    ?.map((method) => {
      return remindOptions?.find((item) => item.value == method)?.label;
    })
    ?.join("、");
  return (
    <div>
      <div>
        设置 <span class="txt-btn">{level}</span> 负责人为 {head}
      </div>
      <div>
        设置 <span class="txt-btn">{level}</span> 抄送人为 {real}
      </div>
      <div>
        设置 <span class="txt-btn">{level}</span> 提醒方式为 <span class="txt-btn">{remind}</span>
      </div>
      <div>
        设置 <span class="txt-btn">{level}</span> 未接单提醒
      </div>
    </div>
  );
};

// 新增管理员
const addAdmin = function(details) {
  if (details.user_id) {
    details.user_id = JSON.parse(details.user_id);
  }
  let admin = getUserName(details.user_id);
  let module = getModuleName(details.module_config?.split(","));
  return (
    <div>
      新增管理员 {admin} ,权限为 {module}
    </div>
  );
};

// 删除管理员
const delAdmin = function(details) {
  if (details.user_id) {
    details.user_id = JSON.parse(details.user_id);
  }
  let admin = getUserName(details.user_id);
  return <div>删除管理员 {admin}</div>;
};

// 权限变更
const updateAdmin = function(details) {
  let res = "-";
  let oldAmin = "",
    admin = "",
    oldModule = "",
    module = "";
  if (details.update_user_id) {
    details.update_user_id = JSON.parse(details.update_user_id);
    admin = getUserName(details.update_user_id);
  }
  if (details.old_user_id) {
    details.old_user_id = JSON.parse(details.old_user_id);
    oldAmin = getUserName(details.old_user_id);
  }
  if (details.module_config) {
    details.module_config = details.module_config?.split(",");
    oldModule = getModuleName(details.module_config);
  }
  if (details.update_module_config) {
    details.update_module_config = details.update_module_config?.split(",");
    module = getModuleName(details.update_module_config);
  }

  if (details.update_user_id && details.update_module_config) {
    // 人员与模块权限均变更
    res = (
      <div>
        变更管理员 {oldAmin} 为 {admin} ，权限为 {module}
      </div>
    );
  } else if (details.update_user_id) {
    // 人员变更
    res = (
      <div>
        变更管理员 {oldAmin} 为 {admin} ，权限为 {oldModule}
      </div>
    );
  } else if (details.update_module_config) {
    // 模块权限变更
    res = (
      <div>
        管理员 {oldAmin} 权限变更为 {module}
      </div>
    );
  }
  return res;
};

// 处理日志详情字段
export const dealLogDetails = function(text) {
  let { behavior, details } = text;
  let res = "-";
  if (details) {
    details = JSON.parse(details);
    details = arrayToObject(details);
    switch (behavior) {
      case 1:
        // 预警设置

        res = alertSetting(details);
        break;
      case 2:
        // 操作日志导出
        res = "导出日志";
        break;
      case 3:
        // 新增系统管理员
        res = addAdmin(details);
        break;
      case 4:
        // 删除系统管理员
        res = delAdmin(details);
        break;
      case 5:
        // 权限变更
        res = updateAdmin(details);
        break;
      default:
        res = "-";
    }
  }
  return res;
};

/**
 * 各个类型预警记录列表 columns 配置，由于存在 jsx 调用时需要绑定this为当前vue实例
 * @params {*} type 预警类型
 */
export const getSafetyRecordColumns = function (type) {
  // eslint-disable-next-line no-unused-vars
  const h = this.$createElement; // 非.vue 文件中使用jsx必须声明 render 函数
  let res = [];
  switch (type) {
    // 1烟雾 2水压 3电力 4监控 5人流 6牌匾
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      res = [
        { title: "预警时间", dataIndex: "alarm_time" },
        {
          title: "预警类型",
          dataIndex: "type",
          customRender: (text) => <span>{getKeyTitle(warningOptions, text, "value", "label")}</span>,
        },
        {
          title: "预警级别",
          dataIndex: "alarm_level",
          customRender: (text) => <span>{getKeyTitle(levelOptions, text, "value", "label")}</span>,
        },
        { title: "报警设备", dataIndex: "device_name" },
        { title: "设备类型", dataIndex: "device_type" },
        { title: "设备位置", dataIndex: "device_location" },
        {
          title: "处理状态",
          dataIndex: "status",
          customRender: (text) => <span>{getKeyTitle(statusOptions, text, "value", "label")}</span>,
        },
        { title: "操作", key: "action", fixed: "right", width: 120, scopedSlots: { customRender: "action" } },
      ];
      break;
    // 山体
    case 7:
      res = [
        { dataIndex: "time", title: "产生时间" },
        { dataIndex: "l", title: "水平位移" },
        { dataIndex: "v", title: "垂直位移" },
        { dataIndex: "x", title: "坐标X" },
        { dataIndex: "y", title: "坐标Y" },
        { dataIndex: "z", title: "坐标Z" },
        { dataIndex: "status", title: "状态" },
      ];
      break;
    // 气象
    case 8:
      res = [
        { title: "预警时间", dataIndex: "alarm_time" },
        { title: "预警内容", dataIndex: "alarm_describe" },
        {
          title: "预警级别",
          dataIndex: "alarm_level",
          customRender: (text) => <span>{getKeyTitle(levelOptions, text, "value", "label")}</span>,
        },
      ];
      break;
  }
  return res;
}
