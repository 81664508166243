<template>
  <div class="tab-bar">
    <div class="tab-item" :class="{active: tab === i}" v-for="(t, i) in list" @click="selectTab(i)" :key="i">
      <slot name="tab" v-bind:tab="t" v-if="$scopedSlots.tab"></slot>
      <template v-else>
        <span>{{t}}</span>
      </template>
      <div class="tab-active"></div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "tab-bar",
        props: {
            list: Array,
            tab: Number //初始选择的tab
        },
        methods: {
            selectTab(i) {
                this.$emit("update:tab", i);
            }
        }
    }
</script>

<style lang="less">
  .tab-bar {
    display: flex;
    align-items: center;
  }

  .tab-item {
    position: relative;
    padding: @padding-xs @padding-sm;
    cursor: pointer;
    &.active {
      color: @primary-color;
      .tab-active {
        display: block;
      }
    }
    &:not(:first-of-type) {
      margin-left: 16px;
    }
  }

  .tab-active {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    border-radius: 2px;
    background-color: @primary-color;
  }
</style>
