<template>
  <div class="select-book">
    <div class="select-content" v-if="type == 'all' || type == 'book'">
      <div class="select-book-list">
        <div class="book-item" v-for="(b, i) in list" :key="i">
          <div class="icon-tag book-icon" v-if="b.type == 'tag'"></div>
          <a-icon class="book-icon" :type="b.type == 'user' ? 'user' : 'folder'" v-else/>
          <div class="book-name" v-if="b.type == 'tag'">{{b.name}}</div>
          <open-data class="book-name" :type="`${b.type}Name`" :openid="b.id" v-else/>
          <a-icon type="close" class="book-remove" @click="removeSelect(i)" v-if="closeable && edit"/>
        </div>
        <div class="select-book-edit" @click="showSelect" v-if="edit">{{ list && list.length > 0 ? '修改' : '添加' }}</div>
      </div>
    </div>
    <div class="select-content" v-else-if="type == 'tag'">
      <div class="select-book-list">
        <div class="book-item" v-for="(t, i) in list" :key="i">
          <div class="icon-tag book-icon"></div>
          <div class="book-name">{{t.name}}</div>
          <a-icon type="close" class="book-remove" @click="removeSelect(i)" v-if="closeable && edit"/>
        </div>
        <div class="select-book-edit" @click="showSelect" v-if="edit">{{ list && list.length > 0 ? '修改' : '添加' }}</div>
      </div>
    </div>
    <div class="select-content" v-else>
      <div class="select-book-list">
        <div class="book-item" v-for="(b, i) in list" :key="i">
          <a-icon class="book-icon" :type="type == 'user' ? 'user' : 'folder'" />
          <open-data class="book-name" :type="type == 'user' ? 'userName' : 'departmentName'" :openid="b" />
          <a-icon type="close" class="book-remove" @click="removeSelect(i)" v-if="closeable && edit"/>
        </div>
        <div class="select-book-edit" @click="showSelect" v-if="edit">{{ list && list.length > 0 ? '修改' : '添加' }}</div>
      </div>
    </div>
    <a-modal
        title="选择部门/成员"
        :maskClosable="false"
        :closable="false"
        :width="600"
        :body-style="{padding: 0}"
        @ok="confirmSelect"
        v-model="visible">
      <dept-picker
          ref="bookPicker"
          :select="value"
          :multiple="multiple"
          :type="type"
          :tag-type="tagType"
          :hide-user="hideUser"
          :editable="editable"
          :range="range"
          @set="handleDeptSet"
          @select="handleDeptSelect"/>
    </a-modal>
  </div>
</template>

<script>
import deptPicker from "./dept-picker";
import {clone, isArray} from "../common/js/tool";

export default {
  name: "select-book",
  model: {
    event: "change"
  },
  props: {
    value: [String, Number, Array],
    limit: {
      type: Number,
      default: 100
    },
    multiple: Boolean,
    hideUser: Boolean,
    editable: Boolean,
    range: Array,
    tagType: Number, // 标签类型
    edit: {
      type: Boolean,
      default: true
    },
    closeable: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'dept'
    }
  },
  components: {
    deptPicker
  },
  data() {
    return {
      groupList: [],
      visible: false,
      list: [],
      selectItems: []
    }
  },
  watch: {
    type() {
      this.setList(true);
    },
    value() {
      this.setList();
    }
  },
  created() {
    // this.getList();
    this.setList();
  },
  methods: {
    setList(filter) {
      let value = this.value;
      if(value) {
        const type = this.type;
        if(!isArray(value)) {
          if(type == "all") {
            value = JSON.parse(value);
          } else {
            value = value.split(",");
          }
        }
        // 类型发生变化 对现有值进行筛选
        const list = filter ? value.filter(item => {
          return ((type == "all" || "book") && typeof item === "object" && item.type != "tag") ||
            (type == "user" && isNaN(Number(item))) ||
            (type == "dept" && !isNaN(Number(item))) ||
            (type == "tag" && typeof item === "object" && item.type == "tag")
        }) : value;
        this.list = list;
        // 如果有过滤掉选择项则抛出事件
        if(list.length != value.length) {
          this.dispatchChange();
        }
      } else {
        this.list = [];
      }
    },
    getList() {
      let url = '/admin/common/get-dept';
      this.$axios(url).then(res => {
        this.groupList = res.data;
      })
    },
    showSelect() {
      if(!this.edit || (this.limit > 0 && this.list.length >= this.limit)) return;
      this.visible = true;
      this.$nextTick(() => {
          this.$refs.bookPicker.setSelect();
      })
    },
    removeSelect(index) {
      this.list.splice(index, 1);
      this.dispatchChange();
    },
    handleDeptSet(list) {
      this.selectItems = list || [];
      this.updateList();
    },
    handleDeptSelect(list) {
      this.selectItems = list || [];
    },
    dispatchChange() {
      this.$emit("change", this.list);
    },
    updateList() {
      if(this.type == "all" || this.type == 'book') {
        this.list = this.selectItems.map(item => {
          let b = {
            id: item.userid || item.id,
            type: item.userid ? 'user' : 'department'
          }
          if(item.tag_id) {
            b.type = 'tag';
            b.name = item.name;
          }
          return b;
        });
      } else if(this.type == 'tag') {
        this.list = clone(this.selectItems);
      } else {
        this.list = this.selectItems.map(item => item.userid || item.id);
      }
    },
    confirmSelect() {
      this.updateList();
      this.visible = false;
      this.dispatchChange();
    }
  }
}
</script>

<style scoped lang="less">
.select-list {
  display: flex;
  flex-wrap: wrap;
}
.select-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(:first-child) {
    margin-left: 12px;
  }
}
.select-item-icon {
  position: relative;
}
.select-item-remove {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 100%;
  font-size: 14px;
  color: @red-6;
  cursor: pointer;
}
.select-item-name {
  margin-top: 10px;
  max-width: 50px;
  text-align: center;
}
.select-btn {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: var(--border);
  cursor: pointer;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #999;
  }
  &::before {
    content: "";
    width: 16px;
    height: 2px;
  }
  &::after {
    content: "";
    width: 2px;
    height: 16px;
  }
}
.select-book-edit {
  display: inline-block;
  cursor: pointer;
  color: @primary-color;
}
.book-remove {
  margin-left: 6px;
  color: #999;
}
</style>
