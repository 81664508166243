export const routers = [];

export const menus = [
    {
        type: 1,
        list: [
            {
                title: '数据看板',
                path: '/culture/board',
                children: [
                    {
                        title: '游客统计',
                        key: '/culture/visitor-statistics',
                    },
                    {
                        title: '停留数据',
                        key: '/culture/stay-data',
                    },
                    {
                        title: '热门点位',
                        key: '/culture/hot-points',
                    },
                    {
                        title: '线上互动数据',
                        key: '/culture/online-data',
                    },
                    {
                        title: '线下互动数据',
                        key: '/culture/offline-data',
                    },
                    {
                        title: '留言墙',
                        key: '/culture/message-wall',
                    },
                    {
                        title: '日志列表',
                        key: '/culture/logs',
                    }
                ]
            },
            {
                title: '工作平台',
                path: '/culture/platform',
                children: [
                    {
                        title: '留言管理',
                        key: '/culture/message',
                    },
                    {
                        title: '审核机制',
                        key: '/culture/audit',
                    },
                    {
                        title: '敏感数据库',
                        key: '/culture/sensitive',
                    },
                ]
            },
            {
                title: '系统设定',
                path: '/culture/setting',
                children: [
                    {
                        title: '系统设定',
                        key: '/culture/sys-setting',
                    },
                    {
                        title: '数据读取通道管理',
                        key: '/culture/data-source',
                    },
                ]
            },
        ]
    }
]
