<template>
  <a-checkbox v-on="$listeners" v-bind="attrs">{{ title }}</a-checkbox>
</template>

<script>
    export default {
        name: "title-checkbox",
        props: {
            title: String
        },
        computed: {
            listeners: function () {
                let vm = this;
                return Object.assign({},
                    // 我们从父级添加所有的监听器
                    this.$listeners,
                    // 然后我们添加自定义监听器，
                    // 或覆写一些监听器的行为
                    {
                        // 这里确保组件配合 `v-model` 的工作
                        change: function (e) {
                            let value = e.target.checked;
                            vm.$emit('input', value);
                        },
                    }
                )
            },
            attrs() {
                const res = this.$attrs;
                res.checked = res.value;
                return { ...res }
            }
        }
    }
</script>

<style lang="less">

</style>
