export const statusList = [
    {key: 0, title: '待审核'},
    {key: 1, title: '审核通过'},
    {key: 2, title: '未通过'},
]

export const educationTypes = [
    {key: 0, title: '无'},
    {key: 1, title: '博士及以上'},
    {key: 2, title: '硕士研究生'},
    {key: 3, title: '本科'},
    {key: 4, title: '专科'},
    {key: 5, title: '高中及以下'},
]

export const auditStatusList = [
    {value: 2, label: '通过'},
    {value: 3, label: '不通过'},
]

export const getStatusText = function (status) {
    let res = statusList.find(s => s.key == status);
    return res ? res.title : null;
}

export const getEducationText = function (type) {
    let res = educationTypes.find(e => e.key == type);
    return res ? res.title : null;
}
