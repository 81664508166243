<template>
    <a-date-picker
        v-bind="attrs"
        @change="val => $emit('change', val)">
        <template slot="dateRender" slot-scope="current">
            <div class="calendar-date" :class="{selected: attrs.value == current.format('YYYY-MM-DD'), disabled: isDateDisabled(current)}">
                <div class="calendar-date-num">{{current.date()}}</div>
                <div class="calendar-date-lunar">{{getLunarDay(current.toDate())}}</div>
            </div>
        </template>
        <div class="calendar-date-show">
            <div class="calendar-date-text" v-if="attrs.value">{{attrs.value}} <span class="date-text-lunar">{{getLunarDay(new Date(attrs.value), true)}}</span></div>
            <div class="calendar-date-text date-text-place" v-else>{{attrs.placeholder || '请选择日期'}}</div>
            <a-icon type="calendar" class="calendar-date-icon" />
        </div>
    </a-date-picker>
</template>

<script>
    import moment from 'moment';
    import {getLunarDay} from "./calendar/lunar";

    export default {
        name: "lunar-picker",
        model: {
            event: "change"
        },
        computed: {
            attrs() {
                return Object.assign({
                    format: 'YYYY-MM-DD',
                    valueFormat: 'YYYY-MM-DD'
                }, this.$attrs);
            }
        },
        methods: {
            getLunarDay,
            isDateDisabled(current) {
                return current && current >= moment().endOf('day')
            }
        }
    }
</script>

<style scoped lang="less">
    .calendar-date {
        font-size: 12px;
        cursor: pointer;
        &.selected {
            background-color: #bae7ff;
        }
        &.disabled {
            color: @disabled-color;
            .calendar-date-lunar {
                color: @disabled-color;
            }
        }
    }
    .calendar-date-lunar {
        color: @text-color-secondary;
    }
    .calendar-date-show {
        display: inline-block;
        position: relative;
        padding: 4px 11px;
        width: 180px;
        border-radius: 4px;
        border: var(--border);
        line-height: 1.5;
        cursor: pointer;
        &:hover {
            border-color: @primary-5;
        }
        .calendar-date-icon {
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            margin: auto 0;
            width: 14px;
            height: 14px;
            font-size: 14px;
            line-height: 1;
            color: @text-color-secondary;
        }
    }
    .date-text-lunar {
        font-size: 12px;
    }
    .date-text-place {
        color: @normal-color;
    }
</style>
