const activity = {
    id: 'activity',
    router: {
        path: 'activity',
        name: 'Activity',
        component: () => import(/* webpackChunkName: "act" */ '../../views/act/Activity.vue'),
        meta: {
            title: '大型活动系统'
        }
    },
}

const actAdmin =  {
    id: 'actAdmin',
    router: {
        path: '/act/admin',
        name: 'ActAdmin',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/act/ActAdmin.vue'),
        meta: {
            title: '权限设置'
        }
    },
}

export default {
    activity,
    actAdmin
}

export const routers = [
    {
        type: 1,
        list: [
            {id: 'activity', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'temp', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'actAdmin', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    }
]
export const menus = [
    {
        type: 1,
        list: [
            {
                title: '数据统计',
                path: '/temp/act/data',
                icon: 'table'
            },
            {
                title: '模板设置',
                path: '/temp/act/form',
                icon: 'profile'
            },
            {
                title: '规则权限',
                path: '/temp/act/setting',
                icon: 'setting'
            },
            // {
            //     title: '权限设置',
            //     path: '/act/admin',
            //     icon: 'setting'
            // }
        ]
    }
]
