<template>
  <a-date-picker
    :value="select"
    format="YYYY-MM-DD"
    value-format="YYYY-MM-DD"
    :placeholder="placeholder"
    :disabled="disabled"
    @change="handleChange"
    v-if="mode == 'date'"></a-date-picker>
  <a-select
    :value="select"
    :options="list"
    :placeholder="placeholder"
    :disabled="disabled"
    @change="handleChange" v-else></a-select>
</template>

<script>

    import {isEmpty, isSameObject} from "../../common/js/tool";

    export default {
        name: "cl-select",
        model: {
            event: "change"
        },
        props: {
            value: [Object, String, Number],
            options: Array,
            mode: String,
            disabled: Boolean,
            fields: String,
            placeholder: {
                type: String,
                default: '请选择'
            },
            defaultValue: [Object, String, Number],
            rangeKey: String,
            valueKey: String, // 当 range 是一个 Object Array 时，通过 valueKey 来指定 Object 中 key 的值作为选项的唯一标识
        },
        data() {
            return {
                list: []
            }
        },
        computed: {
            select: {
                get() {
                    let value = this.value;
                    if(value) {
                        if(typeof value === "object") {
                            const valueKey = this.valueKey;
                            const list = this.list;
                            if(!valueKey) {
                                value = list.findIndex(item => isSameObject(item, value));
                                value = value >= 0 ? value : undefined;
                            }
                        }
                    }
                    return value;
                },
                set(val) {
                    const mode = this.mode;
                    let value = val;
                    // 普通选择器
                    if(!mode || mode == "selector") {
                        const valueKey = this.valueKey;
                        // rangeKey 有值 说明options为对象数组
                        if(this.rangeKey) {
                            // valueKey 未传 a-select value值为数组下标 返回整个item
                            if(!valueKey) {
                                value = this.list[val];
                            }
                        }
                    }
                    this.$emit("change", value);
                }
            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                this.setDefaultValue();
                this.setOptionsList();
            },
            setDefaultValue() {
                const value = this.value;
                let defaultValue = this.defaultValue;
                if(isEmpty(value)) {
                    const mode = this.mode;
                    if (!isEmpty(defaultValue)) {
                        // 日期选择器
                        if(mode == 'date') {
                            const val = Number(defaultValue);
                            switch (val) {
                                // 当天
                                case 0:
                                    defaultValue = new Date().pattern("yyyy-MM-dd");
                                    break;
                            }
                        }
                        // 时间选择器
                        else if(mode == 'time') {
                            const val = Number(defaultValue);
                            switch (val) {
                                // 当前时间
                                case 0:
                                    defaultValue = new Date().pattern("HH:mm");
                                    break;
                            }
                        }
                        this.select = defaultValue;
                    }
                }
            },
            setOptionsList() {
                const options = this.options;
                let list = [];
                if (options && options.length > 0) {
                    const rangeKey = this.rangeKey;
                    const valueKey = this.valueKey;
                    list = options.map((item, index) => {
                        if(typeof item === "object") {
                            return {
                                ...item,
                                label: item[rangeKey],
                                value: valueKey ? item[valueKey]: index, // valueKey 未传 a-select value值为数组下标
                            }
                        } else {
                            return {
                                label: item,
                                value: item
                            }
                        }
                    })
                }
                this.list = list;
            },
            handleChange(val) {
                this.select = val;
            }
        }
    }
</script>

<style scoped>

</style>
