import flunar from "./lunar";

export const isLeap = function (year) {
    return (year % 100 == 0 ? (year % 400 == 0 ? 1 : 0) : (year % 4 == 0 ? 1 : 0));
}
export const getMonthDays = function (year) {
    return new Array(31, 28 + isLeap(year), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
}
export const getLunarDay = function (date) {
    return new flunar(date);
}
