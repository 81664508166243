<template>
    <ww-open-data :type="type" :openid="openid" v-if="hasConfig == 'success'" />
</template>

<script>
    import WWOpenData from "WWOpenData";
    import store from "../store";
    export default {
        name: "open-data",
        props: ['type', 'openid'],
        watch: {
            openid() {
                this.update();
            },
            type() {
                this.update();
            },
            hasConfig() {
                this.update();
            }
        },
        computed: {
            hasConfig() {
                return store.getters.hasConfig;
            }
        },
        inject: {
            container: {
                from: "container",
                default: null
            }
        },
        created() {
            if(this.container) {
                this.container.addNumber();
            }
            if(this.hasConfig === "init") {
                store.dispatch("WWAgentConfig");
            }
        },
        mounted() {
            if(this.hasConfig == 'success') {
                if(this.container) {
                    this.container.addNode(this.$el);
                } else {
                    WWOpenData.bind(this.$el);
                }
            }
        },
        methods: {
            update() {
                if(this.hasConfig == 'success') {
                    this.$nextTick(() => {
                        WWOpenData.bind(this.$el);
                    });
                }
            },
        }
    }
</script>

<style>

</style>
