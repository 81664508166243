<template>
    <div class="child-temp">
        <ul class="child-temp-list" v-if="list.length > 0">
            <li class="child-temp-item" v-for="(t, i) in list" :key="t.id">
                <img :src="base + t.icon" class="child-temp-icon">
                <div class="child-temp-name">{{t.name}}</div>
                <div class="txt-btn child-temp-btn" @click="delNodeTemp(i)" v-if="edit">删除</div>
            </li>
        </ul>
        <a-button icon="plus" @click="visible = true" v-if="edit">添加</a-button>
        <a-modal
                title="选择模板"
                :maskClosable="false"
                :width="480"
                @ok="confirmSelect"
                v-model="visible">
            <a-select v-model="select" style="width: 100%" placeholder="请选择模板">
                <a-select-option :value="t.id" v-for="t in temps" :key="t.id">{{t.name}}</a-select-option>
            </a-select>
        </a-modal>
    </div>
</template>

<script>
    import {base} from "./icon-select"
    export default {
        name: "child-temp",
        model: {
            event: "change"
        },
        props: {
            value: Array,
            current: Number,
            edit: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                base,
                visible: false,
                select: undefined,
                temps: [],
                list: []
            }
        },
        watch: {
            value() {
                this.setList();
            }
        },
        created() {
            this.setList();
            if(this.edit) {
                this.getTemps();
            }
        },
        methods: {
            setList() {
                let value = this.value;
                if(value) {
                    this.list = [...value];
                } else {
                    this.list = [];
                }
            },
            delNodeTemp(i) {
                this.list.splice(i, 1);
                this.$emit("change", this.list);
            },
            confirmSelect() {
                let select = this.select;
                let temp = {...this.temps.find(t => t.id == select)};
                let list = this.list;
                if(list.findIndex(t => t.id == temp.id) >= 0 ){
                    this.$message.warn("不能重复添加同一模板");
                    return;
                } else {
                    list.push(temp);
                    this.$emit("change", this.list);
                }
                this.visible = false;
            },
            getTemps() {
                let tempList = this.$store.getters.tempList;
                if(tempList) {
                    this.setTemps(tempList);
                }
                this.$axios("/admin/apply-temp?pageSize=100").then(res => {
                    this.$store.commit("setTempList", res.data);
                    this.setTemps(res.data);
                })
            },
            setTemps(list) {
                this.temps = list.filter(t => t.id != this.current);
            }
        }
    }
</script>

<style scoped lang="less">
    .child-temp-item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 16px;
        width: 360px;
        height: 36px;
        background-color: @background-color-base;
        .child-temp-btn {
            display: flex;
            cursor: pointer;
        }
        &:not(:first-child) {
            margin-top: 6px;
        }
    }
    .child-temp-icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
    .child-temp-name {
        margin-left: 12px;
    }
    .child-temp-btn {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 16px;
        height: 14px;
        margin: auto 0;
        line-height: 1;
        &:hover {
            .txt-btn {
                text-decoration: underline;
            }
        }
    }
</style>
