import oa from './oa';
import hr from './hr';
import rec from './rec';
import act from './act';
import buddha from "./buddha";
import safe from "./safe";
import light from "./light";
import asset from "./asset";
import space from "./space";
import core from "./core";
import program from "./program";

const administrator =  {
    id: 'permission',
    router: {
        path: '/admin/:type',
        name: 'Permission',
        component: () => import(/* webpackChunkName: "admin" */ '../../views/Permission.vue'),
        meta: {
            title: '管理员'
        }
    }
}
const screen = {
    id: 'screen',
    router: {
        path: '/screen',
        name: 'Screen',
        component: () => import(/* webpackChunkName: "screen" */ '../../views/Screen.vue'),
        meta: {
            title: '智慧寺院应用场景',
            open: true
        }
    },

}

export default {
    ...oa,
    ...hr,
    ...rec,
    ...act,
    ...buddha,
    ...asset,
    ...safe,
    ...light,
    ...space,
    ...core,
    ...program,
    administrator,
    screen
}

export const entityRouter = {
    path: 'entity',
    name: 'Entity',
    component: () => import(/* webpackChunkName: "l" */ '../../views/Entity.vue'),
    meta: {},
    children: [
        {
            path: ':type',
            name: 'List',
            component: () => import(/* webpackChunkName: "l" */ '../../views/EntityList.vue'),
            meta: {
                keepAlive: true
            },
        },
        {
            path: ':type/add',
            name: 'AddEntity',
            component: () => import(/* webpackChunkName: "a" */ '../../views/EntityDetail.vue'),
            meta: {
                keepAlive: false,
                nv: true
            },
        },
        {
            path: ':type/detail/:id',
            name: 'EntityDetail',
            component: () => import(/* webpackChunkName: "d" */ '../../views/EntityDetail.vue'),
            meta: {
                keepAlive: false,
                nv: true
            },
        }
    ]
};

export const homeRouter = {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "password" */ '../../views/Home.vue'),
    children: [
        {
            path: '/app/home',
            name: 'AppHome',
            component: () => import(/* webpackChunkName: "app-home" */ '../../views/app/AppHome.vue'),
            meta: {
                title: '首页'
            }
        },
        {
            path: '/app/:id/apply',
            name: 'AppApply',
            component: () => import(/* webpackChunkName: "app-apply" */ '../../views/app/AppApply.vue'),
            meta: {
                title: '应用申请'
            }
        },
        {
            path: '/find-name',
            name: 'FindName',
            component: () => import(/* webpackChunkName: "find-name" */ '../../views/FindName'),
            meta: {
                title: '姓名查找'
            }
        },

    ]
};
