<template>
  <a-date-picker
    v-model="date"
    format="YYYY-MM-DD A"
    value-format="YYYY-MM-DD HH:mm"
    :placeholder="placeholder"
     @change="dateChange">
    <template slot="renderExtraFooter">
      <a-space>
        <a-button
          size="small"
          :type="b.type"
          @click="selectNoon(i)"
          v-for="(b, i) in list"
          :key="i">{{b.title}}</a-button>
      </a-space>
    </template>
  </a-date-picker>
</template>

<script>
import moment from 'moment';
import {isEmpty} from "../common/js/tool";

export default {
    name: "half-date-picker",
    model: {
        event: "change"
    },
    props: {
        value: Array,
        placeholder: String
    },
    data() {
        return {
            date: '',
            noon: 0
        }
    },
    computed: {
        day() {
            let res = '';
            if(this.date) {
                const temp = this.date.split(" ");
                res = temp[0];
            }
            return res;
        },
        list() {
            const noon = this.noon;
            return [
                { title: '上午', time: '10:00', type: noon === 0 ? 'primary' : 'default'},
                { title: '下午', time: '14:00', type: noon === 1 ? 'primary' : 'default'},
            ]
        },
        time() {
            return this.list[this.noon].time;
        },
    },
    watch: {
        value() {
            this.setValue();
        }
    },
    created() {
        this.setValue();
    },
    methods: {
        moment,
        setValue() {
            const value = this.value;
            if(value && value.length > 0) {
                this.noon = value[1];
                this.date = value[0] + ' ' + this.time;
            } else {
                this.date = '';
                this.noon = 0;
            }
        },
        selectNoon(i) {
            this.noon = i;
            if(!this.day) {
                this.date = new Date().pattern('yyyy-MM-dd') + ' ' + this.time;
            }
            this.dispatchChange();
        },
        dateChange() {
            if(!this.day || !isEmpty(this.noon)) {
                this.dispatchChange();
            }
        },
        dispatchChange() {
            this.$emit("change", this.day && !isEmpty(this.noon) ? [this.day, this.noon] : null);
        }
    }
}
</script>

<style scoped>

</style>
