const assetHome = {
  id: "assetHome",
  router: {
    path: "/asset/home",
    name: "AssetHome",
    component: () => import(/* webpackChunkName: "asset-home" */ "../../views/asset/AssetHome.vue"),
    meta: {
      title: "首页",
    },
  },
};

const tangKou = { id: "kt", entity: "kt" };

const commodityList = {
  id: "commodityList",
  router: {
    path: "/asset/maintenance/list",
    name: "CommodityList",
    component: () => import(/* webpackChunkName: "commodity-list" */ "../../views/asset/CommodityList.vue"),
    meta: {
      title: "商品清单",
    },
  },
};

const commodityUnit = {
  id: "commodityUnit",
  router: {
    path: "/asset/maintenance/unit",
    name: "CommodityUnit",
    component: () => import(/* webpackChunkName: "commodity-unit" */ "../../views/asset/CommodityUnit.vue"),
    meta: {
      title: "商品单位",
    },
  },
};

const commodityCategory = {
  id: "commodityCategory",
  router: {
    path: "/asset/maintenance/category",
    name: "CommodityCategory",
    component: () => import(/* webpackChunkName: "commodity-category" */ "../../views/asset/CommodityCategory.vue"),
    meta: {
      title: "商品类型",
    },
  },
};

const purchaseList = {
  id: "purchaseList",
  router: {
    path: "/asset/purchase/list",
    name: "PurchaseList",
    component: () => import(/* webpackChunkName: "purchase-list" */ "../../views/asset/PurchasePolymerize.vue"),
    meta: {
      title: "申购单列表",
    },
  },
};

const purchaseAdd = {
  id: "purchaseAdd",
  router: {
    path: "/asset/purchase/add",
    name: "PurchaseAdd",
    component: () => import(/* webpackChunkName: "purchase-add" */ "../../views/asset/PurchaseAdd.vue"),
    meta: {
      title: "添加申购单",
    },
  },
};

const purchaseDetail = {
  id: "purchaseDetail",
  router: {
    path: "/asset/purchase/:id",
    name: "PurchaseDetail",
    component: () => import(/* webpackChunkName: "purchase-detail" */ "../../views/asset/PurchaseDetail.vue"),
    meta: {
      title: "申购单详情",
    },
  },
};

const warehousingList = {
  id: "warehousingList",
  router: {
    path: "/asset/warehousing/list",
    name: "WarehousingList",
    component: () => import(/* webpackChunkName: "warehousing-list" */ "../../views/asset/WarehousingList.vue"),
    meta: {
      title: "入库清单",
    },
  },
};

const warehousingAdd = {
  id: "warehousingAdd",
  router: {
    path: "/asset/warehousing/add",
    name: "WarehousingAdd",
    component: () => import(/* webpackChunkName: "warehousing-add" */ "../../views/asset/WarehousingAdd.vue"),
    meta: {
      title: "添加入库单",
    },
  },
};

const warehousingStatistics = {
  id: "warehousingStatistics",
  router: {
    path: "/asset/warehousing/statistics",
    name: "WarehousingStatistics",
    component: () =>
      import(/* webpackChunkName: "warehousing-statistics" */ "../../views/asset/WarehousingStatistics.vue"),
    meta: {
      title: "入库统计",
    },
  },
};

const outboundList = {
  id: "outboundList",
  router: {
    path: "/asset/outbound/list",
    name: "OutboundList",
    component: () => import(/* webpackChunkName: "outbound-list" */ "../../views/asset/OutboundList.vue"),
    meta: {
      title: "出库清单",
    },
  },
};

const outboundAdd = {
  id: "outboundAdd",
  router: {
    path: "/asset/outbound/add",
    name: "OutboundAdd",
    component: () => import(/* webpackChunkName: "outbound-add" */ "../../views/asset/OutboundAdd.vue"),
    meta: {
      title: "添加出库单",
    },
  },
};

const outboundStatistics = {
  id: "outboundStatistics",
  router: {
    path: "/asset/outbound/statistics",
    name: "OutboundStatistics",
    component: () => import(/* webpackChunkName: "outbound-statistics" */ "../../views/asset/OutboundStatistics.vue"),
    meta: {
      title: "出库统计",
    },
  },
};

const useStatistics = {
  id: "useStatistics",
  router: {
    path: "/asset/outbound/use",
    name: "UseStatistics",
    component: () => import(/* webpackChunkName: "use-statistics" */ "../../views/asset/UseStatistics.vue"),
    meta: {
      title: "领用统计",
    },
    redirect: { name: "UseTemplateData" },
    children: [
      {
        path: "data",
        name: "UseTemplateData",
        component: () => import(/* webpackChunkName: "utd" */ "../../views/template/TemplateData.vue"),
      },
      {
        path: "setting",
        name: "UseTemplateSetting",
        component: () => import(/* webpackChunkName: "uts" */ "../../views/template/TemplateSetting.vue"),
      },
    ],
  },
};

const borrowStatistics = {
  id: "borrowStatistics",
  router: {
    path: "/asset/outbound/borrow",
    name: "BorrowStatistics",
    component: () => import(/* webpackChunkName: "borrow-statistics" */ "../../views/asset/BorrowStatistics.vue"),
    meta: {
      title: "借用统计",
    },
    redirect: { name: "BorrowTemplateData" },
    children: [
      {
        path: "data",
        name: "BorrowTemplateData",
        component: () => import(/* webpackChunkName: "btd" */ "../../views/template/TemplateData.vue"),
      },
      {
        path: "setting",
        name: "BorrowTemplateSetting",
        component: () => import(/* webpackChunkName: "bts" */ "../../views/template/TemplateSetting.vue"),
      },
    ],
  },
};

const inventoryList = {
  id: "inventoryList",
  router: {
    path: "/asset/inventory/list",
    name: "InventoryList",
    component: () => import(/* webpackChunkName: "inventory-list" */ "../../views/asset/InventoryList.vue"),
    meta: {
      title: "当前库存",
    },
  },
};

const fixedList = {
  id: "fixedList",
  router: {
    path: "/asset/fixed/list",
    name: "FixedList",
    component: () => import(/* webpackChunkName: "fixed-list" */ "../../views/asset/FixedList.vue"),
    meta: {
      title: "固定资产清单",
    },
  },
};

const fixedAdd = {
  id: "fixedAdd",
  router: {
    path: "/asset/fixed/add",
    name: "FixedAdd",
    component: () => import(/* webpackChunkName: "fixed-add" */ "../../views/asset/FixedAdd.vue"),
    meta: {
      title: "添加固定资产",
    },
  },
};

const fixedInventory = {
  id: "fixedInventory",
  router: {
    path: "/asset/fixed/inventory",
    name: "FixedInventory",
    component: () => import(/* webpackChunkName: "fixed-inventory" */ "../../views/asset/FixedInventory.vue"),
    meta: {
      title: "固定资产盘点",
    },
  },
};

const warningList = {
  id: "warningList",
  router: {
    path: "/asset/warning/list",
    name: "WarningList",
    component: () => import(/* webpackChunkName: "warning-list" */ "../../views/asset/WarningList.vue"),
    meta: {
      title: "预警设置",
    },
  },
};

const memberSet = {
  id: "memberSet",
  router: {
    path: "/asset/warning/member",
    name: "MemberSet",
    component: () => import(/* webpackChunkName: "member-set" */ "../../views/asset/MemberSet.vue"),
    meta: {
      title: "人员设置",
    },
  },
};

const assetAuth = {
  id: "assetAuth",
  router: {
    path: "/asset/authority",
    name: "AssetAuth",
    component: () => import(/* webpackChunkName: "asset-auth" */ "../../views/asset/AssetAuth.vue"),
    meta: {
      title: "权限设置",
    },
  },
};

export default {
  assetHome,
  tangKou,
  commodityList,
  commodityUnit,
  commodityCategory,
  purchaseList,
  purchaseAdd,
  purchaseDetail,
  warehousingList,
  warehousingAdd,
  warehousingStatistics,
  outboundList,
  outboundAdd,
  outboundStatistics,
  useStatistics,
  borrowStatistics,
  inventoryList,
  fixedList,
  fixedAdd,
  fixedInventory,
  warningList,
  memberSet,
  assetAuth,
};

export const routers = [
  {
    type: 1,
    list: [
      { id: "assetHome", action: ["add", "delete", "update", "query", "audit"] },
      { id: "tangKou", action: ["add", "delete", "update", "query", "audit"] },
      { id: "commodityList", action: ["add", "delete", "update", "query", "audit"] },
      { id: "commodityUnit", action: ["add", "delete", "update", "query", "audit"] },
      { id: "commodityCategory", action: ["add", "delete", "update", "query", "audit"] },
      { id: "purchaseList", action: ["add", "delete", "update", "query", "audit"] },
      { id: "purchaseAdd", action: ["add", "delete", "update", "query", "audit"] },
      { id: "purchaseDetail", action: ["add", "delete", "update", "query", "audit"] },
      { id: "warehousingList", action: ["add", "delete", "update", "query", "audit"] },
      { id: "warehousingAdd", action: ["add", "delete", "update", "query", "audit"] },
      { id: "warehousingStatistics", action: ["add", "delete", "update", "query", "audit"] },
      { id: "outboundList", action: ["add", "delete", "update", "query", "audit"] },
      { id: "outboundAdd", action: ["add", "delete", "update", "query", "audit"] },
      { id: "outboundStatistics", action: ["add", "delete", "update", "query", "audit"] },
      { id: "useStatistics", action: ["add", "delete", "update", "query", "audit"] },
      { id: "borrowStatistics", action: ["add", "delete", "update", "query", "audit"] },
      { id: "inventoryList", action: ["add", "delete", "update", "query", "audit"] },
      { id: "fixedList", action: ["add", "delete", "update", "query", "audit"] },
      { id: "fixedAdd", action: ["add", "delete", "update", "query", "audit"] },
      { id: "fixedInventory", action: ["add", "delete", "update", "query", "audit"] },
      { id: "warningList", action: ["add", "delete", "update", "query", "audit"] },
      { id: "memberSet", action: ["add", "delete", "update", "query", "audit"] },
      { id: "assetAuth", action: ["add", "delete", "update", "query", "audit"] },
    ],
  },
  {
    type: 2,
    list: [
      { id: "assetHome", action: ["add", "query"] },
      { id: "tangKou", action: ["add", "query"] },
      { id: "commodityList", action: ["add", "query"] },
      { id: "commodityUnit", action: ["add", "query"] },
      { id: "commodityCategory", action: ["add", "query"] },
      { id: "purchaseList", action: ["add", "query"] },
      { id: "purchaseAdd", action: ["add", "query"] },
      { id: "purchaseDetail", action: ["add", "query"] },
      { id: "warehousingList", action: ["add", "query"] },
      { id: "warehousingAdd", action: ["add", "query"] },
      { id: "warehousingStatistics", action: ["add", "query"] },
      { id: "outboundList", action: ["add", "query"] },
      { id: "outboundAdd", action: ["add", "query"] },
      { id: "outboundStatistics", action: ["add", "query"] },
      { id: "useStatistics", action: ["add", "query"] },
      { id: "borrowStatistics", action: ["add", "query"] },
      { id: "inventoryList", action: ["add", "query"] },
      { id: "fixedList", action: ["add", "query"] },
      { id: "fixedAdd", action: ["add", "query"] },
      { id: "fixedInventory", action: ["add", "query"] },
      { id: "warningList", action: ["add", "query"] },
      { id: "memberSet", action: ["add", "query"] },
    ],
  },
];

export const menus = [
  {
    type: 1,
    list: [
      {
        title: "首页",
        path: "/asset/home",
      },
      {
        title: "堂口管理",
        path: "/entity/kt",
      },
      {
        title: "商品维护",
        path: "/asset/maintenance",
        children: [
          {
            title: "商品清单",
            path: "/asset/maintenance/list",
          },
          {
            title: "商品单位",
            path: "/asset/maintenance/unit",
          },
          {
            title: "商品类别",
            path: "/asset/maintenance/category",
          },
        ],
      },
      {
        title: "申购单",
        path: "/asset/purchase",
        children: [
          {
            title: "申购单列表",
            path: "/asset/purchase/list",
          },
          {
            title: "填写申购单",
            path: "/asset/purchase/add",
          },
        ],
      },
      {
        title: "入库管理",
        path: "/asset/warehousing",
        children: [
          {
            title: "入库清单",
            path: "/asset/warehousing/list",
          },
          {
            title: "添加入库单",
            path: "/asset/warehousing/add",
          },
          {
            title: "入库统计",
            path: "/asset/warehousing/statistics",
          },
        ],
      },
      {
        title: "出库管理",
        path: "/asset/outbound",
        children: [
          {
            title: "出库清单",
            path: "/asset/outbound/list",
          },
          {
            title: "添加出库单",
            path: "/asset/outbound/add",
          },
          {
            title: "出库统计",
            path: "/asset/outbound/statistics",
          },
          {
            title: "领用统计",
            path: "/asset/outbound/use",
          },
          {
            title: "借用统计",
            path: "/asset/outbound/borrow",
          },
        ],
      },
      {
        title: "库存管理",
        path: "/asset/inventory",
        children: [
          {
            title: "当前库存",
            path: "/asset/inventory/list",
          },
        ],
      },
      {
        title: "固定资产管理",
        path: "/asset/fixed",
        children: [
          {
            title: "固定资产清单",
            path: "/asset/fixed/list",
          },
          {
            title: "添加固定资产",
            path: "/asset/fixed/add",
          },
          {
            title: "固定资产盘点",
            path: "/asset/fixed/inventory",
          },
        ],
      },
      {
        title: "预警设置",
        path: "/asset/warning",
        children: [
          {
            title: "库存预警",
            path: "/asset/warning/list",
          },
          {
            title: "人员设置",
            path: "/asset/warning/member",
          },
        ],
      },
      {
        title: "权限设置",
        path: "/asset/authority",
      },
    ],
  },
  {
    type: 2,
    list: [
      {
        title: "首页",
        path: "/asset/home",
      },
      {
        title: "商品维护",
        path: "/asset/maintenance",
        children: [
          {
            title: "商品清单",
            path: "/asset/maintenance/list",
          },
          {
            title: "商品单位",
            path: "/asset/maintenance/unit",
          },
          {
            title: "商品类别",
            path: "/asset/maintenance/category",
          },
        ],
      },
      {
        title: "申购单",
        path: "/asset/purchase",
        children: [
          {
            title: "申购单列表",
            path: "/asset/purchase/list",
          },
          {
            title: "填写申购单",
            path: "/asset/purchase/add",
          },
        ],
      },
      {
        title: "入库管理",
        path: "/asset/warehousing",
        children: [
          {
            title: "入库清单",
            path: "/asset/warehousing/list",
          },
          {
            title: "添加入库单",
            path: "/asset/warehousing/add",
          },
          {
            title: "入库统计",
            path: "/asset/warehousing/statistics",
          },
        ],
      },
      {
        title: "出库管理",
        path: "/asset/outbound",
        children: [
          {
            title: "出库清单",
            path: "/asset/outbound/list",
          },
          {
            title: "添加出库单",
            path: "/asset/outbound/add",
          },
          {
            title: "出库统计",
            path: "/asset/outbound/statistics",
          },
          {
            title: "领用统计",
            path: "/asset/outbound/use",
          },
          {
            title: "借用统计",
            path: "/asset/outbound/borrow",
          },
        ],
      },
      {
        title: "库存管理",
        path: "/asset/inventory",
        children: [
          {
            title: "当前库存",
            path: "/asset/inventory/list",
          },
        ],
      },
      {
        title: "固定资产管理",
        path: "/asset/fixed",
        children: [
          {
            title: "固定资产清单",
            path: "/asset/fixed/list",
          },
          {
            title: "添加固定资产",
            path: "/asset/fixed/add",
          },
          {
            title: "固定资产盘点",
            path: "/asset/fixed/inventory",
          },
        ],
      },
      {
        title: "预警设置",
        path: "/asset/warning",
        children: [
          {
            title: "库存预警",
            path: "/asset/warning/list",
          },
          {
            title: "人员设置",
            path: "/asset/warning/member",
          },
        ],
      },
      {
        title: "权限设置",
        path: "/asset/authority",
      },
    ],
  },
];
