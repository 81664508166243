import dataList from './data-list';
import formArea from './form-area';
import formUpload from './form-upload';
import fileUpload from './file-upload';
import richEditor from './tiny-editor';
import vcolorpicker from 'vcolorpicker'
import tabBar from './tab-bar';
import selectEntity from './select-entity';
import coordinatePicker from './coordinate-picker';
import timePeriod from './time-period';
import liveConfig from './live-config';
import tagList from './tag-list';
import numberRange from './number-range';
import lunarPicker from './lunar-picker'
import timeRange from "./time-range";
import iconSelect from "./icon-select";
import selectBook from "./select-book";
import assConfig from "./ass/ass-rules";
import assItems from "./ass/ass-items";
import assScope from "./ass/ass-scope";
import assSetting from "./ass/ass-setting";
import assSysRule from "./ass/ass-sys-rule";
import chantingSet from "./buddha/chanting-set"
import buddhaGrant from "./buddha/buddha-grant"
import selectMaster from "./select-master";
import selectDate from "./buddha/select-date";
import itemList from "./item-list";
import halfDatePicker from "./half-date-picker";
import childTemp from "./child-temp";
import openData from "./open-data";
import openDataBox from "./open-data-box";
import sImg from "./s-img";
import remind from "./remind";
import define from "./define";
import chart from "./chart";
import checkboxGroup from "./checkbox-group";
import levelGroup from "./level-group";
import questionOptions from "./question-options";
import uploadVolunteer from "./upload-volunteer.vue";
import uploadFile from "./upload-file.vue";
import roleSelect from "./role-select";
import titleCheckbox from "./title-checkbox";
import remoteSearch from "./remote-search";

const install = function (Vue) {
    Vue.component("dataList", dataList);
    Vue.component("formUpload", formUpload);
    Vue.component("fileUpload", fileUpload);
    Vue.component("richEditor", richEditor);
    Vue.component("selectEntity", selectEntity);
    Vue.component("coordinatePicker", coordinatePicker);
    Vue.component("timePeriod", timePeriod);
    Vue.component("liveConfig", liveConfig);
    Vue.component("tagList", tagList);
    Vue.component("numberRange", numberRange);
    Vue.component("timeRange", timeRange);
    Vue.component("iconSelect", iconSelect);
    Vue.component("selectBook", selectBook);
    Vue.component("assConfig", assConfig);
    Vue.component("assItems", assItems);
    Vue.component("assScope", assScope);
    Vue.component("assSetting", assSetting);
    Vue.component("assSysRule", assSysRule);
    Vue.component("chantingSet", chantingSet);
    Vue.component("buddhaGrant", buddhaGrant);
    Vue.component("lunarPicker", lunarPicker);
    Vue.component("selectMaster", selectMaster);
    Vue.component("selectDate", selectDate);
    Vue.component("itemList", itemList);
    Vue.component("halfDatePicker", halfDatePicker);
    Vue.component("childTemp", childTemp);
    Vue.use(vcolorpicker);
    Vue.component("formArea", formArea);
    Vue.component("tabBar", tabBar);
    Vue.component("openData", openData);
    Vue.component("openDataBox", openDataBox);
    Vue.component("sImg", sImg);
    Vue.component("remind", remind);
    Vue.component("define", define);
    Vue.component("chart", chart);
    Vue.component("checkboxGroup", checkboxGroup);
    Vue.component("levelGroup", levelGroup);
    Vue.component("questionOptions", questionOptions);
    Vue.component("uploadVolunteer", uploadVolunteer);
    Vue.component("uploadFile", uploadFile);
    Vue.component("roleSelect", roleSelect);
    Vue.component("titleCheckbox", titleCheckbox);
    Vue.component("remoteSearch", remoteSearch);
    Vue.component("Vnodes", {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes
    });
}

export default install;
