<template>
    <div class="flex-box vertical address-book">
        <div class="address-book-query" v-if="search || type == 'book'">
            <a-input-search allow-clear v-model="keyword" :placeholder="placeholder" :loading="searchLoading" @search="handleSearch" v-if="search"/>
            <a-radio-group v-model="orgType" button-style="solid" class="book-org-type" size="small" v-if="type == 'book'">
                <a-radio-button class="book-org-radio" :value="1">组织架构</a-radio-button>
                <a-radio-button class="book-org-radio" :value="2">标签</a-radio-button>
            </a-radio-group>
        </div>
        <div class="address-book-ctx">
            <a-tree
                :multiple="multiple"
                :expanded-keys.sync="expandKeys"
                :selected-keys="keys"
                :tree-data="list"
                @select="handleSelect"
                v-show="orgType == 1">
                <template v-slot:node="{title, key, user}">
                    <a-icon class="node-icon" :type="user ? 'user' : 'folder'" />
                    <open-data :type="user ? 'userName' : 'departmentName'" :openid="title" />
                    <a-icon class="node-select" type="check" v-show="keys.indexOf(key) >= 0"/>
                </template>
            </a-tree>
            <div class="org-tags" v-show="orgType == 2">
                <div class="org-tag-list" v-if="tagList && tagList.length > 0">
                    <div class="org-tags-item"
                         :class="{selected: selectItems.findIndex(item => item.tag_id == t.id) >= 0}"
                         v-for="t in tagList" :key="t.id"
                         @click="selectTag(t)">
                        <div class="icon-tag"></div>
                        <div class="tag-name">{{t.name}}</div>
                        <a-dropdown :trigger="['click']" class="node-more" v-if="editable">
                            <a-icon type="more" @click.stop="e => e.preventDefault()" />
                            <a-menu slot="overlay">
                                <a-menu-item key="0" @click="showEditTag(t)">编辑</a-menu-item>
                                <a-menu-item key="1" @click="deleteTag(t)">删除</a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>
                <div class="no-tag" v-else-if="tagList">暂无标签</div>
            </div>
            <div class="address-book-loading" v-show="loading">
                <a-spin tip="加载中...">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                </a-spin>
            </div>
        </div>
        <a-space class="add-dept" @click="addTag()" v-show="orgType == 2" v-if="editable">
            <a-icon type="plus" />
            <span>添加标签</span>
        </a-space>
        <div class="search-result" v-if="resultList">
            <template v-if="resultList.user || resultList.dept || resultList.tag">
                <div class="search-result-area" v-if="resultList.user && resultList.user.length > 0">
                    <div class="result-area-title">成员</div>
                    <div class="search-result-list">
                        <div
                            class="search-result-item"
                            v-for="u in resultList.user"
                            :key="u"
                            @click="selectSearchItem('user', u)">
                            <open-data type="userName" :openid="u" />
                        </div>
                    </div>
                </div>
                <div class="search-result-area" v-if="resultList.dept && resultList.dept.length > 0">
                    <div class="result-area-title">部门</div>
                    <div class="search-result-list">
                        <div
                            class="search-result-item"
                            v-for="d in resultList.dept"
                            :key="d"
                            @click="selectSearchItem('dept', d)">
                            <a-icon class="search-result-icon" type="folder" />
                            <open-data type="departmentName" :openid="d" />
                        </div>
                    </div>
                </div>
                <div class="search-result-area" v-if="resultList.tag && resultList.tag.length > 0">
                    <div class="result-area-title">标签</div>
                    <div class="search-result-list">
                        <div
                            class="search-result-item"
                            v-for="t in resultList.tag"
                            :key="t.id"
                            @click="selectSearchItem('tag', t)">
                            <div class="icon-tag search-result-icon"></div>
                            <div class="search-result-name">{{t.name}}</div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="no-tag" v-else>暂无搜索结果</div>
        </div>
        <a-modal
          :title="form.id ? '编辑标签' : '添加标签'"
          :maskClosable="false"
          :closable="false"
          :width="600"
          :body-style="{padding: 0}"
          @ok="confirm"
          v-model="visible">
            <form-area
              ref="orgForm"
              class="flex-grow flex-box vertical org-form"
              layout="horizontal"
              hide-btn
              :label-col="{span: 4}"
              :wrapper-col="{span: 20}"
              :items="items"
              :entity="form"></form-area>
        </a-modal>
    </div>
</template>

<script>
    import {clone, isArray, isEmpty} from "../common/js/tool";
    import {tagItems} from "../common/constant/org";

    export default {
        name: "address-book",
        props: {
            select: [String, Number, Array], // 选中的部门/成员/标签的ID
            multiple: Boolean,
            editable: Boolean,
            hideUser: Boolean,
            range: Array,
            // 标签类型 1 普通标签 2 资产标签
            tagType: {
                type: Number,
                default: 1
            },
            search: {
                type: Boolean,
                default: true
            },
            type: String // 选择类型 dept 部门 user 成员 all 部门加成员 tag 标签 file 部门成员及标签
        },
        data() {
            return {
                keys: [],
                list: [],
                keyword: null,
                searchLoading: false,
                searchSelect: null,
                orgType: 1,
                loading: false,
                expandKeys: [],
                selectItems: [],
                resultList: null,
                groupList: null,
                form: {},
                visible: false,
                items: tagItems,
            }
        },
        computed: {
            orgList() {
              let type = this.type;
              if(type == 'dept' || this.hideUser) {
                return this.$store.getters.deptList;
              } else {
                return this.$store.getters.bookList;
              }
            },
            tagList() {
                return this.tagType == 2 ? this.$store.getters.assetTagList : this.$store.getters.tagList;
            },
            tagUrl() {
                return this.tagType == 2 ? "/admin/asset-tag" : "/admin/tag";
            },
            placeholder() {
                let type = this.type;
                if(type == 'dept') {
                    return '搜索部门';
                } else if(type == 'user') {
                    return '搜索成员';
                } else if(type == 'all') {
                    return '搜索部门、成员';
                } else if(type == 'tag') {
                    return '搜索标签';
                } else if(type == 'book') {
                    return '搜索部门、成员、标签';
                } else {
                    return '搜索';
                }
            },
            sourceType() {
                let type = this.type;
                if(type == 'tag') {
                    return 'tag';
                } else if(type == 'book') {
                    return 'all';
                } else if(this.range && this.range.length > 0) {
                    return 'custom';
                } else {
                    return 'org';
                }
            },
        },
        watch: {
            orgList(val) {
                if(val) {
                    this.dealList(val);
                }
                this.setSelect();
            },
            tagList() {
                this.setSelect();
            },
            select() {
                if(this.tagList && this.orgList) {
                    this.setSelect();
                }
            },
            keyword(val) {
                if(!val) {
                    this.resultList = null;
                }
            },
            type() {
                this.init();
            },
            range() {
                this.init();
            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                let sourceType = this.sourceType;
                let num = 0;
                const range = this.range;
                if(range && range.length > 0) {
                    this.dealList(range);
                } else {
                    if(!this.orgList && sourceType != 'tag') {
                        this.getList();
                        num += 1;
                    } else if(this.orgList) {
                        this.dealList(this.orgList);
                    }
                    if(!this.tagList && sourceType != 'org') {
                        this.getTagList();
                        num += 1;
                    }
                }
                if(this.type == 'tag') {
                    this.orgType = 2;
                } else if(this.type != 'book') {
                    this.orgType = 1;
                }
                // 不需要加载数据
                if(num == 0) {
                    this.setSelect();
                }
            },
            selectSearchItem(type, item) {
                let entity;
                if(type == 'user') {
                    entity = {userid: item};
                } else if(type == 'dept') {
                    entity = {id: item}
                } else if(type == 'tag') {
                    entity = {tag_id: item.id, ...item};
                }
                if(entity) {
                    // 选择标签
                    this.selectItem(entity);
                    this.keyword = null;
                    this.resultList = null;
                }
            },
            addTag() {
                this.form = {};
                this.visible = true;
            },
            deleteTag(tag) {
                this.$confirm({
                    title: '提示',
                    content: '确认删除此标签吗？',
                    onOk: () => {
                        this.$axios({
                            url: `${this.tagUrl}/${tag.id}`,
                            method: 'DELETE',
                        }).then(() => {
                            const tagList = [...this.tagList];
                            const i = tagList.findIndex(t => t.id == tag.id);
                            if(i >= 0) {
                                tagList.splice(i, 1);
                                this.commitTagList(tagList);
                            }
                            this.$emit("change", { type: 3, operate: "delete", item: tag});
                        })
                    }
                })
            },
            showEditTag(tag) {
                this.form = clone(tag);
                this.visible = true;
            },
            confirm() {
                this.$refs.orgForm.handleConfirm().then(form => {
                    this.updateTag(form)
                })
            },
            updateTag(form) {
                let url = this.tagUrl;
                let method = 'POST';
                if(form.id) {
                    url += '/' + form.id;
                    method = 'PATCH';
                }
                if(form.ids){
                    let real_ids = {
                        uids: [],
                        dept: []
                    };
                    form.ids.forEach(item => {
                        if(item.type == "department") {
                            real_ids.dept.push(item.id);
                        } else {
                            real_ids.uids.push(item.id);
                        }
                    });
                    form.real_ids = real_ids;
                    form.visible_ids = JSON.stringify(form.ids);
                }
                return this.$axios({
                    url,
                    method,
                    data: form
                }).then(res => {
                    this.dealTag(res);
                    res.tag_id = res.id;
                    this.$message.success(form.id ? '更新成功' : '添加成功');
                    const tagList = [...this.tagList];
                    const i = tagList.findIndex(t => t.id == res.id);
                    if(i >= 0) {
                        tagList.splice(i, 1, res);
                    } else {
                        tagList.push(res);
                    }
                    this.commitTagList(tagList);
                    // 新增默认选中
                    if(!form.id) {
                        this.selectItem(res);
                    }
                    this.visible = false;
                    this.$emit("change", { type: 3, operate: form.id ? "update" : "add", item: res});
                });
            },
            handleSearch() {
                if(this.searchLoading || !this.keyword) return;
                this.searchLoading = true;
                let type = this.type;
                let pro;
                if(type == 'dept') {
                    pro = [this.searchBook(2)];
                } else if(type == 'user') {
                    pro = [this.searchBook(1)];
                } else if(type == 'all') {
                    pro = [this.searchBook(0)];
                } else if(type == 'tag') {
                    pro = [this.searchTag()];
                } else if(type == 'book') {
                    pro = [this.searchBook(0), this.searchTag()];
                }
                if(pro) {
                    Promise.all(pro).then(() => {
                        this.searchLoading = false;
                    }).catch(() => {
                        this.searchLoading = false;
                    })
                }
            },
            searchBook(type) {
                let keyword = this.keyword;
                let url = `/common/search-contact?keyword=${keyword}&queryType=${type}`;
                return this.$axios(url).then(res => {
                    if(res.error == 0) {
                        let deptList = res.data.party ? res.data.party.department_id : null;
                        let userList = res.data.user ? res.data.user.userid : null;
                        if(this.resultList) {
                            this.$set(this.resultList, 'user', userList);
                            this.$set(this.resultList, 'dept', deptList);
                        } else {
                            this.resultList = {
                                user: userList,
                                dept: deptList
                            }
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            searchTag() {
                let keyword = this.keyword;
                let url = `${this.tagUrl}?filter[name][like]=${keyword}`;
                return this.$axios(url).then(res => {
                    let list = res.data;
                    if(list && list.length > 0) {
                        this.dealTagList(list);
                        if(this.resultList) {
                            this.$set(this.resultList, 'tag', list);
                        } else {
                            this.resultList = {
                                tag: list
                            }
                        }
                    }
                })
            },
            selectTag(tag) {
                let item = {tag_id: tag.id, ...tag};
                this.selectItem(item);
            },
            selectItem(item) {
                let selectItems = this.selectItems;
                if(this.multiple) {
                    let index = this.findSelectItemsIndex(item);
                    if(index === -1) {
                        selectItems.push(item);
                    }
                    // 选择部门/成员
                    if(!item.tag_id) {
                        let keys = this.findItems([item]);
                        keys.forEach(key => {
                            if(this.keys.indexOf(key) === -1) {
                                this.keys.push(key);
                            }
                        });
                    }
                } else {
                    if(!item.tag_id) {
                        this.keys = [...this.findItems([item])];
                    }
                    this.selectItems = [item]
                }
                this.dispatchSelect();
            },
            findSelectItemsIndex(item) {
                return this.selectItems.findIndex(s => {
                    if(item.tag_id) {
                        return s.tag_id && item.tag_id == s.tag_id;
                    } else if(item.userid) {
                        return s.userid == item.userid;
                    } else {
                        return !s.tag_id && s.id == item.id;
                    }
                })
            },
            removeAll() {
                this.selectItems = [];
                this.keys = [];
                this.dispatchSelect();
            },
            removeItem(item) {
                let selectItems = this.selectItems;
                let index = this.findSelectItemsIndex(item);
                if(index >= 0) {
                    selectItems.splice(index, 1);
                }
                if(!item.tag_id) {
                    this.removeKey(item.key);
                }
                this.dispatchSelect();
            },
            removeKey(key) {
                let item = this.getKeyItem(key);
                if(item) {
                    item.keys.forEach(k => {
                        let index = this.keys.indexOf(k);
                        if(index >= 0) {
                            this.keys.splice(index, 1);
                        }
                    });
                }
            },
            setSelect() {
                let select = this.select;
                let tagList = this.tagList;
                let orgList = this.orgList;
                let sourceType = this.sourceType;
                if((sourceType == 'all' && !(orgList && tagList)) || (sourceType == 'tag' && !tagList) || (sourceType == 'org' && !orgList)) return;
                let items = [];
                if(!isEmpty(select)) {
                    let type = this.type;
                    // 只选择标签
                    if(type == 'tag') {
                        items = select;
                    } else {
                        // 选择成员、部门及标签
                        if(type == 'dept' || type == 'user') {
                            if(!isArray(select)) {
                                select = [select];
                            }
                            select = select.map(s => {
                                return {
                                    type: type == 'user' ? 'user' : 'department',
                                    id: s
                                }
                            })
                        }
                        let keys = [];
                        let list = this.groupList;
                        select.forEach(s => {
                            if(s.type == 'tag') {
                                let tag = tagList.find(t => t.id == s.id);
                                if(tag) {
                                    items.push({...tag, tag_id: tag.id});
                                }
                            } else {
                                // 成员、部门
                                let book = list.find(b => s.type == 'user' ? b.userid == s.id : b.id == s.id);
                                if(book) {
                                    if(book.keys) {
                                        keys.push(...book.keys);
                                    }
                                    // 展开父级部门
                                    if(book.parentKey && this.expandKeys.indexOf(book.parentKey) === -1) {
                                        this.expandKeys.push(book.parentKey);
                                    }
                                    items.push(book);
                                }
                            }
                        });
                        this.keys = keys;
                    }
                } else {
                    this.selectItems = [];
                    this.keys = [];
                }
                this.selectItems = items;
                this.dispatchSelect("set");
            },
            findItems(select) {
                let keys = [];
                let list = this.groupList;
                select.forEach(item => {
                    let book = list.find(b => b.userid ? b.userid == item : b.id == item);
                    if(book) {
                        if(book.keys) {
                            keys.push(...book.keys);
                        }
                        // 展开父级部门
                        if(book.parentKey && this.expandKeys.indexOf(book.parentKey) === -1) {
                            this.expandKeys.push(book.parentKey);
                        }
                    }
                });
                return keys;
            },
            getTagList() {
                const url = `${this.tagUrl}?pageSize=1000`;
                return this.$axios(url).then(res => {
                    let list = res.data;
                    this.dealTagList(list);
                    this.commitTagList(list);
                });
            },
            commitTagList(list) {
                this.$store.commit(this.tagType === 2 ? "setAssetTagList" : "setTagList", list);
            },
            dealTagList(list) {
                list.forEach(this.dealTag);
            },
            dealTag(tag) {
                if(tag.visible_ids) {
                    tag.ids = JSON.parse(tag.visible_ids);
                }
            },
            getList() {
                this.loading = true;
                this.$store.dispatch("getBookList", this.type === 'dept' || this.hideUser).finally(() => {
                  this.loading = false;
                });
            },
            dealList(list) {
                let groupList = [];
                let deptList = this.dealDept(list, null, groupList);
                this.list = deptList;
                this.groupList = groupList;
                // 第一层级只有一个 默认展开
                if(deptList.length === 1) {
                    let key = deptList[0].key;
                    if(this.expandKeys.indexOf(key) === -1) {
                        this.expandKeys.push(key);
                    }
                }
                this.$emit("org", list);
            },
            dealDept(list, key, groupList) {
                return list.map(item => {
                    let itemKey, id, selectable = true, keys = [];
                    // 成员用 ${部门ID}_${成员ID}作为key 部门直接用ID作为key
                    if(item.userid) {
                        id = item.userid;
                        itemKey = key ? `${key}_${id}` : id;
                        keys = item.department && item.department.length > 0 ? item.department.map(d => `${d}_${id}`) : [itemKey];
                        selectable = this.type != 'dept';
                    } else {
                        id = item.id;
                        itemKey = item.id;
                        keys = [itemKey];
                        selectable = this.type != 'user';
                    }
                    let res = {
                        key: itemKey,
                        value: itemKey, // 用于a-tree-select数据
                        title: item.name,
                        id,
                        selectable,
                        user: !!item.userid,
                        scopedSlots: {
                            title: 'node'
                        }
                    }
                    // 成员存在多个部门 列出所有部门下的key
                    groupList.push({
                        ...item,
                        id,
                        key: itemKey,
                        keys,
                        parentKey: key
                    });
                    let children = [];
                    if(item.children && item.children.length > 0) {
                        children = children.concat(this.dealDept(item.children, res.key, groupList));
                    }
                    // 不是只选择部门则将成员也渲染
                    if(this.type != 'dept' && !this.hideUser && item.user && item.user.length > 0) {
                        children = children.concat(this.dealDept(item.user, res.key, groupList));
                    }
                    res.children = children;
                    return res;
                });
            },
            getKeyItem(key) {
                return this.groupList.find(item => item.key == key);
            },
            dispatchSelect(event = 'select') {
                let items = this.selectItems;
                if(items && items.length > 0) {
                    this.$emit(event, items);
                } else {
                    this.$emit(event, null);
                }
            },
            handleSelect(keys) {
                let oldKeys = [].concat(this.keys);
                // 新增项
                let addKeys = keys.filter(k => oldKeys.indexOf(k) === -1);
                // 删除项
                let delKeys = oldKeys.filter(k => keys.indexOf(k) === -1);
                let selectItems = this.selectItems;
                if(addKeys.length > 0) {
                    let addItems = addKeys.map(this.getKeyItem);
                    // 新增元素是成员且有在多个部门将所有key加入keys中
                    addItems.forEach(item => {
                        item.keys.forEach(k => {
                            if(oldKeys.indexOf(k) === -1) {
                                oldKeys.push(k);
                            }
                        });
                        // 增加选中项
                        if(this.multiple) {
                            let i = this.findSelectItemsIndex(item);
                            if(i === -1) {
                                selectItems.push(item);
                            }
                        } else {
                            this.selectItems = [item];
                        }
                    });
                }
                if(delKeys.length > 0) {
                    let delItems = delKeys.map(this.getKeyItem);
                    delItems.forEach(item => {
                        // 删除元素是成员且有在多个部门 将成员的所有key删除
                        item.keys.forEach(k => {
                            let index = oldKeys.indexOf(k);
                            if(index >= 0) {
                                oldKeys.splice(index, 1);
                            }
                            // 删除移除的项
                            let i = this.findSelectItemsIndex(item);
                            if(i >= 0) {
                                selectItems.splice(i, 1);
                            }
                        })
                    })
                }
                this.keys = oldKeys;
                this.dispatchSelect();
            }
        }
    }
</script>

<style scoped lang="less">
    .address-book {
        position: relative;
    }
    .address-book-query {
        padding: 10px;
        border-bottom: var(--border);
    }
    .book-org-type {
        margin-top: 10px;
        width: 100%;
    }
    .book-org-radio {
        width: 50%;
        text-align: center;
        font-size: 12px;
    }
    .address-book-ctx {
        flex: 1;
        position: relative;
        min-height: 0;
        overflow: auto;
    }
    .search-result {
        position: absolute;
        top: 52px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: @component-background;
        border-top: var(--border);
        z-index: 1;
    }
    .address-book-loading {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 40px;
        background-color: rgba(255,255,255,.6);
    }
    .add-dept {
        justify-content: center;
        padding: 10px 0;
        border-top: var(--border);
        cursor: pointer;
    }
    .org-tags-item {
        display: flex;
        align-items: center;
        padding: 6px 16px;
        margin-top: 6px;
        cursor: pointer;
        .icon-tag {
            margin-right: 6px;
            width: 24px;
            height: 24px;
            font-size: 24px;
            line-height: 1;
            color: @primary-color;
        }
        &:hover {
            background-color: @background-color-light;
            .node-more {
                display: inline-block;
            }
        }
        &.selected {
            background-color: #bae7ff;
            .node-more {
                display: inline-block;
                color: #999;
            }
        }
        .node-more {
            display: none;
        }
        .tag-name {
            flex: 1;
        }
    }
    .node-icon {
        margin-right: 6px;
        font-size: 16px;
        color: @blue-6;
    }
    .node-more {
        margin-left: 8px;
        color: @primary-color;
    }
    .no-tag {
        margin-top: 40px;
        text-align: center;
        color: @text-color-secondary;
    }
    .result-area-title {
        padding: 6px 12px;
        color: @text-color-secondary;
    }
    .search-result-item {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        cursor: pointer;
        .search-result-icon {
            margin-right: 6px;
            color: @primary-color;
            font-size: 16px;
            &.icon-tag {
                width: 20px;
                height: 20px;
                font-size: 20px;
                line-height: 1;
            }
        }
        &:hover {
            background-color: @background-color-light;
        }
    }
</style>
