import { clone } from "@/common/js/tool";
import * as oa from "../permission/router/oa";
import * as hr from "../permission/router/hr";
import * as rec from "../permission/router/rec";
import * as buddha from "../permission/router/buddha";
import * as asset from "../permission/router/asset";
import * as safe from "../permission/router/safe";
import * as culture from "../permission/router/culture";
import * as light from "../permission/router/light";
import * as space from "../permission/router/space";
import * as core from "../permission/router/core";
import * as program from "../permission/router/program";
import { getStorageInfo, getTemple } from "@/common/js/storage";
export const ADMIN_ROLE = 1; // 超管权限值
const routerMenuConfig = { oa, hr, rec, buddha, asset, safe, culture, light, space, core, program };

export const volunteerRoles = [
  { key: 9, title: "组长" },
  { key: 10, title: "值班组长" },
  { key: 11, title: "副组长" },
  { key: 12, title: "岗位负责人" },
  { key: 13, title: "统计人员" },
];

const sysConfig = [
  {
    id: 1,
    type: "oa",
    name: "服务协同系统",
    title: "服务协同管理后台",
    icon: require("../assets/icon/oa.png"),
    path: "/oa",
    children: [
      { key: 1, title: "系统管理员" },
      { key: 2, title: "应用管理员" },
      { key: 3, title: "用餐核销员" },
      { key: 4, title: "上香核销员" },
      { key: 5, title: "礼品核销员" },
      { key: 11, title: "身份码核销员" },
      { key: 12, title: "会议室核销员" },
      { key: 13, title: "文宣核销员" },
      { key: 14, title: "通行码核销员" },
      // {key: 6, title: '第一道岗'},
      // {key: 7, title: '第二道岗'},
      // {key: 8, title: '第三道岗'},
      { key: 9, title: "车牌下发" },
      { key: 15, title: "报备车辆" },
      { key: 10, title: "库房管理员" },
    ],
  },
  {
    id: 2,
    type: "hr",
    name: "人事评价系统",
    title: "人事评价管理后台",
    icon: require("../assets/icon/hr.png"),
    path: "/hr",
    children: [
      { key: 1, title: "系统管理员" },
      { key: 2, title: "部门领导" },
      { key: 3, title: "志工服务" },
      { key: 4, title: "志工活动" },
      { key: 5, title: "志工培训" },
      { key: 6, title: "用餐券" },
      { key: 7, title: "结缘品" },
      { key: 8, title: "通行" },
      ...volunteerRoles,
      { key: 14, title: "志工管理员" },
    ],
  },
  {
    id: 12,
    type: "program",
    name: "部门数据看板",
    title: "部门数据看板",
    icon: require("../assets/icon/act.png"),
    path: "/program/overview",
  },
  {
    id: 4,
    type: "act",
    name: "大型活动系统",
    title: "大型活动管理后台",
    icon: require("../assets/icon/act.png"),
    path: "http://activity.zj-qq.cn",
    blank: true,
  },
  {
    id: 5,
    type: "buddha",
    name: "佛事法会系统",
    title: "佛事法会管理后台",
    icon: require("../assets/icon/buddha.png"),
    path: "/buddha",
    children: [
      { key: 1, title: "系统管理员" },
      { key: 2, title: "客堂管理员" },
      { key: 3, title: "开牌管理员" },
    ],
  },
  {
    id: 6,
    type: "asset",
    name: "资产管理系统",
    title: "资产管理后台",
    icon: require("../assets/icon/asset.png"),
    // path: 'https://kf.lysguide.com',
    path: "/asset",
    // blank: true,
    children: [
      { key: 1, title: "系统管理员" },
      { key: 2, title: "操作员" },
    ],
  },
  {
    id: 7,
    type: "safe",
    name: "安全管理系统",
    title: "安全管理后台",
    icon: require("../assets/icon/safe.png"),
    path: "/safe",
    children: [
      { key: 1, title: "系统管理员" }, // 固定只有一个身份
    ],
  },
  {
    id: 3,
    type: "rec",
    name: "走进灵隐系统",
    title: "走进灵隐后台",
    icon: require("../assets/icon/rec.png"),
    path: "/rec",
    children: [{ key: 1, title: "系统管理员" }],
  },
  // {
  //     id: 8,
  //     type: 'culture',
  //     name: '走进灵隐系统',
  //     title: '走进灵隐系统',
  //     icon: require("../assets/icon/culture.png"),
  //     path: '/culture'
  // },
  {
    id: 9,
    type: "light",
    name: "佛像供奉系统",
    title: "佛像供奉后台",
    icon: require("../assets/icon/light.png"),
    path: "/light",
    children: [{ key: 1, title: "系统管理员" }],
  },
  {
    id: 10,
    type: "space",
    name: "智能空间系统",
    title: "智能空间后台",
    icon: require("../assets/icon/light.png"),
    path: "/space",
    children: [{ key: 1, title: "系统管理员" }],
  },
  {
    id: 11,
    type: "core",
    name: "核心数据平台",
    title: "核心数据平台",
    icon: require("../assets/icon/light.png"),
    path: "/core",
    children: [{ key: 1, title: "系统管理员" }],
  },
];

export const getSysList = function() {
  let temple = getTemple();
  let res = [...sysConfig];
  if (temple) {
    if (temple === 2) {
      const index = sysConfig.findIndex((sys) => sys.id == 7);
      if (index >= 0) {
        const safe = res.splice(index, 1);
        res.unshift(safe[0]);
      }
    }
  }
  return res;
};

const getSubSystemRouterMenus = function(sys, type) {
  let routers = [],
    menus = [];
  let conf = routerMenuConfig[sys];
  if (conf) {
    let sr = conf.routers.find((r) => r.type == type);
    let sm = conf.menus.find((r) => r.type == type);
    if (sr) {
      routers = clone(sr.list);
    }
    if (sm) {
      menus = clone(sm.list);
    }
  }
  return { routers, menus };
};

const getSysListRoutersMenus = function(sysList) {
  let routers = [],
    menus = [
      {
        title: "首页",
        path: "/app/home",
        icon: require("../assets/icon/home.png"),
      },
    ];
  sysList.forEach((sys) => {
    sys.routers.forEach((r) => {
      if (routers.findIndex((a) => a.id == r.id) === -1) {
        routers.push(r);
      }
    });
    if (sys.menus && sys.menus.length > 0) {
      let menu = {
        title: sys.name,
        path: sys.path,
        icon: sys.icon,
      };
      menu.children = sys.menus;
      menus.push(menu);
    } else if (sys.blank) {
      menus.push({
        title: sys.name,
        path: sys.path,
        icon: sys.icon,
        blank: true,
      });
    }
  });
  return { routers, menus };
};

export const getSafeSystemRouterMenus = function(sys, role) {
  let routers = [],
    menus = [];
  let conf = routerMenuConfig[sys];
  if (conf) {
    let modules_ids = role.module_config?.split(",");
    // console.log("module_config:", role, role.module_config, modules_ids);
    let sr = conf.routers[0].list.filter((r) => modules_ids.indexOf(r.key) > -1);
    // let sm = conf.menus[0].list.filter(r => modules_ids.indexOf(r.key) > -1);
    let sm = [];
    conf.menus[0].list.forEach((item) => {
      let children = item.children.filter((r) => modules_ids.indexOf(r.key) > -1);
      if (children.length > 0) {
        let menu = clone(item);
        menu.children = children;
        sm.push(menu);
      }
    });
    if (sr) {
      routers = clone(sr);
    }
    if (sm) {
      menus = clone(sm);
    }
  }
  return { routers, menus };
};

export const getProgramSystemRouterMenus = function(sys, role, dept_board_new) {
  let routers = [],
    menus = [];
  let conf = routerMenuConfig[sys];
  if (conf) {
    let sr = conf.routers.find((r) => r.type === role);
    let sm = conf.menus.find((r) => r.type === role);
    if (sr) {
      routers.push(sr.list[0]); // 数据看板
    }
    if (sm) {
      menus.push(sm.list[0]); // 数据看板
    }
    program.specialDeptList.forEach((dept) => {
      const target = dept_board_new.find((item) => item.dept_id === dept.dept_id);
      if (target) {
        dept.router_ids.forEach((id) => {
          const router = sr.list.find((r) => r.id === id);
          if (router) {
            routers.push(router);
          }
        });
        dept.menus.forEach((name) => {
          const menu = sm.list.find((m) => m.name === name);
          if (menu) {
            menus.push(menu);
          }
        });
      }
    });
    // if (sr) {
    //   routers.push(sr.list[sr.list.length - 1]); // 权限设置
    //   menus.push(sm.list[sm.list.length - 1]); // 权限设置
    // }
  }
  return { routers, menus };
};

export const getRoleRouterMenus = function(user) {
  user = user || getStorageInfo();
//   user.role = 2;
  let sysList = [];
  let admin = false,
    screen = false,
    oa = false;
  const baseList = getSysList();
  if (user.role === ADMIN_ROLE) {
    sysList = baseList.map((sys) => {
      let { routers, menus } = getSubSystemRouterMenus(sys.type, 1);
      return { ...sys, routers, menus };
    });
    admin = true;
    screen = true;
    oa = true;
  } else {
    let hr = false;
    if (user.userRole && user.userRole.length > 0) {
      user.userRole.forEach((r) => {
        if (r.system === 12) return; // 部门数据看板权限不根据userRole来判定
        let temp = baseList.find((sys) => sys.id === r.system);
        if (temp) {
          let routerMenus = {};
          if (r.system === 7) {
            // 安全管理系统
            routerMenus = getSafeSystemRouterMenus(temp.type, r);
          } else {
            routerMenus = getSubSystemRouterMenus(temp.type, r.privilege);
          }
          let { routers, menus } = routerMenus;
          let sys = sysList.find((s) => s.id === temp.id);
          if (sys) {
            routers.forEach((r) => {
              if (sys.routers.findIndex((a) => a.id === r.id) === -1) {
                sys.routers.push(r);
              }
            });
            menus.forEach((m) => {
              if (sys.menus.findIndex((a) => a.path === m.path) === -1) {
                sys.menus.push(m);
              }
            });
          } else {
            temp = { ...temp, routers, menus };
            sysList.push(temp);
          }
          if (temp.id === 1) {
            oa = true;
          } else if (temp.id === 2) {
            hr = true;
          }
        }
        if (r.privilege === 1) {
          screen = true;
        }
      });
    }
    // 部门看板 单独处理权限
    if (user.dept_board_new?.length) {
      let program = baseList.find((sys) => sys.id === 12);
      if (program) {
        const routerMenus = getProgramSystemRouterMenus("program", 1, user.dept_board_new);
        sysList.push({ ...program, ...routerMenus });
      }
    }
    // 没有人事系统权限 增加人事系统普通用户权限
    if (!hr) {
      let nh = baseList.find((sys) => sys.id === 2);
      nh && sysList.push({ ...nh, ...getSubSystemRouterMenus(nh.type, 0) });
    }
  }
  let { routers, menus } = getSysListRoutersMenus(sysList);
  if (admin) {
    routers.push({ id: "administrator" });
    menus.push({
      title: "权限设置",
      path: "/admin/super",
      icon: require("../assets/icon/auth.png"),
    });
  }
  screen && routers.push({ id: "screen" });
  oa && routers.push({ id: "process" });
  return { routers, menus };
};

export const getSystemRoleName = function(id, role) {
  let res = "普通用户";
  let sys = getSysList().find((s) => s.id == id);
  if (sys && sys.children) {
    let c = sys.children.find((r) => r.key == role);
    if (c) {
      res = c.title;
    }
  }
  return res;
};

export const getSysName = function(id) {
  let res = getSysList().find((sys) => sys.id == id);
  return res ? res.name : "";
};

export const getSystemOptions = function() {
  return getSysList()
    .filter((sys) => !!sys.children)
    .map((sys) => {
      return {
        key: sys.id,
        title: sys.name,
      };
    });
};

export const getSystemPermissionOptions = function(system) {
  let sys = getSysList().find((s) => s.id == system);
  return sys && sys.children ? clone(sys.children) : [];
};
