const hrHome =  {
    id: 'hrHome',
    router: {
        path: '/hr/home',
        name: 'HRHome',
        component: () => import(/* webpackChunkName: "hh" */ '../../views/hr/HRHome.vue'),
        meta: {
            title: '首页'
        }
    },
}
const hrWorkbench =  {
    id: 'hrWorkbench',
    router: {
        path: '/hr/workbench',
        name: 'HRWorkbench',
        component: () => import(/* webpackChunkName: "hw" */ '../../views/hr/workbench/HRWorkbench.vue'),
        meta: {
            title: '工作台'
        }
    },
}
const hrDesktop =  {
    id: 'hrDesktop',
    router: {
        path: '/hr/desktop',
        name: 'HRDesktop',
        component: () => import(/* webpackChunkName: "hd" */ '../../views/hr/HRDesktop.vue'),
        meta: {
            title: '我的待办'
        }
    },
}
const hrMessage =  {
    id: 'hrMessage',
    router: {
        path: '/hr/msg',
        name: 'HRMessage',
        component: () => import(/* webpackChunkName: "hh" */ '../../views/hr/HRMessage.vue'),
        meta: {
            title: '动态通知'
        }
    },
}
const hrOrganization =  {
    id: 'hrOrganization',
    router: {
        path: '/hr/org',
        name: 'HROrganization',
        component: () => import(/* webpackChunkName: "ho" */ '../../views/hr/HROrganization.vue'),
        meta: {
            title: '组织架构',
            keepAlive: true
        }
    },
}
const hrBook =  {
    id: 'hrBook',
    router: {
        path: '/hr/file/:type',
        name: 'HRBook',
        component: () => import(/* webpackChunkName: "hb" */ '../../views/hr/file/HRBook.vue'),
        meta: {
            title: '档案管理',
            keepAlive: true
        }
    },
}

const hrBookForm =  {
    id: 'hrBook',
    router: {
        path: '/hr/file/form/:type',
        name: 'HRBookForm',
        component: () => import(/* webpackChunkName: "hbf" */ '../../views/hr/file/HRBookForm.vue'),
        meta: {
            title: '人员管理'
        }
    },
}

const hrBookDetail =  {
    id: 'hrBookDetail',
    router: {
        path: '/hr/file/detail/:id',
        name: 'HRBookDetail',
        component: () => import(/* webpackChunkName: "hbf" */ '../../views/hr/file/HRBookDetail.vue'),
        meta: {
            title: '人员管理'
        }
    },
}

const weChatUser =  {
    id: 'weChatUser',
    router: {
        path: '/hr/user',
        name: 'WeChatUser',
        component: () => import(/* webpackChunkName: "user" */ '../../views/hr/file/WeChatUser.vue'),
        meta: {
            title: '用户列表',
            keepAlive: true
        }
    },
}

const hrPrefer =  {
    id: 'hrPrefer',
    router: {
        path: '/hr/prefer/:type',
        name: 'HRPrefer',
        component: () => import(/* webpackChunkName: "hr-prefer" */ '../../views/hr/prefer/HRPrefer.vue'),
        meta: {
            title: '人员选拔',
            keepAlive: true
        }
    },
}
const hrPost =  {
    id: 'hrPost',
    router: {
        path: '/hr/post/:type',
        name: 'HRPost',
        component: () => import(/* webpackChunkName: "hp" */ '../../views/hr/HRPost.vue'),
        meta: {
            title: '人员变动'
        }
    },
}
const assessment =  {
    id: 'assessment',
    router: {
        path: '/hr/assess',
        name: 'Assessment',
        component: () => import(/* webpackChunkName: "ass" */ '../../views/hr/Assessment.vue'),
        meta: {
            title: '评价列表'
        }
    },
}
const assProgram = {id: 'assProgram', entity: 'assProgram'};
// const assProgram =  {
//     id: 'assProgram',
//     router: {
//         path: 'program',
//         name: 'AssProgram',
//         component: () => import(/* webpackChunkName: "asp" */ '../../views/hr/Program.vue'),
//         meta: {
//             title: '方案管理'
//         }
//     },
// }
const assTemplate = {id: 'assTemplate', entity: 'assTemp'};
// const assTemplate =  {
//     id: 'assTemplate',
//     router: {
//         path: 'temp',
//         name: 'AssTemplate',
//         component: () => import(/* webpackChunkName: "ast" */ '../../views/hr/AssTemplate.vue'),
//         meta: {
//             title: '模板管理'
//         }
//     },
// }

const assApproveDetail =  {
    id: 'assApproveDetail',
    router: {
        path: '/hr/apd/:id',
        name: 'AssApproveDetail',
        component: () => import(/* webpackChunkName: "apd" */ '../../views/hr/AssApproveDetail.vue'),
        meta: {
            title: '评价详情'
        }
    },
}

const assDetail =  {
    id: 'assDetail',
    router: {
        path: '/hr/ad/:id',
        name: 'AssessmentDetail',
        component: () => import(/* webpackChunkName: "ad" */ '../../views/hr/AssessmentDetail.vue'),
        meta: {
            title: '评价详情'
        }
    },
}

const assProgramDetail =  {
    id: 'assProgramDetail',
    router: {
        path: '/hr/pd/:id',
        name: 'AssProgramDetail',
        component: () => import(/* webpackChunkName: "pd" */ '../../views/hr/AssProgramDetail.vue'),
        meta: {
            title: '评价详情'
        }
    },
}

const assPersonDetail =  {
    id: 'assPersonDetail',
    router: {
        path: '/hr/pi/:id',
        name: 'AssPersonDetail',
        component: () => import(/* webpackChunkName: "pd" */ '../../views/hr/AssPersonDetail.vue'),
        meta: {
            title: '评价详情'
        }
    },
}

const groupList = {
    id: 'groupList',
    router: {
        path: '/hr/volunteer/group/list',
        name: 'GroupList',
        component: () => import(/* webpackChunkName: "group-list" */ '../../views/hr/volunteer/GroupList.vue'),
        meta: {
            title: '小组列表'
        }
    }
}

const groupAdd = {
    id: 'groupAdd',
    router: {
        path: '/hr/volunteer/group/add',
        name: 'GroupAdd',
        component: () => import(/* webpackChunkName: "group-add" */ '../../views/hr/volunteer/GroupAdd.vue'),
        meta: {
            title: '添加小组'
        }
    }
}

const groupDetail = {
    id: 'groupDetail',
    router: {
        path: '/hr/volunteer/group/detail/:id',
        name: 'GroupDetail',
        component: () => import(/* webpackChunkName: "group-detail" */ '../../views/hr/volunteer/GroupDetail.vue'),
        meta: {
            title: '小组详情'
        }
    },
}

const memberList = {
    id: 'memberList',
    router: {
        path: '/hr/volunteer/member/list',
        name: 'MemberList',
        component: () => import(/* webpackChunkName: "member-list" */ '../../views/hr/volunteer/MemberList.vue'),
        meta: {
            title: '志工列表'
        }
    }
}

const memberAdd = {
    id: 'memberAdd',
    router: {
        path: '/hr/volunteer/member/add',
        name: 'MemberAdd',
        component: () => import(/* webpackChunkName: "member-add" */ '../../views/hr/volunteer/MemberAdd.vue'),
        meta: {
            title: '添加志工'
        }
    }
}

const memberDetail = {
    id: 'memberDetail',
    router: {
        path: '/hr/volunteer/member/detail/:id',
        name: 'MemberDetail',
        component: () => import(/* webpackChunkName: "member-detail" */ '../../views/hr/volunteer/MemberDetail.vue'),
        meta: {
            title: '志工详情'
        }
    },
}

const roleSet = {
    id: 'roleSet',
    router: {
        path: '/hr/volunteer/role',
        name: 'RoleSet',
        component: () => import(/* webpackChunkName: "rs" */ '../../views/hr/volunteer/RoleSet.vue'),
        meta: {
            title: '身份列表'
        }
    }
}

const attVerifiedBy = {
    id: 'attVerifiedBy',
    router: {
        path: '/hr/volunteer/verifiedBy',
        name: 'AttVerifiedBy',
        component: () => import(/* webpackChunkName: "avb" */ '../../views/hr/volunteer/AttVerifiedBy.vue'),
        meta: {
            title: '核销人列表'
        }
    }
}

const volunteerTemplate = {
    id: 'volunteerTemplate',
    router: {
        path: '/hr/volunteer/app/template',
        name: 'VolunteerTemplateList',
        component: () => import(/* webpackChunkName: "tl" */ '../../views/template/TemplateList.vue'),
        meta: {
            title: '志工应用'
        }
    }
};

const volunteerTemplateAdd = {
    id: 'volunteerTemplateAdd',
    router: {
        path: '/hr/volunteer/app/templateAdd',
        name: 'VolunteerTemplateAdd',
        component: () => import(/* webpackChunkName: "tl" */ '../../views/template/TemplateAdd.vue'),
        meta: {
            title: '添加应用'
        }
    }
};

const volunteerTemp = {
    id: 'volunteerTemp',
    router: {
        path: '/hr/volunteer/app/volunteerTemp/:id',
        name: 'VolunteerTemplate',
        redirect: {name: 'VolunteerTemplateData'},
        component: () => import(/* webpackChunkName: "tl" */ '../../views/template/Template.vue'),
        children: [
            {
                path: 'data',
                name: 'VolunteerTemplateData',
                component: () => import(/* webpackChunkName: "td" */ '../../views/template/TemplateData.vue')
            },
            {
                path: 'form',
                name: 'VolunteerTemplateForm',
                component: () => import(/* webpackChunkName: "tf" */ '../../views/template/TemplateForm.vue')
            },
            {
                path: 'setting',
                name: 'VolunteerTemplateSetting',
                component: () => import(/* webpackChunkName: "ts" */ '../../views/template/TemplateSetting.vue')
            },
            {
                path: 'plate',
                name: 'VolunteerTemplatePlate',
                component: () => import(/* webpackChunkName: "tp" */ '../../views/template/TemplatePlate.vue')
            },
            {
                path: 'team-leader-log',
                name: 'TeamLeaderLog',
                component: () => import(/* webpackChunkName: "log-l" */ '../../views/template/team/TeamLeaderLog.vue'),
                meta: {
                    title: '组长日志'
                },
            },
            {
                path: 'team-leader-log/detail/:lid',
                name: 'TeamLeaderLogDetail',
                component: () => import(/* webpackChunkName: "log-d" */ '../../views/template/team/TeamLeaderLogDetail.vue'),
                meta: {
                    title: '组长日志详情'
                },
            },
            {
                path: 'file-notice',
                name: 'FileNotice',
                component: () => import(/* webpackChunkName: "fn" */ '../../views/template/file/FileNotice.vue'),
                meta: {
                    title: '文件通知'
                },
            },
        ]
    }
}

const volunteerApply =  {
    id: 'volunteerApply',
    router: {
        path: '/hr/volunteer/app/apply',
        name: 'VolunteerApply',
        component: () => import(/* webpackChunkName: "va" */ '../../views/hr/volunteer/VolunteerApply.vue'),
        meta: {
            title: '配置志工申请'
        }
    },
}

const volunteerApplyList =  {
    id: 'volunteerApplyList',
    router: {
        path: '/hr/volunteer/app/apply-list',
        name: 'VolunteerApplyList',
        component: () => import(/* webpackChunkName: "val" */ '../../views/hr/volunteer/VolunteerApplyList.vue'),
        meta: {
            title: '志工申请列表'
        }
    },
}

const volunteerApplyAdd =  {
    id: 'volunteerApplyAdd',
    router: {
        path: '/hr/volunteer/app/apply/add',
        name: 'VolunteerApplyAdd',
        component: () => import(/* webpackChunkName: "vaa" */ '../../views/hr/volunteer/VolunteerApplyAdd.vue'),
        meta: {
            title: '添加业务'
        }
    },
}

const volunteerApplyDetail = {
    id: 'volunteerApplyDetail',
    router: {
        path: '/hr/volunteer/service/apply/:id',
        name: 'VolunteerApplyDetail',
        component: () => import(/* webpackChunkName: "vad" */ '../../views/hr/volunteer/VolunteerApplyDetail.vue'),
        meta: {
            title: '业务详情'
        }
    },
}

const volunteerProcess = {
    id: "volunteerProcess",
    router: {
        path: '/volunteer/process/:id',
        name: 'VolunteerProcess',
        component: () => import(/* webpackChunkName: "volunteer-process" */ '../../views/Process.vue'),
        meta: {
            title: '审批流程'
        }
    }
}

const serviceType = {
    id: 'serviceType',
    router: {
        path: '/hr/volunteer/service/type',
        name: 'ServiceType',
        component: () => import(/* webpackChunkName: "service-type" */ '../../views/hr/volunteer/ServiceType.vue'),
        meta: {
            title: '服务类型'
        }
    }
}

const serviceList = {
    id: 'serviceList',
    router: {
        path: "/hr/volunteer/service/list",
        name: 'ServiceList',
        component: () => import(/* webpackChunkName: "service-list" */ '../../views/hr/volunteer/ServiceList.vue'),
        meta: {
            title: '服务列表'
        },
    }
}

const serviceAdd = {
    id: 'serviceAdd',
    router: {
        path: '/hr/volunteer/service/add',
        name: 'ServiceAdd',
        component: () => import(/* webpackChunkName: "service-add" */ '../../views/hr/volunteer/ServiceAdd.vue'),
        meta: {
            title: '添加服务'
        }
    }
}

const serviceDetail = {
    id: 'serviceDetail',
    router: {
        path: '/hr/volunteer/service/detail/:id',
        name: 'ServiceDetail',
        component: () => import(/* webpackChunkName: "service-detail" */ '../../views/hr/volunteer/ServiceDetail.vue'),
        meta: {
            title: '服务详情'
        }
    },
}

const activityType = {
    id: 'activityType',
    router: {
        path: '/hr/volunteer/activity/type',
        name: 'ActivityType',
        component: () => import(/* webpackChunkName: "activity-type" */ '../../views/hr/volunteer/ActivityType.vue'),
        meta: {
            title: '活动类型'
        }
    }
}

const activityList = {
    id: 'train',
    router: {
        path: "/hr/volunteer/activity/list",
        name: 'ActivityList',
        component: () => import(/* webpackChunkName: "activity-list" */ '../../views/hr/volunteer/ActivityList.vue'),
        meta: {
            title: '活动列表'
        },
    }
}

const activityAdd = {
    id: 'activityAdd',
    router: {
        path: '/hr/volunteer/activity/add',
        name: 'ActivityAdd',
        component: () => import(/* webpackChunkName: "activity-add" */ '../../views/hr/volunteer/ActivityAdd.vue'),
        meta: {
            title: '添加活动'
        }
    }
}

const activityDetail = {
    id: 'activityDetail',
    router: {
        path: '/hr/volunteer/activity/detail/:id',
        name: 'ActivityDetail',
        component: () => import(/* webpackChunkName: "activity-detail" */ '../../views/hr/volunteer/ActivityDetail.vue'),
        meta: {
            title: '活动详情'
        }
    },
}

const trainType = {
    id: 'trainType',
    router: {
        path: '/hr/volunteer/train/type',
        name: 'TrainType',
        component: () => import(/* webpackChunkName: "train-type" */ '../../views/hr/volunteer/TrainType.vue'),
        meta: {
            title: '培训类型'
        }
    }
}

const trainQuestion = {id: 'trainQuestion', entity: 'trainQuestion'}

const trainList = {
    id: 'train',
    router: {
        path: "/hr/volunteer/train/list",
        name: 'TrainList',
        component: () => import(/* webpackChunkName: "train-list" */ '../../views/hr/volunteer/TrainList.vue'),
        meta: {
            title: '培训列表'
        },
    }
}

const trainAdd = {
    id: 'trainAdd',
    router: {
        path: '/hr/volunteer/train/add',
        name: 'TrainAdd',
        component: () => import(/* webpackChunkName: "train-add" */ '../../views/hr/volunteer/TrainAdd.vue'),
        meta: {
            title: '添加培训'
        }
    }
}

const trainDetail = {
    id: 'trainDetail',
    router: {
        path: '/hr/volunteer/train/detail/:id',
        name: 'TrainDetail',
        component: () => import(/* webpackChunkName: "train-detail" */ '../../views/hr/volunteer/TrainDetail.vue'),
        meta: {
            title: '培训详情'
        }
    },

}

const shareArticle = { id: 'shareArticle', entity: 'shareArticle' }
// const shareArticle = {
//     id: 'shareArticle',
//     router: {
//         path: '/hr/volunteer/share/article',
//         name: 'ShareArticle',
//         component: () => import(/* webpackChunkName: "share-article" */ '../../views/hr/volunteer/ShareArticle.vue'),
//         meta: {
//             title: '文章列表'
//         }
//     }
// }

const shareArticleAudit = {
    id: 'shareArticleAudit',
    router: {
        path: '/hr/volunteer/share/articleAudit',
        name: 'ShareArticleAudit',
        component: () => import(/* webpackChunkName: "share-article-audit" */ '../../views/hr/volunteer/ShareArticleAudit.vue'),
        meta: {
            title: '文章审核'
        }
    }
}

const culType = { id: 'culType', entity: 'culType' }

const culStatistics = {
    id: 'culStatistics',
    router: {
        path: '/hr/volunteer/cultivation/statistics',
        name: 'CulStatistics',
        component: () => import(/* webpackChunkName: "cul-s" */ '../../views/hr/volunteer/CulStatistics.vue'),
        meta: {
            title: '共修统计'
        }
    }
}

const attendanceList = {
    id: 'attendanceList',
    router: {
        path: '/hr/volunteer/attendance',
        name: 'AttendanceList',
        component: () => import(/* webpackChunkName: "avb" */ '../../views/hr/volunteer/AttendanceList.vue'),
        meta: {
            title: '考勤列表'
        }
    }
}

// const opeVoucher = {
//     id: 'opeVoucher',
//     router: {
//         path: '/hr/volunteer/operate/voucher',
//         name: 'OpeVoucher',
//         component: () => import(/* webpackChunkName: "voucher" */ '../../views/hr/volunteer/OpeVoucher.vue'),
//         meta: {
//             title: '配置用餐券'
//         }
//     }
// }

// const opeVoucherRecord = {
//     id: 'opeVoucherRecord',
//     router: {
//         path: '/hr/volunteer/operate/record',
//         name: 'OpeVoucherRecord',
//         component: () => import(/* webpackChunkName: "voucher-record" */ '../../views/hr/volunteer/OpeVoucherRecord.vue'),
//         meta: {
//             title: '用餐券购买记录'
//         }
//     }
// }

// const opeSouvenir = { id: 'opeSouvenir', entity: "opeSouvenir" };
const lessonType = { id: 'lessonType', entity: "lessonType" };
const lesson = { id: 'lesson', entity: "lesson" };

const attendance =  {
    id: 'attendance',
    router: {
        path: '/hr/att',
        name: 'Attendance',
        component: () => import(/* webpackChunkName: "att" */ '../../views/hr/Attendance.vue'),
        meta: {
            title: '考勤管理'
        }
    },
}
const opeSouvenir = {
    id: 'opeSouvenir',
    router: {
        path: '/hr/volunteer/operate/souvenir',
        name: 'Souvenir',
        component: () => import(/* webpackChunkName: "os" */ '../../views/hr/volunteer/OpeSouvenir.vue'),
        meta: {
            title: '结缘品管理'
        }
    },
};
const birthdayCare =  {
    id: 'birthdayCare',
    router: {
        path: '/hr/volunteer/operate/birthday',
        name: 'BirthdayCare',
        component: () => import(/* webpackChunkName: "bc" */ '../../views/hr/volunteer/BirthdayCare.vue'),
        meta: {
            title: '生日关怀'
        }
    },
}
const manualList =  {
    id: 'manualList',
    router: {
        path: '/hr/volunteer/operate/manual',
        name: 'Manual',
        component: () => import(/* webpackChunkName: "ml" */ '../../views/hr/volunteer/ManualList.vue'),
        meta: {
            title: '共修手册'
        }
    },
}

const hrProcess =  {
    id: 'hrProcess',
    router: {
        path: '/hr/process',
        name: 'HRProcess',
        component: () => import(/* webpackChunkName: "apr" */ '../../views/hr/HRProcess.vue'),
        meta: {
            title: '流程管理'
        }
    },
}

const hrAdmin =  {
    id: 'hrAdmin',
    router: {
        path: '/hr/admin',
        name: 'HRAdmin',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/hr/HRAdmin.vue'),
        meta: {
            title: '权限设置'
        }
    },
}

export default {
    hrHome,
    hrWorkbench,
    hrDesktop,
    hrMessage,
    hrOrganization,
    hrBook,
    hrBookForm,
    hrBookDetail,
    weChatUser,
    hrPrefer,
    hrPost,
    assessment,
    assProgram,
    assTemplate,
    assApproveDetail,
    assDetail,
    assProgramDetail,
    assPersonDetail,
    groupList,
    groupAdd,
    groupDetail,
    memberList,
    memberAdd,
    memberDetail,
    roleSet,
    attVerifiedBy,
    volunteerTemplate,
    volunteerTemplateAdd,
    volunteerTemp,
    volunteerApply,
    volunteerApplyList,
    volunteerApplyAdd,
    volunteerApplyDetail,
    volunteerProcess,
    serviceType,
    serviceList,
    serviceAdd,
    serviceDetail,
    activityType,
    activityList,
    activityAdd,
    activityDetail,
    trainQuestion,
    trainType,
    trainList,
    trainAdd,
    trainDetail,
    shareArticle,
    shareArticleAudit,
    culType,
    culStatistics,
    attendanceList,
    // opeVoucher,
    // opeVoucherRecord,
    opeSouvenir,
    attendance,
    birthdayCare,
    manualList,
    hrProcess,
    lesson,
    lessonType,
    hrAdmin,
}

const normalHRRoutes = [
    {id: 'hrHome', action: ['add', 'delete', 'update', 'query', 'audit']},
    {id: 'hrWorkbench', action: ['add', 'delete', 'update', 'query', 'audit']},
    {id: 'hrDesktop', action: ['add', 'delete', 'update', 'query', 'audit']},
    {id: 'hrMessage', action: ['add', 'delete', 'update', 'query', 'audit']},
    {id: 'hrBookForm', action: ['add', 'delete', 'update', 'query', 'audit']},
    {id: 'assessment', action: ['add', 'delete', 'update', 'query', 'audit']},
    {id: 'assApproveDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
    {id: 'assDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
];

export const routers = [
    {
        type: 1,
        list: [
            {id: 'hrHome', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrWorkbench', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrDesktop', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrMessage', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrOrganization', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrBook', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrBookForm', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrBookDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'weChatUser', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrPrefer', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrPost', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'assessment', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'assTemplate', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'assApproveDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'assDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'assProgramDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'assPersonDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'assProgram', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'groupList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'groupAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'groupDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'memberList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'memberAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'memberDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'roleSet', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'attVerifiedBy', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerTemplate', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerTemplateAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerTemp', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerApply', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerApplyList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerApplyAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerApplyDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerProcess', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'serviceType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'serviceList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'serviceAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'serviceDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'activityType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'activityList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'activityAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'activityDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainQuestion', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'shareArticle', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'shareArticleAudit', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'culType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'culStatistics', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'attendanceList', action: ['add', 'delete', 'update', 'query', 'audit']},
            // {id: 'opeVoucher', action: ['add', 'delete', 'update', 'query', 'audit']},
            // {id: 'opeVoucherRecord', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'opeSouvenir', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'attendance', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'birthdayCare', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'manualList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrProcess', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'lesson', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'lessonType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'hrAdmin', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    },
    {
        type: 2,
        list: [...normalHRRoutes]
    },
    {
        type: 0,
        list: [...normalHRRoutes]
    },
    {
        type: 14,
        list: [
            {id: 'groupList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'groupAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'groupDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'memberList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'memberAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'memberDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'roleSet', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'attVerifiedBy', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerTemplate', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerTemplateAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerTemp', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerApply', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerApplyList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerApplyAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerApplyDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'volunteerProcess', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'serviceType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'serviceList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'serviceAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'serviceDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'activityType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'activityList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'activityAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'activityDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainQuestion', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainList', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'trainDetail', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'shareArticle', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'shareArticleAudit', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'culType', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'culStatistics', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'attendanceList', action: ['add', 'delete', 'update', 'query', 'audit']},
            // {id: 'opeVoucher', action: ['add', 'delete', 'update', 'query', 'audit']},
            // {id: 'opeVoucherRecord', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'opeSouvenir', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    }
];

export const menus = [
    {
        type: 1,
        list: [
            {
                title: '工作台',
                path: '/hr/workbench',
            },
            // {
            //     title: '事项',
            //     path: '/hr/home',
            //     icon: 'desktop',
            //     children: [
            //         {
            //             title: '我的待办',
            //             path: '/hr/desktop',
            //         },
            //         {
            //             title: '申请',
            //             path: '/hr/apply',
            //         }
            //     ]
            // },
            {
                title: '组织架构',
                path: '/hr/org',
            },
            {
                title: '档案管理',
                path: '/hr/file',
                icon: 'book',
                children: [
                    {
                        title: '僧众档案',
                        path: '/hr/file/monk',
                    },
                    {
                        title: '职工档案',
                        path: '/hr/file/staff',
                    },
                    {
                        title: '其他人员',
                        path: '/hr/file/other',
                    },
                    {
                        title: '成员列表',
                        path: '/hr/user',
                    }
                ]
            },
            {
                title: '人员选拔',
                path: '/hr/prefer',
                icon: 'book',
                children: [
                    {
                        title: '僧众选拔',
                        path: '/hr/prefer/monk',
                    },
                    {
                        title: '职工选拔',
                        path: '/hr/prefer/staff',
                    },
                    {
                        title: '评价列表',
                        path: '/hr/assess',
                    },
                    {
                        title: '方案管理',
                        path: '/entity/assProgram',
                    },
                    {
                        title: '评价指标',
                        path: '/entity/assTemp',
                    }
                ]
            },
            {
                title: '人员变动管理',
                path: '/hr/staff',
                icon: 'team',
                children: [
                    {title: '进单', path: '/hr/post/monk'},
                    {title: '入职', path: '/hr/post/board'},
                    {
                        title: '离单',
                        path: '/hr/post/um',
                    },
                    {
                        title: '离职',
                        path: '/hr/post/ub',
                    },
                    {
                        title: '调岗',
                        path: '/hr/post/trans',
                    }
                ]
            },
            {
                title: '学修管理',
                path: '/hr/lesson',
                icon: 'solution',
                children: [
                    {
                        title: '课程管理',
                        path: '/entity/lesson',
                    },
                    {
                        title: '课程类型',
                        path: '/entity/lessonType',
                    }
                ]
            },
            {
                title: '志工管理',
                path: '/hr/volunteer',
                icon: 'solution',
                children: [
                    {
                        title: '小组管理',
                        path: '/hr/volunteer/group/list',
                    },
                    {
                        title: '志工列表',
                        path: '/hr/volunteer/member/list',
                    },
                    {
                        title: '身份管理',
                        path: '/hr/volunteer/role',
                    },
                    {
                        title: '核销人管理',
                        path: '/hr/volunteer/verifiedBy'
                    },
                    {
                        title: '志工应用',
                        path: '/hr/volunteer/app',
                        children: [
                            {
                                title: '应用管理',
                                path: '/hr/volunteer/app/template',
                            },
                            {
                                title: '配置志工申请',
                                path: '/hr/volunteer/app/apply',
                            },
                            {
                                title: '志工申请列表',
                                path: '/hr/volunteer/app/apply-list',
                            },
                        ]
                    },
                    {
                        title: '志工服务',
                        path: '/hr/volunteer/service',
                        children: [
                            {
                                title: '服务类型',
                                path: '/hr/volunteer/service/type'
                            },
                            {
                                title: '服务列表',
                                path: '/hr/volunteer/service/list'
                            },
                        ]
                    },
                    {
                        title: '志工活动',
                        path: '/hr/volunteer/activity',
                        children: [
                            {
                                title: '活动类型',
                                path: '/hr/volunteer/activity/type'
                            },
                            {
                                title: '活动列表',
                                path: '/hr/volunteer/activity/list'
                            },
                        ]
                    },
                    {
                        title: '志工培训',
                        path: '/hr/volunteer/train',
                        children: [
                            {
                                title: '培训类型',
                                path: '/hr/volunteer/train/type'
                            },
                            {
                                title: '题库管理',
                                path: '/entity/trainQuestion'
                            },
                            {
                                title: '培训列表',
                                path: '/hr/volunteer/train/list'
                            },
                        ]
                    },
                    {
                        title: '志工分享',
                        path: '/hr/volunteer/share',
                        children: [
                            {
                                title: '文章列表',
                                // path: '/hr/volunteer/share/article'
                                path: '/entity/shareArticle'
                            },
                            {
                                title: '文章审核',
                                path: '/hr/volunteer/share/articleAudit'
                            },
                        ]
                    },
                    {
                        title: '志工共修',
                        path: '/hr/volunteer/cultivation',
                        children: [
                            {
                                title: '共修类型',
                                path: '/entity/culType'
                            },
                            {
                                title: '共修统计',
                                path: '/hr/volunteer/cultivation/statistics'
                            }
                        ]
                    },
                    {
                        title: '考勤管理',
                        path: '/hr/volunteer/attendance',
                    },
                    {
                        title: '志工关怀',
                        path: '/hr/volunteer/operate',
                        children: [
                            // {
                            //     title: '配置用餐券',
                            //     path: '/hr/volunteer/operate/voucher'
                            // },
                            // {
                            //     title: '用餐券购买记录',
                            //     path: '/hr/volunteer/operate/record'
                            // },
                            {
                                title: '结缘品管理',
                                path: '/hr/volunteer/operate/souvenir'
                            },
                            {
                                title: '生日关怀',
                                path: '/hr/volunteer/operate/birthday'
                            },
                            {
                                title: '共修手册',
                                path: '/hr/volunteer/operate/manual'
                            },
                        ]
                    },
                ]
            },
            {
                title: '考勤管理',
                path: '/hr/att',
                icon: 'apartment'
            },
            {
                title: '流程管理',
                path: '/hr/process',
                icon: 'apartment'
            },
            {
                title: '权限设置',
                path: '/hr/admin',
            },
        ]
    },
    {
        type: 2,
        list: [
            {
                title: '工作台',
                path: '/hr/workbench',
            },
            // {
            //     title: '评价列表',
            //     path: '/hr/assess',
            // },
        ]
    },
    // 普通用户
    {
        type: 0,
        list: [
            {
                title: '工作台',
                path: '/hr/workbench',
            },
        ]
    },
    // 志工管理员
    {
        type: 14,
        list: [
            {
                title: '志工管理',
                path: '/hr/volunteer',
                icon: 'solution',
                children: [
                    {
                        title: '小组管理',
                        path: '/hr/volunteer/group/list',
                    },
                    {
                        title: '志工列表',
                        path: '/hr/volunteer/member/list',
                    },
                    {
                        title: '身份管理',
                        path: '/hr/volunteer/role',
                    },
                    {
                        title: '核销人管理',
                        path: '/hr/volunteer/verifiedBy'
                    },
                    {
                        title: '志工应用',
                        path: '/hr/volunteer/app',
                        children: [
                            {
                                title: '应用管理',
                                path: '/hr/volunteer/app/template',
                            },
                            {
                                title: '配置志工申请',
                                path: '/hr/volunteer/app/apply',
                            },
                            {
                                title: '志工申请列表',
                                path: '/hr/volunteer/app/apply-list',
                            },
                        ]
                    },
                    {
                        title: '志工服务',
                        path: '/hr/volunteer/service',
                        children: [
                            {
                                title: '服务类型',
                                path: '/hr/volunteer/service/type'
                            },
                            {
                                title: '服务列表',
                                path: '/hr/volunteer/service/list'
                            },
                        ]
                    },
                    {
                        title: '志工活动',
                        path: '/hr/volunteer/activity',
                        children: [
                            {
                                title: '活动类型',
                                path: '/hr/volunteer/activity/type'
                            },
                            {
                                title: '活动列表',
                                path: '/hr/volunteer/activity/list'
                            },
                        ]
                    },
                    {
                        title: '志工培训',
                        path: '/hr/volunteer/train',
                        children: [
                            {
                                title: '培训类型',
                                path: '/hr/volunteer/train/type'
                            },
                            {
                                title: '题库管理',
                                path: '/entity/trainQuestion'
                            },
                            {
                                title: '培训列表',
                                path: '/hr/volunteer/train/list'
                            },
                        ]
                    },
                    {
                        title: '志工分享',
                        path: '/hr/volunteer/share',
                        children: [
                            {
                                title: '文章列表',
                                // path: '/hr/volunteer/share/article'
                                path: '/entity/shareArticle'
                            },
                            {
                                title: '文章审核',
                                path: '/hr/volunteer/share/articleAudit'
                            },
                        ]
                    },
                    {
                        title: '志工共修',
                        path: '/hr/volunteer/cultivation',
                        children: [
                            {
                                title: '共修类型',
                                path: '/entity/culType'
                            },
                            {
                                title: '共修统计',
                                path: '/hr/volunteer/cultivation/statistics'
                            }
                        ]
                    },
                    {
                        title: '考勤管理',
                        path: '/hr/volunteer/attendance',
                    },
                    {
                        title: '志工关怀',
                        path: '/hr/volunteer/operate',
                        children: [
                            // {
                            //     title: '配置用餐券',
                            //     path: '/hr/volunteer/operate/voucher'
                            // },
                            // {
                            //     title: '用餐券购买记录',
                            //     path: '/hr/volunteer/operate/record'
                            // },
                            {
                                title: '结缘品管理',
                                path: '/entity/opeSouvenir'
                            },
                            {
                                title: '生日关怀',
                                path: '/hr/volunteer/operate/birthday'
                            },
                            {
                                title: '共修手册',
                                path: '/hr/volunteer/operate/manual'
                            },
                        ]
                    },
                ]
            },
        ]
    },
]
