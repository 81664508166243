<template>
    <div class="s-img" :class="[size ? `s-img-${size}` : null]">
        <img :src="src" @click="view">
    </div>
</template>

<script>
    export default {
        name: "s-img",
        props: {
            src: String,
            size: String
        },
        methods: {
            view() {
                if(this.src) {
                    window.open(this.src, "_blank");
                }
            }
        }
    }
</script>

<style lang="less">
.s-img {
    width: 200px;
    height: 100px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &.s-img-small {
        width: 80px;
        height: 40px;
    }
}
</style>
