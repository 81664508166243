<template>
  <div class="ass-items">
    <div class="ass-items-piece">
      <a-button type="link" @click="addItem(1)" v-if="editable">添加主观项</a-button>
      <div class="ass-items-table">
        <a-table
          size="small"
          :columns="subjectiveColumns"
          :data-source="subjectiveItems"
          :pagination="false"
          :row-key="record => record.key || record.id"
          bordered>
          <template v-slot:action="text, record, index">
            <div class="row-btn">
              <a class="txt-btn" @click.stop="editItem(index, 1)" >编辑</a>
              <a class="txt-btn" @click.stop="deleteItem(index, 1)" >删除</a>
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <div class="ass-items-piece" v-if="false">
      <a-button type="link" @click="addItem(2)" v-if="editable">添加客观项</a-button>
      <div class="ass-items-table">
        <a-table
          size="small"
          :columns="objectiveColumns"
          :data-source="objectiveItems"
          :pagination="false"
          :row-key="record => record.key || record.id"
          bordered>
          <template v-slot:action="text, record, index">
            <div class="row-btn">
              <a class="txt-btn" @click.stop="editItem(index, 2)" >编辑</a>
              <a class="txt-btn" @click.stop="deleteItem(index, 2)" >删除</a>
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <ass-item-select
      ref="itemSelect"
      :type="type"
      :value="type == 1 ? subjectiveItems : objectiveItems"
      :visible="visible"
      @confirm="handleConfirm"
      @cancel="handleCancel"></ass-item-select>
  </div>
</template>

<script>
    import {getAssNormColumns} from "../../common/hr/ass";
    import assItemSelect from "./ass-item-select";

    export default {
        name: "ass-items",
        components: {assItemSelect},
        model: {
            event: "change"
        },
        props: {
            value: Object,
            editable: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                type: 1,
                visible: false
            }
        },
        computed: {
            subjectiveItems: {
                get() {
                    let res = [];
                    if(this.value && this.value.subjective) {
                        res = this.value.subjective;
                    }
                    return res;
                },
                set(val) {
                    this.dispatchChange(val);
                }
            },
            objectiveItems: {
                get() {
                    let res = [];
                    if(this.value && this.value.objective) {
                        res = this.value.objective;
                    }
                    return res;
                },
                set(val) {
                    this.dispatchChange(null, val);
                }
            },
            subjectiveColumns() {
                return getAssNormColumns.call(this, 1, this.editable);
            },
            objectiveColumns() {
                return getAssNormColumns.call(this, 2, this.editable);
            }
        },
        methods: {
            addItem(type) {
                this.type = type;
                this.visible = true;
            },
            editItem(index, type) {
                this.addItem(type);
                const list = type == 1 ? this.subjectiveItems : this.objectiveItems;
                this.$refs.itemSelect.editItem(list[index]);
            },
            deleteItem(index, type) {
                const key = type == 1 ? "subjectiveItems" : "objectiveItems";
                const list = [...this[key]];
                list.splice(index, 1);
                this[key] = list;
            },
            handleConfirm(list) {
                list.forEach(item => item.percent = item.percent * 1);
                const type = this.type;
                if(type == 1) {
                    this.subjectiveItems = list;
                } else {
                    this.objectiveItems = list;
                }
                this.visible = false;
            },
            handleCancel() {
                this.visible = false;
            },
            dispatchChange(subjective, objective) {
                subjective = subjective || this.subjectiveItems;
                objective = objective || this.objectiveItems;
                this.$emit("change",subjective.length > 0 || objective.length > 0 ? { subjective, objective } : null);
            }
        }
    }
</script>

<style lang="less">
.ass-items-piece {
  margin-bottom: 10px;
}
</style>
