<template>
    <div class="flex-box modal-select">
        <address-book
            class="modal-org"
            ref="orgTree"
            v-bind="$attrs"
            @set="handleChange($event, 'set')"
            @select="handleChange($event, 'select')"></address-book>
        <div class="select-dept">
            <div class="select-dept-ti">已选择</div>
            <div class="select-dept-box">
                <div class="select-dept-list" v-if="list && list.length > 0">
                    <div class="flex-box align-center select-dept-item" v-for="item in list" :key="`${item.tag_id ? 'tag' : 'book'}_${item.tag_id || item.userid || item.id}`">
                        <div class="icon-tag select-dept-icon" v-if="item.tag_id"></div>
                        <a-icon class="select-dept-icon" :type="item.userid ? 'user' : 'folder'" v-else/>
                        <span class="select-dept-name" v-if="item.tag_id">{{item.name}}</span>
                        <open-data class="select-dept-name" type="userName" :openid="item.userid" v-else-if="item.userid"/>
                        <open-data class="select-dept-name" type="departmentName" :openid="item.id" v-else/>
                        <a-icon type="close" style="cursor: pointer" @click="clearSelect(item)"/>
                    </div>
                </div>
                <div v-else>无</div>
            </div>
        </div>
    </div>
</template>

<script>
    import addressBook from "../components/address-book";

    export default {
        name: "dept-picker",
        components: {
            addressBook
        },
        data() {
            return {
                list: []
            }
        },
        methods: {
            handleChange(list, event) {
                this.list = list;
                this.$emit(event, list);
            },
            setSelect() {
                this.$refs.orgTree.setSelect();
            },
            clearSelect(item) {
                this.$refs.orgTree.removeItem(item)
            },
            clearAll() {
                this.$refs.orgTree.removeAll();
            }
        }
    }
</script>

<style scoped lang="less">
    .modal-org {
        width: 50%;
        height: 400px;
        overflow: auto;
    }
    .select-dept {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 400px;
        border-left: var(--border);
    }
    .select-dept-ti {
        padding: 10px;
        border-bottom: var(--border);
        line-height: 32px;
        color: @text-color;
    }
    .select-dept-box {
        flex: 1;
        padding: 0 16px;
        min-height: 0;
        overflow: auto;
    }
    .select-dept-item {
        height: 32px;
    }
    .select-dept-icon {
        margin-right: 6px;
        font-size: 16px;
        color: @blue-6;
        &.icon-tag {
            width: 20px;
            height: 20px;
            font-size: 20px;
            line-height: 1;
        }
    }
    .select-dept-ti {
        margin-bottom: 10px;
        color: @text-color-secondary;
    }
    .select-dept-name {
        flex: 1;
    }
</style>
