<template>
  <div class="buddha-grant">
    <div class="buddha-grant-item" v-for="g in grantList" :key="g.name">
      <div class="buddha-grant-label">{{g.name}}</div>
      <div class="buddha-grant-ctx">
        <a-input-number :min="0.01" v-model="g.grant" placeholder="请输入补助金额" class="buddha-grant-input" @change="dispatchInput" @blur="handleBlur" v-if="edit"/>
        <span v-else>{{g.grant || 0}}</span>元
      </div>
    </div>
  </div>
</template>

<script>
    import {clone} from "../../common/js/tool";

    export default {
        name: "buddha-grant",
        props: {
            list: Array,
            value: Array,
            //是否可编辑
            edit: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                grantList: []
            }
        },
        watch: {
            list() {
                this.setGrantList();
            },
            value() {
                this.setGrantList();
            }
        },
        created() {
            this.setGrantList();
        },
        methods: {
            setGrantList() {
                let res = [];
                if(this.list) {
                    let value = this.value || [];
                    this.list.forEach(item => {
                        const val = value.find(v => v.name == item);
                        res.push({
                            name: item,
                            grant: val ? val.grant : null
                        });
                    })
                }
                this.grantList = res;
            },
            dispatchInput() {
                const grantList = this.grantList;
                this.$emit("input", clone(grantList));
            },
            handleBlur() {
                this.$emit("blur")
            }
        }
    }
</script>

<style lang="less">
  .buddha-grant-item {
    display: flex;
    align-items: center;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
  .buddha-grant-label {
    width: 80px;
    color: @text-color-secondary;
  }
  .buddha-grant-ctx {
    .buddha-grant-input {
      margin-right: 8px;
      width: 140px;
    }
  }
</style>
