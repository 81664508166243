<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
    import WWOpenData from "WWOpenData";
    export default {
        name: "open-data-box",
        provide() {
            return {
                container: this
            }
        },
        inject: {
            container: {
                from: "container",
                default: null
            }
        },
        data() {
            return {
                number: 0,
                nodes: []
            }
        },
        methods: {
            bindAll() {
                if(this.nodes.length > 0) {
                    WWOpenData.bindAll(this.nodes);
                }
            },
            addNode(node) {
                if(this.container) {
                    this.container.addNode(node);
                } else {
                    this.nodes.push(node);
                    if(this.nodes.length == this.number) {
                        this.bindAll();
                    }
                }
            },
            addNumber() {
                if(this.container) {
                    this.container.addNumber();
                } else {
                    this.number += 1;
                }
            }
        }
    }
</script>

<style scoped>

</style>
