import Vue from 'vue'

/**
 * Action 权限指令
 * 指令用法：
 *  - 在需要控制 action 级别权限的组件上使用 v-action:[method] , 如下：
 *    <i-button v-action:add >添加用户</a-button>
 *    <a-button v-action:delete>删除用户</a-button>
 *    <a v-action:edit @click="edit(record)">修改</a>
 *
 *  - 当前用户没有权限时，组件上使用了该指令则会被隐藏
 *
 */
const action = Vue.directive('action', {
    inserted: function (el, binding, vnode) {
        const actionName = binding.arg;
        // 获取当前路由下的操作权限
        let vm = vnode.context;
        const action = vm.action;
        if(action) {
            // 没有权限则删除/隐藏
            if(action.indexOf(actionName) === -1) {
                el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
            }
        }
    }
})

export default action
