<template>
  <div class="file-upload">
    <a-upload
      @change="handleChange"
      :file-list="fileList"
      :custom-request="customRequest">
      <a-button> <a-icon type="upload" /> 选择文件上传 </a-button>
    </a-upload>
  </div>
</template>

<script>
    import {directUploadTencentCloud} from "../common/js/tencent_cloud";
    import {getUrlFileName} from "../common/js/tool";

    export default {
        name: "fileUpload",
        model: {
            event: "change"
        },
        props: {
            value: Array
        },
        data() {
            return {
                fileList: [],
                files: []
            }
        },
        watch: {
            value () {
                this.setFileList();
            }
        },
        created() {
            this.setFileList();
        },
        methods: {
            setFileList() {
                const value = this.value;
                if(value && value.length > 0) {
                   this.fileList = value.map((item, index) => {
                       if(typeof item === 'object') {
                           return {...item, uid: `upload_${index}`, status: 'done'}
                       } else {
                           return {url: item, name: getUrlFileName(item), uid: `upload_${index}`, status: 'done'}
                       }
                   });
                   this.files = [...value];
                } else {
                    this.fileList = [];
                    this.files = [];
                }
            },
            handleChange(info) {
                const files = this.files;
                const file = info.file;
                const status = file.status;
                switch (status) {
                    case "done":
                        files.push({
                            name: file.name,
                            url: file.response || file.url,
                            size: file.size
                        });
                        this.$emit("change", files);
                        break;
                    case "removed":{
                        const i = files.findIndex(f => f.url == file.url);
                        if(i >= 0) {
                            files.splice(i, 1);
                        }
                        this.$emit("change", files);
                        break;
                    }
                    default:
                        this.fileList = info.fileList;

                }
            },
            customRequest({file, onSuccess, onProgress, onError}) {
                directUploadTencentCloud(file, p => {
                    onProgress(Math.round(p.percent * 100))
                }).then(data => {
                    let path = "https://" + data.Location;
                    onSuccess(path);
                }).catch(err => {
                    onError(err);
                });
            },
        }
    }
</script>

<style scoped>

</style>
