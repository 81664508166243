import axios from "@/common/js/req";

const state = {
    bookList: null,
    deptList: null,
    tagList: null,
    assetTagList: null
};

// getters
const getters = {
    bookList: state => state.bookList,
    deptList: state => state.deptList,
    tagList: state => state.tagList,
    assetTagList: state => state.assetTagList,
};

// mutations
const mutations = {
    setBookList(state, value) {
        state.bookList = value;
    },
    setDeptList(state, value) {
        state.deptList = value;
    },
    setTagList(state, value) {
        state.tagList = value;
    },
    setAssetTagList(state, value) {
        state.assetTagList = value;
    },
};

const actions = {
    getBookList(context, dept) {
        let url;
        // 部门列表
        if (dept) {
            const deptList = context.getters.deptList;
            if (deptList) {
                return Promise.resolve(deptList);
            }
            url = "/admin/common/get-dept-list";
        }
        // 部门成员列表
        else {
            const bookList = context.getters.bookList;
            if (bookList) {
                return Promise.resolve(bookList);
            }
            url = "/admin/common/get-dept";
        }
        return axios(url).then(res => {
            if(res.error === 0) {
                if(dept) {
                    context.commit("setDeptList", res.data);
                } else {
                    context.commit("setBookList", res.data);
                }
                return Promise.resolve(res.data)
            } else {
                return Promise.reject(res.msg);
            }
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}
