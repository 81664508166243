<template>
  <a-select :value="select" :options="options" @change="handleChange"></a-select>
</template>

<script>

    import {getUserWxId} from "../../common/js/storage";

    export default {
        name: "cl-tk",
        model: {
            event: "change"
        },
        props: {
            value: Object
        },
        data() {
            return {
                options: [],
                list: []
            }
        },
        computed: {
            select: {
                get() {
                    return this.value?.id;
                },
                set(val) {
                    this.$emit("change", this.list.find(item => item.id == val))
                }
            }
        },
        created() {
            this.getTkList();
        },
        methods: {
            getTkList() {
                const url = `/admin/ketang?&pageSize=1000&filter[visible_ids][fis]=${getUserWxId()}`
                this.$axios(url).then(res => {
                    this.list = res.data;
                    this.options = res.data.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        }
                    })
                    if (res.data.length > 0) {
                        this.select = res.data[0].id;
                    }
                })
            },
            handleChange(val) {
                this.select = val;
            }
        }
    }
</script>

<style lang="less">

</style>
