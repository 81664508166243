<template>
  <div class="ass-setting" :class="{'ass-setting-edit': edit}">
    <div class="ass-user-set" v-if="type == 1">
      <div class="flex-box align-center ass-set-item">
        <div class="ass-set-ti">主管评价范围</div>
        <a-radio-group v-model="directorScope" v-if="edit">
          <a-radio :value="1">主部门直接上级</a-radio>
        </a-radio-group>
        <div v-else>主部门直接上级</div>
      </div>
    </div>
    <div class="ass-user-set" v-else>
      <div class="ass-set-item ass-set-box">
        <div class="ass-set-ti">部门自评</div>
        <div class="ass-set-item">
          <a-radio-group v-model="selfScope" v-if="edit">
            <a-radio class="radio-ver" :value="1">部门主管</a-radio>
          </a-radio-group>
          <div class="ass-set-ti" v-else>部门主管</div>
          <div class="ass-set-box">
            <div class="ass-set-ti">当部门有多个主管时</div>
            <div class="ass-set-item">
              <a-radio-group v-model="multipleSelf" v-if="edit" @change="dispatchChange">
<!--                <a-radio class="radio-ver" :value="1">所有主管均需参与评价，结果按平均值</a-radio>-->
                <a-radio class="radio-ver" :value="2">指定一个主管</a-radio>
              </a-radio-group>
              <div class="ass-set-ti" v-else>{{multipleSelf == 1 ? '所有主管均需参与评价，结果按平均值' : '指定一个主管'}}</div>
              <div class="ass-dept-list" v-if="multipleSelf == 2 && deptList && deptList.length > 0">
                <div class="ass-dept-item" v-for="d in deptList" :key="d.id">
                  <open-data class="ass-dept-name" type="departmentName" :openid="d.id"/>
                  <select-book type="user" :edit="edit" v-model="d.user_id"></select-book>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ass-set-item ass-set-box">
        <div class="ass-set-ti">主管评价</div>
        <div class="ass-set-item">
          <a-radio-group v-model="directorScope" v-if="edit">
            <a-radio class="radio-ver" :value="1">部门上一级主管</a-radio>
          </a-radio-group>
          <div class="ass-set-ti" v-else>部门上一级主管</div>
          <div class="ass-set-box">
            <div class="ass-set-ti">当上级有多个主管时</div>
            <div class="ass-set-item">
              <a-radio-group v-model="multipleDirector" @change="dispatchChange" v-if="edit">
<!--                <a-radio class="radio-ver" :value="1">所有主管均需参与评价，结果按平均值</a-radio>-->
                <a-radio class="radio-ver" :value="2">指定一个主管</a-radio>
              </a-radio-group>
              <div class="ass-set-ti" v-else>{{multipleDirector == 1 ? '所有主管均需参与评价，结果按平均值' : '指定一个主管'}}</div>
              <div class="ass-dept-list" v-if="multipleDirector == 2 && deptList && deptList.length > 0">
                <div class="ass-dept-item" v-for="d in deptList" :key="d.id">
                  <open-data class="ass-dept-name" type="departmentName" :openid="d.id"/>
                  <select-book type="user" :edit="edit" v-model="d.director_id"></select-book>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ass-setting",
  model: {
    event: "change"
  },
  props: {
    value: [Object, String],
    edit: {
      type: Boolean,
      default: true
    },
    type: {
      type: Number,
      default: 1
    },
    dept: Array
  },
  data() {
    return {
      directorScope: 1,
      selfScope: 1,
      multipleSelf: 2,
      multipleDirector: 2,
      deptList: []
    }
  },
  watch: {
    value() {
      this.setValue();
    },
    dept() {
      this.setDeptList();
    },
    type() {
      this.dispatchChange();
    }
  },
  created() {
    this.setValue();
    this.dispatchChange();
  },
  methods: {
    setValue() {
      let value = this.value;
      if(value) {
        if(typeof value === 'string') {
          value = JSON.parse(value);
        }
        this.directorScope = value.directorScope || 1;
        this.selfScope = value.selfScope || 1;
        this.multipleSelf = value.multipleSelf || 2;
        this.multipleDirector = value.multipleDirector || 2;
        if(value.deptList) {
          this.deptList = value.deptList;
        } else {
          this.setDeptList();
        }
      }
    },
    setDeptList() {
      let dept = this.dept || [];
      let deptList = this.deptList;
      this.deptList = dept.map(d => {
        let res = deptList.find(item => item.id == d);
        res = res || {
          id: d,
          user_id: null,
          director_id: null
        };
        return res
      });
    },
    dispatchChange() {
      let config;
      let type = this.type;
      if(type == 1) {
        config = {directorScope: this.directorScope}
      } else {
        config = {
          selfScope: this.selfScope,
          directorScope: this.directorScope,
          multipleSelf: this.multipleSelf,
          multipleDirector: this.multipleDirector
        }
        if(this.multipleSelf == 2 || this.multipleDirector == 2) {
          config.deptList = this.deptList;
        }
      }
      this.$emit("change", config);
    }
  }
}
</script>

<style scoped lang="less">
  .ass-user-set {
    padding: 5px 0;
  }
  .ass-setting-edit {
    .ass-set-ti {
      line-height: 30px;
    }
  }
  .ass-set-item {
    margin-bottom: 10px;
    line-height: 1;
  }
  .ass-set-box {
    display: flex;
    align-items: flex-start;
  }
  .ass-set-ti {
    margin-right: 1em;
    line-height: 1.4;
  }
  .radio-ver {
    display: block;
    line-height: 30px;
  }
  .ass-dept-list {
    margin-top: 6px;
  }
  .ass-dept-item {
    display: flex;
    align-items: center;
    line-height: 32px;
  }
  .ass-dept-name {
    margin-right: 1em;
  }
</style>
