import axios from "../../common/js/req";
import {dealTimeRangeList} from "../../common/constant/timeRange";
import {getDate} from "../../common/js/tool";
import {getLunarDay} from "../../components/calendar/lunar";

const state = {
    timeRange: null,
    buddhaBirthList: null,
    palaceList: null,
    openUserList: null, // 开牌管理员列表
};

// getters
const getters = {
    timeRange: state => state.timeRange,
    buddhaBirthList: state => state.buddhaBirthList,
    palaceList: state => state.palaceList,
    openUserList: state => state.openUserList,
};

// mutations
const mutations = {
    setTimeRange(state, value) {
        state.timeRange = value;
    },
    setBuddhaBirthList(state, value) {
        state.buddhaBirthList = value;
    },
    setPalaceList(state, value) {
        state.palaceList = value;
    },
    setOpenUserList(state, value) {
        state.openUserList = value;
    },
};

const actions = {
    getOpenUserList(context) {
        const list = context.getters.openUserList;
        if(list) {
            return Promise.resolve(list);
        } else {
            return axios("/admin/user-role?pageSize=100&filter[system]=5&filter[privilege]=3").then(res => {
                const list = [];
                res.data.forEach(item => {
                    if(item.user_id) {
                        try {
                            const ids = JSON.parse(item.user_id);
                            ids.forEach(id => {
                                if(!list.includes(id)) {
                                    list.push(id);
                                }
                            })
                        } catch (e) {
                            console.error(e)
                        }
                    }
                })
                context.commit("setOpenUserList", list);
                return list;
            });
        }
    },
    getBuddhaTimeRange(context) {
        const timeRange = context.getters.timeRange;
        if(timeRange) {
            return Promise.resolve(timeRange);
        } else {
            return axios("/admin/fs-datelist?pageSize=1000").then(res => {
                let list = res.data;
                dealTimeRangeList(list);
                list = list.map(r => {
                    return {
                        id: r.id,
                        range: r.range,
                        name: r.name
                    }
                })
                context.commit("setTimeRange", list);
                return list;
            });
        }
    },
    getBuddhaBirthList(context, update) {
        if(!context.getters.buddhaBirthList || update) {
            axios("/admin/buddha-birthday?pageSize=1000").then(res => {
                let list = res.data;
                list.forEach(item => {
                    item.lunarDay = getLunarDay(getDate(item.date), true);
                })
                context.commit("setBuddhaBirthList", list);
            })
        }
    },
    getPalaceList(context, update) {
        if(!context.getters.palaceList || update) {
            axios("/admin/palace?pageSize=1000&sort=order").then(res => {
                let list = res.data;
                context.commit("setPalaceList", list);
            })
        }
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}
