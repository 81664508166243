<template>
  <div>
    <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
      全选
    </a-checkbox>
    <a-checkbox-group v-model="checkedList" :options="options" @change="onChange" />
  </div>
</template>

<script>
export default {
  name: "CheckboxGroup",
  model: {
    event: "change",
  },
  props: {
    value: String,
    options: {
      type: Array,
      default: () => ["Apple", "Pear", "Orange"],
    },
  },
  data() {
    return {
      checkedList: [],
      indeterminate: false,
      checkAll: false,
    };
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      if (this.value) {
        this.checkedList = this.value?.split(",");
        this.checkAll = this.checkedList.length === this.options.length;
        this.indeterminate = this.checkedList.length > 0 && this.checkedList.length < this.options.length;
      } else {
        this.checkedList = [];
        this.indeterminate = false;
      }
    },
    onChange(checkedList) {
      let options = this.options;
      this.indeterminate = !!checkedList.length && checkedList.length < options.length;
      this.checkAll = checkedList.length === options.length;
      this.dispatchChange();
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.options.map(item => item.value) : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      this.dispatchChange();
    },
    dispatchChange() {
      this.$emit("change", this.checkedList.join(","));
    },
  },
};
</script>
