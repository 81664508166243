<template>
    <div :style="tableStyle" v-if="buddhism">
        <div style="font-size: 28px;font-weight:600;letter-spacing:2px;" v-if="table">{{date}}</div>
        <table style="margin:0;padding:0;width: 100%;text-align:center;border-collapse: collapse;" :border="table ? 1 : 0" cellpadding="0" cellspacing="0">
            <tr
                v-for="(tr, i) in tableList"
                :key="i">
                <td
                    :rowspan="td.row"
                    :colspan="td.col"
                    :width="td.w ? `${td.w}%` : null"
                    :style="{
                        ...tdStyle,
                        ...getStyle(td)
                    }"
                    v-for="(td, j) in tr"
                    :key="j">
                    <div :style="styleSheet.multi" v-if="isArray(td.text)">
                        <div v-for="(p, i) in getCellText(td, null, palaceName)" :key="i" :style="{marginLeft: i > 0 ? '20px' : 0}">
                            <div v-for="(t, n) in p.split(',')" :key="n" :style="!t ? styleSheet.half : styleSheet.normal">{{t}}</div>
                        </div>
                    </div>
                    <div style="position:relative;display: inline-block" v-else>
                        <div
                          class="master-drop-box"
                          @dragenter="handleDragEnter"
                          @dragleave="handleDragLeave"
                          @drop.stop="handleDrop($event, td)"
                          @dragover.stop="handleDragOver"
                          v-if="editable && td.seat && master[td.seat] && (td.seat != '师' || td.order < shiNum)">
                            <template v-if="master[td.seat][td.order || 0]">
                                <div v-for="(t, n) in getCellText(td, master[td.seat][td.order || 0]).split(',')" :key="n" :style="!t ? styleSheet.half : styleSheet.normal">
                                    <img :style="styleSheet.tan" :src="require(`../assets/buddha/tan-${tableStyle.color == '#fff' ? 'white' : 'black'}.png`)" v-if="t === '壇' && !table">
                                    <span v-else>{{t}}</span>
                                </div>
                                <a-icon type="close-circle" theme="filled" class="master-select-remove" @click="removeSelect(td.seat, td.order)" />
                            </template>
                        </div>
                        <template v-else-if="td.text || master[td.seat]">
                            <div v-for="(t, n) in getCellText(td, master[td.seat] ? master[td.seat][td.order || 0] : null).split(',')" :key="n" :style="!t ? styleSheet.half : styleSheet.normal">
                                <img :style="styleSheet.tan" :src="require(`../assets/buddha/tan-${tableStyle.color == '#fff' ? 'white' : 'black'}.png`)" v-if="t === '壇' && !table">
                                <span v-else>{{t}}</span>
                            </div>
                        </template>
                        <span class="tablet-seat-info" :style="styleSheet.info" v-if="table && td.info">{{td.info}}</span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import {getBuddhismMaterTypeList} from "../common/buddha/buddhism";
    import {clone, getDate, isArray} from "../common/js/tool";
    import {getLunarDay} from "../components/calendar/date";
    // 礼忏诵经
    const normalTable = [
        [
            {row: 6, col: 2, w: 20, text: '壇,设', styleName: 'big', seat: 'palace'},
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10},
            {row: 1, col: 2, w: 20},
            {row: 13, w: 30, text: [',,,,,恭,,,请',''], styleName: 'big', seat: 'book'},
        ],
        [
            {row: 1, col: 2, w: 20, text: '', info: '大鱼', seat: '大鱼'},
            {row: 1, w: 10},
            {row: 1, col: 2, w: 20, text: '', info: '磬', seat: '磬'},
        ],
        [
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, col: 2, w: 20, text: '', info: '铪', seat: '铪'},
            {row: 1, w: 10},
            {row: 1, col: 2, w: 20, text: '', info: '铛', seat: '铛'},
        ],
        [
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, col: 2, w: 20, text: '', info: '小鱼', seat: '小鱼'},
            {row: 1, w: 10},
            {row: 1, col: 2, w: 20, text: '', info: '小鼓', seat: '小鼓'},
        ],
        [
            {row: 1, w: 10, text: '香,灯', styleName: 'right'},
            {row: 1, w: 10, text: '司,鼓', styleName: 'left'},
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, w: 10, text: '', styleName: 'right', seat: '香灯'},
            {row: 1, w: 10, text: '', styleName: 'left', seat: '司鼓'},
            {row: 1, w: 10, order: 3, seat: '师'},
            {row: 1, w: 10, order: 0, text: '', seat: '师'},
            {row: 1, w: 10},
            {row: 1, w: 10, order: 1, seat: '师'},
            {row: 1, w: 10, order: 2, seat: '师'},
        ],
        [
            {row: 1, col: 2, w: 20, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, col: 2, w: 20, text: '监,坛'},
            {row: 1, w: 10, order: 7, seat: '师'},
            {row: 1, w: 10, order: 6, seat: '师'},
            {row: 1, w: 10},
            {row: 1, w: 10, order: 5, seat: '师'},
            {row: 1, w: 10, order: 4, seat: '师'},
        ],
        [
            {row: 3, col: 2, w: 20, text: '', seat: '监坛'},
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, w: 10, order: 11, seat: '师'},
            {row: 1, w: 10, order: 10, seat: '师'},
            {row: 1, w: 10, text: ',', styleName: 'transparent'},
            {row: 1, w: 10, order: 9, seat: '师'},
            {row: 1, w: 10, order: 8, seat: '师'},
        ],
        [
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ]
    ]
    // 一大士
    const oneTable = [
        [
            {row: 6, col: 2, w: 20, text: '壇,设', styleName: 'big', seat: 'palace'},
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10},
            {row: 1, col: 2, w: 20},
            {row: 13, w: 30, text: [',,,,,恭,,,请',''], styleName: 'big', seat: 'one'},
        ],
        [
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '主,法'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, seat: '主法'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, col: 2, w: 20, text: '', info: '铛', seat: '铛'},
            {row: 1, w: 10},
            {row: 1, col: 2, w: 20, text: '', info: '鱼', seat: '鱼'},
        ],
        [
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, col: 2, w: 20, order: 0, seat: '师'},
            {row: 1, w: 10},
            {row: 1, col: 2, w: 20, info: '引磬', seat: '引磬'},
        ],
        [
            {row: 1, w: 10, text: '香,灯', styleName: 'right'},
            {row: 1, w: 10, text: '司,鼓', styleName: 'left'},
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, w: 10, text: '', order: 0, styleName: 'right', seat: '香灯'},
            {row: 1, w: 10, text: '', styleName: 'left', seat: '司鼓'},
            {row: 1, w: 10, order: 6, seat: '师'},
            {row: 1, w: 10, order: 2, seat: '师'},
            {row: 1, w: 10},
            {row: 1, w: 10, order: 1, seat: '师'},
            {row: 1, w: 10, order: 5, seat: '师'},
        ],
        [
            {row: 1, col: 2, w: 20, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, col: 2, w: 20, text: '监,坛'},
            {row: 1, w: 10, order: 8, seat: '师'},
            {row: 1, w: 10, order: 4, seat: '师'},
            {row: 1, w: 10},
            {row: 1, w: 10, order: 3, seat: '师'},
            {row: 1, w: 10, order: 7, seat: '师'},
        ],
        [
            {row: 3, col: 2, w: 20, text: '', seat: '监坛'},
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ],
        [
            {row: 1, w: 10, order: 10, seat: '师'},
            {row: 1, w: 10, info: '小鼓', seat: '小鼓'},
            {row: 1, w: 10, text: ',', styleName: 'transparent'},
            {row: 1, w: 10, info: '铪', seat: '铪'},
            {row: 1, w: 10, order: 9, seat: '师'},
        ],
        [
            {row: 1, col: 2, w: 20},
            {row: 1, w: 10, text: '师', styleName: 'master'},
            {row: 1, col: 2, w: 20},
        ]
    ];
    // 三大士
    const threeTable = clone(oneTable);
    threeTable[2][0].seat = '三座';
    threeTable[2][2].seat = '二座';
    threeTable[8].splice(0, 1, {row: 1, w: 10, text: '', styleName: 'right', order: 1, seat: '香灯'}, {row: 1, col: 1, w: 10},)

    export default {
        name: "OpenTablet",
        props: {
            buddhism: Object,
            boxStyle: Object,
            palace: String,
            table: {
                type: Boolean,
                default: true
            },
            editable: Boolean
        },
        data() {
            return {
                selectMaster: []
            }
        },
        computed: {
            styleSheet() {
                let styleSheet;
                if(this.table) {
                    styleSheet = {
                        big: {fontSize: '36px', fontWeight: 600},
                        normal: {height: '1.1em', boxSizing: 'content-box', lineHeight: '1.1em'},
                        half: {height: '0.55em', boxSizing: 'content-box', lineHeight: '0.55em'},
                        left: {textAlign: 'left'},
                        right: {textAlign: 'right'},
                        master: {padding: '16px 0',fontWeight: 'normal'},
                        transparent: {color: 'transparent'},
                        multi: {display: 'flex', justifyContent: 'center'},
                        info: {
                            position: 'absolute',
                            bottom: 0,
                            right: '-4px',
                            fontSize: '12px',
                            whiteSpace: 'nowrap',
                            transform: 'scale(0.8) translateX(100%)',
                        }
                    }
                } else {
                    styleSheet = {
                        big: {fontSize: '94px'},
                        normal: {height: '1.1em', boxSizing: 'content-box', lineHeight: '1.1em'},
                        half: {height: '0.55em', boxSizing: 'content-box', lineHeight: '0.55em'},
                        left: {textAlign: 'left'},
                        right: {textAlign: 'right'},
                        master: {padding: '24px 0', fontSize: '50px'},
                        transparent: {color: 'transparent'},
                        multi: {display: 'flex', justifyContent: 'center'},
                        tan: {width: '1em', height: '1em', objectFit: 'contain'},
                        info: {
                            position: 'absolute',
                            bottom: 0,
                            right: '-4px',
                            fontSize: '12px',
                            whiteSpace: 'nowrap',
                            transform: 'scale(0.8) translateX(100%)',
                        }
                    }
                }
                return styleSheet;
            },
            tdStyle() {
                let style;
                if(this.table) {
                    style = {
                        padding: '16px 4px',
                        height: '36px',
                        lineHeight: 1,
                    }
                } else {
                    style = {
                        padding: '12px 4px',
                        height: '96px',
                        lineHeight: 1,
                    }
                }
                return style
            },
            tableStyle() {
                let style;
                if(this.table) {
                    style = {
                        width: '640px',
                        fontFamily: 'Simsun',
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#000',
                        lineHeight: 1.5
                    }
                } else {
                    style = {
                        display: 'flex',
                        justifyContent: 'center',
                        width: '1080px',
                        height: '1920px',
                        fontFamily: 'pangmen',
                        fontSize: '44px',
                        color: '#000',
                        lineHeight: 1.5
                    }
                }
                if(this.boxStyle) {
                    style = Object.assign(style, this.boxStyle);
                }
                return style;
            },
            date() {
                let buddhism = this.buddhism;
                if(buddhism && (buddhism.begin_time || buddhism.date)) {
                    let date = buddhism.begin_time || buddhism.date;
                    date = getDate(date);
                    let lunar = getLunarDay(date);
                    let str = `${date.pattern("M月d日")}（农历：${lunar.lnongMonth}月${lunar.lnongDate}）`
                    return str;
                } else {
                    return '';
                }
            },
            types() {
                const buddhism = this.buddhism;
                let res = [];
                if(buddhism) {
                    res = getBuddhismMaterTypeList(buddhism);
                    const baseNum = res.reduce((acc, val) => acc + (val.number || 1), 0);
                    if(buddhism.number) {
                        let offset = +buddhism.number + (buddhism.extraNumber || 0) * 1 - baseNum;
                        offset = offset >= 0 ? offset : 0;
                        const type = res.find(t => t.master);
                        if(type) {
                            type.number = (type.number || 1) + offset
                        }
                    }
                    if(buddhism.master) {
                        res.forEach(t => {
                            let select = buddhism.master.filter(item => {
                                return item.num == t.name || (!item.num && t.name == '师');
                            }).map(item => {
                                if(item.master) {
                                    return {
                                        id: item.master.id,
                                        name: item.master.name,
                                        user_id: item.master.user_id
                                    }
                                } else {
                                    return {name: item.name};
                                }
                            });
                            const left = Math.max((t.number || 1) - select.length, 0);
                            t.select = select.concat(new Array(left).fill(null));
                        });
                    }
                }
                return res;
            },
            showTypes() {
                const res = [...this.types];
                const selectMaster = this.selectMaster;
                if(selectMaster.length > 0) {
                    selectMaster.forEach(s => {
                        const t = res.find(item => item.name == s.name);
                        if(t) {
                            t.select[s.order || 0] = s.master;
                        }
                    })
                }
                return res;
            },
            master() {
                const res = {};
                const types = this.showTypes;
                types.forEach(t => {
                    res[t.name] = t.select;
                })
                return res;
            },
            /* 师的数量 */
            shiNum() {
                let res = 0;
                const type = this.types.find(t => t.master);
                if(type) {
                    res = type.number;
                }
                return res;
            },
            palaceName() {
                const buddhism = this.buddhism;
                return this.palace || (buddhism && buddhism.palaceInfo ? buddhism.palaceInfo.name : '');
            },
            book() {
                const buddhism = this.buddhism;
                return buddhism ? buddhism.scripture || buddhism.chanting : "";
            },
            userName() {
                const buddhism = this.buddhism;
                return buddhism ? buddhism.username : "";
            },
            tableList() {
                let buddhism = this.buddhism;
                let res = null;
                if(buddhism) {
                    let table = [];
                    let type = buddhism.tabletType;
                    switch (type) {
                        // 礼忏诵经
                        case "05":
                        case "06":
                            table = clone(normalTable);
                            break;
                        // 焰口一大士
                        case "07":
                            table = clone(oneTable);
                            break;
                        // 焰口三大士
                        case "08":
                            table = clone(threeTable);
                            break;
                    }
                    return table;
                }
                return res;
            }
        },
        watch: {
            showTypes() {
                this.dispatchChange();
            }
        },
        created() {
            this.dispatchChange();
        },
        methods: {
            isArray,
            dispatchChange() {
                const types = this.showTypes;
                const list = types.map(t => {
                    return {
                        ...t,
                        select: t.select ? t.select.filter(m => !!m) : []
                    }
                })
                this.$emit("change", list);
            },
            removeSelect(seat, order) {
                const selectMaster = this.selectMaster;
                const index = selectMaster.findIndex(s => s.seat == seat && order == s.order);
                if(index >= 0) {
                    selectMaster.splice(index, 1, null);
                } else {
                    const item = {
                        name: seat,
                        master: null
                    }
                    if(order) {
                        item.order = order;
                    }
                    selectMaster.push(item);
                }
            },
            handleDragEnter(e) {
                e.currentTarget.classList.add("drag-over");
            },
            handleDragLeave(e) {
                e.currentTarget.classList.remove("drag-over");
            },
            handleDrop(e, cell) {
                e.currentTarget.classList.remove("drag-over");
                const text = e.dataTransfer.getData("text/plain");
                if(text) {
                    try {
                        const m = JSON.parse(text);
                        const selectMaster = this.selectMaster;
                        const index = selectMaster.findIndex(s => s.seat == cell.seat && cell.order == s.order);
                        if(index >= 0) {
                            const item = { ...selectMaster[index], master: m };
                            selectMaster.splice(index, 1, item);
                        } else {
                            const item = {
                                name: cell.seat,
                                master: m
                            }
                            if(cell.order) {
                                item.order = cell.order;
                            }
                            selectMaster.push(item);
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
            },
            handleDragOver(e) {
                e.preventDefault();
            },
            getStyle(td) {
                let res = {};
                if(td.styleName) {
                    if(isArray(td.styleName)) {
                        td.styleName.forEach(s => {
                            const style = this.styleSheet[s] || {};
                            res = {...res, ...style};
                        })
                    } else {
                        res = this.styleSheet[td.styleName] || {};
                    }
                }
                return res;
            },
            splitString(str) {
                return (str + "").split("").join(",")
            },
            getCellText(cell, master, palace) {
                const book = this.book;
                palace = palace || this.palaceName;
                const userName = this.userName;
                let res = clone(cell.text);
                if(cell.seat) {
                    switch (cell.seat) {
                        case 'palace':
                            res = `壇,设,,${this.splitString(palace)}`;
                            break;
                        case 'book':
                            res[1] = `今,有,,${this.splitString(userName)},,府,来,寺,启,建,,${this.splitString(book)},,一,永,日`;
                            break;
                        case 'one':
                            res[1] = `今,有,,${this.splitString(userName)},,府,来,寺,设,放,,瑜,伽,焰,口,,一,堂`;
                            break;
                        case '监坛':
                        case '主法':
                        case '二座':
                        case '三座':
                            res = master ? `${this.splitString(master.name)},法,师` : '';
                            break;
                        case '师':
                            res = master ? this.splitString(master.name) : '';
                            break;
                        default:
                            res = master ? this.splitString(master.name) : '';
                    }
                }
                return res;
            },
            getMasterText(seat, name) {
                return ["监坛", "主法", "二座", "三座"].indexOf(seat) >= 0 ? `${name}法师` : name;
            },
            getTable(master, table) {
                const buddhism = this.buddhism;
                const palace = buddhism.palaceInfo ? buddhism.palaceInfo.name : '';
                const book = buddhism.scripture || buddhism.chanting;
                const shi = master['师'] || {};
                table.forEach(tr=> {
                   tr.forEach(td => {
                       if(td.seat) {
                           switch (td.seat) {
                               case 'palace':
                                   td.text = `壇,设,,${this.splitString(palace)}`;
                                   break;
                               case 'book':
                                   td.text[1] = `今,有,,${this.splitString(buddhism.username || '')},,府,来,寺,启,建,,${this.splitString(book)},,一,永,日`;
                                   break;
                               case 'one':
                                   td.text[1] = `今,有,,${this.splitString(buddhism.username || '')},,府,来,寺,设,放,,瑜,伽,焰,口,,一,堂`;
                                   break;
                               case '监坛':
                               case '主法':
                               case '二座':
                               case '三座':
                                   td.text = master[td.seat] ? `${this.splitString(master[td.seat][0].name)},法,师` : '';
                                   break;
                               case '师':
                                   td.text = shi[td.order] ? this.splitString(shi[td.order].name) : '';
                                   break;
                               default:
                                   td.text = master[td.seat] ? this.splitString(master[td.seat][0].name) : '';
                           }
                       }
                   });
                });
                return table;
            },
        }
    }
</script>

<style lang="less">
    @font-face {
        font-family: 'pangmen';
        src: url('https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/assets/buddha/PANGMENZHENGDAOCUSHUTI-2.TTF') format('truetype');
    }
    .master-drop-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 4px 0;
        width: 2.2em;
        min-height: 3em;
        background-color: @background-color-base;
        &.drag-over {
            border: 1px dotted @primary-color;
        }
        .master-select-remove {
            position: absolute;
            top: -4px;
            right: -4px;
            font-size: 12px;
            line-height: 1;
            color: @error-color;
        }
    }
</style>
