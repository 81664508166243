import {getKeyTitle} from "@/common/js/tool";
import {assRuleCatList} from "./ass";

export const staffTypes = [
    {key: 1, title: '僧众'},
    {key: 2, title: '职工'},
    // {key: 3, title: '志工'},
];

export const educationList = [
    {key: 1, title: '博士及以上'},
    {key: 2, title: '硕士研究生'},
    {key: 3, title: '本科'},
    {key: 4, title: '专科'},
    {key: 5, title: '高中以及下'},
]

export const sexList = [
    {key: 1, title: '男'},
    {key: 2, title: '女'},
]

export const politicalList = [
    {key: 1, title: '中共党员'},
    {key: 2, title: '中共预备党员'},
    {key: 3, title: '共青团员'},
    {key: 4, title: '民革会员'},
    {key: 5, title: '民盟盟员'},
    {key: 6, title: '民建会员'},
    {key: 7, title: '民进会员'},
    {key: 8, title: '农工党党员'},
    {key: 9, title: '致公党党员'},
    {key: 10, title: '九三学社社员'},
    {key: 11, title: '台盟盟员'},
    {key: 12, title: '无党派人士'},
    {key: 13, title: '群众'},
]

export const maritalStatus = [
    { key: 1, title: '未婚' },
    { key: 2, title: '已婚' },
    { key: 3, title: '离异' },
    { key: 4, title: '丧偶' },
]

export const bookAgeList = [
    { value: "age_twenty", label: "20-29岁", min: 20, max: 29 },
    { value: "age_thirty", label: "30-39岁", min: 30, max: 39 },
    { value: "age_forty", label: "40-49岁", min: 40, max: 49 },
    { value: "age_fifty", label: "50-59岁", min: 50, max: 59 },
    { value: "age_sixty", label: "60-69岁", min: 60, max: 69 },
    { value: "age_seventy", label: "70岁以上", min: 70 }
]

export const bookStatus = [
    { value: 1, label: "在" },
    { value: 2, label: "离" },
]

export const hrRoles = [
    { key: 1, title: '超级管理员' },
    { key: 2, title: '部门领导' },
]

export const getStaffRadar = function (type) {
    let res = [];
    if(type == 1) {
        res = assRuleCatList.slice(0, 6);
    } else if(type == 2) {
        res = assRuleCatList.slice(-5);
    }
    return res.map(item => {
        return {
            cat: item.key,
            name: item.title,
            max: 100
        }
    });
}

export const getStaffTypeName = function (type) {
    return getKeyTitle(staffTypes, type);
}

export const getEducation = function (edu) {
    return getKeyTitle(educationList, edu);
}

export const getSex = function (sex) {
    return getKeyTitle(sexList, sex);
}

export const getPoliticalName = function (political) {
    return getKeyTitle(politicalList, political);
}

export const getMaritalStatusText = function (status){
    return getKeyTitle(maritalStatus, status);
}

export const getBookStatusText = function (status) {
    return getKeyTitle(bookStatus, status, "value", "label");
}

export const getHRRoleName = function (role) {
    return getKeyTitle(hrRoles, role);
}

export const dealStaff = function (staff) {
    if(staff && staff.base_info) {
        staff.typeName = getStaffTypeName(staff.type);
        try {
            let detail = JSON.parse(staff.base_info);
            for(let key in detail) {
                staff[key] = detail[key];
            }
        } catch (e) {
            console.error(e);
            staff = false;
        }
    } else {
        staff = false;
    }
    return staff;
}
