<template>
  <div class="entity-picker">
    <div class="entity-picker-button" @click="showModal">
      <slot></slot>
    </div>
    <a-modal
      :title="btnText"
      :maskClosable="false"
      :closable="false"
      :destroyOnClose="true"
      :width="modalWidth"
      @ok="confirmSelect"
      v-model="visible">
      <data-list
        hide-action
        :height="500"
        :url="url"
        :search-key-type="searchKeyType"
        :row-selection="{type: multiple ? 'checkbox' : 'radio', selectedRowKeys: selectKeys, onChange: onSelectChange}"
        :columns="columns"
        :query="query"
        :request-config="noTempleFilter ? {noTempleFilter: true} : null"
        v-if="url">
        <template v-slot:query="{form}">
          <slot name="query" v-bind:form="form"></slot>
        </template>
      </data-list>
    </a-modal>
  </div>
</template>

<script>
    import {clone} from "../../common/js/tool";

    export default {
        name: "entity-picker",
        props: {
            btnText: {
                type: String,
                default: '点击选择'
            },
            url: String,
            query: String,
            value: [Array, Object],
            columns: Array,
            modalWidth: {
                type: Number,
                default: 600
            },
            searchKeyType: Object,
            multiple: Boolean,
            noTempleFilter: Boolean
        },
        data() {
            return {
                visible: false,
                entity: null,
                selectKeys: [],
                selectItems: [],
                entityList: []
            }
        },
        methods: {
            getSelectList() {
                let value = this.value;
                let res = [];
                if(value) {
                    if(Array.isArray(value)) {
                        res = this.value;
                    } else {
                        res = [this.value]
                    }
                }
                return res;
            },
            removeSelect(index) {
                this.entityList.splice(index, 1);
                this.dispatchInput();
            },
            showModal() {
                let list = this.getSelectList();
                let keys = list.map(item => item.id);
                this.selectKeys = keys;
                this.selectItems = clone(list)
                this.visible = true;
            },
            onSelectChange(keys, rows) {
                this.selectKeys = keys;
                this.selectItems = rows;
            },
            handleRowClick({item}) {
                let keys = this.selectKeys;
                if(this.multiple) {
                    let index = keys.indexOf(item.id);
                    if(index >= 0) {
                        this.selectKeys.splice(index, 1);
                        this.selectItems.splice(index, 1);
                    } else {
                        this.selectKeys.push(item.id);
                        this.selectItems.push(item);
                    }
                } else {
                    if(keys.length > 0 && keys[0] == item.id) {
                        this.selectKeys = [];
                        this.selectItems = [];
                    } else {
                        this.selectKeys = [item.id];
                        this.selectItems = [item];
                    }
                }
            },
            hideModal() {
                this.selectKeys = [];
                this.selectItems = [];
                this.visible = false;
            },
            dispatchInput(list) {
                list = list || this.entityList;
                this.$emit('change', list.length > 0 ? this.multiple ? list : list[0] : null);
            },
            confirmSelect() {
                if(this.selectItems.length > 0) {
                    this.entityList = this.selectItems;
                    this.dispatchInput(this.entityList);
                }
                this.hideModal();
            }
        }
    }
</script>

<style scoped>

</style>
