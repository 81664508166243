const state = {
    safeRoute: null,
};

// getters
const getters = {
    safeRoute: state => state.safeRoute,
};

// mutations
const mutations = {
    setSafeRoute(state, value) {
        state.safeRoute = value;
    },
};

const actions = {
};

export default {
    state,
    getters,
    mutations,
    actions
}
