<template>
  <div class="flex-box align-center">
    <div class="time-range-prefix" v-if="startPrefix">{{ startPrefix }}</div>
    <a-time-picker
      v-model="start"
      format="HH:mm"
      value-format="HH:mm"
      placeholder="开始时间"
      :disabled-hours="disabledHours('Start')"
      :disabled-minutes="disabledMinutes('Start')"
      @change="dispatchEvent" />
    <div class="time-range-gap">{{ gapStr }}</div>
    <div class="time-range-prefix" v-if="endPrefix">{{ endPrefix }}</div>
    <a-time-picker
      v-model="end"
      format="HH:mm"
      value-format="HH:mm"
      placeholder="结束时间"
      :disabled-hours="disabledHours('End')"
      :disabled-minutes="disabledMinutes('End')"
      @change="dispatchEvent" />
  </div>
</template>

<script>
export default {
  name: "time-range",
  model: {
    event: 'change'
  },
  props: {
    value: Array,
    prefix: Array,
    min: Array,
    max: Array,
    gapStr: {
      type: String,
      default: '至'
    }
  },
  data() {
    return {
      start: null,
      end: null,
    }
  },
  computed: {
    startPrefix() {
      return this.prefix ? this.prefix[0] : null;
    },
    endPrefix() {
      return this.prefix ? this.prefix[1] : null;
    },
    minStart() {
      return this.min && this.min[0] ? this.min[0] : null;
    },
    maxStart() {
      return this.max && this.max[0] ? this.max[0] : null;
    },
    minEnd() {
      return this.min && this.min[1] ? this.min[1] : null;
    },
    maxEnd() {
      return this.max && this.max[1] ? this.max[1] : null;
    }
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledHours(type) {
      return () => {
        const minTime = this[`min${type}`], maxTime = this[`max${type}`];
        let min = 0, max = 23;
        if(minTime) {
          min = minTime.split(":")[0] * 1;
        }
        if(maxTime) {
          max = maxTime.split(":")[0] * 1;
        }
        let range = this.range(0, 24).filter(h => h < min || h > max);
        return range;
      }
    },
    disabledMinutes(type) {
      return selectedHour => {
        const minTime = this[`min${type}`], maxTime = this[`max${type}`];
        let min = 0, max = 59;
        if(minTime) {
          const mt = minTime.split(":");
          const mth = mt[0] * 1, mtm = mt[1] * 1;
          if(mth == selectedHour) {
            min = mtm;
          }
        }
        if(maxTime) {
          const mt = maxTime.split(":");
          const mth = mt[0] * 1, mtm = mt[1] * 1;
          if(mth == selectedHour) {
            max = mtm;
          }
        }
        let range = this.range(0, 60).filter(m => m < min || m > max);
        return range
      }
    },
    setValue() {
      let value = this.value;
      if(value) {
        this.start = value[0];
        this.end = value[1];
      }
    },
    dispatchEvent() {
      let start = this.start;
      let end = this.end;
      if(start && end) {
        this.$emit("change", [start, end]);
      } else {
        this.$emit("change", null);
      }
    }
  }
}
</script>

<style scoped lang="less">
  .time-range-gap {
    margin: 0 10px;
  }
  .time-range-prefix {
    margin-right: 1em;
  }
</style>
