<template>
    <div class="coordinate-picker">
        <div class="flex-box align-center" style="height: 40px">
            <a-button @click="showModal"><a-icon type="select"/>{{value ? '重新选择' : '选择地址'}}</a-button>
            <div class="address-info" v-if="location">{{location.address}}</div>
        </div>
        <a-modal
                title="选择地址"
                :maskClosable="false"
                :closable="false"
                :width="600"
                @ok="confirmSelect"
                v-model="visible">
            <div class="location-map">
                <iframe id="mapPage" width="100%" height="100%" frameborder=0 src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=IMXBZ-QK7KJ-2STFH-KEBXF-5QGHV-6PFVX&referer=myapp"></iframe>
            </div>
        </a-modal>
    </div>
</template>

<script>
    export default {
        name: "coordinate-picker",
        model: {
            event: 'change'
        },
        props: {
            value: Object
        },
        data() {
            return {
                visible: false,
                location: null,
                position: null
            }
        },
        watch: {
            value() {
                this.setAddress();
            }
        },
        created() {
            this.setAddress();
            window.addEventListener('message', this.handlePosition, false);
        },
        beforeDestroy() {
            window.removeEventListener('message', this.handlePosition, false);
        },
        methods: {
            setAddress() {
                let value = this.value;
                if(value && value.address) {
                    this.location = value;
                } else {
                    this.location = null
                }
            },
            handlePosition (e) {
                // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
                let loc = e.data;
                if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
                    // console.log(loc);
                    this.position = {
                        lat: loc.latlng.lat,
                        lng: loc.latlng.lng,
                        latLng: loc.latlng.lat + "," + loc.latlng.lng,
                        name: loc.poiname,
                        address: loc.poiaddress
                    };
                }
            },
            showModal() {
                this.visible = true;
            },
            confirmSelect() {
                let position = this.position;
                if(position) {
                    this.location = position;
                    this.$emit("change", position);
                }
                this.visible = false;
            }
        }
    }
</script>

<style scoped lang="less">
    .address-info {
        margin-left: @padding-md;
    }
    .location-map {
        margin-top: 10px;
        height: 600px;
        border: var(--border);
    }
</style>
