<template>
    <div class="icons-select">
        <div class="icons-show" @click.stop="showIconList">
            <img :src="base + value" v-if="value">
            <a-icon type="select" class="icons-default" v-else/>
        </div>
        <div class="icons-images" v-show="show">
            <div class="icons-images-title">模板图标</div>
            <div class="icons-img-list">
                <div class="icons-img-item" @click="selectIcon(img)" v-for="(img, i) in list" :key="i">
                    <img :src="base + img">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {base} from "./index";
    export default {
        name: "icons-select",
        model: {
            event: 'change'
        },
        props: {
            value: String
        },
        data() {
            return {
                show: false,
                base,
                list: [
                    'act.png',
                    'act-1.png',
                    'act-2.png',
                    'admin.png',
                    'approve.png',
                    'attend.png',
                    'attend-1.png',
                    'business.png',
                    'calendar.png',
                    'car.png',
                    'car-2.png',
                    'dining.png',
                    'gift.png',
                    'incense.png',
                    'leave.png',
                    'legal.png',
                    'legal-1.png',
                    'overwork.png',
                    'place.png',
                    'plan.png',
                    'public.png',
                    'public-1.png',
                    'recheck.png',
                    'repair.png',
                    'report.png',
                    'room.png',
                    'seal.png',
                    'security.png',
                    'tablet.png',
                    'tablet-1.png',
                    'temple.png',
                    'temple-1.png',
                    'temple-2.png',
                    'use.png',
                    'shopping.png',
                    'visit.png'
                ]
            }
        },
        created() {
            document.addEventListener("click", this.hideIconList, false);
        },
        beforeDestroy() {
            document.removeEventListener("click", this.hideIconList, false);
        },
        methods: {
            hideIconList() {
                this.show = false;
            },
            showIconList() {
                this.show = !this.show;
            },
            selectIcon(img) {
                this.$emit("change", img);
                this.show = false;
            }
        }
    }
</script>

<style lang="less">
    .icons-select {
        position: relative;
    }
    .icons-show {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border-radius: 4px;
        border: var(--border);
        cursor: pointer;
        img {
            width: 32px;
            height: 32px;
        }
    }
    .icons-default {
        width: 22px;
        height: 22px;
        font-size: 22px;
    }
    .icons-images {
        position: absolute;
        top: 60px;
        left: 0;
        padding: 12px;
        box-shadow: @box-shadow-base;
        background-color: @component-background;
        border: var(--border);
        border-radius: 4px;
        z-index: 1;
    }
    .icons-images-title {
        line-height: 1;
    }
    .icons-img-list {
        margin-top: 12px;
        width: 288px;
        font-size: 0;
    }
    .icons-img-item {
        display: inline-block;
        width: 48px;
        height: 48px;
        border-radius: 2px;
        border: 1px solid transparent;
        cursor: pointer;
        text-align: center;
        line-height: 46px;
        &:hover {
            border-color: #4A90E2;
            background-color: #FCFDFF;
            box-shadow: 0 0 6px #aed1f3;
        }
        img {
            width: 80%;
            height: 80%;
        }
    }
</style>
