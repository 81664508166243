export const industryList = [
    {key: 1, title: '经营管理类'},
    {key: 2, title: '公关/市场营销类'},
    {key: 3, title: '贸易/销售/业务类'},
    {key: 4, title: '财务类'},
    {key: 5, title: '行政/人力资源管理类'},
    {key: 6, title: '文职类'},
    {key: 7, title: '客户服务类'},
    {key: 8, title: '工厂类'},
    {key: 9, title: '计算机/互联网类'},
    {key: 10, title: '电子/通讯类'},
    {key: 11, title: '机械类'},
    {key: 12, title: '规划/建筑/建材类'},
    {key: 13, title: '房地产/物业管理类'},
    {key: 14, title: '金融/经济'},
    {key: 15, title: '设计类'},
    {key: 16, title: '法律类'},
    {key: 17, title: '酒店/餐饮类'},
    {key: 18, title: '物流/交通运输类'},
    {key: 19, title: '商场类'},
    {key: 20, title: '电气/电力类'},
    {key: 21, title: '咨询/顾问类'},
    {key: 22, title: '化工/生物类'},
    {key: 23, title: '文化/教育/体育类/艺术类'},
    {key: 24, title: '医疗卫生/护理/保健类'},
    {key: 25, title: '新闻/出版/传媒类'},
    {key: 26, title: '公众服务类'},
    {key: 27, title: '印刷/染织类'},
    {key: 28, title: '技工类'},
    {key: 29, title: '其他专业类'},
]

export const getIndustryText = function (value) {
    let res = industryList.find(i => i.key == value);
    return res ? res.title : null
}

export default {
    name: '职位',
    url: '/job-cat', // rest接口表名
    query: '&sort=-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '职位名称', dataIndex: 'name'},
            {
                title: '行业',
                dataIndex: 'industry',
                customRender: text => {
                    return <span>{text ? getIndustryText(text) : '无'}</span>
                }
            },
            {title: '排序', dataIndex: 'order'}
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '输入职位名称查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '职位名称',
            component: 'a-input',
            props: {
                placeholder: '请输入职位名称',
            },
            rules: [{ required: true, message: '请输入职位名称', trigger: 'blur' }]
        },
        {
            key: 'industry',
            label: '所属行业',
            component: 'a-select',
            props: {
                options: industryList,
                placeholder: "请选择所属行业"
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '职位名称',
                key: 'name'
            },
            {
                label: '所属行业',
                slot: entity => {
                    return <span>{getIndustryText(entity.industry)}</span>
                }
            },
            {
                label: '排序',
                key: 'order'
            },
        ]
    },
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            detail.area = [detail.province, detail.city, detail.district];
            resolve(detail);
        })
    },
}
