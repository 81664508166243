<template>
  <div class="item-list">
    <a-tag class="item-list-li" closable @close="handleClose(i)" v-for="(li, i) in list" :key="i">{{ li }}</a-tag>
    <a-input
      ref="input"
      style="margin-right: 10px;width: 120px"
      auto-focus
      size="small"
      v-model="str"
      @blur="handleInputConfirm"
      @keyup.enter="handleInputConfirm"
      v-if="edit"></a-input>
    <a-tag style="background: #fff; borderStyle: dashed;cursor:pointer;" @click="addItem" v-else><a-icon type="plus"/> {{addTitle}}</a-tag>
    <span class="tip" v-show="error">{{error}}</span>
  </div>
</template>

<script>

    export default {
        name: "item-list",
        model: {
            event: "change"
        },
        props: {
            value: [String, Array],
            addTitle: {
                type: String,
                default: '添加条目'
            },
            validator: Function,
            format: Function
        },
        data() {
            return {
                list: [],
                str: null,
                edit: false,
                error: ''
            }
        },
        computed: {
            valueValidator() {
                return typeof this.validator === "function" ? this.validator : () => null;
            },
            valueFormat() {
                return typeof this.format === "function" ? this.format : (val) => val;
            }
        },
        watch: {
            value() {
                this.setList();
            }
        },
        created() {
            this.setList();
        },
        methods: {
            setList() {
                let value = this.value;
                let list = [];
                if(value) {
                    if(typeof value === "string") {
                        try {
                            list = value.split(",");
                        } catch (e) {
                            console.error(e);
                        }
                    } else {
                        list = [...value];
                    }
                }
                this.list = list;
            },
            addItem() {
                this.str = null;
                this.edit = true;
                this.$nextTick(() =>{
                    this.$refs.input.focus();
                });
            },
            handleClose(i) {
                this.list.splice(i, 1);
                this.dispatchChange();
            },
            handleInputConfirm() {
                let str = this.valueFormat(this.str);
                if(str && this.list.indexOf(str) === -1) {
                    const error = this.valueValidator(str);
                    if(error) {
                        this.error = error;
                    } else {
                        this.error = "";
                        this.list.push(str);
                        this.dispatchChange();
                        this.edit = false;
                    }
                } else {
                    this.edit = false;
                }
            },
            dispatchChange() {
                let list = this.list;
                this.$emit("change", list && list.length > 0 ? list.join(",") : null);
            }
        }
    }
</script>

<style scoped>

</style>
