import "./common/js/init"
import Vue from 'vue'
import Local from './Local.vue'
import router from './router'
import store from './store'
import common from './components/index'
import axios from './common/js/req'
import './plugins/ant-design-vue.js'
import './directives/action';

Vue.use(common);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.config.ignoredElements = ['ww-open-data']

new Vue({
  router,
  store,
  render: h => h(Local)
}).$mount('#app');
