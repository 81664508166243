<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'app'
  }
</script>

<style lang="less">
  @import "./common/less/common";
  @import "./common/less/layout";
</style>
