<template>
  <div class="question-options">
    <a-button type="primary" @click="addOption">添加选项</a-button>
    <div class="option-tip" v-if="options && options.length > 0">点击选择正确答案</div>
    <a-radio-group v-model="answer">
      <a-radio :value="option.key" v-for="(option, index) in options" :key="option.key">
        <span class="option-value">{{ bigLetters[index] }}.</span>
        <span class="option-content">{{ option.content }}</span>
        <a-space class="option-operate">
          <span class="txt-btn" @click.prevent.stop="editOption(option)">编辑</span>
          <span
            class="txt-btn"
            :class="option.key === answer ? 'grey' : 'danger'"
            @click.prevent.stop="deleteOption(option, index)"
          >
            删除
          </span>
        </a-space>
      </a-radio>
    </a-radio-group>
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      :title="`${form.key ? '编辑' : '添加'}选项`"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
    >
      <a-form-model
        class="item-form"
        ref="editForm"
        :model="form"
        :rules="rules"
        :labelCol="modalForm.labelCol"
        :wrapperCol="modalForm.wrapperCol"
        :layout="modalForm.layout"
        :labelAlign="modalForm.labelAlign"
      >
        <!-- <a-form-model-item label="选项名称" prop="value">
          <a-input v-model="form.value" placeholder="输入选项名称" allowClear></a-input>
        </a-form-model-item> -->
        <a-form-model-item label="选项内容" prop="content">
          <a-input v-model="form.content" placeholder="输入选项内容" allowClear></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { clone, randomString } from "../common/js/tool";

export const bigLetters = (function() {
  // 创建一个包含26个字母的数组
  let letters = [];
  for (let i = 65; i <= 90; i++) {
    letters.push(String.fromCharCode(i));
  }
  return letters;
})();

export default {
  name: "QuestionOptions",
  model: {
    event: "change",
  },
  props: {
    value: String,
  },
  data() {
    return {
      answer: null,
      visible: false,
      modalForm: {
        layout: "vertical",
        labelAlign: "left",
      },
      form: {},
      rules: {
        // value: [{ required: true, message: "请输入选项名称", trigger: "blur" }],
        content: [{ required: true, message: "请输入选项内容", trigger: "blur" }],
      },
      options: [],
      bigLetters,
    };
  },
  watch: {
    value() {
      this.setValue();
    },
    answer() {
      this.dispatchEvent();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      let value = this.value;
      if (value) {
        value = JSON.parse(value);
        this.options = value.options;
        this.answer = value.answer;
      } else {
        this.options = [];
        this.answer = null;
      }
    },
    addOption() {
      this.form = {};
      this.visible = true;
    },
    editOption(option) {
      this.form = clone(option);
      this.visible = true;
    },
    deleteOption(option, index) {
      // 正确答案不允许删除
      if (option.key === this.answer) {
        return;
      }
      this.options.splice(index, 1);
      this.dispatchEvent();
    },
    dispatchEvent() {
      let options = this.options;
      let answer = this.answer;
      this.$emit("change", JSON.stringify({ options, answer }));
    },
    confirm() {
      let editForm = this.$refs.editForm;
      editForm.validate((flag) => {
        if (flag) {
          let form = clone(this.form);
          let options = this.options;
          if (form.key) {
            let index = options.findIndex((option) => option.key == form.key);
            Object.assign(options[index], form);
          } else {
            form.key = randomString(16);
            options.push(form);
          }
          if (options.length == 1) {
            // 只有一条数据，默认为正确答案
            this.answer = options[0].key;
          }
          this.visible = false;
          editForm.resetFields();
          this.dispatchEvent();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  display: block;
  margin-top: 6px;
}
.ant-radio-wrapper {
  display: block;
  white-space: normal;
  &:not(:first-of-type) {
    margin-top: 6px;
  }
}
.option-content {
  margin-left: 6px;
}
.option-operate {
  margin-left: 16px;
}
.txt-btn.grey {
  cursor: not-allowed;
}
</style>
