<template>
    <div class="data-list flex-box vertical">
        <div class="data-title">
            <div class="title-left flex-box">
                <slot name="title" v-bind:total="pagination.total" v-if="$scopedSlots.title"></slot>
                <span v-else>共{{pagination.total}}条记录</span>
            </div>
            <a-form-model class="query-form" layout="inline" v-if="!hideQuery">
                <slot name="query" v-bind:form="form"></slot>
            </a-form-model>
            <div class="title-btn">
                <a-button @click="getList" v-if="!hideQuery">查询</a-button>
                <a-button type="primary" @click="addItem" v-if="addBtn" v-action:add><a-icon type="plus-circle"/>{{addBtn}}</a-button>
                <slot name="button" v-bind:form="form"></slot>
            </div>
        </div>
        <div v-if="$scopedSlots.panel" class="data-pannel">
          <slot name="panel" v-bind:form="form"></slot>
        </div>
        <slot name="prefix"></slot>
        <div class="data-area">
            <slot name="table" v-bind:list="list" v-if="$scopedSlots.table"></slot>
            <a-table v-else
                ref="dataTable"
                :columns="renderColumns"
                :row-key="record => record[rowKey]"
                :data-source="url ? list : data"
                :loading="loading"
                :row-selection="rowSelection"
                :pagination="pagination"
                :scroll="scroll"
                :custom-row="customRow"
                :indent-size="indentSize"
                @change="handleTableChange">
                <template v-slot:action="text, record, index">
                    <div class="row-btn" @mousedown.stop @mouseup.stop>
<!--                        <a class="txt-btn warning" @click.stop="auditRow(record, index)" v-action:audit v-if="!hideAudit && record[statusKey] == 0">审核</a>-->
                        <a class="txt-btn" @click.stop="showDetail(record, index)" v-action:query v-if="!hideShowDetail">查看</a>
                        <a class="txt-btn" @click.stop="editRow(record, index)" v-action:update v-if="!hideEdit">编辑</a>
                        <a class="txt-btn danger" @click.stop="delRow(record, index)" v-action:delete v-if="!hideDelete">删除</a>
                        <slot name="action" v-bind:item="record" v-bind:index="index"></slot>
                    </div>
                </template>
            </a-table>
        </div>
        <a-modal :maskClosable="false" v-model="visible" :width="modalWidth" :title="audit ? '审核' : `${single.id ? '编辑' : '添加'}${entityName}`" ok-text="确认" cancel-text="取消" @ok="confirm">
            <a-form-model
                class="item-form"
                ref="editForm"
                :model="single"
                :rules="rules"
                :labelCol="modalForm.labelCol"
                :wrapperCol="modalForm.wrapperCol"
                :layout="modalForm.layout"
                :labelAlign="modalForm.labelAlign">
                <slot name="edit" v-bind:form="single"></slot>
                <a-form-model-item label="审核结果" v-if="audit">
                    <a-radio-group v-model="single[statusKey]" :options="auditStatusList"></a-radio-group>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import {clone, isEmpty} from "../common/js/tool";
    import {auditStatusList} from "../common/constant/status";

    export default {
        name: "data-list",
        props: {
            data: Array,
            action: Array, // 可运行操作的权限
            hideQuery: Boolean, //是否隐藏筛选
            hideAction: Boolean, //是否隐藏操作列
            hideEdit: Boolean, //是否隐藏编辑按钮
            hideDelete: Boolean, //是否隐藏删除按钮
            hideAudit: Boolean, //是否隐藏审核按钮
            hideShowDetail: Boolean, //是否隐藏查看按钮
            showRowNum: Boolean, //是否显示行编号
            beforeEdit: Function, //显示编辑条目之前的处理函数
            beforeUpdate: Function, //添加/编辑条目点击确定之前的处理函数
            customDelete: Function, //自定义删除操作
            customUpdate: Function, //自定义添加、更新条目操作
            dealList: Function, //自定义数据处理函数,
            customUrl: Function, //自定义请求接口URL函数
            addBtn: [String, Boolean], //添加条目按钮文案
            url: String,
            query: String,
            rowSelection: Object,
            width: Number, //表格内容区域宽度
            height: Number, //表格内容区域高度 不传自适应
            addRoute: String, //点击添加条目跳转的路由name
            actionWidth: Number, //操作列宽度
            //搜索字段的搜索方式 {字段名：搜索方式} true 模糊搜索 false 精确搜索
            defaultQuery: Object, //查询字段默认值
            requestConfig: Object, //axios请求额外配置
            defaultForm: Object, // 新增条目默认值
            pageSize: Number,
            // query自动查询方式 0 精确查询 1 模糊查询 2 get传参 默认精确查询
            searchKeyType: {
                type: Object,
                default() {
                    return {}
                }
            },
            columns: {
                type: Array,
                default() {
                    return []
                }
            },
            entityName: {
                type: String,
                default: '条目'
            },
            rowKey: {
                type: String,
                default: 'id'
            },
            statusKey: {
                type: String,
                default: 'status'
            },
            //modal Form配置
            modalForm: {
                type: Object,
                default() {
                    return {layout: 'vertical', labelAlign: 'left'}
                }
            },
            modalWidth: {
                type: Number,
                default: 400
            },
            rules: {
                type: Object,
                default() {
                    return {};
                }
            },
            // 展示树形数据时，每层缩进的宽度，以 px 为单位
            indentSize: {
                type: Number,
                default: 200,
            },
        },
        data() {
            return {
                loading: false,
                h: 300,
                single: {},
                form: {},
                list: [],
                visible: false,
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                audit: false,
                auditStatusList: auditStatusList,
                index: null,
                sorter: null,
            }
        },
        computed: {
            scroll() {
                let temp = {y: this.h};
                return this.width ? {...temp, x: this.width} : temp;
            },
            renderColumns() {
                let pre = [], end = [], columns = this.columns || [];
                if(this.showRowNum) {
                    pre = [{title: '序号', dataIndex: 'rowIndex', key: 'index', width: 60}]
                }
                if(!this.hideAction) {
                    let temp = {title: '操作', key: 'action', fixed: 'right', scopedSlots: { customRender: 'action'}};
                    temp.width = this.actionWidth || 180;
                    end = [temp];
                }
                return pre.concat(columns).concat(end);
            },
            api() {
                return this.url + this.query;
            }
        },
        watch: {
            pageSize() {
                this.setPagination();
            },
            'pagination.total'(val) {
                this.$emit('countChange', val);
            },
            api() {
                this.getList();
                this.setHeight();
            },
            height() {
                this.setHeight();
            },
            // 表头变化会导致高度变化 重新设置高度
            renderColumns() {
                this.$nextTick(() => {
                    this.setHeight();
                })
            },
            defaultQuery() {
                this.setQuery();
            }
        },
        created() {
            this.setPagination();
            this.setQuery();
            this.getList();
        },
        mounted() {
            this.setHeight();
            window.addEventListener("resize", this.setHeight, false);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.setHeight, false);
        },
        methods: {
            setPagination() {
                this.$set(this.pagination, "pageSize", this.pageSize || 10);
            },
            setQuery() {
                let val = this.defaultQuery;
                if(val) {
                    for(let key in val) {
                        this.$set(this.form, key, val[key]);
                    }
                } else {
                    this.form = {};
                }
            },
            showDetail(record, index) {
                this.$emit('detail', {item: record, index});
            },
            customRow(record, index) {
                let time = 0;
                return {
                    on: { // 事件
                        mousedown: (e) => {
                            time = e.timeStamp;
                        },
                        mouseup: (e) => {
                            let offset = e.timeStamp - time;
                            //区分长按和点击
                            if(offset < 300) {
                                this.$emit('rowClick', {e, item: record, index});
                            }
                        }
                    },
                };
            },
            confirm() {
                this.$refs.editForm.validate(flag => {
                    if(flag) {
                        let form = this.single;
                        if(typeof this.beforeUpdate === 'function') {
                            this.beforeUpdate(form, res => {
                                this.updateItem(res || form);
                            });
                        } else {
                            this.updateItem(form);
                        }
                    }
                })
            },
            updateItem(form) {
                delete form.create_time;
                delete form.update_time;
                if(typeof this.customUpdate === "function") {
                    this.customUpdate(form, this.index, () => {
                        this.getList();
                        this.hideModal();
                    });
                } else {
                    let url = this.url;
                    if(form.id) {
                        url += `/${form.id}`;
                    }
                    this.$axios({
                        url,
                        method: form.id ? 'PATCH' : 'POST',
                        data: form
                    }).then(() => {
                        this.$message.success(form.id ? '更新成功' : '添加成功');
                        this.getList();
                        this.hideModal();
                    });
                }
            },
            setHeight() {
                let height = this.height;
                if(height) {
                    this.h = height;
                } else {
                    let dataTale = this.$refs.dataTable;
                    if(dataTale) {
                        const area = this.$el.querySelector(".data-area");
                        const head = this.$el.querySelector(".ant-table-header");
                        this.h = area.clientHeight - (head?.clientHeight ?? 54) - 64;
                    }
                }
            },
            auditRow(item) {
                let form = {id: item.id};
                form[this.statusKey] = 1;
                this.audit = true;
                this.showEdit(form);
            },
            showModal() {
                this.visible = true;
            },
            hideModal() {
                this.visible = false;
            },
            addItem() {
                //如果传入路由则跳转 否则检测编辑插槽是否有内容 两者都没有抛出事件
                if(this.addRoute) {
                    this.$router.push({name: this.addRoute});
                } else if(this.$scopedSlots.edit) {
                    this.editRow({ ...(this.defaultForm || {}) });
                } else {
                    this.$emit("add");
                }
            },
            editRow(item, index) {
                this.index = index
                let form = clone(item);
                if(typeof this.beforeEdit === 'function') {
                    this.beforeEdit(form, res => {
                        this.audit = false;
                        this.showEdit(res || form);
                    });
                } else if(this.$scopedSlots.edit) {
                    this.audit = false;
                    this.showEdit(form);
                } else {
                    this.$emit("edit", form);
                }
            },
            showEdit(form) {
                this.single = form;
                this.showModal();
            },
            delRow(item, index) {
                if(typeof this.customDelete === 'function') {
                    this.customDelete(item, index, () => {
                        this.getList();
                    })
                } else {
                    this.$confirm({
                        title: '提示',
                        content: `确定删除此${this.entityName}吗？`,
                        onOk: () => {
                            this.$axios({
                                url: `${this.url}/${item.id}`,
                                method: 'DELETE'
                            }).then(() => {
                                this.$message.success('删除成功');
                                this.getList();
                            })
                        }
                    });
                }
            },
            handleTableChange(pagination, filters, sorter) {
                this.sorter = sorter;
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
                this.getList();
            },
            //对外的处理内部数据的方法 接收一个数据处理函数并刷新数据
            dealData(fn) {
                typeof fn === "function" && fn(this.list, list => {
                    this.list = list;
                })
            },
            updateList() {
                this.pagination.current = 1;
                this.getList();
            },
            getList() {
                let url = this.url;
                if(!url) return;
                this.loading = true;
                let query = this.query, form = this.form, sorter = this.sorter;
                let {current, pageSize} = this.pagination;
                url += `?page=${current}&pageSize=${pageSize}`;
                if(this.customUrl && typeof this.customUrl == "function") {
                    url += this.customUrl(form);
                } else {
                    let searchKeyType = this.searchKeyType;
                    for(let key in form) {
                        if(!isEmpty(form[key])) {
                            //模糊搜索
                            let type = searchKeyType[key] || 0;
                            if (typeof type == "function") {
                                url += type(form, key);
                            } else if(type == 1) {
                                url += `&filter[${key}][like]=${form[key]}`;
                            } else if(type == 2) {
                                url += `&${key}=${form[key]}`;
                            } else {
                                url += `&filter[${key}]=${form[key]}`;
                            }
                        }
                    }
                }
                if(query) {
                    url += query;
                }
                if (sorter) {
                    if (sorter.field && sorter.order) {
                        let sIndex = url.indexOf("sort=");
                        let prefix = "";
                        if (sorter.order == "descend") {
                            prefix = "-";
                        }
                        if (sIndex === -1) {
                            url += `&sort=${prefix}${sorter.field}`;
                        } else {
                            url = url.slice(0, sIndex + 5) + `${prefix}${sorter.field},` + url.slice(sIndex + 5);
                        }
                    }
                }
                this.$axios.get(url, this.requestConfig).then(res => {
                    this.loading = false;
                    let list = res.data;
                    if(this.showRowNum) {
                        list.forEach((item, index) => {
                            item.rowIndex = (current - 1) * pageSize + index + 1;
                        });
                    }
                    if(typeof this.dealList === 'function') {
                        this.dealList(list, res => {
                            this.list = res || list;
                        })
                    } else {
                        this.list = list;
                    }
                    if(res.page) {
                        this.$set(this.pagination, 'total', res.page.totalCount);
                    }
                }).catch(e => {
                    console.error(e)
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped lang="less">
.data-page {
    text-align: right;
}
</style>
