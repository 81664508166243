const accessControl = {
  id: "accessControl",
  router: {
    path: "/space/access-control",
    name: "AccessControl",
    component: () => import(/* webpackChunkName: "access-control" */ "../../views/space/AccessControl.vue"),
    meta: {
      title: "门禁列表",
    },
  },
};

const accessControlRecord = {
  id: "accessControlRecord",
  router: {
    path: "/space/access-control/record/:id",
    name: "AccessControlRecord",
    component: () =>
      import(/* webpackChunkName: "access-control-record" */ "../../views/space/AccessControlRecord.vue"),
    meta: {
      title: "进出记录",
    },
  },
};

const accessControlAuth = {
  id: "accessControlAuth",
  router: {
    path: "/space/access-control/auth/:id",
    name: "AccessControlAuth",
    component: () => import(/* webpackChunkName: "access-control-auth" */ "../../views/space/AccessControlAuth.vue"),
    meta: {
      title: "权限管理",
    },
  },
};

const spaceOffice = {
  id: "spaceOffice",
  router: {
    path: "/space/office",
    name: "SpaceOffice",
    component: () => import(/* webpackChunkName: "space-office" */ "../../views/space/SpaceOffice.vue"),
    meta: {
      title: "办公室管理",
    },
  },
};

const spaceAuth = {
  id: "spaceAuth",
  router: {
    path: "/space/authority",
    name: "SpaceAuth",
    component: () => import(/* webpackChunkName: "space-auth" */ "../../views/space/SpaceAuth.vue"),
    meta: {
      title: "权限设置",
    },
  },
};

export default {
  accessControl,
  accessControlRecord,
  accessControlAuth,
  spaceOffice,
  spaceAuth,
};

export const routers = [
  {
    type: 1,
    list: [
      { id: "accessControl", action: ["add", "delete", "update", "query", "audit", "auth"] },
      { id: "accessControlRecord", action: ["add", "delete", "update", "query", "audit"] },
      { id: "accessControlAuth", action: ["add", "delete", "update", "query", "audit"] },
      { id: "spaceOffice", action: ["add", "delete", "update", "query", "audit"] },
      { id: "spaceAuth", action: ["add", "delete", "update", "query", "audit"] },
    ],
  },
];

export const menus = [
  {
    type: 1,
    list: [
      {
        title: "门禁管理",
        path: "/space/access-control",
      },
      {
        title: "办公室管理",
        path: "/space/office",
      },
      {
        title: "权限设置",
        path: "/space/authority",
      },
    ],
  },
];
