import Vue from 'vue'
import VueRouter from 'vue-router'
import {getStorageInfo, getToken} from "@/common/js/storage";
import store from "../store/index";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {generateRouter} from "@/permission";
// import RouteView from "../layouts/RouteView";

Vue.use(VueRouter);

const notFoundRouter = {
  path: '*',
  name: 'NotFound',
  component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue')
}
const routes = [
  {
    path: '/password',
    name: 'Password',
    component: () => import(/* webpackChunkName: "password" */ '../views/Password.vue'),
    meta: {
      title: '修改密码',
    }
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () => import(/* webpackChunkName: "l" */ '../views/Overview.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: '登录',
      open: true
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth.vue'),
    meta: {
      title: '登录中...',
      open: true
    }
  },
  {
    path: '/light',
    name: 'Light',
    component: () => import(/* webpackChunkName: "login" */ '../views/Light.vue'),
    meta: {
      title: '供灯',
      open: true
    }
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "404" */ '../views/Forbidden.vue'),
    meta: {
      title: '403 Forbidden',
      open: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// const loginRoutePath = '/login';
const defaultRoutePath = '/template';

router.beforeEach((to, from, next) => {
  NProgress.start();
  let token = getToken();
  if(token) {
    let meta = to.meta;
    //页面标题
    let title = meta.title;
    if (title) {
      document.title = title;
    }
    let user = store.getters.user;
    // 根据vuex的user信息判断是否已经生成router
    if(!user) {
      user = getStorageInfo();
      let {routers, defaultPath} = generateRouter(user);
      if(routers) {
        routers.forEach(r => {
          router.addRoute(r);
        })
      }
      router.addRoute(notFoundRouter);
      store.commit("setUser", user);
      // 已经登录或者根路由则直接跳转
      if(to.path === '/') {
        let path = to.query.r || defaultPath || defaultRoutePath;
        next({path, replace: true});
      } else {
        next({...to});
      }
    } else {
      if(to.path === '/') {
        let defaultPath = store.getters.defaultPath;
        let path = to.query.r || defaultPath || defaultRoutePath;
        next({path, replace: true});
      } else {
        next();
      }
    }
  } else if(to.meta.open) {
    next();
  } else {
    next({name: 'Login', query: {r: to.path}});
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router
