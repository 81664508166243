<template>
  <a-modal
    title="批量导入"
    :ok-text="loading ? '正在导入' : '导入'"
    :ok-button-props="{props: {loading}}"
    :maskClosable="false"
    :closable="false"
    :width="480"
    @cancel="handleCancel"
    @ok="confirmModal"
    v-model="visible">
    <div class="import-title">
      <div class="import-btn">
        <a-button type="primary">选择文件上传</a-button>
        <input
          class="import-input"
          ref="importInput"
          type="file"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="handleFileSelect">
      </div>
      <div class="import-temp">Excel模板下载：<a-button @click="downloadTemplate">{{name}}<a-icon type="download" /></a-button></div>
    </div>
    <div class="import-file">
      <div class="import-file-info" v-if="file">
        <div class="file-name">{{ file.name }}</div>
        <div class="txt-btn" @click="file = null">移除</div>
      </div>
      <div class="import-drag-area" @dragover="fileDragover" @drop="fileDrop" v-else>
        <div class="import-drag-title">未选择文件</div>
        <div class="import-drag-tip">Chrome 和 FireFox 支持拖拽到此区域上传</div>
      </div>
    </div>
  </a-modal>
</template>

<script>

    export default {
        name: "batchImportModal",
        model: {
            event: 'change',
            prop: 'show'
        },
        props: {
            show: Boolean,
            name: String,
            url: String,
        },
        data() {
            return {
                file: null,
                loading: false,
                visible: false
            }
        },
        watch: {
            show(val) {
                this.visible = val;
                if(val) {
                    this.clearSelect();
                }
            }
        },
        created() {
            this.visible = this.show;
        },
        methods: {
            clearSelect() {
                this.file = null;
            },
            downloadTemplate() {
                const url = this.url;
                if(url) {
                    window.open(url, "_blank");
                }
            },
            confirmModal() {
                if(this.loading) return;
                if(this.file) {
                    this.$emit("select", this.file);
                } else {
                    this.$message.warning("请选择文件");
                }
            },
            handleFileSelect(e) {
                let file = e.target.files[0];
                this.file = file;
                this.$refs.importInput.value = "";
            },
            // 拖拽上传
            fileDragover (e) {
                e.preventDefault()
            },
            fileDrop (e) {
                e.preventDefault();
                const file = e.dataTransfer.files[0]; // 获取到第一个上传的文件对象
                this.file = file;
            },
            handleCancel() {
                this.$emit("change", false);
            }
        }
    }
</script>

<style lang="less">
  .import-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .import-btn {
    position: relative;
    .import-input {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  .import-file {
    margin-top: 16px;
  }
  .import-file-info {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-radius: 4px;
    border: 1px solid @border-color-base;
  }
  .file-name {
    padding-right: 1em;
  }
  .import-drag-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
    border-radius: 4px;
    border: 1px dashed @border-color-base;
    text-align: center;
  }
  .import-drag-title {
    font-weight: 700;
  }
  .import-drag-tip {
    margin-top: 8px;
    color: @text-color-secondary;
  }
</style>
