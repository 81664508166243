import {getKeyTitle} from "../../js/tool";
import {getTemple} from "../../js/storage";

const skillList = ['木鱼', '磬', '引磬', '铪', '铛', '小木鱼', '手鼓', '香灯', '鼓', '监坛']

export const masterStatusList = [
    {key: 1, title: '空闲'},
    {key: 2, title: '有佛事'},
    {key: 3, title: '请假'},
]

export const getMasterStatusText = function (status) {
    return getKeyTitle(masterStatusList, status);
}

export default {
    name: '法师',
    listTitle: '法师管理',
    url: '/admin/master', // rest接口表名
    query: () => `&temple_id=${getTemple()}`, // rest接口query参数
    requestConfig: {noTempleFilter: true},
    searchKeyType: {name: 2},
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                title: '法师',
                dataIndex: 'user_id',
                customRender: text => {
                    return <open-data type="userName" openid={text} />
                }
            },
            {title: '法名',dataIndex: 'name'},
            {title: '手机号码',dataIndex: 'mobile'},
            {title: '堂口', dataIndex: 'tangkou', customRender: text => text?.name || "--"},
            {title: '技能', dataIndex: 'ability'},
            {title: '参与佛事次数', dataIndex: 'fs_count'},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '输入法名查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'user',
            label: '法师',
            component: 'select-book',
            props: {
                type: 'user'
            },
            listeners: {
                change(val) {
                    if(val) {
                        this.$set(this.form, 'user_id', val[0]);
                    } else {
                        this.$set(this.form, 'user_id', null);
                    }
                }
            },
            rules: [{ required: true, message: '请选择法师', trigger: 'change' }]
        },
        {
            key: 'name',
            label: '法名',
            component: 'a-input',
            props: {
                placeholder: '请输入法名',
            },
            rules: [{ required: true, message: '请输入法名', trigger: 'blur' }]
        },
        {
            key: 'mobile',
            label: '手机号码',
            component: 'a-input',
            props: {
                placeholder: '请输入手机号码',
            },
            rules: [
                { required: true, message: "请输入手机号码", trigger: "blur" },
                { pattern: "^1[3-9]\\d{9}$", message: "请输入正确的手机号码" },
            ]
        },
        {
            key: 'skills',
            label: '技能',
            component: 'a-select',
            props: {
                mode: 'multiple',
                placeholder: '请选择技能',
                options: skillList.map(item => {return{key: item, title: item}})
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'ability', val ? val.join(",") : undefined);
                }
            },
            style: {
                width: '100%'
            }
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '法师',
                slot: entity => <open-data type="userName" openid={entity.user_id} />
            },
            {
                label: '法名',
                key: 'name',
            },
            {
                label: '手机号码',
                key: 'mobile',
            },
            {
                label: '技能',
                key: 'ability',
            },
        ]
    },
    // 编辑实体前处理函数
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            if(detail.user_id) {
                detail.user = [detail.user_id];
            }
            if(detail.ability) {
                detail.skills = detail.ability.split(",");
            }
            resolve();
        })
    },
}
