const app = {id: 'app', title: '应用管理'};


const template = {
    id: 'template',
    router: {
        path: '/oa/template',
        name: 'TemplateList',
        component: () => import(/* webpackChunkName: "tl" */ '../../views/template/TemplateList.vue'),
        meta: {
            title: '应用管理'
        }
    }
};
const templateAdd = {
    id: 'templateAdd',
    router: {
        path: '/oa/templateAdd',
        name: 'TemplateAdd',
        component: () => import(/* webpackChunkName: "tl" */ '../../views/template/TemplateAdd.vue'),
        meta: {
            title: '添加应用'
        }
    }
};
const temp = {
    id: 'temp',
    router: {
        path: 'temp/:id',
        name: 'Template',
        redirect: {name: 'TemplateData'},
        component: () => import(/* webpackChunkName: "tl" */ '../../views/template/Template.vue'),
        children: [
            {
                path: 'data',
                name: 'TemplateData',
                component: () => import(/* webpackChunkName: "td" */ '../../views/template/TemplateData.vue')
            },
            {
                path: 'form',
                name: 'TemplateForm',
                component: () => import(/* webpackChunkName: "tf" */ '../../views/template/TemplateForm.vue')
            },
            {
                path: 'setting',
                name: 'TemplateSetting',
                component: () => import(/* webpackChunkName: "ts" */ '../../views/template/TemplateSetting.vue')
            },
            {
                path: 'plate',
                name: 'TemplatePlate',
                component: () => import(/* webpackChunkName: "tp" */ '../../views/template/TemplatePlate.vue')
            },
            {
                path: 'holiday-balance',
                name: 'HolidayBalance',
                component: () => import(/* webpackChunkName: "hb" */ '../../views/oa/HolidayBalance.vue'),
                meta: {
                    title: '假期余额'
                }
            },
            {
                path: 'leave-record',
                name: 'LeaveRecord',
                component: () => import(/* webpackChunkName: "lr" */ '../../views/oa/LeaveRecord.vue'),
                meta: {
                    title: '请假记录'
                }
            },
            {
                path: 'leave/:user',
                name: 'PersonLeave',
                component: () => import(/* webpackChunkName: "lr" */ '../../views/oa/PersonLeave.vue'),
                meta: {
                    title: '个人请假记录'
                }
            },
        ]
    }
}

const checkin = {
    id: "checkin",
    router: {
        path: "/oa/checkin",
        name: 'Checkin',
        redirect: {name: 'CheckinHome'},
        component: () => import(/* webpackChunkName: "checkin" */ '../../views/template/checkin/Checkin.vue'),
        children: [
            {
                path: "home",
                name: 'CheckinHome',
                component: () => import(/* webpackChunkName: "ch" */ '../../views/template/checkin/CheckinHome.vue'),
            },
            {
                path: "checkin-rule-add",
                name: 'CheckinRuleAdd',
                component: () => import(/* webpackChunkName: "cra" */ '../../views/template/checkin/CheckinRuleAdd.vue'),
                meta: {
                    title: "添加打卡规则"
                }
            },
            {
                path: "checkin-rule/:id",
                name: 'CheckinRuleDetail',
                component: () => import(/* webpackChunkName: "crd" */ '../../views/template/checkin/CheckinRuleDetail.vue'),
                meta: {
                    title: "打卡规则设置"
                }
            },
            {
                path: "checkin-wish",
                name: 'CheckinWish',
                component: () => import(/* webpackChunkName: "cw" */ '../../views/template/checkin/CheckinWish.vue'),
                meta: {
                    title: "今日祝福"
                }
            },
            {
                path: "checkin-record",
                name: 'CheckinRecord',
                component: () => import(/* webpackChunkName: "cr" */ '../../views/template/checkin/CheckinRecord.vue'),
                meta: {
                    title: "考勤记录"
                }
            },
        ]
    }
}

const process = {
    id: "process",
    router: {
        path: '/process/:id',
        name: 'Process',
        component: () => import(/* webpackChunkName: "process" */ '../../views/Process.vue'),
        meta: {
            title: '审批流程'
        }
    }
}
const calendar = {
    id: 'calendar',
    router: {
        path: '/oa/calendar',
        name: 'Calendar',
        component: () => import(/* webpackChunkName: "tem" */ '../../views/Calendar.vue'),
        meta: {
            title: '寺院日历'
        }
    },
};
const org = {
    id: 'org',
    router: {
        path: '/oa/org',
        name: 'Org',
        component: () => import(/* webpackChunkName: "tem" */ '../../views/Organization.vue'),
        meta: {
            title: '通讯录'
        }
    },
};

const holiday = {id: 'holiday', entity: 'holiday', temp: {type: 1}}; // temp属性用于关联到哪个模板的后台导航菜单
const place = {id: 'place', entity: 'place', temp: {type: 4}}; // temp属性用于关联到哪个模板的后台导航菜单
const room = {id: 'room', entity: 'room', temp: {type: 5}};
const notice = {id: 'notice', entity: 'notice' };
const oaAdmin =  {
    id: 'oaAdmin',
    router: {
        path: '/oa/admin',
        name: 'OaAdmin',
        component: () => import(/* webpackChunkName: "bd" */ '../../views/template/OaAdmin.vue'),
        meta: {
            title: '权限设置'
        }
    },
}

const car = {id: 'car', entity: 'car', temp: {type: 12}}; // temp属性用于关联到哪个模板的后台导航菜单
const box = {id: 'box', entity: 'box', temp: {type: 7}}; // temp属性用于关联到哪个模板的后台导航菜单

export const routers = [
    {
        type: 1,
        list: [
            {id: 'template', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'templateAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'temp', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'checkin', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'holiday', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'room', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'place', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'calendar', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'org', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'oaAdmin', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'car', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'box', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'notice', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    },
    {
        type: 2,
        list: [
            {id: 'template', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'templateAdd', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'temp', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'checkin', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'holiday', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'room', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'place', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'car', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'box', action: ['add', 'delete', 'update', 'query', 'audit']},
            {id: 'notice', action: ['add', 'delete', 'update', 'query', 'audit']},
        ]
    }
]

export const menus = [
    {
        type: 1,
        list: [
            {
                title: '应用管理',
                path: '/oa/template',
                icon: 'appstore'
            },
            {
                title: '寺院日历',
                path: '/oa/calendar',
                icon: 'calendar'
            },
            {
                title: '通讯录',
                path: '/oa/org',
                icon: 'team'
            },
            {
                title: '权限设置',
                path: '/oa/admin',
                icon: 'team'
            },
        ]
    },
    {
        type: 2,
        list: [
            {
                title: '应用管理',
                path: '/oa/template',
                icon: 'appstore'
            },
        ]
    }
]

export default {
    app,
    template,
    templateAdd,
    temp,
    holiday,
    process,
    calendar,
    room,
    place,
    org,
    oaAdmin,
    car,
    box,
    checkin,
    notice
}
