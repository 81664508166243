<template>
  <div class="chanting-set">
    <div class="chanting-set-box" v-for="(item, j) in typeList" :key="item.key + item.subType">
      <div class="chanting-set-label">{{item.name}}</div>
      <div class="chanting-set-list">
        <div class="chanting-set-item" v-for="(n, i) in item.options" :key="i">
          <template v-if="n.input">
            <div class="flex-box align-center chanting-input-form">
              <div class="chanting-input-item" v-if="type == 2">
                <div class="chanting-input-label">经书名称</div>
                <div class="chanting-input-box">
                  <a-input size="small" style="width: 88px;" auto-focus v-model="n.value"></a-input>
                </div>
              </div>
              <div class="chanting-input-item">
                <div class="chanting-input-label">诵经人数</div>
                <div class="chanting-input-box">
                  <a-input-number size="small" :min="1" style="width: 88px;" v-model="n.number"></a-input-number>
                </div>
              </div>
              <div class="chanting-form-btn">
                <span class="txt-btn grey" @click="cancelSet(j, i)">取消</span>
                <span class="txt-btn" style="margin-left: 6px" @click="saveSet(j, i)">添加</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="set-item-option">
              <span class="set-option-text" v-if="type == 3">{{n.value}}{{item.suffix || ''}}</span>
              <span class="set-option-text" v-else>{{`${n.value} - ${n.number || 0}人`}}{{item.suffix || ''}}</span>
              <a-icon type="close" class="set-option-remove" @click="removeOption(j, i)" v-if="edit"/>
            </div>
            <a-icon type="plus-circle" class="chanting-set-icon chanting-option-add" @click="addOption(j, i)" v-if="edit"/>
          </template>
        </div>
        <div class="chanting-set-item" v-if="edit && item.options.length <= 0">
          <a-icon type="plus-circle" class="chanting-set-icon chanting-option-add" @click="addOption(j, -1)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import {clone} from "../../common/js/tool";
    import {getBuddhaSubTypeMasterNumberConfig} from "../../common/buddha/buddhism";

    export default {
        name: "chanting-set",
        model: {
            event: "change"
        },
        props: {
            //是否可编辑
            edit: {
                type: Boolean,
                default: true
            },
            //佛事类型
            type: {
                type: Number,
                default: 2
            },
            value: Object // 配置
        },
        data() {
            return {
                typeList: [],
                rules: {
                    value: [{}]
                }
            }
        },
        computed: {
            items() {
                let res = [
                    { key: 'scripture', name: '礼忏经书（延生）', options: [], subType: '05' },
                    { key: 'scripture', name: '礼忏经书（往生）', options: [], subType: '06' },
                    { key: 'chanting', name: '诵经经书（延生）', options: [], subType: '05' },
                    { key: 'chanting', name: '诵经经书（往生）', options: [], subType: '06' },
                ];
                if(this.type == 3) {
                    res = [
                        {key: 'oneNumber', name: '焰口一大士诵经人数', options: [], suffix: '人', subType: '07'},
                        {key: 'threeNumber', name: '焰口三大士诵经人数', options: [], suffix: '人', subType: '08'},
                    ]
                }
                return res;
            },
            numberConfig() {
                let res = {}
                const items = this.items;
                items.forEach(item => {
                    if(!res[item.subType]) {
                        res[item.subType] = getBuddhaSubTypeMasterNumberConfig(item.subType);
                    }
                });
                return res;
            }
        },
        watch: {
            value() {
                this.setOptions()
            }
        },
        created() {
            this.setOptions()
        },
        methods: {
            saveSet(j, i) {
                const conf = this.typeList[j];
                const options = conf.options;
                let item = options[i];
                const numberConfig = this.numberConfig[conf.subType];
                if(this.type == 2 && !item.value) {
                    this.$message.warning("请输入经书名称");
                    return;
                }
                if(!item.number) {
                    this.$message.warning("请输入诵经人数");
                    return;
                } else if(numberConfig.min && item.number < numberConfig.min) {
                    this.$message.warning(`${this.type == 3 ? conf.name : '诵经人数'}至少为${numberConfig.min}人`);
                    return;
                } else if(numberConfig.max && item.number > numberConfig.max) {
                    this.$message.warning(`${this.type == 3 ? conf.name : '诵经人数'}最多为${numberConfig.max}人`);
                    return;
                }
                this.$set(item, 'input', false);
                this.dispatchChange();
            },
            cancelSet(j, i) {
                let options = this.typeList[j].options;
                options.splice(i, 1);
            },
            setOptions() {
                let value = this.value;
                let items = clone(this.items);
                if(value) {
                    if(value.error) return;
                    items.forEach(item => {
                        let options = clone(value[item.key] || []);
                        options = options.map(o => {return typeof o === "object" ? {...o} : {value: o}});
                        item.options = options.filter(o => !o.subType || o.subType == item.subType);
                    })
                }
                this.typeList = items;
            },
            dispatchChange() {
                let typeList = this.typeList;
                let error = null;
                let res = {};
                for(let i = 0, l = typeList.length; i < l; i++) {
                    let item = typeList[i];
                    let options = item.options.filter(item => !item.input);
                    if(options.length <= 0) {
                        error = `请至少设置一个${item.name}选项`;
                        break;
                    } else {
                        const list = options.map(o => this.type == 3 ? o.value : {value: o.value, number: o.number, subType: item.subType});
                        if(res[item.key]) {
                            res[item.key].push(...list);
                        } else {
                            res[item.key] = list;
                        }
                    }
                }
                if(error) {
                    this.$emit("change", {error});
                } else {
                    this.$emit("change", res);
                }
            },
            handleInputConfirm(type, i) {
                let options = this.typeList.find(t => t.key == type).options;
                let item = options[i];
                if(item.value) {
                    this.$set(item, 'input', false);
                } else {
                    options.splice(i, 1);
                }
                this.dispatchChange();
            },
            addOption(j, index) {
                let item = {input: true};
                let options = this.typeList[j].options;
                options.splice(index + 1, 0, item);
            },
            removeOption(j, index) {
                let options = this.typeList[j].options;
                options.splice(index, 1);
                this.dispatchChange();
            }
        }
    }
</script>

<style lang="less">
  .chanting-set {
    display: flex;
    flex-wrap: wrap;
    line-height: 1;
  }
  .chanting-set-box {
    margin-bottom: 20px;
    width: 50%;
  }
  .chanting-set-label {
    margin-bottom: 10px;
    min-width: 90px;
    line-height: 32px;
    font-size: 16px;
    font-weight: 600;
  }
  .chanting-set-item {
    display: flex;
    align-items: center;
    height: 32px;
    &:not(:first-of-type) {
      margin-top: 6px;
    }
  }
  .chanting-input-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    height: 32px;
    font-size: 14px;
  }
  .chanting-input-label {
    margin-right: 6px;
  }
  .chanting-form-btn {
    font-size: 14px;
  }
  .set-item-option {
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 0 6px;
    height: 32px;
    border: var(--border);
    border-radius: 4px;
    line-height: 32px;
  }
  .set-option-remove {
    margin-left: 6px;
    color: @text-color-secondary;
    font-size: 12px;
  }
  .chanting-set-icon {
    font-size: 18px;
    &.chanting-option-add {
      color: @primary-color;
    }
    &.chanting-option-remove {
      color: @error-color;
    }
  }
</style>
