<template>
  <div>
    报警未处理时，每隔
    <a-input v-model="form.minute" placeholder="间隔" class="input" @change="dispatchEvent"></a-input>
    分钟提醒一次，共提醒
    <a-input v-model="form.number" placeholder="次数" class="input" @change="dispatchEvent"></a-input>
    次
  </div>
</template>

<script>
import { clone, isEmpty } from "../common/js/tool";
export default {
  name: "Remind",
  model: {
    event: "change",
  },
  props: {
    value: Object,
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      let value = this.value;
      if (value && !isEmpty(value.minute) && !isEmpty(value.number)) {
        this.form = clone(value);
      } else {
        this.form = {};
      }
    },
    dispatchEvent() {
      let form = this.form;
      if (!isEmpty(form.minute) && !isEmpty(form.number)) {
        this.$emit("change", form);
      } else {
        this.$emit("change", null);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.input {
  width: 60px;
}
</style>
