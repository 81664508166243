const state = {
    temp: null,
    tempList: null
};

// getters
const getters = {
    temp: state => state.temp,
    tempList: state => state.tempList
};

// mutations
const mutations = {
    setTemp(state, value) {
        state.temp = value;
    },
    setTempList(state, value) {
        state.tempList = value;
    }
};

const actions = {
};

export default {
    state,
    getters,
    mutations,
    actions
}
