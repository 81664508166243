<template>
  <div class="level-box flex-box" v-if="levelList && levelList.length > 0">
    <div class="level-item" v-for="level in levelList" :key="level.id">
      <span class="level-name">{{ level.levelItem.label }}（{{ level.count }}）</span>
      <a-button type="link" @click="setLevel(level)">设置</a-button>
    </div>
  </div>
</template>

<script>
import { levelOptions } from "../common/constant/safe";

export default {
  name: "LevelGroup",
  props: {
    type: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      levelOptions,
      levelList: [],
    };
  },
  created() {
    this.getLevel();
  },
  methods: {
    getLevel() {
      this.$axios({
        url: "/admin/security-set",
        method: "GET",
        params: {
          "filter[type]": this.type,
          expand: "count",
        },
      }).then((res) => {
        this.levelList = res.data?.map((item) => {
          const target = levelOptions.find((option) => option.value == item.level);
          item.levelItem = target;
          return item;
        });
      });
    },
    setLevel(level) {
      this.$router.push({ name: "SafeLevel", params: { id: level.id } });
    },
  },
};
</script>

<style lang="less" scoped>
.level-box {
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.level-item {
  flex-shrink: 0;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.level-name {
  font-size: 18px;
  margin-right: 12px;
}
</style>
