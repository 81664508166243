<template>
    <div class="tag-list">
        <a-tag color="green" closable @close="handleClose(i)" v-for="(item, i) in list" :key="i">{{item}}</a-tag>
        <a-input
            v-if="inputVisible"
            ref="input"
            type="text"
            size="small"
            :style="{ width: '78px' }"
            :value="inputValue"
            @change="handleInputChange"
            @blur="handleInputConfirm"
            @keyup.enter="handleInputConfirm"/>
        <a-tag v-else style="background: #fff; borderStyle: dashed;cursor: pointer" @click="showInput" v-show="list.length < limit"><a-icon type="plus" />新标签</a-tag>
    </div>
</template>

<script>
    export default {
        name: "tag-list",
        model: {
            event: 'change'
        },
        props: {
            value: String,
            length: {
                type: Number,
                default: 4
            },
            limit: {
                type: Number,
                default: 3
            }
        },
        created() {
            this.setList()
        },
        data() {
            return {
                list: [],
                inputVisible: false,
                inputValue: '',
            }
        },
        watch: {
            value() {
                this.setList();
            }
        },
        methods: {
            setList(){
                let value = this.value;
                if(value) {
                    this.list = value.split(",");
                } else {
                    this.list = []
                }
            },
            dispatchInput() {
                this.$emit("change", this.list.join(","))
            },
            handleClose(index) {
                this.list.splice(index, 1);
                this.dispatchInput();
            },
            showInput() {
                this.inputVisible = true;
                this.$nextTick(function() {
                    this.$refs.input.focus();
                });
            },
            handleInputChange(e) {
                this.inputValue = e.target.value;
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                let len = this.length;
                if(len > 0 && inputValue.length > len) {
                    this.$message.warning(`标签不能超过${len}个字`);
                    return;
                }
                let list = this.list;
                if (inputValue && list.indexOf(inputValue) === -1) {
                    list = [...list, inputValue];
                }
                Object.assign(this, {
                    list,
                    inputVisible: false,
                    inputValue: '',
                });
                this.dispatchInput();
            },
        }
    }
</script>

<style scoped>

</style>
